import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { login } from "../Redux/ReduxSlices/AuthReducerSlice";
import { UserProvider, useUserContext } from '../Contexts/UserContext';
import { Logger } from "ag-grid-community";
import { auth } from "../firebaseConfig"; // ✅ Correct import
import { signInWithCustomToken } from "firebase/auth";  // ✅ Use modular function

const Login = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const comingFrom = location.state?.from?.pathname || "/";
  // const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [eye, setEye] = useState(false);
  const { setUser} = useUserContext();

  const isAuth = useSelector(state => state.auth.isAuth);

  // useEffect(() => {
  //   if (isAuth) {
  //     console.log("Redirecting to home page...");
  //     navigate("/");  // ✅ Redirect to home
  //   }
  // }, [isAuth, navigate]);

  const handleEye = () => {
    setEye((prev) => !prev);
  };
  const loginHandler = async () => {
    if (email && password) {
      const params = {
        email,
        password,
      };

      dispatch(login(params, toast)).then(async (res) => {
        let userEmail = res.payload.email;
        let userRole = res.payload.role;
        console.log("Got response from dispatch login", userEmail, res.payload);

        if (res.payload.firebaseToken) {
          try {

            // ✅ Update User State
            setUser({
              firstName: 'John',
              lastName: 'Doe',
              email: userEmail,
            });

            // ✅ Show success toast
            toast({
              title: "Login Success",
              description: "You are successfully logged in",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: 'bottom-right',
            });

            // ✅ Store the ID token in localStorage instead of the custom token
            //localStorage.setItem("authHeader", JSON.stringify({ Authorization: `Bearer ${idToken}` }));
            //dispatch({ type: "LOGIN_SUCCESS", payload: {  ...res.payload,  firebaseToken: idToken } });

            console.log("Ready to navigate to", comingFrom);
            navigate(comingFrom, {replace: true});

          } catch (firebaseError) {
            console.error("Firebase Login Failed:", firebaseError);
            toast({
              title: "Authentication Error",
              description: "Failed to authenticate with Firebase",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
          }
        } else {
          toast({
            title: res.payload.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        }
      });
    }
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}>
      <Stack
        spacing={8}
        mx={"auto"}
        maxW={"lg"}
        py={12}
        px={6}
        color={"white"}
        borderRadius={"2rem"}
        bg={"#4e4b58"}
      >
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Sign in to your account</Heading>
        </Stack>
        <Box rounded={"lg"} boxShadow={"lg"} p={8}>
          <Stack spacing={4}>
            <FormControl id="username" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  type={eye ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement h={"full"}>
                  <Button variant={"ghost"} onClick={handleEye}>
                    <ViewIcon />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"#2cb2ba"}>Forgot password?</Link>
              </Stack>
              <Button
                bg={"#e4e7e4"}
                color={"black"}
                _hover={{
                  bg: "white",
                }}
                onClick={loginHandler}
              >
                Sign in
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={"center"}>
                Don't have an account?{" "}
                <RouterLink to="/signup" color={"blue.400"}>
                  Signup
                </RouterLink>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
