import React, { useState,useRef,  createRef, useEffect, useCallback } from 'react'
import { renderToString } from 'react-dom/server'
import { AgGridReact } from 'ag-grid-react'
import Hotkeys from 'react-hot-keys'
import { format } from 'date-fns'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import SplitPanel from "../../Components/splitpage/splitpanel";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { SelectDepositsFile } from '../../Modals/SelectApexDepositsFile'
import { useDocument } from '../../Contexts/DocumentContext';
import { v4 as uuid } from 'uuid'
import { GridApi } from 'ag-grid-community'
import AddTagForm from "../../Components/tags/AddTagForm";
import ApexPdfjsViewer
 from '../../Components/ApexPdfjsViewer'
import axios from 'axios';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Avatar,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Box,
    Text,
    HStack,
    useRadioGroup,
    useRadio,
    VStack,
    Container,
    Tabs,
   TabList,
   Tab,
   TabPanels,
   TabPanel,
   Editable,
   EditableInput,
   EditablePreview,
   EditableTextarea,
   Checkbox,
   Switch,
   Input,
   Textarea

  } from "@chakra-ui/react";



import {
  loadGridData,
  DATA_DEPOSIT_DOCS,
  DATA_DEPOSIT_DETAIL
} from '../../utils/ecw/EcwData';

import  apiConfig, { useLocalStorage }  from '../../apiConfig'
import splitpanel from "../../Components/splitpage/splitpanel";
import DraggableDialog from "./DaggableDialog";
import EditApexTag from "./EditApexTag";

export const defaultColDef = {
  editable: true,
  resizable: true,
  filter: true,
  floatingFilter: true,
  sortable: true,
  suppressKeyboardEvent: params => params.editing
};



//import './App.css'
// import {text as deposit_header_5555_b64 } from  './depoist_header_5555';

const useStateRef=require('react-usestateref')
 const getColumnDefs_DocumentDetail = (validaterowfunction, saverowfunction) => {

  const documentDetail = {
    docID: 0,
    docdetailID: 0,
    checkamount: 0.0,
    checknumber: '1234',
    checkdate: '2/4/2021',
    pageno: 0,
    endpageno: 0,
    PatientName: "",
    PatientId: 0,
    cardamount: 0.0,
    docname: ""
  };
  const columnList = [];

  Object.keys(documentDetail).forEach(element => {
    //console.log(element);
    switch(element) {
      case 'id' :
      case 'docname' :
      case 'endpageno' :
      case 'PatientName' :
      case 'id' :
        break
      case 'docdetailID' :
      columnList.push(  {
        colId: element + "id",
        checkboxSelection : true,
        headerCheckboxSelection: false,
        headerName: element,
        field: element,
        cellEditor: "simpleEditor",
        editable: false,
        resizable: false,
      });
      break;
      case 'pageno' :
      columnList.push(  {
        colId: element + "id",
        headerName: 'Pg',
        field: element,
        cellEditor: "simpleEditor",
        maxWidth: 100,
        editable: true,
        filter: false,
        floatingFilter: false,
        resizable: true,
        sort: 'asc'
      });
      break;
      case 'checkamount' :
      case 'checknumber' :

        case 'cardamount' :


        columnList.push(  {
          colId: element + "id",
          headerName: element,
          field: element,
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 0,
          },
          editable: true,
          resizable: true,
        });
        break;

          case 'date' :
          case 'checkdate':
            columnList.push(
          {
            headerName: "Date",
            field: element,

            cellEditor: 'agDateCellEditor',
            editable: true,
            resizable: true,
            minWidth: 150,
            filter: true,
            floatingFilter:  true,
            filterParams: {
              clearButton: true,
              suppressAndOrCondition: true,
              comparator: function(filterLocalDateAtMidnight, cellValue) {
                var dateAsString = cellValue;
                var dateParts = dateAsString.split("/");
                var cellDate = new Date(
                  Number(dateParts[2]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[0])
                );
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0;
                }
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1;
                }
              }
            }
          });
          break;
      default:
      //console.log("GOT Field: DEFAULT " + element);
    }
  }
  );


  columnList.push(
    {
      headerName: "",
      colId: "actions",
      cellRenderer: "actionsRenderer",
      btnUpdateHandler: saverowfunction,
      validaterowfunction : validaterowfunction,
      suppressRowClickSelection :true,
      editable: false,
      filter: false,
      floatingFilter: false
    }
    );

  //console.log(columnList);
  return (columnList);

};


const docudetail_row = {
  docdetailID: '',
  pageno: '',
  checkamount: '',
  checknumber: '',
  checkdate: '',
  cardamount: '',
  docname: '',
  endpageno: '',
  PatientName: ''
}

function Deposits () {

  const docGridRef = useRef();
  const docDtlGridRef = useRef();
  //const [authHeader, setauthHeader] = useLocalStorage("authHeader", {});
  const [gridApi, setGridApi] = useState(null)
  const [columnApi, setColumnApi] = useState(null)
  const [selectedDepositsFile, setSelectedDepositsFile] = useState();

  const [depositDocsData, setdepositDocsData] = useState(null)
  const [DespositDocumentcolumnDefs, setDespositDocumentcolumnDefs] = useState(null)
  const [rowData, setRowData] = useState(null)
  const [columnDefs, setColumnDefs] = useState(null)
  const [output, setOutput] = useState(
    'Hello, I am a component that listens to keydown and keyup of a'
  )
  const [docinfo, setdocinfo] = useState("")

  let [editing, setEditing] = useState(false);
  let [editingRow, setEditingRow] = useState('');
  const [addBtnRef, setaddBtnRef] = useState(null)

  const [tablValue, settablValue] = useState(1)

  const [b64DepositHeader, setb64DepositHeader] = useState(null)

  const [documentInfo, setdocumentInfo, documentInfoRef ] = useStateRef({
    id: -1,
    name: '',
    account: ''
  })


    const [anchorElTagForm, setAnchorElTagForm] = useState(null);

    const [openTagForm, setOpenTagForm] = useState(false);

    const handleOpenTagForm = (event) => {
        setOpenTagForm(true);
        setAnchorElTagForm(event.currentTarget);
    };

    const handleCloseTagForm = () => {
        console.log("TagForm closed");
        setOpenTagForm(false);
        setAnchorElTagForm(null);
    };

    const handleSubmitTag = async (tagData) => {

        console.log("Submitted Tag", tagData);

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }


        console.log("DOCUMENT CONTEXT", selectedDepositsFile);
        // let docpagenum = documentContext.docinfo.documentpage;
        // let docpath = documentContext.docinfo.documentpath;
        // console.log("DOC CONTXT", docpagenum, docpath);


        try {

            let urlPrefix = apiURL + '/exsql?dbserver=';
            let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.apexsp_mnt_tags  @argop = 'add', @tagid = null,
    @tag = '${tagData.tag}',
    @onEntityType = '${tagData.onEntityType}:${selectedDepositsFile.filename}',
    @onEntityKey = ${tagData.onEntityKey},
    @linkEntityType = '${tagData.linkEntityType}',
    @linkEntityKey = ${tagData.linkEntityKey},
    @tagDesc = '${tagData.tagDesc}',
    @tagPage = '${tagData.tagPage}',
    @tagDate = '${tagData.tagDate}';
    
       `;

            console.log("SQL to execute : ", dataURL)
            const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });

        if (res2.data) {
            console.log("GOT result from add temp Billing patient:", res2.data);
            handleCloseTagForm();

        }


        } catch (error) {
            console.error('Error adding tag:', error);

        }
    };

  const [startingPageNum, setstartingPageNum] = useState(1)
  const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
  const [currentPageNum, setCurrentPageNum] = useState();
  

  const { documentContext, updateDocinfo, updateDocPageinfo } = useDocument();
  let apiURL = apiConfig.REACT_APEX_BASE_API;
  const handleGridRowButtonClick = (data, cmdtype) => {
    if (typeof cmdtype === 'string' || cmdtype instanceof String) {
        console.log("RowButton", cmdtype, data);
    }

    if (cmdtype.toString().includes("Statement")) {
        //handleAgStatement(data);
        //setcurrentPatientName(data.Name);
        //loadGridData(DATA_APEX_PT_DOCS, {patient_id: data.PatientId}, recvGridData);
        return;
    }

    if (cmdtype.toString().includes("PtDocs")) {
        //handleAgStatement(data);
        console.log("Will Retrieve Documents", data);
        //setcurrentPatientName(data.Name);
        //loadGridData(DATA_APEX_PT_DOCS, {patient_id: data.PatientId}, recvGridData);
        return;
    }

    if (cmdtype.toString().includes("View")) {
        handleAgDocument(data);

        let args = {
            document_id: data.docid
          }
          console.log("Loading detilas for ", data);
        loadGridData(DATA_DEPOSIT_DETAIL, args, recvGridData);

        setdocinfo(`${data.docid}_${data.filename}`)
        setdocumentInfo({
          id: data.docid,
          name: data.filename,
          account: data.account
        })

        return;
    }

    if (cmdtype.toString().includes("Upload")) {
        console.log("Will Upload this document:", data);

        //uploadDocumentToServer(data);
        return;
    }
   
    //console.log("handleGridRowButtonClick:",data, cmdtype);
};

  
const [docColumnDefs, setDocColumnDefs] = useState(
    [
        { field: 'docid', filter: true , width: 150,   sortable: true,
        resizable: true,editable: true},
       
        { field: 'foldername', filter: true, width: 200, sortable: true,
        resizable: true, },
        { field: 'adoc_customname', filter: true, width: 200, sortable: true,
        resizable: true, },
        { field: 'customname', filter: true, width: 200, sortable: true,
        resizable: true, },
        { field: 'check_amount', width: 150 , sortable: true,
        resizable: true,},
        { field: 'card_amount', width: 150 , sortable: true,
        resizable: true,},
        { field: '',  cellRenderer: BtnCellRenderer,
                            cellRendererParams: {
                                clicked: handleGridRowButtonClick,
                                buttonText: 'Statement'
                            }, width: 100 ,
         },
         { field: '',  cellRenderer: BtnCellRenderer,
         cellRendererParams: {
             clicked: handleGridRowButtonClick,
             buttonText: 'PtDocs'
         }, width: 100 ,
          },


    ]
);

  const handleGridRefresh = gridName => {
    //console.log('Refresh called on: ', gridName)
    loadGridData(DATA_DEPOSIT_DOCS, {}, recvGridData)
  }

  const recvGridData = (gridName, args, gridData) => {
    console.log('ReceivedData for :', gridName, args, gridData)

    switch (gridName) {
      case DATA_DEPOSIT_DOCS:
        let data0 = gridData.filter(row => {
          if (row.filename.toUpperCase().includes('PB')) return row
          if (row.filename.toUpperCase().includes('OPMT')) return row
          if (row.foldername.toUpperCase().includes('DEPOSIT')) return row
        })

        console.log('Setting Document Rows for :', gridName, args, data0)
        setdepositDocsData(data0)
        //setDocListGridColumns();
        break;

      case DATA_DEPOSIT_DETAIL:

      //setRowData(gridData);
    
        let data2 = gridData.map(row => {
          //console.log('CHECK DATE', row.checkdate.slice(0, 10))
          let dateParts = row.checkdate.slice(0, 10).split('-')

          //new Date(row.checkdate.slice(0,10)); //.toDateString('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'}).split("/");
          let cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1] - 1),
            Number(dateParts[2])
          )

          // let cellDateChar =
          //   Number(dateParts[1]) +
          //   '/' +
          //   Number(dateParts[2]) +
          //   '/' +
          //   Number(dateParts[0])

          // console.log(
          //   'DATE: ',
          //   row.checkdate,
          //   format(cellDate, 'MM/dd/yyyy'),
          //   dateParts,
          //   cellDate
          // )
          //row.checkdate = format(cellDate, 'MM/dd/yyyy')

          row.checkdate = cellDate;

          return row
        })
        console.log("After Map setting detail data", data2);

        let data3 = data2.map(row => {row.id = uuid(); return row; });
        console.log(data3)
        setRowData(data3)
        console.log("Completed setting detail data", data2);

        break

      default:
    }
  }

  const onRowSelectExam = event => {
    let selectedNodes = event.api
      .getSelectedNodes()
      .filter(node => node.selected)
    //console.log(selectedNodes)

    let datarow = selectedNodes[0].data
    //console.log('AG Row selected', datarow)

  
    setdocumentInfo({
      id: datarow.docid,
      name: datarow.filename,
      account: datarow.account
    })

    let args = {
      document_id: datarow.docid
    }
    loadGridData(DATA_DEPOSIT_DETAIL, args, recvGridData)

    let filename = datarow.customname
    let dirpath = datarow.dirpath
    let full_file_path = `\\\\192.168.1.17\\d$\\eClinicalWorks\\ftp\\${dirpath}\\${filename}`
    
    console.log("show document:", datarow);
    if (datarow.foldername.includes("deposit")) {
      full_file_path = `\\\\192.168.1.17\\d$\\apexdocs\\deposit\\${datarow.adoc_customname}`
    }

    full_file_path = full_file_path.replace('/', '\\')

    //console.log('Starting to get deposit ', full_file_path)

    full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;
    // file_name =
    //   '\\' +
    //   file_name
    //     .replace('/mnt/scanhome', '\\192.168.1.17\\scanhome')
    //     .replace('/', '\\');
    // console.log('Getting windows file: ', file_name);

      const token = localStorage.getItem("token");
      if (!token) {
          console.error("No token found. User must log in.");
          return;
      }

      let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
      }
    
    fetch(
      encodeURI(full_file_path), { headers: authHeader }
    )
      //.then(this.handleErrors)
      .then((r) => r.blob())
      .then((blob) => {

        //this.showFilePdf(blob);
        let url = URL.createObjectURL(blob);
        let viewerUrl = encodeURIComponent(url);

        const frame_element = `/pdfjs/web/viewer.html?file=${viewerUrl} `;

        setPdfControl({ ...pdfControl, filepath: frame_element });
      });

  }

  const onRowSelectView = (datarow, gridname) => {
    //console.log('Transaction View:', gridname, datarow)
    switch (gridname) {
      case DATA_DEPOSIT_DOCS:

        setdocinfo(`${datarow.docid}_${datarow.filename}`)
        setdocumentInfo({
          id: datarow.docid,
          name: datarow.filename,
          account: datarow.account
        })

        let args = {
          document_id: datarow.docid
        }
        loadGridData(DATA_DEPOSIT_DETAIL, args, recvGridData)

        settablValue(0);

        break
      default:
    }
  }

  const formatMoney = num => {
    // const dec = num.toString().split('.');
    // console.log("FORMAT NUM", num, dec, dec.length);
    // if (dec.length == 1) {
    //   num = Number (dec[0] + ".00")
    //   return dec[0] + ".00";
    // }
    // [1];
    // const len = dec && dec.length > 2 ? dec.length : 2
    // return Number(num).toFixed(len);

    return Number(num).toFixed(2) //* 1.0;
  }

  const onFileChange = event => {
    //console.log('File to upload:', event.target.files[0])
    let file = event.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = _handleReaderLoaded.bind(this)
      reader.readAsBinaryString(file)
    }
  }

  const _handleReaderLoaded = readerEvt => {
    let binaryString = readerEvt.target.result
    console.log('File B64:', btoa(binaryString))
    setb64DepositHeader(btoa(binaryString))
  }

  const onFileSubmit = event => {
    event.preventDefault()
    console.log('Clicked Submit')
  }
  const genDefTable = () => {
    console.log("GENERATING DATA FOR PDF");
    let headarr = [['ID', 'Page #', 'PmtType', 'Check Date', 'Check# last four', 'Amount']]
    let rownum = 1;
    let rowdata1 = rowData.filter((row)=> { return Number(row.checkamount) > 0
    }).map(row => {
      console.log("Check Row", row, format(row.checkdate, 'yyyy-MM-dd'));
      let rowarr = [
        rownum++,
        row.pageno,
        'Check',
        format(row.checkdate, 'yyyy-MM-dd'),
        row.checknumber,
        Number(row.checkamount).toFixed(2)
      ]

      return rowarr
    })

    let rowdata2 = rowData.filter((row)=> { return Number(row.cardamount) > 0
    }).map(row => {
      console.log("CC Row", row, format(row.checkdate, 'yyyy-MM-dd'));
      let rowarr = [
        rownum++,
        row.pageno,
        'Card',
        format(row.checkdate, 'yyyy-MM-dd'),
        row.checknumber,
        Number(row.cardamount).toFixed(2)
      ]

      return rowarr
    })

    let rowdata3 = rowData.filter((row)=> { return Number(row.otheramount) > 0
    }).map(row => {
      console.log("OTH Row", row, format(row.checkdate, 'yyyy-MM-dd'));
      let rowarr = [
        rownum++,
        row.pageno,
        'Other',
        format(row.checkdate, 'yyyy-MM-dd'),
        row.checknumber,
        Number(row.otheramount).toFixed(2)
      ]

      return rowarr
    })

    let total_checks = 0
    let total_amount = 0.0

    for (let i = 0; i < rowdata1.length; i++) {
      total_checks += 1
      console.log("Check Amount", rowdata1[i]);
      total_amount +=Number( rowdata1[i][5])
    }

    rowdata1.push([
      '','',
      'Checks Count: ' + total_checks,
      'Total Checks Amt: ',
      Number(total_amount).toFixed(2),
      ''
    ])

    let total_cc = 0
    let total_cc_amount = 0.0

    for (let i = 0; i < rowdata2.length; i++) {
      rowdata1.push(rowdata2[i]);
      total_cc += 1
      console.log("Card amount", rowdata2[i]);
      total_cc_amount += Number(rowdata2[i][5])
    }

    rowdata1.push(['','',
      'CC Count: ' + total_cc,
      'Total CC Amt: ',
      Number(total_cc_amount).toFixed(2),
      ''
    ])
    
    let total_oth = 0
    let total_oth_amount = 0.0

    for (let i = 0; i < rowdata3.length; i++) {
      rowdata1.push(rowdata3[i]);
      total_oth += 1
      console.log("Other amount", rowdata3[i]);
      total_oth_amount += Number(rowdata3[i][5])
    }

    //rowdata1.push(rowdata2);
    rowdata1.push([
      '','',
      'Other Count: ' + total_oth,
      'Total Others Amt: ',
      Number(total_oth_amount).toFixed(2),
      ''
    ])

    console.log("DATA FOR PDF:", rowdata1); //total_amount.toFixed(2)

    let dtable = {
      head: headarr,
      body: rowdata1,
      startY: 90,
      columnStyles: { 
        0: { cellWidth: 20, halign: 'left' },
        1: { cellWidth: 20, halign: 'left' }, 
        2: { cellWidth: 40, halign: 'left' },
        3: { cellWidth: 40, halign: 'left' }, 
        4: { cellWidth: 30, halign: 'left' },        
        5: {cellWidth: 40,  halign: 'right' } },
      options: { margin: { top: 180 } }
    }

    // doc.autoTable({
    //   html: '#table',
    //   columnStyles: {
    //     0: {cellWidth: 100},
    //     1: {cellWidth: 80},
    //     2: {cellWidth: 80},
    //     // etc
    //   }
    // });

    //console.log('DocDetail Table to Print', dtable)
    return dtable
  }


  const printDepositSlipPDF = (despositSlipName) => {
    //alert("Ready to print slip" + despositSlipName);
    const doc = new jsPDF()
    //const string = renderToString(<Prints />);
    // doc.text("Hello world!", 10, 10);
    // doc.autoTable({
    //   head: [['Name', 'Email', 'Country']],
    //   body: [
    //     ['David', 'david@example.com', 'Sweden'],
    //     ['Castille', 'castille@example.com', 'Spain'],
    //     // ...
    //   ],
    // });

    // imageToBase64("/deposit_header_5555.jpg") // Path to the image
    // .then(
    //     (response) => {
    //         console.log("JPEG AS b64", response); // "cGF0aC90by9maWxlLmpwZw=="
    //     }
    // )
    // .catch(
    //     (error) => {
    //         console.log("JPEG AS b64 Error", error); // Logs an error if there was one
    //     }
    // )

    //console.log("PRINT PDF", b64DepositHeader);
    doc.moveTo(0, 10)
    doc.addImage(b64DepositHeader, 'PNG', 10, 10, 190, 75)
    doc.text(
      '--------------------------------------------------------------------------',
      30,
      88
    )
    //doc.moveTo (200,200);

    doc.setFontSize(24)
    //console.log("Adding Account number to the document");

    if (despositSlipName.toLocaleLowerCase().includes("pncperchk")) {
      doc.text("4 9 4 4 4 5 0 6 0 5", 18, 36)
      doc.setFontSize(12)
      doc.text("SIVA &HEMA KORLAKUNTA", 30, 54)
    }else if (despositSlipName.toLocaleLowerCase().includes("hunterchk")) {
      doc.text("4 9 5 8 4 1 0 1 2 9", 18, 36)
      doc.setFontSize(12)
      doc.text("Hunter Sharks Realty LLC", 30, 54)
    }else if (despositSlipName.toLocaleLowerCase().includes("ntrchk")) {
      doc.text("4 9 5 2 4 0 9 9 9 5", 18, 36)
      doc.setFontSize(12)
      doc.text("North Tarrant Realty LLC", 30, 54)
    }else if (despositSlipName.toLocaleLowerCase().includes("sneharealty")) {
      doc.text("4 9 4 6 8 9 0 8 0 7", 18, 36)
      doc.setFontSize(12)
      doc.text("Sneha Realty LLC", 30, 54)
    }else if (despositSlipName.toLocaleLowerCase().includes("pncapexmain")) {
      alert("Ready with accnum 3158");
      doc.text("4 9 4 5 8 5 3 1 5 8", 18, 36)
      doc.setFontSize(12)
      doc.text("HEMA L KORLAKUNTA MD PA", 30, 54)
    }else {
      doc.text("4 9 4 5 8 5 3 1 5 8", 18, 36)
      doc.setFontSize(12)
      doc.text("HEMA L KORLAKUNTA MD PA", 30, 54)
    }
 
    //doc.text(documentInfo.account.replace('.pdf', ''), 18, 55)

    //doc.setFontSize(12)
    //doc.text("HEMA L KORLAKUNTA MD PA", 30, 54)

    doc.setFontSize(12)
    doc.text("X", 19, 60)

    doc.setFontSize(12)
    doc.text("X", 46, 65)


    //rowdata1.push([total_checks, "", total_amount.toFixed(2), ""]);

   // doc.autoTable(genDefTable())
    //let file = doc.output('blob');

    //doc.addImage(b64DepositHeader, 'PNG', 15, 40, 175, 75);

    doc.autoTable(genDefTable())
    let base = doc.output('datauri')
    setpdfToDisplayURL(base);
    //doc.save("a4.pdf");

    //alert('c:\\' + despositSlipName + ".pdf");
    doc.save( despositSlipName + ".pdf");
    console.log("Saved to: " ,  despositSlipName , ".pdf");
  }



  const printPDF = tbl => {
    const doc = new jsPDF()
    //const string = renderToString(<Prints />);
    // doc.text("Hello world!", 10, 10);
    // doc.autoTable({
    //   head: [['Name', 'Email', 'Country']],
    //   body: [
    //     ['David', 'david@example.com', 'Sweden'],
    //     ['Castille', 'castille@example.com', 'Spain'],
    //     // ...
    //   ],
    // });

    // imageToBase64("/deposit_header_5555.jpg") // Path to the image
    // .then(
    //     (response) => {
    //         console.log("JPEG AS b64", response); // "cGF0aC90by9maWxlLmpwZw=="
    //     }
    // )
    // .catch(
    //     (error) => {
    //         console.log("JPEG AS b64 Error", error); // Logs an error if there was one
    //     }
    // )

    console.log("PRINT PDF", documentInfo);
    doc.moveTo(0, 10)
    doc.addImage(b64DepositHeader, 'PNG', 10, 10, 190, 75)
    doc.text(
      '--------------------------------------------------------------------------',
      30,
      88
    )
    //doc.moveTo (200,200);

    doc.setFontSize(24)
    //console.log("Adding Account number to the document");

    if (!documentInfo.account || documentInfo.toLocaleLowerCase().includes("pncperchk")) {
      doc.text("4 9 4 4 4 5 0 6 0 5", 18, 36)
      doc.setFontSize(12)
      doc.text("SIVA &HEMA KORLAKUNTA", 30, 54)
    }else if (documentInfo.account.toLocaleLowerCase().includes("hunterchk")) {
      doc.text("4 9 5 8 4 1 0 1 2 9", 18, 36)
      doc.setFontSize(12)
      doc.text("Hunter Sharks Realty LLC", 30, 54)
    }else if (documentInfo.account.toLocaleLowerCase().includes("ntrchk")) {
      doc.text("4 9 5 2 4 0 9 9 9 5", 18, 36)
      doc.setFontSize(12)
      doc.text("North Tarrant Realty LLC", 30, 54)
    }else if (documentInfo.account.toLocaleLowerCase().includes("sneharealty")) {
      doc.text("4 9 4 6 8 9 0 8 0 7", 18, 36)
      doc.setFontSize(12)
      doc.text("Sneha Realty LLC", 30, 54)
    }else if (documentInfo.account.toLocaleLowerCase().includes("pncapexmain")) {
      doc.text("4 9 4 5 8 5 3 1 5 8", 18, 36)
      doc.setFontSize(12)
      doc.text("HEMA L KORLAKUNTA MD PA", 30, 54)
    }else {
      doc.text("4 9 4 5 8 5 3 1 5 8", 18, 36)
      doc.setFontSize(12)
      doc.text("HEMA L KORLAKUNTA MD PA", 30, 54)
    }
 
    //doc.text(documentInfo.account.replace('.pdf', ''), 18, 55)

    //doc.setFontSize(12)
    //doc.text("HEMA L KORLAKUNTA MD PA", 30, 54)

    doc.setFontSize(12)
    doc.text("X", 19, 60)

    doc.setFontSize(12)
    doc.text("X", 46, 65)

    let total_amount = 0.0

    for (let i = 0; i < rowData.length; i++) {
      total_amount += rowData[i].checkamount
    }
    doc.setFontSize(30)
    doc.text(total_amount.toFixed(2), 154, 68)
    doc.setFontSize(16)
    doc.text(documentInfo.name.replace('.pdf', '') + '  ' + documentInfo.account, 32, 11)

    //rowdata1.push([total_checks, "", total_amount.toFixed(2), ""]);

    //console.log("BEFORE genDefTable");
    doc.autoTable(genDefTable())
    let base = doc.output('datauri')
    setpdfToDisplayURL(base);
    doc.save("a4.pdf");

        //let file = doc.output('blob');

    //doc.addImage(b64DepositHeader, 'PNG', 15, 40, 175, 75);
    //loadblobPDF(base)
    //  PDFViewerApplication.open(pdfData);


  }

  const base64ToUint8Array = base64 => {
    var raw = atob(base64)
    var uint8Array = new Uint8Array(raw.length)
    for (var i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i)
    }
    return uint8Array
  }

  const loadblobPDF = blob => {
    //console.log('Ready to Loadin PDF from base64')

    try {
      let iframePdf = pdfControl.iframeRef.current.contentWindow
      //console.log(iframePdf);
      //console.log(iframePdf.PDFViewerApplication);
      if (iframePdf !== undefined) {
        let iframePdf2 = iframePdf.contentWindow
        //iframePdf.PDFViewerApplication.open();
        iframePdf.PDFViewerApplication.open(blob)
        setTimeout(() => {
          // Wait for PDFViewerApplication object to exist
          iframePdf.PDFViewerApplication.setTitleUsingUrl(`${docinfo.replaceAll(" ", "-")}.pdf`);
        }, 10);
        //iframePdf.PDFViewerApplication.toolbar.openFile.click(); // iframePdf.PDFViewerApplication
        //iframePdf.print();
      }
    } catch (error) {}

    // ipcRenderer.send('show-file', 'ping')
  }

  const docDetailRowValidate = row => {

    let datarow = row;
    let data = row;
    console.log(
      'GOT IN VALIDATE ON ROW:',
      getPDFPage(),
      data
    )

    if (!data.checkamount) {
        if (!data.cardamount) { 
            console.log("Checkamout or Cardamount should be present", data, data.checkamount, data.cardamount,  data.checknumber, data.checkdate);
            return false
        }else{

        }
    }
    if (  !data.checknumber ||  data.checkdate == undefined 
    ) {
      console.log("check number and date need to be defined", data, data.checkamount, data.checknumber, data.checkdate);
      return false
    }

    if (!data.pageno || data.pageno == undefined) {
      data['pageno'] = currentPageNum
      console.log('Setting page to: ', data['pageno'], data)
    }


    return true
  }

  const docDetailRowDelete = row => {

    let datarow = row;
    let data = row;
    console.log('GOT IN DATASAVERFUNC DELETE ON ROW:', documentInfo, getPDFPage(), data)


    let detailid = 0

    console.log("CURRENT DOCUMENT: ",documentInfoRef.current, data);

    try {
      detailid = datarow.docdetailID
    } catch (err) {
      console.log('Inserting a row for document: ', documentInfoRef.current.id)
      detailid = -1
    }

    let rowchange_SQL = ''
    let currentPage = getPDFPage()

    let checkamt =  0 ;
    let cardamt =  0 ;

    if (detailid == undefined || detailid == -1) {
      console.log('Can not delete row. It is not in DB yet. a row', detailid);
      
    } else {
      console.log('Updating a row', detailid)
      rowchange_SQL = `
      delete apex..apex_documentdetail  
      where docdetailID = ${detailid}

  `
    }

    console.log(rowchange_SQL)

    const endpoint_document_detail = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=set%20rowcount%20200%20 ${rowchange_SQL}  `

      const token = localStorage.getItem("token");
      if (!token) {
          console.error("No token found. User must log in.");
          return;
      }

      let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
          "Content-Type": "application/json"
      }

    fetch(endpoint_document_detail, { headers: authHeader })
      .then(response => {
        if (response.status !== 200) {
          return this.setState({
            placeholder: 'Something went wrong in getting data'
          })
        }
        return response
      })
      .then(data => {
        //console.log("___________________");
        console.log('RETURN from insert/update', data)
        // let dframe = data["frame0"];
        // let myObj = JSON.parse(dframe);
        // let data2 = myObj["rows"];
        // data2.map(row=> {
        //   console.log("CHECK DATE", row.checkdate.slice(0,10));
        //   // let dateParts =  row.checkdate.slice(0,10).split("-");

        //   // //new Date(row.checkdate.slice(0,10)); //.toDateString('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'}).split("/");
        //   // let cellDate = new Date(
        //   //   Number(dateParts[0]),
        //   //   Number(dateParts[1] - 1) ,
        //   //   Number(dateParts[2])
        //   // );

        //   // let  cellDateChar =  Number(dateParts[1]) + "/" + Number(dateParts[2]) + "/" + Number(dateParts[0]);

        //   // console.log("DATE: ", row.checkdate,  format(cellDate, 'MM/dd/yyyy'), dateParts, cellDate);
        //   // row.checkdate =  format(cellDate, 'MM/dd/yyyy');

        // });
      })
  }

  const docDetailRowSave = row => {

    let datarow = row;
    let data = row;

    console.log('GOT IN DATASAVERFUNC UPDATE ON ROW:', documentInfo, getPDFPage(), data)

   // if (1> 0) return;

    let detailid = 0

    console.log("CURRENT DOCUMENT: ",documentInfoRef.current, data);

    try {
      detailid = datarow.docdetailID
    } catch (err) {
      console.log('Inserting a row for document: ', documentInfoRef.current.id)
      detailid = -1
    }

    let rowchange_SQL = ''
    let currentPage = currentPageNum

    let checkamt =  0 ;
    let cardamt =  0 ;

    checkamt = datarow.checkamount ? datarow.checkamount : 0 ;
    cardamt = datarow.cardamount ?  datarow.cardamount : 0 ;
    let checkDate =  format(datarow.checkdate, 'yyyy-MM-dd');

    if (detailid == undefined || detailid == -1) {
      console.log('Inserting a row', detailid)
      detailid = -1;

       checkamt = datarow.checkamount ? datarow.checkamount : 0 ;
       cardamt = datarow.cardamount ?  datarow.cardamount : 0 ;



      rowchange_SQL = `

      declare @apexdocid int
      select @apexdocid =  apexdoc.docid from apex..apex_document apexdoc where ecwdocid = ${documentInfoRef.current.id}

  insert into apex..apex_documentdetail (
    docID ,
    pageno ,
  endpageno,
    detailType,
    checkamount,
    cardamount,
    otheramount ,
    checkdate ,
    checknumber ,
    PatientId,
      PatientName )
  values (
    @apexdocid, ${currentPage}, ${currentPage}, '', ${checkamt} , ${cardamt} ,
    0, '${checkDate}', '${datarow.checknumber}', 29623 , dbo.PatientName(29623) )

  `
    } else {
      console.log('Updating a row', detailid)
      rowchange_SQL = `

      update apex..apex_documentdetail set
       pageno = ${datarow.pageno},
       endpageno = ${datarow.pageno},
       detailType = '',
       checkamount = ${checkamt},
       cardamount = ${cardamt},
       checkdate = '${checkDate}',
       checknumber = '${datarow.checknumber}'

      where docdetailID = ${detailid}


  `
    }

    console.log(rowchange_SQL)

    const endpoint_document_detail = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=set%20rowcount%20200%20 ${rowchange_SQL}  `

      const token = localStorage.getItem("token");
      if (!token) {
          console.error("No token found. User must log in.");
          return;
      }

      let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
          "Content-Type": "application/json"
      }

    fetch(endpoint_document_detail, { headers: authHeader })
      .then(response => {
        if (response.status !== 200) {
          return this.setState({
            placeholder: 'Something went wrong in getting data'
          })
        }
        return response
      })
      .then(data => {
        //console.log("___________________");
        console.log('RETURN from insert/update', data)
        // let dframe = data["frame0"];
        // let myObj = JSON.parse(dframe);
        // let data2 = myObj["rows"];
        // data2.map(row=> {
        //   console.log("CHECK DATE", row.checkdate.slice(0,10));
        //   // let dateParts =  row.checkdate.slice(0,10).split("-");

        //   // //new Date(row.checkdate.slice(0,10)); //.toDateString('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'}).split("/");
        //   // let cellDate = new Date(
        //   //   Number(dateParts[0]),
        //   //   Number(dateParts[1] - 1) ,
        //   //   Number(dateParts[2])
        //   // );

        //   // let  cellDateChar =  Number(dateParts[1]) + "/" + Number(dateParts[2]) + "/" + Number(dateParts[0]);

        //   // console.log("DATE: ", row.checkdate,  format(cellDate, 'MM/dd/yyyy'), dateParts, cellDate);
        //   // row.checkdate =  format(cellDate, 'MM/dd/yyyy');

        // });
      })
  }

  const [pdfControl, setPdfControl] = useState({
    filepath:
      '/pdfjs/web/viewer.html?file=21219_B17EA5D0-D1C2-4E82-A913-3F9B2E9D80EB.pdf',
    iframeRef: createRef()
  })

  //apiPyURL + "/getecwfile?filename=mobiledoc/2021/02052021/21219_9E9A66B7-D0BD-45E6-A390-EF07226DCB4D.pdf
  //    filepath: "/pdfjs/web/viewer.html?file=/PB162_DELETE.pdf",

//   const frameworkComponents = {
//     simpleEditor: GridComponents.SimpleEditor,
//     asyncValidationEditor: GridComponents.AsyncValidationEditor,
//     autoCompleteEditor: GridComponents.AutoCompleteEditor,
//     agDateInput: GridComponents.MyDatePicker,
//     dateEditor: GridComponents.DateEditor,
//     actionsRenderer: GridComponents.ActionsRenderer,
//     addRowStatusBar: GridComponents.AddRowStatusBar
//   }

//   const loadFile = () => {
//     console.log('LOAD File Called:', documentInfo)

//     getPDFFileName()

//     const load_detail_sql = `
//  select
//  docID ,
//  docdetailID,
//  pageno ,
// endpageno,
//  detailType,
//  checkamount,
//  cardamount,
//  otheramount ,
//  checkdate ,
//  checknumber ,
//  PatientId,
//    PatientName,
//  DOS_start ,
//  DOS_end ,
//  cptcode ,
//  cptunits ,
//  encId ,
//  invoiceid ,
//  invcptid ,
//  delFlag,
//  insuranceid ,
//  notes,
// pmtrow,
// pmtprocessed,
// pmtprocesseddt,
// pmtreceipt
// from apex..apex_documentdetail where docid = ${documentInfo.id}
// `

//     const endpoint_document_detail = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=set rowcount 0  ${load_detail_sql}  `

//     console.log("Retrieving document detail: ", endpoint_document_detail);
//     fetch(endpoint_document_detail, authHeader)
//       .then(response => {
//         if (response.status !== 200) {
//           return this.setState({
//             placeholder: 'Something went wrong in getting data'
//           })
//         }
//         return response.json()
//       })
//       .then(data => {
//         //console.log("___________________");
//         console.log(data)
//         let dframe = data['frame0']
//         let myObj = JSON.parse(dframe)
//         let data2 = myObj['rows']
//         data2.map(row => {
//           console.log('CHECK DATE', row.checkdate.slice(0, 10))
//           let dateParts = row.checkdate.slice(0, 10).split('-')

//           //new Date(row.checkdate.slice(0,10)); //.toDateString('en-GB', {year: 'numeric', month: '2-digit', day: '2-digit'}).split("/");
//           let cellDate = new Date(
//             Number(dateParts[0]),
//             Number(dateParts[1] - 1),
//             Number(dateParts[2])
//           )

//           let cellDateChar =
//             Number(dateParts[1]) +
//             '/' +
//             Number(dateParts[2]) +
//             '/' +
//             Number(dateParts[0])

//           console.log(
//             'DATE: ',
//             row.checkdate,
//             format(cellDate, 'MM/dd/yyyy'),
//             dateParts,
//             cellDate
//           )
//           row.checkdate = format(cellDate, 'MM/dd/yyyy')
//         })
//         console.log(data2)

//         data2.forEach(row => (row.id = uuid()))

//         let colDEFs1 = getColumnDefs_DocumentDetail(
//           datavalidatefunc_athletedata,
//           datasaverfunc_athletdata
//         )
//         //let colDEFs2 = getColumnDefs_DocumentDetail(data[0]);

//         //console.log("DEF1", colDEFs1);
//         //console.log("DEF2", JSON.parse(colDEFs2));
//         setColumnDefs(colDEFs1)
//         setRowData(data2)
//         //setColumnDefs(JSON.parse(colDEFs2));

//         //documentDetail = documentDetail.json();

//         // console.log("SETTING DATA", documentDetail);
//         // let rowdata = documentDetail;
//         // rowdata = rowdata.forEach(row => (row.id = uuid()));
//         // console.log("SETTING DATA 2", rowdata);
//         // getColumnDefs_DocumentDetail(rowdata[0]);
//         //setRowData(rowdata);
//         gridApi.sizeColumnsToFit()

//         //console.log(data2);
//         // this.setState(
//         //   {
//         //     invoice_cpt: data2,
//         //     series_locations: [],
//         //     series_ciga_jobs: [],
//         //     loaded: true
//         //   },
//         //   () => {
//         //     //console.log("Changed state", this.state.series.length);
//         //     this.invoiceGridElement.current.changeGridData(this.state.invoice_cpt,getColumnsList(data2[0]) );

//         //   }
//         // );
//       })
//   }


const deleteRow = (data, api, force = false) => {

  let confirm = true;
  if (!force) {
      confirm = window.confirm(`are you sure you want to delete this row: ${JSON.stringify(data)})`)
  }
  if (confirm) {
    console.log("DELETING ROW", data);
    docDetailRowDelete(data);
      api.applyTransaction({ remove: [data] });
      api.refreshCells({ force: true });

  }
};

  const detailRowSelected = event => {
    console.log("Row Selected Detail row", editing, editingRow, event.rowIndex, documentInfo, docDtlGridRef.current.api.getFocusedCell().column.getColId());
    
    const selectedNode = docDtlGridRef.current.api.getRowNode(event.rowIndex);
    if (selectedNode) {
    	const selectedRowId = selectedNode.id;
      console.log("Row Selected Detail row curent info",selectedNode);
    }


    if (editingRow !== '' && editingRow !== event.rowIndex) {
      console.log("DIFFERENT ROW CLICKED.  NOT THE EDITING ROW");
      //docDtlGridRef.current.api.stopEditing(true);
      setEditing(false);
      setEditingRow('');
      return;
    }

    let colid = docDtlGridRef.current.api.getFocusedCell().column.getColId();

    if (colid == 0 || colid == 1) {
    if (colid == 0 ) {
      if (editing) {

        docDtlGridRef.current.api.stopEditing(false);
        if (!docDetailRowValidate(event.data)) {
          docDtlGridRef.current.api.stopEditing(false);
          const selectedNode = docDtlGridRef.current.api.getRowNode(event.rowIndex);
          console.log("Validation Failed", selectedNode);
          setEditing(false);
          setEditingRow('');
          return;
        }

        docDetailRowSave(event.data);
        setEditing(false);
        setEditingRow('');
        docDtlGridRef.current.api.stopEditing(false);
        console.log("UPDATE");
      }else {
        setEditing(true);
        setEditingRow(event.rowIndex);
        docDtlGridRef.current.api.startEditingCell({
              rowIndex: event.rowIndex,
              colKey: 'checkdate',
            });
        
        console.log("NO ACTION");
      }
    }

    if (colid == 1 ) {
      if (editing) {

        setEditing(false);
        setEditingRow('');
        docDtlGridRef.current.api.stopEditing(true);
        console.log("CANCEL");
      }else {
        console.log("DELETE");
        deleteRow(event.data, docDtlGridRef.current.api, false);
      }
    }

  //   if (docDtlGridRef.current.api.getFocusedCell().column.getColId() == 0) {

  //     if (editing) {
  //       setEditing(false);
  //       docDtlGridRef.current.api.stopEditing();
  //       let col = docDtlGridRef.current.api.getSelectedRows()[0];;
  //       let curnode = docDtlGridRef.current.api.getRowNode(col.id);
  //       docDtlGridRef.current.api.getFocusedCell().column.colDef.cellRendererParams.buttonText = "Edit";
  //       console.log("Current selected row node:", col, curnode);
  //       //docDtlGridRef.current.api.redrawRows({curnode});
  //       console.log("Row editing done for this row.", docDtlGridRef.current.api.getFocusedCell().column);
  //     }else {
  //       setEditing(true);
  //       let col = docDtlGridRef.current.api.getSelectedRows()[0];;
  //       let curnode = docDtlGridRef.current.api.getRowNode(col.id);
  //       docDtlGridRef.current.api.getFocusedCell().column.colDef.cellRendererParams.buttonText = "Update";
  //       console.log("Current selected row node:", col, curnode);
  //       //docDtlGridRef.current.api.redrawRows({curnode});
  //       console.log("Row editing starting for this row.", docDtlGridRef.current.api.getFocusedCell().column.colDef.cellRendererParams.buttonText);
  //       docDtlGridRef.current.api.startEditingCell({
  //     rowIndex: event.rowIndex,
  //     colKey: 'checkdate',
  //   });
  // }

    return;
  }
    let inEditing = docDtlGridRef.current.api.getEditingCells().length > 0 ? true : false

    if (inEditing && docDtlGridRef.current.api.getFocusedCell().column.getColId() != 'actions') {
      console.log(
        'NO ACTION Clicked column',
        docDtlGridRef.current.api.getEditingCells(),
        docDtlGridRef.current.api.getFocusedCell().column.getColId(),
        docDtlGridRef.current.api.getFocusedCell().column
      )
      //console.log("Clicked column ACTIONS",  gridApi.getEditingCells());
      return
    }

    if (!inEditing && docDtlGridRef.current.api.getFocusedCell().column.getColId() == 'actions') {
      console.log(
        'NO ACTION Clicked column',
        docDtlGridRef.current.api.getEditingCells(),
        docDtlGridRef.current.api.getFocusedCell().column.getColId(),
        docDtlGridRef.current.api.getFocusedCell().column
      )
      //console.log("Clicked column ACTIONS",  gridApi.getEditingCells());
      return
    }

    //|| gridApi.getFocusedCell().column.getColId() == 'actions'

    console.log(
      'Clicked column',
      docDtlGridRef.current.api.getEditingCells(),
      docDtlGridRef.current.api.getFocusedCell().column.getColId(),
      docDtlGridRef.current.api.getFocusedCell().column
    )

    // if(this.gridApi.getFocusedCell().column.getColId() === 'number'){
    //     this.goToItem(params.data.id);
    //     return;
    // }

    //Other row logic

    //console.log("Clicked column", gridApi.getFocusedCell().column.getColId(), gridApi.getFocusedCell().column );

    let row = event.node.data
    console.log('deselected', event.node.data)
    let pageno = row.pageno

    console.log('MOVE to page: ?? ', pageno)
    //setCurrentPageNum(pageno);
    setstartingPageNum(pageno);

    //gotoPDFPage(pageno)

    // if(event.node.isSelected()){
    //   console.log("deselected", event.node.data );
    // }
    // else {
    //   console.log("selected, add", event.node.data);
    // }

    // window.alert(
    //   'row  selected = ' + event.node.isSelected()
    // );
    // console.log("ROW SELECTED", event.node.data);
  }

  function onGridReady (params) {
    // setGridApi(params.api)
    // setColumnApi(params.columnApi)
    docDtlGridRef.current.api.sizeColumnsToFit();
  }

  const onKeyUp = (keyName, e, handle) => {
    console.log('test:onKeyUp', e, handle)
    setOutput(`onKeyUp ${keyName}`)
  }
  const onKeyDown = (keyName, e, handle) => {
    console.log('test:onKeyDown', keyName, e, handle)
    setOutput(`onKeyDown ${keyName}`)
    addBtnRef.current.click()
  }

  const getDepositHeader = async ()=> {

    const get_deposit_hdr_sql = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=select deposit_header from apexfin.dbo.account where id = 77  `

      const token = localStorage.getItem("token");
      if (!token) {
          console.error("No token found. User must log in.");
          return;
      }

      let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
          "Content-Type": "application/json"
      }

    console.log("Retrieving Deposit Header: ", get_deposit_hdr_sql);
    fetch(get_deposit_hdr_sql, { headers: authHeader })
      .then(response => {
        if (response.status !== 200) {
          return this.setState({
            placeholder: 'Something went wrong in getting data'
          })
        }
        return response.json()
      })
      .then(data => {
        //console.log("___________________");
        let dframe = data['frame0']
        let myObj = JSON.parse(dframe)
        let data2 = myObj['rows']
        //console.log('DepositHeader', data2[0].deposit_header )
       
        setb64DepositHeader(data2[0].deposit_header );
      })

  
  }

  const setDocListGridColumns = () => {
    
//     setDespositDocumentcolumnDefs(['docid', 'adoc_customname', 'filename', 'card_amount', 'check_amount', 'customname' ].map((row) => {
//       return {
//         colId: `${row}_id`,
//         checkboxSelection : true,
//         headerCheckboxSelection: false,
//         headerName: `${row}`,
//         field: `${row}`,
//         cellEditor: "simpleEditor",
//         editable: false,
//         resizable: false,
//       }
//   })
//   );

  }

  useEffect(() => {

    getDepositHeader();
    // setaddBtnRef(createRef())
    loadGridData(DATA_DEPOSIT_DOCS, {}, recvGridData)
    //setDocListGridColumns();
      
//     ])
//     adoc_customname: "2022-08-28 PB185"
// adoc_dirpath: "d:/ApexDocs/"
// card_amount: 112.08
// check_amount: 3706.41
// customname: "21219_59268DBF-A4A1-4FF2-BA0E-6AD54FF9615B.pdf"
// depositbatchid: null
// dirpath: "mobiledoc/2022/08282022"
// docid: 3604
// filename: "2022-08-28 PB185"
// foldername: "EOB"

    // let colDEFs1 = getColumnDefs_DocumentDetail(
    //   datavalidatefunc_athletedata,
    //   datasaverfunc_athletdata
    // )
    // setColumnDefs(colDEFs1)


  }, [])

  const openPDF = () => {
    console.log('Renderer sending message to main')

    try {
      let iframePdf = pdfControl.iframeRef.current.contentWindow
      console.log(iframePdf)
      console.log(iframePdf.PDFViewerApplication)
      if (iframePdf !== undefined) {
        let iframePdf2 = iframePdf.contentWindow
        //iframePdf.PDFViewerApplication.open();
        console.log(iframePdf.PDFViewerApplication.url)
        //iframePdf.PDFViewerApplication.toolbar.openFile.click(); // iframePdf.PDFViewerApplication
        //iframePdf.print();
      }
    } catch (error) {}

    // ipcRenderer.send('show-file', 'ping')
  }

  const getPDFPage = () => {
    //console.log("Called Get Page Number");

    try {
      let iframePdf = pdfControl.iframeRef.current.contentWindow
      //console.log(iframePdf);
      return iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber
      if (iframePdf !== undefined) {
        let iframePdf2 = iframePdf.contentWindow
        //iframePdf.print();
      }
    } catch (error) {
      return 1
    }
  }

  const getPDFFileName = () => {
    console.log('LOAD File Called:', documentInfo)

    const load_detail_sql = `
 select  customName from apex..apex_document where docid = ${documentInfo.id}
`

    const endpoint_document_detail = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=set%20rowcount%20200%20 ${load_detail_sql}  `

      const token = localStorage.getItem("token");
      if (!token) {
          console.error("No token found. User must log in.");
          return;
      }

      let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
          "Content-Type": "application/json"
      }

    fetch(endpoint_document_detail, { headers: authHeader })
      .then(response => {
        if (response.status !== 200) {
          return this.setState({
            placeholder: 'Something went wrong in getting data'
          })
        }
        return response.json()
      })
      .then(data => {
        //console.log("___________________");
        console.log(data)
        let dframe = data['frame0']
        let myObj = JSON.parse(dframe)
        let data2 = myObj['rows']
        console.log('FILE NAME', data2[0].customName)
        setdocumentInfo({ ...documentInfo, name: data2[0].customName ,           account:  data2[0].account})
        console.log('DOCINFO', documentInfo)
      })
  }

  const addDepositRow = () => {
    console.log('Called addDepositRow')
    addBtnRef.current.click();

    // let iframePdf = pdfControl.iframeRef.current.contentWindow
    // if (iframePdf !== undefined) {
    //   console.log(iframePdf)
    //   console.log(
    //     (iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber += 1)
    //   )

    //   iframePdf.PDFViewerApplication.pdfDocument.getPage(1).then(pdfPage => {
    //     pdfPage.getTextContent().then(data => {
    //       console.log(data)
    //     })
    //   })
    // }
  }

  const nextPDFPage = () => {
    console.log('Called Next Page Number')
    setstartingPageNum(startingPageNum + 1);

    // let iframePdf = pdfControl.iframeRef.current.contentWindow
    // if (iframePdf !== undefined) {
    //   console.log(iframePdf)
    //   console.log(
    //     (iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber += 1)
    //   )

    //   addDepositRow();

    //   iframePdf.PDFViewerApplication.pdfDocument.getPage(1).then(pdfPage => {
    //     pdfPage.getTextContent().then(data => {
    //       console.log(data)
    //     })
    //   })
    // }
  }

  const gotoPDFPage = pageno => {
    // try {
    //   let iframePdf = pdfControl.iframeRef.current.contentWindow
    //   if (iframePdf !== undefined) {
    //     console.log(iframePdf)

    //     if (
    //       iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber != pageno
    //     ) {
    //       console.log('Called Next Page Number', pageno)

    //       console.log(
    //         (iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber = pageno)
    //       )
    //     }

    //     //iframePdf.PDFViewerApplication.pdfDocument.getPage(1).then(pdfPage => { pdfPage.getTextContent().then(data => { console.log(data); }); });
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
  }

  const prevPDFPage = () => {
    console.log('Called Previous Page Number')
    setstartingPageNum(startingPageNum - 1);

    // let iframePdf = pdfControl.iframeRef.current.contentWindow
    // if (iframePdf !== undefined) {
    //   console.log(iframePdf)
    //   console.log(
    //     (iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber -= 1)
    //   )

    //   iframePdf.PDFViewerApplication.pdfDocument.getPage(1).then(pdfPage => {
    //     pdfPage.getTextContent().then(data => {
    //       console.log(data)
    //     })
    //   })
    // }
  }

  const upload_EOB = () => {
    alert("Upload EOB");
  }

  const upload_OPMT = () => {
    alert("Upload OPMT");
  }

  const handleTabChange = (event, newValue) => {
    //console.log("CHANGING TAB TO: " , newValue);
    //console.log("STATUS NOW:", depositDocsData, rowData);
    settablValue(newValue)
  }

  const a11yProps = index => {
    //console.log("AllyProps", index);
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const handleAgDocument = (data) => {
    console.log("HandleAgDocument", data);

    if (!data.fileData) {

      if (data.filename.includes('ecwupload') || data.filename.includes('ecwsrv')) {
        handleFolderDocument(data.fileName);
        return;
      }
      handleEcwDocument(data);
      return;
    }

  const reader = new FileReader();

reader.addEventListener(
"load",
() => {
  // convert image file to base64 string
  //preview.src = reader.result;

  setpdfToDisplayURL(reader.result);
  setstartingPageNum(startingPageNum);
  // pdfViewerRef.current.UI.loadDocument(reader.result, {
  //   extension: 'pdf'
  // });

},
false
);

if (data.fileData) {
reader.readAsDataURL(data.fileData);
}


  };

  const handleEcwDocument = (data) => {

    let documentFilePath = `${data.dirpath}/${data.filename}` ;
   
    let full_file_path = `\\\\192.168.1.17\\d$\\eClinicalWorks\\ftp\\${documentFilePath}`;
    full_file_path = full_file_path.replace('/', '\\');

    full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;

    console.log('Starting to get Linq File', full_file_path);

      const token = localStorage.getItem("token");
      if (!token) {
          console.error("No token found. User must log in.");
          return;
      }

      let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
          "Access-Control-Allow-Origin": "*"
      }


      fetch(
      encodeURI(full_file_path), {
        headers: authHeader,}
    )
     // .then(this.handleErrors)
      .then((r) => r.blob())
      .then((blob) => {

        let url = URL.createObjectURL(blob);

        setpdfToDisplayURL(url);
        setstartingPageNum(1);
        // pdfViewerRef.current.UI.loadDocument(url, {
        //   extension: 'pdf'
        // });
     
        // pdfViewerRef.current.UI.loadDocument(url, {
        //   extension: 'pdf'
        // });
    
      });
  
  };


  const handleFolderDocument = (documentFilePath) => {

   // let documentFilePath = `${data.dirpath}/${data.fileName}` ;
      const token = localStorage.getItem("token");
      if (!token) {
          console.error("No token found. User must log in.");
          return;
      }

      let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
          "Access-Control-Allow-Origin": "*"
      }


      let full_file_path = `${documentFilePath}`;
    full_file_path = full_file_path.replace('/', '\\');

    full_file_path = `${apiURL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;

    console.log('Starting to get Linq File', full_file_path);

    fetch(
      encodeURI(full_file_path), {
        headers: authHeader,}
    )
     // .then(this.handleErrors)
      .then((r) => r.blob())
      .then((blob) => {

        let url = URL.createObjectURL(blob);

        setpdfToDisplayURL(url);
        //setstartingPageNum(1);

        // pdfViewerRef.current.UI.loadDocument(url, {
        //   extension: 'pdf'
        // });
     
        // pdfViewerRef.current.UI.loadDocument(url, {
        //   extension: 'pdf'
        // });
    
      });
  
  };

const handleModelButtonClick = (data, cmdtype) => {
  if (typeof cmdtype === 'string' || cmdtype instanceof String) {
      console.log(" Model RowButton", cmdtype, data);
  }


  if (cmdtype.toString().includes("Attach")) {
      console.log("Attach Button clicked:", data);

      //uploadDocumentToServer(data);
      return;
  }
 
  //console.log("handleGridRowButtonClick:",data, cmdtype);
};


const onRowDoubleClicked = useCallback(() => {
  console.log("Row Double Clicked");
  // gridRef.current.api.setFocusedCell(gridRef.current.api.getRowNodeId, 'checkamount');
  // gridRef.current.api.startEditingCell({
  //   rowIndex: gridRef.current.api.getRowNodeId,
  //   colKey: 'checkamount',
  // });
}, []);
const onBtStopEditing = useCallback(() => {
   // docDtlGridRef.current.api.stopEditing();
  }, []);

  const onBtStartEditing = useCallback((event) => {
   // console.log("Start Editiing button", event);
   // gridRef.current.api.setFocusedCell(gridRef.current.api.getRowNodeId(),'checkdate');
    //let currentRowIndex = gridRef.current.api.getFocusedCell().rowIndex;
   /// console.log("Focused cell row index:", currentRowIndex);
    // gridRef.current.api.startEditingCell({
    //   rowIndex: currentRowIndex,
    //   colKey: 'checkdate',
    // });
  }, []);

  const onCellValueChanged = useCallback((event) => {
    console.log(
      'onCellValueChanged: ' + event.colDef.field + ' = ' + event.newValue
    );
  }, []);

  const onRowValueChanged = useCallback((event) => {
    var data = event.data;
    console.log( 'onRowValueChanged: (' , data);

  }, []);

  // const onRowEditingStarted = useCallback((event) => {
  //   setEditing(true);

  // }, []);
    
  // const onRowEditingStopped = useCallback((event) => {
  //   setEditing(false);

  // }, []);


  const addRow = useCallback((event) => {

    let id = uuid();
    let emptyRow = { id };
    let rowcount = docDtlGridRef.current.api.getDisplayedRowCount();
    //console.log("Adding new row at index:", rowcount);

    //gridRef.current.api.updateRowData({ add: [emptyRow], addIndex: rowcount });

    docDtlGridRef.current.api.applyTransaction({ add: [emptyRow], addIndex: parseInt(rowcount)  });


    let node = docDtlGridRef.current.api.getRowNode(id);
    console.log("Added new row at index:", node, rowcount);
    docDtlGridRef.current.api.startEditingCell({
        rowIndex:  parseInt(rowcount) - 1,
        colKey: 'checkdate',
      });
    // gridRef.current.api.ensureIndexVisible(node.rowIndex);


    //   docDtlGridRef.current.api.startEditingCell({
    //     rowIndex: rowcount,
    //     colKey: 'checkamount'
    //   });


    // docDtlGridRef.current.api.ensureIndexVisible(rowcount);
    // docDtlGridRef.current.api.setFocusedCell(id,'checkamount');

//     let firstEditCol = docDtlGridRef.current.columnApi.getAllDisplayedColumns()[0];
// /////
// docDtlGridRef.current.api.ensureColumnVisible(firstEditCol );

// // sets focus into the first grid cell
// docDtlGridRef.current.api.setFocusedCell(rowcount, firstEditCol);  


  //   applyTransaction({
  //     add: [myNewRowData],
  //     addIndex: 0
  // });


    // let id = uuid();
    // let emptyRow = { id };

    // //let emptyrow = {};

    // console.log("Adding detail row");
    // //let emptyRow = { id };
    // //let rowcount = gridRef.current.api.getDisplayedRowCount();
    // gridRef.current.api.applyTransaction({ add: [emptyRow] });
    
    //console.log("Adding new row at index:", rowcount);
    //gridRef.current.api.updateRowData({ add: [emptyRow], addIndex: rowcount });
    //let node = gridRef.current.api.getRowNode(id);
    //console.log("Added new row at index:", node.rowIndex);
    //gridRef.current.api.ensureIndexVisible(node.rowIndex);

    // setTimeout(() => {
    //   gridRef.current.api.startEditingCell({
    //     rowIndex: node.rowIndex,
    //     colKey: props.columnApi.getAllColumns()[0].colId
    //   });
    // }, 300);
  }, []);

  const onCellClicked1 = (params)  =>{
    // Handle click event for action cells
    if (params.column.colId === "action" && params.event.target.dataset.action) {
      let action = params.event.target.dataset.action;

      // if (action === "edit") {
      //   params.api.startEditingCell({
      //     rowIndex: params.node.rowIndex,
      //     // gets the first columnKey
      //     colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
      //   });
      // }

      // if (action === "delete") {
      //   params.api.applyTransaction({
      //     remove: [params.node.data]
      //   });
      // }

      // if (action === "update") {
      //   params.api.stopEditing(false);
      // }

      // if (action === "cancel") {
      //   params.api.stopEditing(true);
      // }
    }
  };

  const onRowEditingStarted1 = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }
  const onRowEditingStopped1 = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  
  const onSetCurrentPageNum = (pagenum) => {
    console.log("Currnet Page Numnber Changed", selectedDepositsFile, documentContext, pagenum);
    setCurrentPageNum(pagenum);

    if (selectedDepositsFile) {
    updateDocinfo({
      ecwdocid: null,
      apexdocid: null,
      documentpath : `${selectedDepositsFile.dirpath}/${selectedDepositsFile.filename}`,
      documentpage: currentPageNum,
      pagecount : 1
    });
  }else {
    updateDocPageinfo(currentPageNum);
  }

   

  }

  const onSelectedDepositsFile = (data) => {
    console.log("select billing Sheet File in documanager", data);
    setSelectedDepositsFile(data);
    updateDocinfo({
      ecwdocid: null,
      apexdocid: null,
      documentpath : `${data.dirpath}/${data.filename}`,
      documentpage: currentPageNum,
      pagecount : 1
    });

    setdocumentInfo({
        id: data.docID,
        name: data.filename,
        account: data.account
      })

    let args = {
        document_id: data.docID
      }
    loadGridData(DATA_DEPOSIT_DETAIL, args, recvGridData)
    handleAgDocument(data, apiURL, setpdfToDisplayURL, setstartingPageNum);
  }


  return (
      <SplitPanel split="horizontal">
          <div style={{width: '100%'}}>
              <VStack>
                  <div style={{width: '100%', marginLeft: '25px', marginTop: '5px'}}>
                      <button
                          variant={editing ? "outlined" : "contained"}
                          color="primary"
                          onClick={addRow}
                          disabled={editing}
                      >
                          Add Row
                      </button>
                  </div>
                      <div className="ag-theme-alpine"
                           style={{width: '100%', height: '80vh'}}>
                          <AgGridReact
                              ref={docDtlGridRef}
                              columnDefs={[

                                  {
                                      field: 'docdetailID', filter: false, width: 75, sortable: true,
                                      resizable: true, editable: false
                                  },

                                  {
                                      field: 'checkamount',
                                      filter: false,
                                      width: 100,
                                      sortable: true,
                                      editable: true,
                                      cellEditor: 'agNumberCellEditor ',
                                      singleClickEdit: true,
                                      resizable: true,
                                      headerName: "ChkAmt",
                                  },
                                  {
                                      field: 'checknumber',
                                      width: 100,
                                      editable: true,
                                      cellEditor: 'agNumberCellEditor ',
                                      singleClickEdit: true,
                                      resizable: true,
                                      headerName: "ChkNum",
                                      filter: false
                                  },
                                  {
                                      field: 'checkdate',
                                      width: 120,
                                      sortable: true,
                                      editable: true,
                                      filter: false,
                                      singleClickEdit: true,
                                      cellEditor: 'agDateCellEditor',
                                      cellEditorPopup: false,
                                      valueFormatter: (params) => {
                                          if (!params.value) {
                                              return '';
                                          }
                                          const month = params.value.getMonth() + 1;
                                          const day = params.value.getDate();
                                          return `${params.value.getFullYear()}-${
                                              month < 10 ? '0' + month : month
                                          }-${day < 10 ? '0' + day : day}`;
                                      },

                                      resizable: true,
                                  },
                                  {
                                      field: 'cardamount',
                                      filter: true,
                                      width: 100,
                                      sortable: true,
                                      editable: true,
                                      cellEditor: 'agNumberCellEditor ',
                                      singleClickEdit: true,
                                      resizable: true,
                                  },
                                  {
                                      field: 'pageno', filter: true, width: 100, sortable: true, editable: true,
                                      resizable: true,
                                  },
                                  // { headerName: "action",
                                  // minWidth: 150,
                                  // cellRenderer: actionCellRenderer,
                                  // editable: false,
                                  // colId: "action"
                                  // },
                                  {
                                      field: '', cellRenderer: BtnCellRenderer,
                                      cellRendererParams: {
                                          clicked: onBtStartEditing,
                                          buttonText: 'Edit',
                                      }, width: 100, editable: false, filter: false,
                                  },
                                  {
                                      field: '', cellRenderer: BtnCellRenderer, editable: false, filter: false,
                                      cellRendererParams: {
                                          clicked: onBtStopEditing,
                                          buttonText: 'Delete'
                                      }, width: 100,
                                  },


                              ]}

                              rowData={rowData}
                              getRowNodeId={data => data.id}
                              onGridReady={onGridReady}
                              editType={'fullRow'}
                              editable={true}
                              onCellValueChanged={onCellValueChanged}
                              onRowValueChanged={onRowValueChanged}
                              rowSelection='single'
                              onRowClicked={detailRowSelected}
                              onRowEditingStopped={onRowEditingStopped1}
                              onRowEditingStarted={onRowEditingStarted1}
                              onCellClicked={onCellClicked1}
                              suppressClickEdit
                          />
                      </div>

                      <div>
                          <Hotkeys
                              keyName='shift+a,alt+s'
                              onKeyDown={onKeyDown.bind(this)}
                              onKeyUp={onKeyUp.bind(this)}
                          ></Hotkeys>
                          <Hotkeys
                              keyName='alt+q'
                              onKeyDown={addDepositRow.bind(this)}
                          ></Hotkeys>
                          <Hotkeys
                              keyName='alt+a'
                              onKeyDown={nextPDFPage.bind(this)}
                          ></Hotkeys>
                          <Hotkeys
                              keyName='alt+z'
                              onKeyDown={prevPDFPage.bind(this)}
                          ></Hotkeys>
                          <Button onClick={printPDF}>printPDF</Button>
                          <Button onClick={() => printDepositSlipPDF('pncapexmain')}>MDPA</Button>
                          <Button onClick={() => printDepositSlipPDF('ntrchk')}>NTR</Button>
                          <Button onClick={() => printDepositSlipPDF('ntrchk')}>SRealty</Button>
                          <Button onClick={() => printDepositSlipPDF('pncperchk')}>PerChk</Button>
                          <div style={{width: '100%', display: 'inline'}}> {docinfo} </div>
                      </div>
              </VStack>
          </div>
          <div style={{width: '100%'}}>
              <VStack>
                  <HStack>
                  <div style={{width: '100%', marginLeft: '25px', marginTop: '5px'}}>
                      <SelectDepositsFile selectedBillingSheetFile={selectedDepositsFile}
                                          setSelectedBillingSheetFile={onSelectedDepositsFile}/>

                  </div>
                      <Box p={2}>
                          <Button variant="contained" color="primary" onClick={handleOpenTagForm}>
                              Add New Tag
                          </Button>
                          <EditApexTag open={openTagForm} handleClose={handleCloseTagForm} handleSubmit={handleSubmitTag} anchorEl={anchorElTagForm} pageNum={currentPageNum}  />
                      </Box>
                    </HStack>
                      <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum}
                                       setCurrentPageNum={onSetCurrentPageNum}/>
              </VStack>
          </div>

      </SplitPanel>
      
      

)
}

export default Deposits;


// function getStorageValue(key, defaultValue) {
//     // getting stored value
//     if (typeof window !== "undefined") {
//       const saved = localStorage.getItem(key);
//       const initial = saved !== null ? JSON.parse(saved) : defaultValue;
//       return initial;
//     }
//   }
  
//   export const useLocalStorage = (key, defaultValue) => {
//     const [value, setValue] = useState(() => {
//       return getStorageValue(key, defaultValue);
//     });
  
//     useEffect(() => {
//       // storing input name
//       localStorage.setItem(key, JSON.stringify(value));
//     }, [key, value]);
  
//     return [value, setValue];
//   };

  // BtnCellRenderer.jsx

function  BtnCellRenderer (props) {
    //let cellformattedValue = ` ${props.data.price}`;

    let editingCells = props.api.getEditingCells();
    // checks if the rowIndex matches in at least one of the editing cells
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === props.node.rowIndex;
    });

    let btnText = props.buttonText;
    if (btnText === "Edit") {
      if (isCurrentRowEditing) {
        btnText = "Save";
      }else {
        btnText = "Edit";
      }

    } 

    if (btnText === "Delete") {
      if (isCurrentRowEditing) {
        btnText = "Cancel";
      }else {
        btnText = "Delete";
      }

    } 

    const btnClickedHandler = () => {
        props.clicked(props.data, props.buttonText);
    }
      return (
        <>
        <div><button onClick={btnClickedHandler}>{btnText}</button> </div>
        
        </>
      )
  }
  
  function  ModelCellRenderer (props) {
    //let cellformattedValue = ` ${props.data.price}`;

    const btnClickedHandler = () => {
        props.clicked(props.data, props.buttonText);
    }
    console.log("MODEL PARAMS INPUT", props.data);
      return (
       <>
            {/* <AttachDoc buttonText="{props.buttonText}" data={props.data}>
          Put in whatever text you want here
        </AttachDoc> */}
       </>
        
      )
  }

  function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
  }
  
  function isNumericKey(event) {
    var charStr = event.key;
    return isCharNumeric(charStr);
  }
  // Implementing ICellEditorComp
  class NumericCellEditor {
    focusAfterAttached;
    eInput;
    cancelBeforeStart;
    // gets called once before the renderer is used
    init(params) {
      // we only want to highlight this cell if it started the edit, it is possible
      // another cell in this row started the edit
      this.focusAfterAttached = params.cellStartedEdit;
  
      // create the cell
      this.eInput = document.createElement('input');
      this.eInput.classList.add('ag-input-field-input');
      this.eInput.style.width = '100%';
      this.eInput.style.height = '100%';
      this.eInput.value = isCharNumeric(params.eventKey)
        ? params.eventKey
        : params.value;
  
      this.eInput.addEventListener('keydown', (event) => {
        if (!event.key || event.key.length !== 1 || isNumericKey(event)) {
          return;
        }
        this.eInput.focus();
  
        if (event.preventDefault) event.preventDefault();
      });
    }
  
    // gets called once when grid ready to insert the element
    getGui() {
      return this.eInput;
    }
  
    // focus and select can be done after the gui is attached
    afterGuiAttached() {
      // only focus after attached if this cell started the edit
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    }
  
    // returns the new value after editing
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    }
  
    // example - will reject the number if it contains the value 007
    // - not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      return false;
    }
  
    // returns the new value after editing
    getValue() {
      return this.eInput.value;
    }
  
    // when we tab onto this editor, we want to focus the contents
    focusIn() {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log('NumericCellEditor.focusIn()');
    }
  
    // when we tab out of the editor, this gets called
    focusOut() {
      // but we don't care, we just want to print it for demo purposes
      console.log('NumericCellEditor.focusOut()');
    }
  }


  const actionCellRenderer = (params)  =>{
    let eGui = document.createElement("div");
  
    let editingCells = params.api.getEditingCells();
    // checks if the rowIndex matches in at least one of the editing cells
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === params.node.rowIndex;
    });
  
    if (isCurrentRowEditing) {
      eGui.innerHTML = `
  <button  class="action-button update"  data-action="update"> update  </button>
  <button  class="action-button cancel"  data-action="cancel" > cancel </button>
  `;
    } else {
      eGui.innerHTML = `
  <button class="action-button edit"  data-action="edit" > edit  </button>
  <button class="action-button delete" data-action="delete" > delete </button>
  `;
    }
  
    return eGui;
  }