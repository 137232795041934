import React, { useState } from 'react';
import { Box, Typography, Button, TextField, FormControl, FormControlLabel, RadioGroup, Radio, IconButton } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import EcwPatientForm from "./EcwPatientForm";
import {Selectfacility} from "../../Modals/SelectApexFacility";
import {SelectPOS} from "../../Modals/SelectPOS";
import SelectClaimDiagnosis from "../../Modals/SelectClaimDiagnosis";
import SelectEcwPcp from "../../Modals/SelectEcwPcp";
import apiConfig from "../../apiConfig";
import axios, {AxiosResponse} from 'axios';
import {usePatient} from "../../Contexts/PatientContext";

const TestEcwPatientForm: React.FC = () => {
    const [selectedPatientRow, setSelectedPatientRow] = useState<any>(null);
    const [formData, setFormData] = useState({
        service_date: '',
        admit_date: '',
        discharge_date: '',
        facility: '',
        pos: '',
        diagnosis: '',
        refPhysician: '',
        comments: '',
        doctorId: 122
    });
    const [selectedFacility, setSelectedFacility] = useState<any | undefined | null>(undefined);
    const [selectedPOS, setSelectedPOS] = useState<any | undefined | null>(undefined);
    const [responseMessage, setResponseMessage] = useState<string | null>(null);
    const [responseStatus, setResponseStatus] = useState<'success' | 'error' | null>(null);
    const [encounterId, setEncounterId] = useState<number | null>(null);
    const [selectedDx, setSelectedDx] = useState("");
    const [showDiagnosisSelector, setShowDiagnosisSelector] = useState(false);
    const [selectedPcp, setSelectedPcp] = useState<any|undefined|null>(undefined);
    const { patientList, addPatientRow, getNewPatients, refresh } = usePatient();

    const handlePatientChange = (patient: any) => {
        setSelectedPatientRow(patient);
        setResponseMessage(null);
        setResponseStatus(null);
        setSelectedPcp(null);
        setSelectedDx("");
        setFormData({
            service_date: '',
            admit_date: '',
            discharge_date: '',
            facility: '',
            pos: '',
            diagnosis: '',
            refPhysician: '',
            comments: '',
            doctorId: 122
        });
    };

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectedPcp = (pcpselected: any) => {
        console.log("Selected PCP: ", pcpselected);
        setSelectedPcp(pcpselected);
        // setFormData((prevData) => ({
        //     ...prevData,
        //     refPhysician: pcpselected,
        // }));
    }

    const handleCopyDate = () => {
        setFormData((prevData) => {
            const serviceDate = prevData.service_date;

            // Date validation regex
            const datePattern = /^\d{4}-\d{2}-\d{2}$/;

            // Validate the service date before copying
            if (!datePattern.test(serviceDate)) {
                return prevData;
            }

            return {
                ...prevData,
                admit_date: serviceDate,
                discharge_date: serviceDate,
            };
        });
    };

    const handleSelectedDiagnosis = (dxString: string) => {
        setSelectedDx(dxString);
        // setFormData((prevData) => ({
        //     ...prevData,
        //     diagnosis: dxString,
        // }));


        console.log("Selected Diagnosis Codes:", dxString);
    };

    const formatDate = (dateString: string) => {
        if (!dateString) return '';
        const dateObj = new Date(`${dateString}T00:00:00Z`); // Force UTC interpretation
        if (isNaN(dateObj.getTime())) return ''; // Invalid date
        const year = dateObj.getUTCFullYear();
        const month = (`0${dateObj.getUTCMonth() + 1}`).slice(-2);
        const day = (`0${dateObj.getUTCDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const getScalarFromResultSet = (response: AxiosResponse<any, any>) => {
        if (response.data) {
            const dframe0: string = response.data.frame0;
            const myObj0: any = dframe0 && JSON.parse(dframe0);
            const gridData0: any[] = myObj0 && myObj0.rows;

            const dframe1: string = response.data.frame1;
            const myObj1: any = dframe1 && JSON.parse(dframe1);
            const gridData1: any[] = myObj1 && myObj1.rows;

            const apexEncounterId = gridData0 && gridData0.length > 0 ? gridData0[0].apex_encounter_id : null;
            const patid = gridData1 && gridData1.length > 0 ? gridData1[0].patid : null;

            return { apexEncounterId, patid };
        }
        return null;
    };


    const handleSaveEchoEcwClaim = async () => {

        try {
            const apiURL = apiConfig.REACT_APEX_BASE_API;
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found. User must log in.");
                return;
            }

            let authHeader =  {
                "Authorization": `Bearer ${token}`,  // ✅ Attach token
                "Content-Type": "application/json"
            }
            const urlPrefix = `${apiURL}/exsql?dbserver=`;
            const dataURL = `apex&sqltype=customSQL&sqltext= declare @patid int set @patid = ${selectedPatientRow.PatientId}

            if exists (select 'x' from mobiledoc..users usr where uid = @patid) begin 
            exec rc.billsp_create_ecw_outoff_enc_1cpt 
            @patient_id = @patid ,
            @facility  = '${selectedFacility? selectedFacility.value: ''}',
            @argpos  = '${selectedPOS? selectedPOS.value : '21'}',
            @servicedt = '${formData.service_date}',
            @admitdt = '${formData.admit_date}',
            @dischargedt = '${formData.discharge_date}',
            @dxcodes = '${selectedDx}',
            @argrefprid = ${selectedPcp? selectedPcp.doctorid : null},
            @comment = '${formData.comments}',
            @cpt_code1 = '93306',
            @cpt_mod1 = '26',
            @argcptunits = 1

            select @patid as patid
            end`;

            console.log("Creating ECHO to ECW: ", dataURL);
            //if (1>0) return;

            const response = await axios.get(urlPrefix + dataURL, {headers: authHeader});

            if (!response.data) {
                setResponseMessage('Failed to save patient.');
                setResponseStatus('error');
            } else {
                const result = getScalarFromResultSet(response);
                if (result) {
                    //setPatientId(result.patid);
                    setEncounterId(result.apexEncounterId);
                    setResponseMessage(`Patient ID: ${result.patid}, Encounter ID: ${result.apexEncounterId}`);
                    setResponseStatus('success');
                } else {
                    setResponseMessage('Failed to save patient.');
                    setResponseStatus('error');
                }
            }
        } catch (error: any) {
            setResponseMessage(error.message || 'Failed to save patient.');
            setResponseStatus('error');
        }

    }


    const handleSaveBaylorEKGClaim = async () => {

        try {
            const apiURL = apiConfig.REACT_APEX_BASE_API;

            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found. User must log in.");
                return;
            }

            let authHeader =  {
                "Authorization": `Bearer ${token}`,  // ✅ Attach token
                "Content-Type": "application/json"
            }

            const urlPrefix = `${apiURL}/exsql?dbserver=`;
            const dataURL = `apex&sqltype=customSQL&sqltext= declare @patid int set @patid = ${selectedPatientRow.PatientId}

            if exists (select 'x' from mobiledoc..users usr where uid = @patid) begin 
            exec rc.billsp_create_ecw_outoff_enc_1cpt 
            @patient_id = @patid ,
            @facility  = 'HHBD',
            @argpos = '21',
            @servicedt = '${formData.service_date}',
            @admitdt = '${formData.admit_date}',
            @dischargedt = '${formData.discharge_date}',
            @dxcodes = 'Z01.818_387299',
            @argrefprid = ${selectedPcp? selectedPcp.doctorid : null},
            @comment = '${formData.comments}',
            @cpt_code1 = '93010',
            @cpt_mod1 = '',
            @argcptunits = 1
            
            select @patid as patid
            end`;

            console.log("Creating Baylor EKG: ", dataURL);
            //if (1>0) return;

            const response = await axios.get(urlPrefix + dataURL, {headers: authHeader});

            if (!response.data) {
                setResponseMessage('Failed to save patient.');
                setResponseStatus('error');
            } else {
                const result = getScalarFromResultSet(response);
                if (result) {
                    //setPatientId(result.patid);
                    setEncounterId(result.apexEncounterId);
                    setResponseMessage(`Patient ID: ${result.patid}, Encounter ID: ${result.apexEncounterId}`);
                    setResponseStatus('success');
                } else {
                    setResponseMessage('Failed to save patient.');
                    setResponseStatus('error');
                }
            }
        } catch (error: any) {
            setResponseMessage(error.message || 'Failed to save patient.');
            setResponseStatus('error');
        }

    }

    const handleSaveEchoTempClaim = async () => {
        try {
            const apiURL = apiConfig.REACT_APEX_BASE_API;

            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found. User must log in.");
                return;
            }

            let authHeader =  {
                "Authorization": `Bearer ${token}`,  // ✅ Attach token
                "Content-Type": "application/json"
            }

            const urlPrefix = `${apiURL}/exsql?dbserver=`;
            const dataURL = `
                apex&sqltype=customSQL&sqltext= declare @patid int set @patid = ${selectedPatientRow.PatientId} exec apex.rc.billsp_manage_tclaim
                @arg_op = 'E',
                @tclaim_id = null,
                @patient_id = @patid ,
                @facility = '${selectedFacility? selectedFacility.value: ''}',
                @argpos = '${selectedPOS? selectedPOS.value : ''}',
                @servicedt = '${formData.service_date}',
                @admitdt = '${formData.admit_date}',
                @dischargedt = '${formData.discharge_date}',
                @dxcodes = '${selectedDx}',
                @argrefprid = ${selectedPcp? selectedPcp.doctorid : 122},
                @comment = '${formData.comments}',
                @cpt_code1 = '93306',
                @cpt_mod1 = '26',
                @argcptunits = 1
            `;

            console.log("Creating ECHO to TEMP: ", dataURL);
            if (1>0) return;
            const response = await axios.get(`${urlPrefix}${dataURL}`, { headers: authHeader });

            if (response.data) {
                const encounterId = response.data.encounter_id || null;
                setEncounterId(encounterId);
                setResponseMessage('Echo claim created successfully.');
                setResponseStatus('success');
            } else {
                setResponseMessage('Failed to create echo claim.');
                setResponseStatus('error');
            }
        } catch (error: any) {
            setResponseMessage(error.message || 'Failed to create echo claim.');
            setResponseStatus('error');
        }
    };

    const clearForm = () => {
        setFormData({
            service_date: '',
            admit_date: '',
            discharge_date: '',
            facility: '',
            pos: '',
            diagnosis: '',
            refPhysician: '',
            comments: '',
            doctorId: 122
        });
        setResponseMessage(null);
        setResponseStatus(null);
        setEncounterId(null);
        setSelectedPcp(null);
        setSelectedDx("");
    };

    return (
        <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', padding: 4 }}>
            <Typography variant="h5" gutterBottom>
                Test ECW Patient Form
            </Typography>

            <Box mb={2} p={2} bgcolor="white" boxShadow={2} borderRadius={2}>
                {selectedPatientRow ? (
                    <Typography variant="body1">
                        {`${selectedPatientRow.Name?.split(', ')[1]} ${selectedPatientRow.Name?.split(', ')[0]} [${selectedPatientRow.PatientId}][${formatDate(selectedPatientRow.DOB)}] `}
                    </Typography>
                ) : (
                    <Typography variant="body1">
                        No patient selected or created yet.
                    </Typography>
                )}
            </Box>

            <Box mb={3} p={2} bgcolor="white" boxShadow={2} borderRadius={2}>
                <EcwPatientForm
                    onPatientChange={handlePatientChange}
                    patientContextID={selectedPatientRow?.PatientId}
                />
            </Box>

            {selectedPatientRow && (
                <Box p={2} bgcolor="white" boxShadow={2} borderRadius={2}>
                    <Box display="flex" gap={2} mb={2}>
                        <TextField
                            label="Service Date"
                            name="service_date"
                            type="date"
                            fullWidth
                            value={formData.service_date}
                            onChange={handleTextFieldChange}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Button
                            onClick={handleCopyDate}
                            color="primary"
                            variant="outlined"
                            sx={{ minWidth: '40px' }}
                        >
                            =
                        </Button>
                        <TextField
                            label="Admit Date"
                            name="admit_date"
                            type="date"
                            fullWidth
                            value={formData.admit_date}
                            onChange={handleTextFieldChange}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Discharge Date"
                            name="discharge_date"
                            type="date"
                            fullWidth
                            value={formData.discharge_date}
                            onChange={handleTextFieldChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>

                    <Box display="flex" gap={2} mb={2}>
                        <Selectfacility selectedfacility={selectedFacility} setSelectedfacility={setSelectedFacility} />
                        <SelectPOS selectedPOS={selectedPOS} setSelectedPOS={setSelectedPOS} />
                        <Box display="flex" alignItems="center" gap={1}>
                            <SelectEcwPcp selectedPcp={selectedPcp} setSelectedPcp={handleSelectedPcp} />
                            {selectedPcp && (
                                <IconButton onClick={() => setSelectedPcp(null)} size="small">
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>

                    <Box display="flex" alignItems="center" gap={2} mb={2}>
                        <Button variant="outlined" onClick={() => setShowDiagnosisSelector(!showDiagnosisSelector)}>
                            {showDiagnosisSelector ? "-" : "+"}
                        </Button>
                        <Typography variant="body1">
                            Selected Diagnosis: {selectedDx || "None"}
                        </Typography>
                    </Box>

                    <TextField
                        label="Comments"
                        name="comments"
                        variant="outlined"
                        fullWidth
                        value={formData.comments}
                        onChange={handleTextFieldChange}
                        size="small"
                        margin="normal"
                        sx={{ mb: 2 }}
                    />

                    <Box display="flex" flexWrap="wrap" alignItems="center" gap={2} mt={2}>
                        <Button onClick={clearForm} color="secondary" variant="contained">
                            Clear Form
                        </Button>
                        <Button onClick={handleSaveEchoEcwClaim} color="primary" variant="contained">
                            Create Echo eCW Claim
                        </Button>
                        <Button onClick={handleSaveEchoTempClaim} color="primary" variant="contained">
                            Create Echo Temp Claim
                        </Button>
                        <Button onClick={handleSaveBaylorEKGClaim} color="primary" variant="contained">
                            Create Baylor EKG Claim
                        </Button>
                        {responseStatus && (
                            <Typography variant="body2" color={responseStatus === 'success' ? 'success.main' : 'error'} sx={{ ml: 2 }}>
                                {responseMessage}
                                {responseStatus === 'success' && ` Encounter ID: ${encounterId}`}
                            </Typography>
                        )}
                    </Box>

                    {showDiagnosisSelector && (
                        <Box mt={2}>
                            <SelectClaimDiagnosis
                                patientId={selectedPatientRow?.PatientId}
                                selectedDiagnosis={handleSelectedDiagnosis}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );

};

export default TestEcwPatientForm;
