import React, {useEffect, useState} from 'react';
import { Editor } from "@tinymce/tinymce-react";
import SplitPage from '../Components/splitpage/splitpage';
import SplitPanel from '../Components/splitpage/splitpanel';
import { Checkbox, Chip, FormControlLabel, Switch } from "@mui/material";
import SampleDataGrid from "../Components/supportadmin/samplegridusage";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import StyledInput from "../Components/ui-elements/styledinput";
import SubmitButton from "../Components/ui-elements/submitbutton";
import moment from "moment";

import apiConfig from "../apiConfig";
import axios from "axios";
import {HStack, Input, VStack} from "@chakra-ui/react";
import {SelectEcwPatient} from "../Modals/SelectApexPatient";
import CommentsSection from "../Components/dialogs/CommentsSection";


let tabs = ['Access Audit', 'Cache Monitor', 'Job Agents', 'Receivers', 'Servers', 'Summary'];

const ClaimsQueueViewPage = (props) => {

    const [queueTabs, setQueueTabs] = useState([]);
    const [currentTab, setCurrentTab] = useState('');
    const [claimsData, setClaimsData] = useState([]);
    const [claimsAssignedToMap, setClaimsAssignedToMap] = useState();
    const [selectedNames, setSelectedNames] = useState(new Set());
    const [positiveBalanceOnly, setPositiveBalanceOnly] = useState(true)
    const [uniqueClaimsArray,setUniqueClaimsArray] = useState([]);
    const [selectedClaimID, setSelectedClaimID] = useState(0);
    const [selectedPatId, setSelectedPatId] = useState(0);
    const [commentValue, setcommentValue] = useState("");
    const [claimFolowups, setClaimFollowups] = useState([]);
    const [currentClaimCPTs, setCurrentClaimCPTs] = useState([]);
    const [quickFilter, setQuickFilter] = useState('');
    const [selectedPatientId, setSelectedPatientId] = useState();
    const [enableFilters, setenableFilters] = useState(false)
    const [recentlyActive, setRecentlyActive] = useState(false)
    const [selectedpatientOnly, setSelectedpatientOnly] = useState(false)
    const [startDate, setStartDate ] = useState( moment(new Date()).add(-24, 'h').format("yyyy-MM-DDTHH:mm:ss"));
    const [endDate, setEndDate ] = useState( moment(new Date()).add(-24, 'h').format("yyyy-MM-DDTHH:mm:ss"));

    const initFocusRef = React.useRef();

    let apiURL = apiConfig.REACT_APEX_BASE_API;
    const claimsGridRef = React.useRef();
    const cptGridRef = React.useRef();

    function processClaims(data) {
        // Create a map to store queueCode information
        const queueMap = new Map();

        // Initialize variables for "All Claims" summary
        const allClaimsSet = new Set();
        let totalRows = 0;

        // Iterate over each item in the JSON array
        data.forEach(item => {
            const queueCode = item.queueDesc;

            // Add the claim_id to the all claims set and increment total rows
            allClaimsSet.add(item.claim_id);
            totalRows++;

            // If queueCode is null or undefined, skip this item
            if (!queueCode) {
                return;
            }

            // If the queueCode is not already in the map, initialize it
            if (!queueMap.has(queueCode)) {
                queueMap.set(queueCode, {
                    queueCode: queueCode,
                    claimCount: new Set(), // To track unique claim_ids
                    cptCount: 0
                });
            }

            // Get the queueCode entry from the map
            const queueEntry = queueMap.get(queueCode);

            // Add the claim_id to the set of unique claim_ids
            queueEntry.claimCount.add(item.claim_id);

            // Increment the cptCount for this queueCode
            queueEntry.cptCount += 1;
        });

        // Convert the map to an array of objects
        const result = Array.from(queueMap.values()).map(entry => ({
            queueCode: entry.queueCode,
            queueIndex: entry.queueIndex,
            claimCount: entry.claimCount.size, // Get the size of the set
            cptCount: entry.cptCount
        }));

        // Add the "All Claims" summary entry
        result.push({
            queueCode: "All Claims",
            queueIndex: 99,
            claimCount: allClaimsSet.size, // Get the size of the set for all claims
            cptCount: totalRows // Total rows in the input
        });

        return result;
    }

    const getRecentClaimsData = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }


        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.recent_claims    `;
        if (selectedpatientOnly && selectedPatientId ) {
            dataURL += ` where patid = ${selectedPatientId.PatientId} `        }

        console.log("getRcent Claims SQL :", dataURL);
        const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
        if (res2.data) {
            console.log("GOT Patient browse Data:", res2.data);
            let dframe = res2.data['frame0'];
            console.log("Next Frame:", dframe);
            let myObj = JSON.parse(dframe);
            console.log(myObj);
            let gridData = myObj['rows'];
            console.log("Got Recent Claims:", gridData);

            setClaimsData(gridData);

        }

    }

    const buildAssignedToMap = (argClaimNotes) => {
        return argClaimNotes.reduce((map, row) => {
            const assignedToName = row.assignedtoname;
            if (assignedToName) { // Check if assignedToName is defined
                if (!map[assignedToName]) {
                    map[assignedToName] = [];
                }
                map[assignedToName].push(row.claim_id);
            }
            return map;
        }, {});
    };

    const getClaimsForSelectedNames = (argslectedNames) => {
        let selectedClaims = new Set();
        argslectedNames.forEach(name => {
            if (claimsAssignedToMap[name]) {
                claimsAssignedToMap[name].forEach(claimId => selectedClaims.add(claimId));
            }
        });
        console.log("All among selected claims", selectedClaims);
        return selectedClaims;
    };

    const getClaimNotes = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }

        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select claim_id, assignedtoname, modifieddate from rc.ecw_claim_assignments asn where modifieddate > dateadd(dd, -365, getdate())
                  and exists (select 'x' from mobiledoc..edi_invoice inv where id = asn.claim_id and inv.ServiceDt > dateadd(dd, -365, getdate()) ) `;

        console.log("getClaim NotesSQL :", dataURL);
        const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
        if (res2.data) {
            console.log("GOT claim followup log Data:", res2.data);
            let dframe = res2.data['frame0'];
            console.log("Next Frame:", dframe);
            let myObj = JSON.parse(dframe);
            console.log(myObj);
            let gridData = myObj['rows'];
            console.log("Got claim notes:", gridData);

           // setClaimFollowups(gridData);
            let assignedToNamesMap = buildAssignedToMap(gridData);
            setClaimsAssignedToMap(assignedToNamesMap);
            console.log("Got claim notes", assignedToNamesMap, gridData);
        }
    }

    const claimColumnDefs = [
        { headerName: "Claim ID", field: "claim_id" ,   width: 120},
        { headerName: "Patient Name", field: "patientname" , width: 150},
        { headerName: "Facility Name", field: "facilityname", width: 120 },
        { headerName: "DOS", field: "dos" , width: 130},
        { headerName: "Billed", field: "InvoiceAmount" , width: 130},
        { headerName: "Paid", field: "inv_netpayment" , width: 130},
        { headerName: "Balance", field: "inv_balance" , width: 130},
        { headerName: "VisitCode", field: "discharge_date" , width: 120},
        { headerName: "Claim With", field: "claimwith", width: 110 },
        { headerName: "Pins", field: "pins" },
        { headerName: "Last Action Time", field: "last_action_time" },
        { headerName: "Patient ID", field: "patid" , width: 100 },
        { headerName: "Billed To", field: "billedto" },
        { headerName: "Claim Status", field: "ClaimStatus" },

    ];

    const cptColumnDefs = [
        { headerName: "CPT ID", field: "cpt_id", width: 100 },
        { headerName: "CPT DOS", field: "cptdos", width: 130 },
        { headerName: "CPT Desc", field: "cptdesc", width: 350 },
        { headerName: "Mods", field: "mods", width: 80 },
        { headerName: "Billed", field: "cptBilledFee" , width: 130},
        { headerName: "Paid", field: "CptPaid" , width: 130},
        { headerName: "Balance", field: "cptBalance" , width: 130},
        { headerName: "CPT Diag Info", field: "cpt_diaginfo" , width: 400},
        { headerName: "PerUnit", field: "cptBilledUnitFee" , width: 130},
        { headerName: "Units", field: "cptUnits" , width: 130},
        { headerName: "Balance", field: "cptBalance" , width: 130},
        { headerName: "Claim ID", field: "claim_id", width: 100 },

    ];


    useEffect(()=> {
        getRecentClaimsData();
        getClaimNotes();
    }, []);

  //   const onCommentValueChanged = (e, varName) => {
  //
  //       const newVal = {};
  // console.log("tinymce update", e, e.target);
  //       switch (varName) {
  //           case "comment":
  //               setcommentValue(e);
  //
  //               break;
  //
  //           default:
  //              // newVal[varName] = e.target.value;
  //               break;
  //       }
  //
  //   }



    const handleCheckboxChange = (name) => {
        console.log("Checked name", name);
        setSelectedNames((prev) => {
            const newSelectedNames = new Set(prev);
            if (newSelectedNames.has(name)) {
                newSelectedNames.delete(name);
            } else {
                newSelectedNames.add(name);
            }
            return newSelectedNames;
        });
    };

    const handleClaimGridRowSelected = (event) => {

        const selectedNodes = claimsGridRef.current.api
            .getSelectedNodes()
            .filter((node) => node.selected);

        const datarow = selectedNodes[0].data;
        console.log('AG Claim Row selected', datarow);
        setSelectedClaimID(datarow.claim_id);
        setSelectedPatId(datarow.patid);

        const filteredCPTArray = claimsData.filter(item => item.claim_id === datarow.claim_id);
        setCurrentClaimCPTs(filteredCPTArray);

        //getClaimNotes(datarow.claim_id);

        // const claimFollowupsJson = claimFolowups.map(row => {
        //     return (`<span>{${row.modifieddate}</span><span></span> [${row.enteredbyName}] <br/><span>${row.action_notes}</span>`)
        // });
        //
        // console.log("Action Folloups",claimJsonNotes,  claimFollowupsJson);
        // setClaimFollowups(claimFollowupsJson);

        // getClaimDocumentLinks(datarow.tclaim_id);
        // refreshCptData(datarow.tclaim_id);

    };


    const handleCptGridRowSelected = (event) => {
        const selectedNodes = cptGridRef.current.api
            .getSelectedNodes()
            .filter((node) => node.selected);

        const datarow = selectedNodes[0].data;
        console.log('AG CPT Row selected', datarow);
        // setCurrentClaimId(datarow.tclaim_id);
        // getClaimDocumentLinks(datarow.tclaim_id);
        // refreshCptData(datarow.tclaim_id);

    };

    const handleQuickFilter = (event) => {
        console.log("Quick filter change", event.target.value)
        setQuickFilter(event.target.value );
        claimsGridRef.current.api.setQuickFilter(event.target.value);
    };

    const onSelectedPatientOnlyChanged = (event) => {
        setSelectedpatientOnly(event.target.checked  );
        //getFilteredClaims(currentTab);
    }

    const onRecentlyActiveOnlyChanged = (event) => {
        setRecentlyActive(!recentlyActive );
       // getFilteredClaims(currentTab);
    }

    const onPositiveBalanceOnlyChanged = (event) => {
        setCurrentTab("All Claims");
        //getFilteredClaims(null, null, claimsData, !positiveBalanceOnly);
        setPositiveBalanceOnly(!positiveBalanceOnly);
    }


    const onStartDateChanged =(event) => {
        setStartDate(event.target.value);
        //getFilteredClaims(currentTab);
    }

    const onEndDateChanged =(event) => {
        setEndDate(event.target.value);
        //getFilteredClaims(currentTab);
    }
    const onSelectedClaimPatient = (data) => {
        console.log("select patient ", data);
        setSelectedPatientId(data)
       // getFilteredClaims(currentTab, data);

        //setSelectedClaimPatient(data);
    }

    const onDataReload = () => {
        console.log("Data Reload Requested");
        getRecentClaimsData();
        getClaimNotes();
    }

    // const filteredClaims = claimsData.filter((claim) =>
    //     selectedNames.size === 0 || selectedNames.has(claim.assignedToName)
    // );



    const getFilteredClaims0 = (tabCode, patient, claimsGrid, argHasBalanceOnly, argslectedNames) => {

        if (!argslectedNames) {
            if (claimsAssignedToMap) {
                argslectedNames = Object.keys(claimsAssignedToMap);
            }
        }
        let argClaimsGrid = claimsGrid ? claimsGrid : ( claimsData ? claimsData : [] );
        setSelectedClaimID(null);

        let argTabCode = tabCode ? tabCode : ( currentTab ? currentTab : 'All Claims' );
        let argPatient = patient ? patient : ( selectedClaimID ? selectedClaimID : null );

        let filteredArray = argClaimsGrid;

        if (recentlyActive && startDate && endDate) {
            filteredArray = filteredArray.filter(item => item.dos >= startDate && item.dos < endDate);
        }
        if (argHasBalanceOnly ) {
            filteredArray = filteredArray.filter(item => item.inv_balance > 0);
        }

        console.log("Filtering on patient", selectedpatientOnly, selectedPatientId);

        if (selectedpatientOnly && argPatient) {
            filteredArray = filteredArray.filter(item => item.patid === argPatient.PatientId);
        }

        if (argslectedNames) {
            const selectedClaims = getClaimsForSelectedNames(argslectedNames);
            console.log("All selected claims", selectedClaims);

            filteredArray = filteredArray.filter((claim) =>
                selectedClaims.size === 0 || selectedClaims.has(claim.claim_id)
            );

        }

        // filteredArray = filteredArray.filter((claim) =>
        //     selectedNames.size === 0 || selectedNames.has(claim.assignedToName)
        // );

        const result_tabs = processClaims(filteredArray);
        setQueueTabs(result_tabs);

        filteredArray = filteredArray.filter(item => argTabCode === 'All Claims' || item.queueDesc === argTabCode);

        const uniqueClaimsMap = new Map();

        filteredArray.forEach(item => {
            if (!uniqueClaimsMap.has(item.claim_id)) {
                uniqueClaimsMap.set(item.claim_id, {
                    claim_id: item.claim_id,
                    claimwith: item.claimwith,
                    dos: item.dos,
                    patientname: item.patientname,
                    patid: item.patid,
                    facilityname: item.facilityname,
                    pins: item.pins,
                    queueCode: item.queueCode,
                    queueDesc: item.queueDesc,
                    billedto: item.billedto,
                    discharge_date: item.discharge_date,
                    queueIndex: item.queueIndex,
                    InvoiceAmount: item.InvoiceAmount,
                    inv_netpayment: item.inv_netpayment,
                    inv_balance: item.inv_balance,
                    last_action_time: item.last_action_time
                });
            }
        });

        console.log("Unique Claims",currentTab,  uniqueClaimsMap );
// Convert the Map values to an array of unique claim objects
        const uniqueClaimsArray = Array.from(  uniqueClaimsMap.values());
        //setUniqueClaimsArray(uniqueClaimsArray);

        return uniqueClaimsArray;
    }

    function getLeftPanelContent() {

        let argTabCode =  currentTab ? currentTab : 'All Claims' ;
        let filteredArray = [];

        //claimsData.filter(item => argTabCode === 'All Claims' || item.queueDesc === argTabCode);

        if (selectedNames && selectedNames.size > 0) {
            const selectedClaims = getClaimsForSelectedNames(selectedNames);
            console.log("All selected claims", selectedClaims);

            filteredArray = claimsData.filter((claim) =>
                selectedClaims.size === 0 || selectedClaims.has(claim.claim_id)
            );

        }else {
            filteredArray = claimsData.filter(item => argTabCode === 'All Claims' || item.queueDesc === argTabCode);
        }


        const result_tabs = processClaims(filteredArray);
        let sortedTabs = result_tabs.sort((a, b) => a.queueIndex - b.queueIndex);

        let assignedToNames =[];
        if (claimsAssignedToMap) {
            assignedToNames = Object.keys(claimsAssignedToMap);
            console.log("Assigned To names: ", assignedToNames, claimsAssignedToMap);
        }

        console.log("Sorted Tabs", sortedTabs);
        return (
            <>
                { selectedpatientOnly && (
                <div style={{ marginLeft: '10px', backgroundColor: 'white'}}>
            <SelectEcwPatient selectedEcwPatient={selectedPatientId} setSelectedEcwPatient={onSelectedClaimPatient}  />
                </div>) }
            <table style={{width: '100%'}}>
                <thead>
                <tr>
                    <th>Select a Queue</th>
                </tr>
                </thead>
                <tbody>
                {sortedTabs.map((tab, ndx) => (
                    <tr
                        key={`tab${tab.queueCode}`}
                        className={tab.queueCode === currentTab ? 'selected' : ''}
                        onClick={() => {setCurrentTab(tab.queueCode); } }
                    >
                        <td>{ tab.queueCode == 'All Claims' ?  `${tab.queueCode}` : `${tab.queueCode} [${tab.claimCount}]` }</td>
                    </tr>
                ))}
                </tbody>
            </table>
                { assignedToNames && (
                <VStack align="start" spacing={2} mb={4}>
                    {assignedToNames.map((name) => (
                        <HStack><Checkbox key={name}
                                    onChange={() => handleCheckboxChange(name)}>{name}</Checkbox>
                            <label style={{ backgroundColor: 'gray'}}>{name}</label></HStack>
                    ))}
                </VStack>
                    ) }
            </>
        );
    }

    function getRightPanelContent() {
        const {currentUser, modalService} = props;

        let argTabCode =  currentTab ? currentTab : 'All Claims' ;
       // let filteredArray = claimsData.filter(item => argTabCode === 'All Claims' || item.queueDesc === argTabCode);

        let filteredArray = [];

        //claimsData.filter(item => argTabCode === 'All Claims' || item.queueDesc === argTabCode);

        if (selectedNames && selectedNames.size > 0) {
            const selectedClaims = getClaimsForSelectedNames(selectedNames);
            console.log("All selected claims", selectedClaims);

            filteredArray = claimsData.filter((claim) =>
                selectedClaims.size === 0 || selectedClaims.has(claim.claim_id)
            );

        }else {
            filteredArray = claimsData.filter(item => argTabCode === 'All Claims' || item.queueDesc === argTabCode);
        }



        const uniqueClaimsMap = new Map();

        filteredArray.forEach(item => {
            if (!uniqueClaimsMap.has(item.claim_id)) {
                uniqueClaimsMap.set(item.claim_id, {
                    claim_id: item.claim_id,
                    claimwith: item.claimwith,
                    dos: item.dos,
                    patientname: item.patientname,
                    patid: item.patid,
                    facilityname: item.facilityname,
                    pins: item.pins,
                    queueCode: item.queueCode,
                    queueDesc: item.queueDesc,
                    billedto: item.billedto,
                    discharge_date: item.discharge_date,
                    queueIndex: item.queueIndex,
                    InvoiceAmount: item.InvoiceAmount,
                    inv_netpayment: item.inv_netpayment,
                    inv_balance: item.inv_balance,
                    last_action_time: item.last_action_time
                });
            }
        });

        console.log("Unique Claims",currentTab,  uniqueClaimsMap );

// Convert the Map values to an array of unique claim objects
        const uniqueClaimsArray = Array.from(  uniqueClaimsMap.values());

        console.log("Unique Claims Array",uniqueClaimsArray );

        switch (currentTab) {
            case 'Access Audit':
                return <div>Access Audit Panel</div>;
            case 'Cache Monitor':
                return (
                    <SampleDataGrid/>
                );
            case 'Receivers':
                return <div>Receiver  Panel</div>;
            default:
                return (

                    <SplitPanel
                        id="first"
                        split="vertical"
                        hidePanel1={false}
                        hidePanel2={false}
                        showExpandButton={false}
                        disablePanel2Collapse={true}
                        initialSize={30}
                    >
                        <div className="ag-theme-alpine" style={{height: '600px', width: '100%'}}>
                            <AgGridReact
                                ref={claimsGridRef}
                                rowData={uniqueClaimsArray}
                                columnDefs={claimColumnDefs}
                                defaultColDef={{resizable: true, sortable: true, filter: true}}
                                rowSelection="single"
                                rowClassRules={{
                                    'ag-row-selected': (params) => {
                                        return params.node.isSelected();
                                    }
                                }}
                                onSelectionChanged={handleClaimGridRowSelected}
                            />

                        </div>
                        <div style={{height: '100%' , width: '100%', overflow: "auto"}}>
                        { selectedClaimID && (
                            <>
                                <div className="ag-theme-alpine" style={{height: '250px', width: '100%'}}>
                                    <AgGridReact
                                        ref={cptGridRef}
                                        rowData={currentClaimCPTs}
                                        columnDefs={cptColumnDefs}

                                        defaultColDef={{resizable: true, sortable: true, filter: true}}
                                        rowSelection="single"
                                        onSelectionChanged={handleCptGridRowSelected}
                                    />
                                </div>
                                    <CommentsSection patientId={selectedPatId} claimId={selectedClaimID} />
                                {/*    <div style={{*/}
                                {/*        backgroundColor: 'white',*/}
                                {/*        color: 'black',*/}
                                {/*        marginTop: '3px',*/}
                                {/*        fontSize: '16px',*/}
                                {/*        paddingLeft: '5px',*/}
                                {/*        paddingRight: '5px'*/}
                                {/*    }}>*/}
                                {/*        {claimFolowups.map((row, index) => (*/}
                                {/*            <div key={index}>*/}
                                {/*                <span>{row.modifieddate}</span>*/}
                                {/*                <span> [ {row.enteredbyName} ] </span>*/}
                                {/*                <span> to {row.assignedToName}  </span>*/}
                                {/*                <br/>*/}
                                {/*                <span>{row.action_notes}</span>*/}
                                {/*            </div>*/}
                                {/*        ))}*/}

                                {/*</div>*/}

                            </>)}
                        </div>

                    </SplitPanel>

                );
                            }
                            }

    return (
        <SplitPage
            actionButtons={<>
                {enableFilters && (
                    <>
                        <label style={{backgroundColor: 'gray'}}>Selected Patient</label>
                        <Switch checked={selectedpatientOnly}
                                onChange={(e) => onSelectedPatientOnlyChanged(e)}/>
                    </>)}

                {/*<label style={{backgroundColor: 'green', marginLeft: 10, fontSize: 'x-large', paddingLeft: '10px',paddingRight: '10px'}}>{claimsGridRef && claimsGridRef.current && claimsGridRef.current.api.getDisplayedRowCount().length}</label>*/}
                <label style={{backgroundColor: 'gray', marginLeft: 10}}>HasBalance</label>
                <Switch checked={positiveBalanceOnly}
                        onChange={(e) => onPositiveBalanceOnlyChanged(e)}/>
                <label style={{backgroundColor: 'gray', marginLeft: 10, marginRight: 5}}>Filter</label>
                <Input as='input' value={quickFilter}
                       backgroundColor={'white'}
                       color={'green'}
                       fontWeight={'900'}
                       onChange={handleQuickFilter}
                       width={'200px'}
                       ref={initFocusRef}
                       placeholder="filter..."
                       variant="filled" fontSize='sm'
                       aria-multiline/>
                {enableFilters && (
                    <>
                        <label style={{backgroundColor: 'gray', marginLeft: 10}}>Duration</label>
                        <Switch checked={recentlyActive}
                                onChange={(e) => onRecentlyActiveOnlyChanged(e)}/>
                        <label style={{backgroundColor: 'gray', marginLeft: 10, marginRight: 5}}>Start Date</label>
                        <StyledInput
                            disabled={!recentlyActive}
                            value={startDate}
                            type="datetime-local"
                            onChange={(e) => onStartDateChanged(e)}
                        />

                        <label style={{backgroundColor: 'gray', marginLeft: 10, marginRight: 5}}>End Date</label>
                        <StyledInput
                            disabled={!recentlyActive}
                            value={endDate}
                            type="datetime-local"
                            onChange={(e) => onEndDateChanged(e)}
                        />
                    </>
                )}
                <span style={{marginLeft: 10}}>
                        <SubmitButton buttonText="Reload" onClick={() => onDataReload()}/>
                    </span>
            </>}
            isLoading={false}
            leftPanelContent={getLeftPanelContent()}
            loadingMsg={'Loading'}
            rightPanelContent={getRightPanelContent()}
            title={`Claims Dashboard`}
            toolButtons={<></>}
        />
    );
}

export default ClaimsQueueViewPage;
