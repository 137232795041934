import React, { useState } from 'react';
import { Popper, Paper, Typography, Box, Button, TextField } from '@mui/material';
import Draggable from 'react-draggable';
import axios from 'axios';
import apiConfig from "../../apiConfig";

interface CreateDepositBatchProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onSave: (newBatch: any) => void;
}

function DraggablePaperComponent(props: any) {
    return (
        <Draggable handle="#draggable-popper-title">
            <Paper elevation={3} {...props} />
        </Draggable>
    );
}

const CreateDepositBatch: React.FC<CreateDepositBatchProps> = ({ open, anchorEl, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        batch_code: '',
        batch_source: '',
        itemcount: 0,
        batch_total: 0,
        bankdeposit_id: 0,
        bankdeposit_date: '',
        reconcilestatus: '',
        username: '',
        notes: '',
        lockedflag: 0,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            handleSaveDepositBatch(formData);
        } catch (error) {
            console.error('Error saving deposit batch:', error);
        }
    };

    const handleSaveDepositBatch = async (formData: any) => {
        console.log("Got called handleSaveDepositBatch", formData);

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }

        try {
            const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=`;
            const dataURL = `apex&sqltype=customSQL&sqltext=exec rc.mnt_depositbatch 
            @arg_op = 'edit', 
            @batch_id = ${formData.batch_id || 'NULL'},
            @batch_code = '${formData.batch_code}',
            @batch_source = '${formData.batch_source}',
            @itemcount = ${formData.itemcount || 'NULL'},
            @batch_total = ${formData.batch_total || 'NULL'},
            @bankdeposit_id = ${formData.bankdeposit_id || 'NULL'},
            @bankdeposit_date = '${formData.bankdeposit_date}',
            @reconcilestatus = '${formData.reconcilestatus}',
            @username = '${formData.username}',
            @notes = '${formData.notes}',
            @lockedflag = ${formData.lockedflag || 'NULL'};`;

            const response = await axios.get(urlPrefix + dataURL, { headers: authHeader });
            if (response.data) {
                onSave(response.data);
                onClose();
            }
        } catch (error) {
            console.error('Error saving deposit batch:', error);
        }
    };


    return (
        <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
            <DraggablePaperComponent style={{ width: 600, position: 'fixed', top: 10, left: '20%' }}>
                <Box p={2} display="flex" justifyContent="space-between" alignItems="center" style={{ cursor: 'move' }}>
                    <Typography variant="h6" id="draggable-popper-title">Create Deposit Batch</Typography>
                    <Button size="small" onClick={onClose}>X</Button>
                </Box>
                <Box p={2}>
                    <TextField
                        label="Batch Code"
                        name="batch_code"
                        variant="outlined"
                        fullWidth
                        value={formData.batch_code}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Batch Source"
                        name="batch_source"
                        variant="outlined"
                        fullWidth
                        value={formData.batch_source}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Item Count"
                        name="itemcount"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={formData.itemcount}
                        onChange={handleChange}

                    />
                    <TextField
                        label="Batch Total"
                        name="batch_total"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={formData.batch_total}
                        onChange={handleChange}

                    />
                    <TextField
                        label="Bank Deposit ID"
                        name="bankdeposit_id"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={formData.bankdeposit_id}
                        onChange={handleChange}

                    />
                    <TextField
                        label="Bank Deposit Date"
                        name="bankdeposit_date"
                        variant="outlined"
                        fullWidth
                        type="date"
                        value={formData.bankdeposit_date}
                        onChange={handleChange}

                    />
                    <TextField
                        label="Reconcile Status"
                        name="reconcilestatus"
                        variant="outlined"
                        fullWidth
                        value={formData.reconcilestatus}
                        onChange={handleChange}

                    />
                    <TextField
                        label="Username"
                        name="username"
                        variant="outlined"
                        fullWidth
                        value={formData.username}
                        onChange={handleChange}

                    />
                    <TextField
                        label="Notes"
                        name="notes"
                        variant="outlined"
                        fullWidth
                        value={formData.notes}
                        onChange={handleChange}

                    />
                    <TextField
                        label="Locked Flag"
                        name="lockedflag"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={formData.lockedflag}
                        onChange={handleChange}

                    />
                </Box>
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button onClick={onClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                </Box>
            </DraggablePaperComponent>
        </Popper>
    );
};

export default CreateDepositBatch;
