import React , { useEffect, useState , useMemo, createRef , useCallback} from 'react';

import { AgGridReact } from 'ag-grid-react';

// import EcwDcouments from '../ecwdocuments/ecwdocuments';
//import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import * as xlsx from 'xlsx';

//export const API_URL = 'https://localhost:8044';
import  apiConfig, { useLocalStorage }  from '../../apiConfig'
//export const API_URL = 'https://192.168.1.20:8044';
//export const apiPyURL = API_URL ;

// import PersonIcon from '@mui/icons-material/Person';
// import AddIcon from '@mui/icons-material/Add';
// import { blue } from '@mui/material/colors';

import {
    Grid,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Dialog,
    DialogTitle,
    Card,
    Text,
    Box,
    Button,
    Modal,
    MenuItem,
    InputLabel,
    FormControl,
    useDisclosure,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter
}  from '@chakra-ui/react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

//const sql = require("mssql/msnodesqlv8");

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const showFilePng = (blob) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'image/png' });
    // var newBlob = new Blob([blob], { type: "application/pdf" })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = 'file.png';
    link.click();
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
};


const showFileDoc = (blob) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'application/doc' });
    // var newBlob = new Blob([blob], { type: "application/pdf" })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = 'file.doc';
    link.click();
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
};



const showFileTiff = (blob) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'image/tiff' });
    // var newBlob = new Blob([blob], { type: "application/pdf" })

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = 'file.tiff';
    link.click();
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
};


const App = (props) => {

    const [authHeader, setauthHeader] = useLocalStorage("authHeader", {});
    const {ScopeCode, ItemID} = props;

    console.log(`Will selection account for ITEM: ${ScopeCode}  ${ItemID} and add tranactions from file. `)

    const handleStatement = (PatientId) => {
        // this.setState({ filters: {}, selectedIndexes: [] });
        // let row = this.getRows().filter( (elem,indx)=> indx == this.state.selectedIndexes)[0];
        //console.log(row);

        if (PatientId == null) return;

        console.log("Calling statement retrieve for:", PatientId);
        fetch(`${apiConfig.API_URL}/getstatementjava?patid=${PatientId}&full=no`, authHeader)

            .then((r) => r.blob())
            .then((blob) => {
                console.log("Received Stateement");
                const url = URL.createObjectURL(blob);
                const viewerUrl = encodeURIComponent(url);

                const frame_element = `/pdfjs/web/viewer.html?file=${viewerUrl}&embedded=true`;

                setPdfFilePath( frame_element );
            });
    };

    const onRowSelectPatient = (event) => {

        if (!event.node.selected)
            return;

        console.log('rowselectednew encounter:', event.data.patient_id);
        setpatientID(event.data.patient_id);
        // handleStatement(event.data.patient_id);

    };

    const  onRowSelectDocument= (data) => {
        console.log("Called onRowSelectDocument with data: ", data);


        console.log(`TO DIsplay${data.dirpath}/${data.fileName}`);

        let fileArgument = `${data.dirpath}/${data.fileName}`;
        fileArgument = fileArgument.replace('/', '\\')
        //let full_file_path = fileArgument.replace('/', '\\');

        let full_file_path = `\\\\192.168.1.17\\d$\\eClinicalWorks\\ftp\\${fileArgument}`;


        full_file_path = `${apiConfig.API_URL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;

        console.log('Starting to get Linq File', full_file_path);

        // // let url = URL.createObjectURL(full_file_path);

        // const frame_element = `../public/pdfjs/web/viewer.html?file=${full_file_path}`;

        // this.setState({ filepath: frame_element });


        // if (full_file_path.includes(".pdf")) {

        // }else {
        //                 window.open(full_file_path);
        //     //showFileDoc(blob);
        //     return;
        // }


        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Access-Control-Allow-Origin": "*"
        }

        fetch(
            encodeURI(full_file_path, { headers: authHeader })
        )
            //.then(this.handleErrors)
            .then((r) => r.blob())
            .then((blob) => {

                //this.showFilePdf(blob);
                let url = URL.createObjectURL(blob);

                if (full_file_path.includes("png")) {
                    showFilePng(blob);
                    return;
                }

                if (full_file_path.includes(".tif2")) {
                    //showFileTiff(blob);
                    // let viewerUrl = encodeURIComponent(url);

                    const html_content = `<!DOCTYPE html>
<html>
<head>
  <title>Test relative URI in blob:// frame</title>
  <!-- here we set the baseURI of our document -->
</head>
<body>
  <!-- here we can use relative URIs, as if we were really on wikimedia's page -->
  <h1>Image will be displayed below</h1>
  <img src="${full_file_path}" width="300" >
  </img>
  <h1>After Image displayed above</h1>
</body>
</html>`;
                    const htmlblob = new Blob( [html_content], { type: "text/html" } );
                    const htmlblobURL = URL.createObjectURL( htmlblob );

                    console.log("SETTING BLOB TO TIFF:", html_content, htmlblobURL);
                    const frame_element = `${htmlblobURL} `;
                    setPdfFilePath(frame_element);
                    return;
                }

                if (full_file_path.includes(".tiff2")) {
                    //showFileTiff(blob);
                    // let viewerUrl = encodeURIComponent(url);

                    const html_content = `<!DOCTYPE html>
<html>
<head>
  <title>Test relative URI in blob:// frame</title>
  <!-- here we set the baseURI of our document -->
</head>
<body>
  <!-- here we can use relative URIs, as if we were really on wikimedia's page -->
  <h1>Image will be displayed below</h1>
  <img src="${full_file_path}" width="300" >
  </img>
  <h1>After Image displayed above</h1>
</body>
</html>`;
                    const htmlblob = new Blob( [html_content], { type: "text/html" } );
                    const htmlblobURL = URL.createObjectURL( htmlblob );

                    console.log("SETTING BLOB TO TIFF:", html_content, htmlblobURL);
                    const frame_element = `${htmlblobURL} `;
                    setPdfFilePath(frame_element);
                    return;
                }

                // if (full_file_path.includes(".doc")) {
                //     window.open(url);
                //     //showFileDoc(blob);
                //     return;
                // }

                // if (full_file_path.includes(".tif")) {
                //     window.open(url);
                //     //showFileDoc(blob);
                //     return;
                // }


                if (full_file_path.includes(".pdf")) {
                    let viewerUrl = encodeURIComponent(url);
                    const frame_element = `/pdfjs/web/viewer.html?file=${viewerUrl} `;
                    setPdfFilePath(frame_element);
                    return;
                }else {
                    window.open(url);
                    //showFileDoc(blob);
                    return;
                }


                // let viewerUrl = encodeURIComponent(url);
                // const frame_element = `/pdfjs/web/viewer.html?file=${viewerUrl} `;
                // setPdfFilePath(frame_element);

            });


    }

    //   const onRowSelectDocument = useCallback( event => {


    //     console.log("Called onRowSelectDocument with data: ", event);
    //     if (!isNumeric(event.nativeEvent)) {
    //         event.preventDefault();
    //     }

    //     function isNumeric(event) {
    //         return /\d/.test(event.key);
    //     }
    // }, []);

    const LaunchCMDButton = (props) => {
        const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
        let accn = '';
        let exam_id = '';
        try {
            accn = props.data.accn;
            exam_id = props.data.exam_id;
        } catch (err) {
            console.log(err);
        }
        //console.log("Button props", props);
        //console.log("WILL DSIPLAY", accn, cellValue);





        const buttonClicked = () => {
            let viewerUrl = `https://google.com/`;
            console.log(viewerUrl);
            try {
                window.open(viewerUrl);
            } catch (ex) {
                console.error(`_bindPdfLink: ${ex}`);
            }

        };

        return (
            <span>
        <button onClick={() => { setpatientID(cellValue);
            handleStatement(cellValue);
        }}>Statement</button>
      </span>
        );
    };

    const [valueDateSelected, setvalueDateSelected] = useState( new Date().toLocaleDateString() );

    const handleDateChange = (newValue) => {
        setvalueDateSelected(newValue.toLocaleDateString());
    };

    const [pdfFilePath, setPdfFilePath ]  = useState('');
    const [mydata, setMydata] = useState([]);
    const [selectedAccounts, setselectedAccounts] = useState([]);
    const [patientID, setpatientID] = useState(null);
    const [columnDefs, setColumnsDefs ] = useState([
        {field: 'cardnum', width: 150 },
        { field: 'transaction_date', width: 200   },
        { field: 'posted_date', width:150   },
        { field: 'Description', width: 250   },
        { field: 'Category', width: 200   },
        { field: 'Debit', width: 100   },
        { field: 'Credit', width: 100   },
    ]);

    const defaultColDef = useMemo( ()=> ({
        sortable: true,
        resizable: true,
        filter: true
    }));


    let SQLForItemAccountList = `
  SELECT 
     acct.[id] as acct_id
      ,acct.[scope_code]
      ,[account_id]
      ,[account_name]
      ,[official_name]
      ,[balances]
      ,[mask]
      ,[account_type]
      ,[account_subtype]
      ,acct.[institution_id]
      ,acct.[item_id]
      ,acct.[user_id]
      ,[acct_custom_name]
  FROM apexfinsb.[dbo].[account] acct, apexfinsb.[dbo].[plaid_item] itm
  where acct.item_id = itm.id
  and acct.scope_code = itm.scope_code
  and itm.item_id = '${ItemID}' 
  ` ;


    if (ScopeCode == 'development') {
        SQLForItemAccountList = `
    SELECT 
       acct.[id] as acct_id
        ,acct.[scope_code]
        ,[account_id]
        ,[account_name]
        ,[official_name]
        ,[balances]
        ,[mask]
        ,[account_type]
        ,[account_subtype]
        ,acct.[institution_id]
        ,acct.[item_id]
        ,acct.[user_id]
        ,[acct_custom_name]
    FROM apexfin.[dbo].[account] acct, apexfin.[dbo].[plaid_item] itm
    where acct.item_id = itm.id
    and acct.scope_code = itm.scope_code
    and itm.item_id = '${ItemID}' 
    ` ;

    }else {
        SQLForItemAccountList = `
    SELECT 
       acct.[id] as acct_id
        ,acct.[scope_code]
        ,[account_id]
        ,[account_name]
        ,[official_name]
        ,[balances]
        ,[mask]
        ,[account_type]
        ,[account_subtype]
        ,acct.[institution_id]
        ,acct.[item_id]
        ,acct.[user_id]
        ,[acct_custom_name]
    FROM apexfinsb.[dbo].[account] acct, apexfinsb.[dbo].[plaid_item] itm
    where acct.item_id = itm.id
    and acct.scope_code = itm.scope_code
    and itm.item_id = '${ItemID}' 
    ` ;
    }

    const SQLforData = `
  select enc.patientID as patient_id,  
  enc.Name as patient_name, 
  visittype as visit_type, 
  reason as reason,
  DOS as dos, 
  ApptAt as appt_at, 
  
   
  EncStatus, 
  insurances  from apex..Apex_Nuclear_Studies enc, apex.rc.vPatient pat  
where cast (dos as date) = cast ('${valueDateSelected}' as date) 
and enc.patientid = pat.patientid 
order by dos desc `;

    const DataSQLURL = `${apiConfig.API_URL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=set%20rowcount%20200%20 ${SQLforData}  `;

    const AccountsListDataSQLURL = `${apiConfig.API_URL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=${encodeURIComponent(SQLForItemAccountList)} `;

    useEffect ( () => {

        console.log("Getting Accounts for ITEM", AccountsListDataSQLURL);
        fetch(AccountsListDataSQLURL, authHeader)
            .then(response => response.json())
            .then(data => {

                    console.log("___________________");
                    console.log(data);
                    const dframe = data.frame0;
                    const myObj = JSON.parse(dframe);
                    const data2 = myObj.rows;

                    console.log("Accounts:", data2);
                    setselectedAccounts(data2);

                }
            );

    }, [ItemID]);

    //   console.log("Getting Data for Date", DataSQLURL);
    //     fetch(DataSQLURL)
    //     .then(response => response.json())
    //     .then(data => {

    //             console.log("___________________");
    //             console.log(data);
    //             const dframe = data.frame0;
    //             const myObj = JSON.parse(dframe);
    //             const data2 = myObj.rows;
    //             const firstrow = data2[0];
    //             console.log("First Row:", firstrow);
    //             if (!firstrow) {
    //               setMydata([]);
    //               return;
    //             }
    //             const showColumns = Object.keys(firstrow).map(col=> {
    //                 console.log(col);
    //                 return { field: col }
    //             });
    //             showColumns.unshift(
    //                 { field: 'patient_id' ,  cellRenderer: LaunchCMDButton, width:100 },
    //             );
    //             setColumnsDefs(showColumns);
    //             setMydata(data2.map((row) => {  return row;}))
    //     }
    //     );

    // }, [valueDateSelected]);

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            console.log("FILE:",e.target.files[0].name);
            let fileType = "WF";

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });

                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                //console.log("Array Read", sheetName, worksheet);
                //const json = xlsx.utils.sheet_to_json(worksheet, { header: 0 });
                const json =  xlsx.utils.sheet_to_json(worksheet, { header: 1 });

                console.log("RECEIVED TRANSACTIONS",json);
                let spreadsheetColumns = [];

                if (fileType.includes("WF")){


                    spreadsheetColumns.push( { field: 'transaction_date', width: 200   });
                    spreadsheetColumns.push( { field: 'Description', width: 250   });
                    spreadsheetColumns.push( { field: 'Category', width: 200   });
                    spreadsheetColumns.push( { field: 'Debit', width: 100   });
                    spreadsheetColumns.push( { field: 'Credit', width: 100   });
                    const basedate = new Date('1900-01-01');
                    json.map(row=> {

                        let trnday = new Date('1900-01-01');
                        trnday.setDate(basedate.getDate() + row[0]);
                        console.log("wf row", row[0], formatDate(trnday),row[1], row[4]);
                        row['transaction_date'] = formatDate(trnday);
                        row['Debit'] = row[1];
                        row['Description'] = row[4];

                    });
                    setColumnsDefs(spreadsheetColumns);
                    setMydata(json.map((row) => {  return row;}))
                    return;
                }
                if (sheetName == "Profit and Loss Detail") {
                    //console.log("P&L Detail Sheet:");

                    spreadsheetColumns.push( { field: 'account', width: 150   });
                    spreadsheetColumns.push( { field: 'date', width: 100   });
                    spreadsheetColumns.push( { field: 'type', width: 100   });
                    spreadsheetColumns.push( { field: 'num', width: 100   });
                    spreadsheetColumns.push( { field: 'name', width: 100   });
                    spreadsheetColumns.push( { field: 'description', width: 200   });
                    spreadsheetColumns.push( { field: 'category', width: 100   });
                    spreadsheetColumns.push( { field: 'amount', width: 100   });
                    spreadsheetColumns.push( { field: 'balance', width: 100   });

                    setColumnsDefs(spreadsheetColumns);


                    let header_account = "";
                    const basedate = new Date('1900-01-01');
                    json.map(row=> {

                        if (row["Apex Heart Care of North Texas"]) {
                            //console.log("Got Header row", row);
                            header_account = row["Apex Heart Care of North Texas"];
                        }else if (row["__EMPTY"]) {
                            //console.log("Got Detail row", row);

                            let trnday = new Date('1900-01-01');
                            let postday = new Date('1900-01-01');
                            trnday.setDate(basedate.getDate() + row['Transaction Date']);
                            row['Transaction Date'] = formatDate(trnday);
                            postday.setDate(basedate.getDate() + row['Posted Date']);
                            row['account'] =  header_account;
                            row['date'] = row['__EMPTY'];
                            row['type'] =   row['__EMPTY_1'];
                            row['num'] =   row['__EMPTY_2'];
                            row['name'] =   row['__EMPTY_3'];
                            row['description'] =   row['__EMPTY_4'];
                            row['category'] =  row['__EMPTY_5'];
                            row['amount'] =  row['__EMPTY_6'];
                            row['balance'] =  row['__EMPTY_7'];

                            row['isgood'] = true;

                        }


                    });


                    setMydata(json.filter((row)=> row['isgood']).map((row) => {  return row;}))

                } else {

                    const firstrow = json[0];
                    console.log("First Row:", firstrow);
                    if (!firstrow) {
                        setMydata([]);
                        return;
                    }



                    spreadsheetColumns.push( { field: 'cardnum', width: 150   });
                    spreadsheetColumns.push( { field: 'transaction_date', width: 200   });
                    spreadsheetColumns.push( { field: 'posted_date', width:150   });
                    spreadsheetColumns.push( { field: 'Description', width: 250   });
                    spreadsheetColumns.push( { field: 'Category', width: 200   });
                    spreadsheetColumns.push( { field: 'Debit', width: 100   });
                    spreadsheetColumns.push( { field: 'Credit', width: 100   });
                    // const showColumns = Object.keys(firstrow).map(col=> {
                    //     console.log(col);
                    //     return { field: col }
                    // });

                    // showColumns.unshift(
                    //     { field: 'Credit', width: 100   },
                    // );

                    // showColumns.unshift(
                    //     { field: 'cardnum' , width: 100 },
                    // );

                    const basedate = new Date('1900-01-01');
                    json.map(row=> {
                        let trnday = new Date('1900-01-01');
                        let postday = new Date('1900-01-01');
                        trnday.setDate(basedate.getDate() + row['Transaction Date']);
                        row['Transaction Date'] = formatDate(trnday);
                        postday.setDate(basedate.getDate() + row['Posted Date']);
                        row['Posted Date'] =  formatDate(postday);
                        row['cardnum'] = row['Card No.'];
                        row['transaction_date'] = row['Transaction Date'];
                        row['posted_date'] = row['Posted Date'];


                    });
                    setColumnsDefs(spreadsheetColumns);
                    setMydata(json.map((row) => {  return row;}))

                }

            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    const load_pl_data = async(data) => {


        //   let connection = await sql.connect('Server=192.168.1.17,49259;Database=apexfin;User Id=user;Password=password;Encrypt=true;TrustServerCertificate=True')
        //   connection.query("delete apexfin.dbo.transaction where account_id = 107 ",  function(err, rows) {
        //     if (err)
        //         console.log("%s ", err);
        //    console.log("Success. Reset PL 2021 data");
        // });

        // data.map((row) => {

        //   connection.query("delete apexfin.dbo.transaction where account_id = 107 ",  function(err, rows) {
        //     if (err)
        //         console.log("%s ", err);
        //    console.log("Success. Reset PL 2021 data");

        // });

        //});


    }

    const submitUploadData = () => {
        let argbody = {
            acct: selectedAccounts,
            trn: mydata
        }


        if (mydata[0]['isgood']) {
            console.log("Process pl data", mydata.length, mydata);
            load_pl_data(mydata);
            return
        }else {
            console.log("Process regular trn data", mydata.length, mydata);
            // if (1 > 0) return;
        }
// ADD Auth Header

        let fullHeaders = authHeader.headers;
        console.log("Spreadsheet. Full Headers: ", fullHeaders);
        fullHeaders['Content-Type'] =  'application/json';

        const requestOptions = {
            method: 'POST',
            headers: fullHeaders,
            body: JSON.stringify(argbody)
        };

        console.log("WILL SUBMIT TO SERVER NOW", argbody);


        console.log("Getting Accounts for ITEM", AccountsListDataSQLURL);
        fetch(`${apiConfig.API_URL}/api/plaid/trnfileupload`, requestOptions)
            .then(response => {
                console.log("________RETURNED FROM FILE UPLOAD 0___________");
                response.json();
            })
            .then(data => {

                    console.log("________RETURNED FROM FILE UPLOAD 1___________");
                    // console.log(data);
                    // const dframe = data.frame0;
                    // const myObj = JSON.parse(dframe);
                    // const data2 = myObj.rows;

                    // console.log("Accounts:", data2);
                    // setselectedAccounts(data2);

                }
            );


    }

    return (

        <div >



            <div style={{width: "100%" }} >
                <form>
                    <label htmlFor="upload">Upload File</label>
                    <input
                        type="file"
                        name="upload"
                        id="upload"
                        onChange={readUploadFile}
                    />
                </form>
                <button onClick={submitUploadData} >UploadFile To Account</button>
                <div className="ag-theme-alpine" style={{ top: 200, height: '800px', width: '1200px'}}>
                    <AgGridReact
                        rowData={mydata}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection="single"
                        onRowSelected={onRowSelectPatient}

                    >
                    </AgGridReact>

                </div>


            </div>
        </div>
    );
}


const ViewPDF = ({PatientId, pdfFilePath}) => {

    const [iframeRef, setiframeRef ]  = useState(createRef());
    //const [pdfFilePath, setPdfFilePath ]  = useState('');


    console.log("View PDF for Patient", PatientId);
    // const handleStatement = () => {
    //   // this.setState({ filters: {}, selectedIndexes: [] });
    //   // let row = this.getRows().filter( (elem,indx)=> indx == this.state.selectedIndexes)[0];
    //   //console.log(row);

    //   if (PatientId == null) return;

    //   console.log("Calling statement retrieve for:", PatientId);
    //   fetch(`${API_URL}/getstatementjava?patid=${PatientId}&full=no`)
    //     //.then(this.handleErrors)
    //     .then((r) => r.blob())
    //     .then((blob) => {
    //         console.log("Received Stateement");
    //         const url = URL.createObjectURL(blob);
    //         const viewerUrl = encodeURIComponent(url);

    //         const frame_element = `/pdfjs/web/viewer.html?file=${viewerUrl}&embedded=true`;

    //         setPdfFilePath( frame_element );
    //       });
    // };

//     const showFile = (blob) =>{
//       // It is necessary to create a new blob object with mime-type explicitly set
//       // otherwise only Chrome works like it should
//       var newBlob = new Blob([blob], { type: "application/pdf" })

//       // IE doesn't allow using a blob object directly as link href
//       // instead it is necessary to use msSaveOrOpenBlob
//       if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//           window.navigator.msSaveOrOpenBlob(newBlob);
//           return;
//       }

//       // For other browsers: 
//       // Create a link pointing to the ObjectURL containing the blob.
//       const data = window.URL.createObjectURL(newBlob);
//       var link = document.createElement('a');
//       link.href = data;
//       link.download = "file.pdf";
//       link.click();
//       setTimeout(function () {
//           // For Firefox it is necessary to delay revoking the ObjectURL
//           window.URL.revokeObjectURL(data);
//       }, 100);
//   }

    // const recevStatement = (pdfblob) => {
    //   console.log('Received PDF Blob');

    //   const viewerUrl = encodeURIComponent(`../../../../${pdfblob}`);

    //   const frame_element = `../public/pdfjs/web/viewer.html?file=${viewerUrl} `;

    //   setPdfFilePath(frame_element);

    //   //ipcRenderer.send('show-blob', pdfblob);

    //   // let fileData = new Int8Array(await pdfblob.arrayBuffer());
    //   // fs.writeFileSync("filepath.png", fileData);
    //   // const url = URL.createObjectURL(pdfblob);
    //   // const viewerUrl = encodeURIComponent(url);

    //   // const frame_element = `../public/pdfjs/web/viewer.html?file=${viewerUrl} `;

    //   // this.setState({ filepath: frame_element });
    // };

    const getPDFPage = () => {
        console.log('Called Get Page Number');

        const iframePdf = iframeRef.current.contentWindow;
        console.log(iframePdf);
        console.log(iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber);
        if (this.iframePdf !== undefined) {
            const iframePdf = this.iframePdf.contentWindow;
            iframePdf.print();
        }
    };

    const  nextPDFPage = () => {
        console.log('Called Next Page Number');

        const iframePdf = iframeRef.current.contentWindow;
        console.log(iframePdf);
        console.log(
            (iframePdf.PDFViewerApplication.pdfViewer.currentPageNumber += 1)
        );

        iframePdf.PDFViewerApplication.pdfDocument.getPage(1).then((pdfPage) => {
            pdfPage.getTextContent().then((data) => {
                console.log(data);
            });
        });
    };


    useEffect ( () => {

        try {
            //handleStatement();
            //setPdfFilePath('https://s29.q4cdn.com/816090369/files/doc_downloads/test.pdf');
        }catch(error) {
            console.log(error);
        }

    }, [PatientId]);


    return (

        <iframe
            title = 'pdfviewer'
            width="100%"
            height="800px"
            backgroundcolor="lightgrey"
            innerRef={iframeRef}
            src={pdfFilePath}
        />

    );
}


function SimpleDialog(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [scrollBehavior, setScrollBehavior] = React.useState('inside')
    const { ItemID, ScopeCode, open , onParentClose} = props;

    console.log("Spreadsheet props:", props);
    const btnRef = React.useRef(null)

    const onModelClose = () => {
        onParentClose();
        onClose();

    }
    if (open) {
        if (!isOpen) onOpen();
    }

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <>

            <Button mt={3} ref={btnRef} onClick={onOpen}>
                Trigger modal
            </Button>

            <Modal
                onClose={onClose}
                finalFocusRef={btnRef}
                isOpen={isOpen}
                scrollBehavior={'inside'}
                size={'full'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Loading Transactions</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box  style={{ width: 1200 , minWidth: 600, maxWidth: 1400 }}>
                            <App ScopeCode={ScopeCode} ItemID={ItemID} />
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onModelClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )



}


export default SimpleDialog;

// onClose={handleClose} open={open}