// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAIjYPyychHs6sYAfAqkGqboe3z3eluoP8",
    authDomain: "apexapi-70fb2.firebaseapp.com",
    projectId: "apexapi-70fb2",
    storageBucket: "apexapi-70fb2.firebasestorage.app",
    messagingSenderId: "783941567642",
    appId: "1:783941567642:web:e57ffcda5fffc066914b23",
    measurementId: "G-TDHP7XENG9"
};

// ✅ Initialize Firebase using the modular approach
const app = initializeApp(firebaseConfig);

// ✅ Export Firebase Auth
export const auth = getAuth(app);
export default app;
