import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

interface User {
    firstName: string;
    lastName: string;
    email: string;
}

interface UserContextType {
    user: User | null;
    setUser: (user: User) => void;
    isLoggedIn: () => boolean;
    logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUserState] = useState<User | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            setUserState(JSON.parse(storedUser));
        }
    }, []);

    const setUser = (user: User) => {
        setUserState(user);
        localStorage.setItem("user", JSON.stringify(user));
    };

    const isLoggedIn = () => !!user;

    const logout = () => {
        setUserState(null);
        localStorage.removeItem("user");
    };

    return (
        <UserContext.Provider value={{ user, setUser, isLoggedIn, logout }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
};
