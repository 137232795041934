import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Flex,
  VStack,
    Text
} from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import apiConfig from '../apiConfig';
import axios from 'axios';
import { SelectEcwPatient } from './SelectApexPatient';
import ApexPdfjsViewer from '../Components/ApexPdfjsViewer';
import FullScreenModal from "../Components/FullScreenModel";
import { handleEcwDocument, handleFolderDocument, handleAgDocument } from '../utils/ecw/hadleDocuments';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const PatientManagementComponent = () => {

  const [isOpen, setIsOpen] = useState(false);

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const gridRef = React.useRef();
    const initFocusRef = React.useRef();
    const gridEcwPatRef= React.useRef();
    const [quickFilter, setQuickFilter] = useState('');
  const [ecwPatquickFilter, setEcwPatQuickFilter] = useState('');
    const [selectedClaimPatient, setSelectedClaimPatient] = useState(); 

  const [rowData, setRowData] = useState([]);
  const [rowDataEcwPat, setRowDataEcwPat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const [startingPageNum, setstartingPageNum] = useState(1)
  const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
  
  const[claimDocumentLinks, setClaimDocumentLinks] = useState();

  const [displayedPage, setdisplayedPage] = useState(null);
  const [displayedDocument, setdisplayedDocument] = useState(null);


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

   
  const getClaimDocumentLinks = async (patientid)  =>{

    console.log("Will get document reference links for patient :", patientid);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }
   

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=select docpagenum, document_path, min(entity_type) as entity_type , isnull(max(isdemogpage),0) as isdemogpage from rc.billing_doc_links   where patient_id = ${patientid} or tpat_id = ${patientid}  group by document_path, docpagenum    `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT LINKS DATA:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      if (dframe) {
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      if (gridData) {
        setClaimDocumentLinks(gridData);
      }
      console.log("Claim Document Links Grid", gridData);
    }
    }


  }


  const onSelectedClaimPatient = (data) => {
    console.log("select ptDoc patient in documanager", data);
    setSelectedClaimPatient(data);
  }

  
  // ag-Grid column definitions
  const columnDefs = [

    
    { headerName: 'Apex Patient', field: 'ApexPatient', width: 150, sortable: true, },
    { headerName: 'Dg', field: 'hasdemogpage', width: 80, sortable: true, },
    { headerName: 'TEMP ID', field: 'tpat_id' , width: 100, sortable: true, },
    { headerName: 'ApexID', field: 'patientid', width: 100 , sortable: true,},
    { headerName: 'P', field: 'priority', width: 50 , sortable: true,},
    { headerName: 'Last Name', field: 'lastname', width: 150 , sortable: true,},
    { headerName: 'First Name', field: 'firstname', width: 150, sortable: true,},

    { headerName: 'Middle Name', field: 'middlename' , width: 100},
    { headerName: 'DOB', field: 'dob', width: 150, sortable: true, },
    { headerName: 'mdate', field: 'mdate', width: 150, sortable: true, },

    { headerName: 'Comment', field: 'comment', width: 150 },
    { headerName: 'Status', field: 'tpatient_status', width: 100 , sortable: true,},
    { headerName: 'Attempt', field: 'demog_comment', width: 100 , sortable: true,},
    {
      headerName: 'Actions',
      cellRendererFramework: (params) => (
        <Button onClick={() => handleEdit(params.data)}>Edit</Button>
      ),
    },
  ];

  const columnDefsEcwPat = [

    { field: 'src', filter: true , width:75 ,  sortable: true,cellStyle: {fontSize: '12px'}, headerName: 'Src' ,
      resizable: true,editable: true},
    { field: 'Name', filter: true , width:180 ,  sortable: true,cellStyle: {fontSize: '14px'}, headerName: 'Patient' ,
      resizable: true,editable: true},
    { field: 'DOB', width: 120 , sortable: true,cellStyle: {fontSize: '12px'},headerName: 'Birth',
      resizable: true,},
    { field: 'mdate', width: 120 , sortable: true,cellStyle: {fontSize: '12px'},headerName: 'MDate',
      resizable: true,},
    { field: 'PatientId', filter: true, width: 90, sortable: true,cellStyle: {fontSize: '14px'},headerName: 'ID',
      resizable: true, sortingOrder:['desc']},
    { field: 'Comment', filter: true, width: 100, sortable: true,cellStyle: {fontSize: '12px'},headerName: 'Comment',
      resizable: true},
  ];
  
  const getData = async () => {

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }


    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.billing_temp_patients order by priority  ";

    console.log("Getting data for ", "TempPatientList", dataURL);
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT Billing Files:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'].map(row=>  {
       // console.log(row['dob'], row['dob'].substring(0,10));
       // console.log(format(row['dob'].substring(0,10), 'yyyy-MM-dd'));
        //row['dob'] = format(row['dob'].substring(0,10), 'yyyy-MM-dd');
         return row;
       });

       //console.log("Temp Patients", gridData);

      setRowData(gridData);
    }


};

  const getRecentEcwPatData = async () => {

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0  select top 100 * from apex.rc.vPatient order by mdate desc  ";

    console.log("Getting data for ", "RecentPatients", dataURL);
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT RecentPatients:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'].map(row=>  {
        // console.log(row['dob'], row['dob'].substring(0,10));
        // console.log(format(row['dob'].substring(0,10), 'yyyy-MM-dd'));
        //row['dob'] = format(row['dob'].substring(0,10), 'yyyy-MM-dd');
        return row;
      });

      //console.log("Temp Patients", gridData);

      setRowDataEcwPat(gridData);
    }


  };


  useEffect(()=> {

   getData();
    getRecentEcwPatData();
  
    return () => {
      // this now gets called when the component unmounts
    };


  }, []);


  const updatePatientID = async (tempid, apexid) => {

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }


    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 1 update apex.rc.billing_tpatient  set patientid = ${apexid}, tpatient_status = 1 where  tpat_id = ${tempid}`;

    console.log(dataURL);
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT update response  data:", res2.data);
      getData(); 

    //   let dframe = res2.data['frame0'];
    //   //console.log(dframe);
    //   let myObj = JSON.parse(dframe);
    //   console.log(myObj);

       //console.log("Temp Patients", gridData);

      //setRowData(gridData);
    }


};

  const updateIsPageADemogPage = async (docname, pagenum) => {

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 1 update rc.billing_links set isDemogPage = 1 where document_path= '${docname}' and docpagenum = ${pagenum}  select isDemogPage from rc.billing_links where document_path= '${docname}' and docpagenum = ${pagenum} set rowcount 0 `;

    console.log(dataURL);
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT update response  data:", res2.data);
      //getData();

        let dframe = res2.data['frame0'];
        //console.log(dframe);
        let myObj = JSON.parse(dframe);
        console.log(myObj);
        console.log("Gor Result setting deomg page: ", myObj);

      //console.log("Temp Patients", gridData);

      //setRowData(gridData);
    }


  };

  const updateTempPatientDemogComment = async (tempid, clearit, has) => {

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 1 update apex.rc.billing_tpatient  set demog_comment = isnull(demog_comment, '')  %2B  ' checked', priority = 5 where  tpat_id = ${tempid}`;

    if (clearit === 1) {
      dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 1 update apex.rc.billing_tpatient  set demog_comment = null, priority = 0 where  tpat_id = ${tempid}`;

    }

    if (clearit === 2) {
      dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 1 update apex.rc.billing_tpatient  set demog_comment = 'facesheet', priority = 9 where  tpat_id = ${tempid}`;

    }
    console.log(dataURL);
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT update response  data:", res2.data);
      getData();

      //   let dframe = res2.data['frame0'];
      //   //console.log(dframe);
      //   let myObj = JSON.parse(dframe);
      //   console.log(myObj);

      //console.log("Temp Patients", gridData);

      //setRowData(gridData);
    }


  };

  const refreshData = () => {
    getData();
    getRecentEcwPatData();
  }

  const handleAdd = () => {
    setSelectedPatient(null);
    setIsModalOpen(true);
  };

  const handleEdit = (patient) => {
    setSelectedPatient(patient);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Function to save or update patient data
  const handleSave = (data) => {
    // TODO: Save or update data and close modal
    handleCloseModal();
  };

  const markRowChecked = () => {
    const selectedNodes = gridRef.current.api
        .getSelectedNodes()
        .filter((node) => node.selected);

    if (!selectedNodes || !selectedNodes[0] ) {
      console.log("Select a TEMP Patient to Mark as unsuccessfully tried to match to Apex Patient");
      return
    }
    const datarow = selectedNodes[0].data;
    console.log('Marking TEMP patient as  unsuccessfully tried to match to Apex Patient', datarow);

    updateTempPatientDemogComment( datarow.tpat_id, 0);
  }

  const markRowFaceSheet = () => {
    const selectedNodes = gridRef.current.api
        .getSelectedNodes()
        .filter((node) => node.selected);

    if (!selectedNodes || !selectedNodes[0] ) {
      console.log("Select a TEMP Patient to Mark as Has Facesheet, ready to enter Apex Patient");
      return
    }
    const datarow = selectedNodes[0].data;
    console.log('Marking TEMP patient as  has Facesheet', datarow);

    updateTempPatientDemogComment( datarow.tpat_id, 2);
  }

  const clearRowChecked = () => {
    const selectedNodes = gridRef.current.api
        .getSelectedNodes()
        .filter((node) => node.selected);

    if (!selectedNodes || !selectedNodes[0] ) {
      console.log("Select a TEMP Patient to Mark as unsuccessfully tried to match to Apex Patient");
      return
    }
    const datarow = selectedNodes[0].data;
    console.log('Marking TEMP patient as  unsuccessfully tried to match to Apex Patient', datarow);

    updateTempPatientDemogComment( datarow.tpat_id, 1);
  }

  const markIsDemog = () => {

    console.log("Mark this page as DemogPage", displayedPage, displayedDocument);
    updateIsPageADemogPage(displayedDocument, displayedPage);
    //console.log("Linking to Apex Patient ", selectedClaimPatient);

    // const selectedNodes = gridRef.current.api
    //     .getSelectedNodes()
    //     .filter((node) => node.selected);
    //
    // if (!selectedNodes || !selectedNodes[0] ) {
    //   console.log("Select a TEMP Patient to mark current page as demog the Apex Patient to");
    //   return
    // }
    // const datarow = selectedNodes[0].data;
    // console.log('Linking to selected TEMP Patient', datarow, selectedClaimPatient);
    //
    // updatePatientID( datarow.tpat_id, selectedClaimPatient.PatientId)

  }
  const LinkPatientRow = () => {

    console.log("Linkning  patient");
    let selectedPatientID = undefined;
    if (selectedClaimPatient ) {
      selectedPatientID = selectedClaimPatient.PatientId;
    }else {

      const selectedEcwPatNodes = gridEcwPatRef.current.api
          .getSelectedNodes()
          .filter((node) => node.selected);

      if (!selectedEcwPatNodes || !selectedEcwPatNodes[0] ) {
        console.log("Select a EcwPatient to link the Temp Patient to");
        return
      }
      const ecwPatdatarow = selectedEcwPatNodes[0].data;
      selectedPatientID = ecwPatdatarow.PatientId;
    }

    if (!selectedPatientID) {
      console.log("Select a EcwPatient to link the Temp Patient to");
      return
    }

    console.log("Linking to Apex Patient ", selectedClaimPatient);

    const selectedNodes = gridRef.current.api
    .getSelectedNodes()
    .filter((node) => node.selected);

    if (!selectedNodes || !selectedNodes[0] ) {
        console.log("Select a TEMP Patient to link the Apex Patient to");
        return
    }
  const datarow = selectedNodes[0].data;
  console.log('Linking to selected TEMP Patient', datarow, selectedClaimPatient);

  updatePatientID( datarow.tpat_id, selectedPatientID)

  }

  const handleQuickFilter = (event) => {
    console.log("Quick Filter:",event.target, event.target.value, gridRef.current);
    setQuickFilter(event.target.value );
    setEcwPatQuickFilter(event.target.value );
    gridRef.current.api.setQuickFilter(event.target.value);
    gridEcwPatRef.current.api.setQuickFilter(event.target.value);

};



  const handleEcwPatQuickFilter = (event) => {
    console.log("Quick Filter:",event.target, event.target.value, gridEcwPatRef.current);
    setEcwPatQuickFilter(event.target.value );
    gridEcwPatRef.current.api.setQuickFilter(event.target.value);

  };

//const onSetCurrentPageNum = (pagenum) => {
//     console.log("Currnet Page Numnber Changed", docToDisplay, documentContext, pagenum);
//     setCurrentPageNum(pagenum);

//     if (docToDisplay) {
//     updateDocinfo({
//       ecwdocid: null,
//       apexdocid: null,
//       documentpath : `${docToDisplay.dirpath}/${docToDisplay.filename}`,
//       documentpage: currentPageNum,
//       pagecount : 1
//     });
//   }else {
//     updateDocPageinfo(currentPageNum);
//   }
//  }

  const onSetStartingPageNum = (pagenum) => {
      setstartingPageNum(pagenum);
      console.log("New page Num:", pagenum);
  }

  const onSetCurrentPageNum = (pagenum) => {
    setdisplayedPage(pagenum);
    console.log("New page Num:", pagenum);
  }

  const ShowDocument = (pagenum, docpath) =>{
    setdisplayedPage(pagenum);
    setdisplayedDocument(docpath);
    console.log("Displaying PG", pagenum, docpath);
    handleAgDocument({
      filename : docpath,
      dirpath: '',
      startpage:pagenum
  }, apiURL, onSetpdfToDisplayURL, onSetStartingPageNum);


    }

    const onSetpdfToDisplayURL = (args) => {
        console.log("PDFURL changed:", args);
        setpdfToDisplayURL(args);
      }

      const handleGridRowSelected = (event) => {

        const selectedNodes = event.api
          .getSelectedNodes()
          .filter((node) => node.selected);
    
        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);
        getClaimDocumentLinks(datarow.tpat_id);


      };

  const handleEcwPatGridRowSelected = (event) => {

    const selectedNodes = event.api
        .getSelectedNodes()
        .filter((node) => node.selected);

    const datarow = selectedNodes[0].data;
    console.log('AG Row selected', datarow);

    setQuickFilter( datarow.Name.substring(0,4));
    gridRef.current.api.setQuickFilter(datarow.Name.substring(0,4));

    //getClaimDocumentLinks(datarow.tpat_id);


  };


  return (
<div>
    <Button onClick={openModal}>Patients</Button>

    <FullScreenModal isOpen={isOpen} onClose={closeModal} title="">
<Flex flexDirection={'row'} >
    <VStack  flex={1}>
<HStack>

      <Input as='input' value={quickFilter}  onChange={handleQuickFilter}  ref={initFocusRef} 
                placeholder="filter..." variant="filled" fontSize='sm' aria-multiline />
      <SelectEcwPatient selectedEcwPatient={selectedClaimPatient} setSelectedEcwPatient={onSelectedClaimPatient} />  
      </HStack>
      <HStack>
        <Text>Total: {rowData.length}</Text>
        <Text>Has FS: {rowData.filter(row=>row.priority === 9).length}</Text>
        <Text>Checked: {rowData.filter(row=>row.priority === 5).length}</Text>
        <Text>To Check: {rowData.filter(row=>row.priority === 0).length}</Text>
        <Button onClick={markIsDemog}>MarkIsDemog</Button>
      </HStack>
      <HStack>

      <Button onClick={LinkPatientRow}>LinkPatient</Button>
      <Button onClick={refreshData}>Refresh Data</Button>
      
        <Checkbox>Include Status 1</Checkbox>
        <Button onClick={markRowChecked}>MarkChecked</Button>
        <Button onClick={clearRowChecked}>ClearChecked</Button>
        <Button onClick={markRowFaceSheet}>Facesheet</Button>

        </HStack>

      <div className="ag-theme-alpine" style={{ height: 400 , width: '40vw'}}>
        <AgGridReact 
            columnDefs={columnDefs} 
            rowData={rowData} 
            ref={gridRef} 
            rowSelection="single"
            onSelectionChanged={handleGridRowSelected}
        />
      </div>
      <Input as='input' value={ecwPatquickFilter}  onChange={handleEcwPatQuickFilter}
             placeholder="filter..." variant="filled" fontSize='sm' aria-multiline />
      <div className="ag-theme-alpine" style={{ height: 400 , width: '40vw'}}>
        <AgGridReact
            columnDefs={columnDefsEcwPat}
            rowData={rowDataEcwPat}
            ref={gridEcwPatRef}
            rowSelection="single"
            onSelectionChanged={handleEcwPatGridRowSelected}
        />
      </div>
      </VStack>
      <Box flex={1} style={{  width: '60vw'}} >
      <HStack align={'flex-end'}>
             {claimDocumentLinks && claimDocumentLinks.map((row, indx)=>{
              return(<Button bgColor={row.isdemogpage == 1 ? 'green' : 'gray'} onClick={()=>ShowDocument(row.docpagenum, row.document_path)}>docuPage{row.docpagenum}</Button>)
             })}
             </HStack>
            

      <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum} setCurrentPageNum={onSetCurrentPageNum}/>
      </Box>

      </Flex>
      </FullScreenModal>
      </div>

  );
};

export default PatientManagementComponent;
