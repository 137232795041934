import React from "react";
import useState from 'react-usestateref';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Button } from "@chakra-ui/react";
import FullScreenModal from "../Components/FullScreenModel";
import { HStack , VStack, Flex, Input, Text, Checkbox, Box} from "@chakra-ui/react";
import ApexPdfjsViewer from "../Components/ApexPdfjsViewer";
import { useDocument } from '../Contexts/DocumentContext';
import apiConfig from "../apiConfig";
import { handleEcwDocument, handleFolderDocument, handleAgDocument } from '../utils/ecw/hadleDocuments';
import CheckboxRenderer from "../Components/CheckboxRenderer";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import axios from "axios";
import { format } from 'date-fns'
import ManageBillingTempClaimCpts from "../Features/billing/ManageBillingTempClaimCpts";
import { current } from "@reduxjs/toolkit";

function MoveButtons(props) {
    return (
      <div bgColor={'red'}>
        <button onClick={props.moveRowUp} >Up</button>
        <button onClick={props.moveRowDown}>Down</button>
      </div>
    );
  }
  

function App({claimID}) {

  const [isOpen, setIsOpen] = useState(false);
  console.log("Current Claim ID:", claimID);

  let apiURL = apiConfig.REACT_APEX_BASE_API;
  const claimsCPTGridRef = React.useRef();
  const claimsICDGridRef = React.useRef();
  const apexICDGridRef = React.useRef();
  const claimsCPTICDGridRef = React.useRef();
  
  const initFocusRef = React.useRef();
  const { documentContext, updateDocinfo, updateDocPageinfo } = useDocument();


  const [docToDisplay, setDocToDisplay] = useState();
  const[claimDocumentLinks, setClaimDocumentLinks] = useState();
  const [startingPageNum, setstartingPageNum] = useState(1)
  const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [currentClaimID, setCurrentClaimId] = useState();
  const [claimCptRowdata, setClaimCptRowdata] = useState();
  const [selectedRow, setSelectedRow] = useState(null);

  const [showPrevICDData, setShowPrevICDData] = useState(false);
  const [patientPrevRefData, setPatientPrevRefData ] = useState();
  const [icdRefData, seticdRefData] = useState();
  const [quickICDFilter, setQuickICDFilter] = useState('');
  const [claimIcdData, setClaimicdData] = useState([]);
  const [claimCPTIcdData, setClaimCPTicdData] = useState([]);

  const [selectedCPTRow, setSelectedCPTRow] = useState(null);
  const [newItem, setNewItem, newItemRef ] = useState({
    tcpt_id: '',
    tclaim_id: '',
    cptcode: '',
    cptdesc: '',
    modifiers: '',
    cpt_assigned_icds: '',
    units: 1,
    dxcodes: '',
    comment: '',
    sdos: null,
    edos: null,
  });

  const [claimStatus, setClaimStatus, claimStatusRef ] = useState({
    cptsChecked: false,
    modifiersChecked: false,
    icdsChecked: false,
    claimLocked: false,
    claimReadyforOOV: false,
    apex_encounter_id: null,
    apex_claim_status : null,
    claim_facility: null,
    claim_servicedt : null,
    claim_pos: null,
    claim_admit_date: null,
    claim_discharge_date: null,
  });


  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const ShowDocument = (pagenum, docpath) =>{

    handleAgDocument({
      filename : docpath,
      dirpath: '',
      startpage:pagenum
  }, apiURL, onSetpdfToDisplayURL, setstartingPageNum);


    }

    const onSetpdfToDisplayURL = (args) => {
        console.log("PDFURL changed:", args);
        setpdfToDisplayURL(args);
      }

      const onSetNewItem = (row) => {
        console.log("SetNewItem Called", row);
        setNewItem(row);
      }
    

  const onSetCurrentPageNum = (pagenum) => {
    console.log("Currnet Page Numnber Changed", docToDisplay, documentContext, pagenum);
    setCurrentPageNum(pagenum);

    if (docToDisplay) {
    updateDocinfo({
      ecwdocid: null,
      apexdocid: null,
      documentpath : `${docToDisplay.dirpath}/${docToDisplay.filename}`,
      documentpage: currentPageNum,
      pagecount : 1
    });
  }else {
    updateDocPageinfo(currentPageNum);
  }
  }



const getPatientPrevIcdData = async (patientid) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found. User must log in.");
    return;
  }

  let authHeader =  {
    "Authorization": `Bearer ${token}`,  // ✅ Attach token
    "Content-Type": "application/json"
  }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from rc.billing_pat_icditemref   where patientid = ${patientid} order by enc_date desc `;

    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT Patient Prev Ref Data:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];

      setPatientPrevRefData(gridData);
    }

};


const getClaimStatusInfo = async (claimid) => {

    if (!claimid) return;
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found. User must log in.");
    return;
  }

  let authHeader =  {
    "Authorization": `Bearer ${token}`,  // ✅ Attach token
    "Content-Type": "application/json"
  }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0   select claim_cpt_checked, claim_modifiers_checked, claim_icds_checked, claim_locked, claim_ready_for_oov, 
     apex_encounter_id, apex_claim_status = 'pending', facility, pos , servicedt, isnull(admit_date, '') as admit_date, isnull(discharge_date,'') as discharge_date
    from rc.billing_tclaim where tclaim_id = ${claimid} `;

    console.log("Executing SQL:", dataURL);
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT Claims Status Retrieved:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      let claimStatusInfo = gridData[0];
      setClaimStatus({
        cptsChecked: claimStatusInfo.claim_cpt_checked ,
      modifiersChecked: claimStatusInfo.claim_modifiers_checked ,
      icdsChecked: claimStatusInfo.claim_icds_checked ,
      claimLocked: claimStatusInfo.claim_locked ,
      claimReadyforOOV: claimStatusInfo.claim_ready_for_oov ,
      apex_encounter_id: claimStatusInfo.apex_encounter_id,
      apex_claim_status :  claimStatusInfo.apex_claim_status,
      claim_facility: claimStatusInfo.facility,
      claim_servicedt : claimStatusInfo.servicedt,
      claim_pos: claimStatusInfo.pos,
      claim_admit_date: claimStatusInfo.admit_date,
      claim_discharge_date: claimStatusInfo.discharge_date

    });

      //setPatientPrevRefData(gridData);
    }

};




  const refreshIcdRefData = async (claimid) => {

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from apex.rc.billing_icditemref order by freqcount desc  `;

    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT Billing Files:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];

      seticdRefData(gridData);
    }

};

  const refreshCptData = async (claimid) => {
    if (!claimid) return;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }


    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from rc.billing_temp_claims tcpt
    where  tclaim_id = ${claimid}    order by tcpt.dispindex `;

    console.log("Exec query refreshCptData:", dataURL);
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT CPT data for Claim:", res2.data);

      try {
        let dframe = res2.data['frame0'];
        //console.log(dframe);
        let myObj = JSON.parse(dframe);
        console.log(myObj);
        let gridData = myObj['rows'];
  
        setClaimCptRowdata(gridData);
        if (gridData[0] && gridData[0].assigned_icds)  loadClaimICDsToGrid(gridData[0].assigned_icds);
        getPatientPrevIcdData(gridData[0].patientId);


      }
      catch(err) {
       console.log("Error:", err);
      }


    }

};

React.useEffect(()=> {
  //if (currentClaimID !== claimID) {
   console.log("Current Claim1", claimID)
   setCurrentClaimId(claimID);
   refreshCptData(claimID);
   getClaimDocumentLinks(claimID);
   getClaimStatusInfo(claimID);
 //}
}, [claimID]);

// React.useEffect(()=> {

//    refreshIcdRefData();
//    console.log("Current Claim0", claimID)
//    setCurrentClaimId(claimID);
//    refreshCptData(claimID);
//    getClaimDocumentLinks(claimID);
//    getClaimStatusInfo(claimID);

// }, []);

React.useEffect(()=> {
  refreshIcdRefData();
},[]);


const updateClaimCPTAssignedICDs = async (sqltext)  =>{

    console.log("Will get ICD links for claim :", sqltext);

  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found. User must log in.");
    return;
  }

  let authHeader =  {
    "Authorization": `Bearer ${token}`,  // ✅ Attach token
    "Content-Type": "application/json"
  }
   

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=${sqltext} `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add document refs lookup:", res2.data);

      refreshCptData(currentClaimID);
      handleClear();
     
    }

  }


  const getClaimDocumentLinks = async (claimid)  =>{

    console.log("Will get document reference links for claim :", claimid);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }
   

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=select docpagenum, document_path, min(entity_type) as entity_type from rc.billing_doc_links  where tclaim_id = ${claimid}  group by document_path, docpagenum  `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add document refs lookup:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      if (dframe) {
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      if (gridData) {
        setClaimDocumentLinks(gridData);
      }
      console.log("Claim Document Links Grid", gridData);
    }
    }


  }

  const loadClaimICDsToGrid = (assignedicds) => {

    let claimAssignedICDs = [];
    setClaimicdData(claimAssignedICDs);
    if (assignedicds) {

        claimAssignedICDs = assignedicds.split(':').map(icdcode => {
            //console.log(icdRefData);
            console.log(icdcode, icdRefData.filter(row=> row.icdcode === icdcode));
            let icdname = icdRefData.filter(row=> row.icdcode === icdcode)[0].icdname;
            return {
                icdcode: icdcode,
                icdname : icdname
            }
        });
        setClaimicdData(claimAssignedICDs);
    }


  }
  const saveClaimICDstoDB = async (claimid, assignedicds)  =>{

    console.log("Will get document reference links for claim :", claimid);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }
   

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=update rc.billing_tclaim set assigned_icds = '${assignedicds}'  where tclaim_id = ${claimid}  `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from save_assigned_icds:", res2.data);
      
    }


  }


  const saveClaimCPTIndextoDB = async (claimid, cptindexmap)  =>{

    console.log("Will get document reference links for claim :", claimid);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec rc.billsp_update_tcpt_dispindex @tclaim_id = ${claimid}, @dispindexmap = '${cptindexmap}'  `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from save_assigned_icds:", res2.data);
      
    }


  }
  
  const handleICDRowSelected = (event) => {

    const selectedNodes = event.api
      .getSelectedNodes()
      .filter((node) => node.selected);
      
    setSelectedRow(selectedNodes.length ? selectedNodes[0] : null);
    if (selectedNodes.length) {
    const datarow = selectedNodes[0].data;
    console.log('AG ICD selected, should add it to calim', datarow);
    }
  };  

  const handleClaimICDRowSelected = (event) => {

    const selectedNodes = event.api
      .getSelectedNodes()
      .filter((node) => node.selected);
      
    setSelectedRow(selectedNodes.length ? selectedNodes[0] : null);
    const datarow = selectedNodes[0].data;
    console.log('AG Claim ICD selected, ', datarow);
  };  

  const handleClaimCPTICDRowSelected = (event) => {

    const selectedNodes = event.api
      .getSelectedNodes()
      .filter((node) => node.selected);
      
    setSelectedRow(selectedNodes.length ? selectedNodes[0] : null);
    if (selectedNodes.length) {
    const datarow = selectedNodes[0].data;
    console.log('AG Claim CPT ICD selected, ', datarow);
    }
  };  

  

  

  const handleCptRowSelected = (event) => {

    const selectedNodes = event.api
      .getSelectedNodes()
      .filter((node) => node.selected);
      
    if (selectedNodes.length > 1) {

        handleClear();
        return;
    }
    setSelectedCPTRow(selectedNodes.length ? selectedNodes[0] : null);

    if (selectedNodes.length) {

    console.log('CPT ROW Selected is : ', selectedCPTRow);
    const datarow = selectedNodes[0].data;
    console.log('AG Row selected', datarow);

    setNewItem({
        tcpt_id: datarow.claim_id,
        tclaim_id: datarow.claimcpt_id,
        cptcode: datarow.cptcode,
        cptdesc: datarow.cptdesc,
        cpt_status: datarow.cpt_status === 1 ? true : false,
        modifiers: datarow.modifiers,
        cpt_assigned_icds: datarow.cpt_assigned_icds,
        dxcodes: datarow.cpt_dx,
        units: datarow.units,
        comment: datarow.cpt_comment
      });

    // setstartingPageNum(datarow.docpagenum);
    // setDocToDisplay({
    //     document_path : datarow.document_path,
    //     docpagenum : datarow.docpagenum
    // })

   
    // handleAgDocument({
    //     filename : datarow.document_path,
    //     dirpath: '',
    //     startpage: datarow.docpagenum
    // }, apiURL, onSetpdfToDisplayURL, setstartingPageNum);

}

    //console.log('Document to Display', docToDisplay);

    //setSelectedBillingSheetFile(datarow);
    //setQuickFilenameFilter(datarow.CustomName);

  };  
  
  const frameworkComponents = {
    MoveButtons: MoveButtons,
  };

  const moveRowUp = () => {
    if (!selectedRow || selectedRow.rowIndex === 0) return;
    const newRowData = [...claimCptRowdata];
    const currentIndex = selectedRow.rowIndex;
    const [movedRow] = newRowData.splice(currentIndex, 1);
    newRowData.splice(currentIndex - 1, 0, movedRow);
    setClaimCptRowdata(newRowData);
  };

  const moveRowDown = () => {
    if (!selectedRow || selectedRow.rowIndex === claimCptRowdata.length - 1) return;
    const newRowData = [...claimCptRowdata];
    const currentIndex = selectedRow.rowIndex;
    const [movedRow] = newRowData.splice(currentIndex, 1);
    newRowData.splice(currentIndex + 1, 0, movedRow);
    setClaimCptRowdata(newRowData);
  };

  const cptRowIndex = (params) => {
    //console.log("ROw index", params.node);
    return params.node.rowIndex + 1;
  };

  const icdRowIndex = (params) => {
    //console.log("ROw index", params.node);
    return params.node.rowIndex + 1;
  };

  const handleRowsSorted = (event) => {
    event.api.refreshCells();
  }

  const updateClaimAssignedICDS = (claimd, assignedICDs) => {

    console.log("Save claim ICDs", assignedICDs);
    if(claimd && assignedICDs && assignedICDs.length > 0) {
        const concatenatedString = assignedICDs.map(obj => {
           console.log( obj );
           return obj.icdcode;
        } ).join(':');
        console.log(concatenatedString);

        saveClaimICDstoDB(claimd, concatenatedString);

    }
  }

  

  function updateICDOrder(currentICDIndexes, fromIndex, toIndex) {
    // Split the current ICD indexes into an array
    const icdArray = currentICDIndexes.split(',');
  
    // Convert fromIndex and toIndex to 0-based indices
    fromIndex = parseInt(fromIndex) - 1;
    toIndex = parseInt(toIndex) - 1;
  
    // Check if the provided indices are valid
    if (
      fromIndex < 0 ||
      fromIndex >= icdArray.length ||
      toIndex < 0 ||
      toIndex >= icdArray.length ||
      fromIndex === toIndex
    ) {
      return currentICDIndexes; // No change needed
    }
  
    // Remove the ICD from the original position
    const removedICD = icdArray.splice(fromIndex, 1)[0];
  
    // Insert the ICD into the new position
    icdArray.splice(toIndex, 0, removedICD);
  
    // Join the array back into a comma-separated string
    const newICDIndexes = icdArray.join(',');
  
    return newICDIndexes;
  }
  
  // Example usage:
//   const currentICDIndexes = "1,2,3,4,5";
//   const fromIndex = "3";
//   const toIndex = "1";
//   const newICDIndexes = updateICDOrder(currentICDIndexes, fromIndex, toIndex);
//   console.log(newICDIndexes); // Output: "3,1,2,4,5"
  
  
 

  const addSelectedICD = () => {

    const selectedNodes = apexICDGridRef.current.api
      .getSelectedNodes()
      .filter((node) => node.selected);
      
    setSelectedRow(selectedNodes.length ? selectedNodes[0] : null);
    if (selectedNodes.length) {
    const datarow = selectedNodes[0].data;
    console.log('ICD Row selected, should add to claim', datarow);
    let newclaimIcdData = [   ...claimIcdData, datarow,]; 
    updateClaimAssignedICDS(currentClaimID, newclaimIcdData);
    setClaimicdData(newclaimIcdData);
    }

  }

  const removeSelectedICD = () => {

    const selectedNodes = claimsICDGridRef.current.api
    .getSelectedNodes()
    .filter((node) => node.selected);
    
  setSelectedRow(selectedNodes.length ? selectedNodes[0] : null);

  if (selectedNodes.length) {
  const datarow = selectedNodes[0].data;
  console.log('ICD Row selected, should remove it from claim', datarow);
  let newclaimIcdData = claimIcdData.filter((row) => row.icdcode !== datarow.icdcode)
  updateClaimAssignedICDS (currentClaimID, newclaimIcdData) ;
  setClaimicdData(newclaimIcdData);
  }

  }

  const updateICDArray = (icdObjectsArray, indexesToAdd) => {
    // Split the indexesToAdd into an array
    const indexesToAddArray = indexesToAdd.split(',');
  
    // Loop through the ICD objects array
    for (let i = 0; i < icdObjectsArray.length; i++) {
      const icdObject = icdObjectsArray[i];
      
      // Split the current cpt_assigned_icds into an array
      const currentICDArray = icdObject.cpt_assigned_icds.split(',');
  
      // Check if each index to add already exists in the current ICD list
      for (let j = 0; j < indexesToAddArray.length; j++) {
        const indexToAdd = indexesToAddArray[j];
  
        if (!currentICDArray.includes(indexToAdd)) {
          // If the indexToAdd doesn't exist, add it to the end of the current list
          currentICDArray.push(indexToAdd);
        }
      }
  
      // Update the cpt_assigned_icds column with the new values
      icdObject.cpt_assigned_icds = currentICDArray.join(',');
    }
  
    // Return the updated ICD objects array
    return icdObjectsArray;
  }
  
  const updateICDListAdd = (currentICDIndexes, indexToAddOrRemove) => {
    // Split the current ICD indexes into an array
    console.log("Ready to parse ICDs", currentICDIndexes, indexToAddOrRemove);
    const icdArray = currentICDIndexes ? currentICDIndexes.split(',') : [];
  
    // Remove any leading/trailing whitespace from the input index
    //indexToAddOrRemove = indexToAddOrRemove.trim();
  
    // Check if the indexToAddOrRemove is already in the list
    const indexExists = icdArray.includes(`${indexToAddOrRemove}`);
    console.log('CHeck if exists', indexExists, icdArray, indexToAddOrRemove)
  
    if (!indexExists && icdArray.length < 4) {
      // If the indexToAddOrRemove is not already in the list and there are less than 5 ICDs,
      // add it to the end of the array
      icdArray.push(indexToAddOrRemove);
    } else if (indexExists) {
        //NO ACTION NEEDED
      // If the indexToAddOrRemove already exists in the list, remove it
    //   const indexToRemove = icdArray.indexOf(indexToAddOrRemove);
    //   icdArray.splice(indexToRemove, 1);
    }
  
    // Join the array back into a comma-separated string
    const newICDIndexes = icdArray.join(',');
  
    return newICDIndexes.replace(/^,|,$/g, ''); 
  }

  const addICDToAllCPTs = () => {

    const selectedNodes = claimsICDGridRef.current.api
    .getSelectedNodes()
    .filter((node) => node.selected);
    
  setSelectedRow(selectedNodes.length ? selectedNodes[0] : null);
  if (selectedNodes.length >= 1) {

    const indexToAddArr = [];
    for (let j = 0; j < selectedNodes.length; j++) {
        indexToAddArr.push(selectedNodes[j].rowIndex + 1);
    }

    const indexesToAdd = indexToAddArr.join(',');
    console.log("Will add indexes ", indexesToAdd);
  
    let newclaimCptRowdata = claimCptRowdata;
    for (let j = 0; j < newclaimCptRowdata.length; j++) {
       // console.log("Current values", newclaimCptRowdata[j].cptcode, newclaimCptRowdata[j].cpt_assigned_icds); 
        for (let k = 0; k < indexToAddArr.length; k++) {
           // console.log("After adding ", indexToAddArr[k], updateICDListAdd(newclaimCptRowdata[j].cpt_assigned_icds, indexToAddArr[k]));
            newclaimCptRowdata[j].cpt_assigned_icds = updateICDListAdd(newclaimCptRowdata[j].cpt_assigned_icds, indexToAddArr[k]);
        }
    }

    let updateSQL = newclaimCptRowdata.map(row=>{
        let sql = ` update apex.rc.billing_tcpt set cpt_assigned_icds = '${row.cpt_assigned_icds}'  where tcpt_id = ${row.tcpt_id}   `
        return sql;
    }).join('  ');
    //setClaimCptRowdata(newclaimCptRowdata);

    console.log("Update SQL:", updateSQL);
    updateClaimCPTAssignedICDs(updateSQL);   
    
  }

  }

const resetCPTICDs = () => {

    let newclaimCptRowdata = claimCptRowdata;
    let updateSQL = newclaimCptRowdata.map(row=>{
        let sql = ` update apex.rc.billing_tcpt set cpt_assigned_icds = ''  where tcpt_id = ${row.tcpt_id}   `
        return sql;
    }).join('  ');
    //setClaimCptRowdata(newclaimCptRowdata);

    console.log("Update SQL:", updateSQL);
    updateClaimCPTAssignedICDs(updateSQL);   

}
  const addICDToSelectedCPTs = () => {

    const selectedNodes = claimsICDGridRef.current.api
    .getSelectedNodes()
    .filter((node) => node.selected);
    
  setSelectedRow(selectedNodes.length ? selectedNodes[0] : null);

  if (selectedNodes.length >= 1) {

    const indexToAddArr = [];
    for (let j = 0; j < selectedNodes.length; j++) {
        indexToAddArr.push(selectedNodes[j].rowIndex + 1);
    }

    const indexesToAdd = indexToAddArr.join(',');
    console.log("Will add indexes ", indexesToAdd);

    const selectedCPTNodes = claimsCPTGridRef.current.api
    .getSelectedNodes()
    .filter((node) => node.selected);
  
    let newclaimCptRowdata = selectedCPTNodes.map(row=> row.data); //claimCptRowdata;
    for (let j = 0; j < selectedCPTNodes.length; j++) {
       // console.log("Current values", newclaimCptRowdata[j].cptcode, newclaimCptRowdata[j].cpt_assigned_icds); 
        for (let k = 0; k < indexToAddArr.length; k++) {
           // console.log("After adding ", indexToAddArr[k], updateICDListAdd(newclaimCptRowdata[j].cpt_assigned_icds, indexToAddArr[k]));
            newclaimCptRowdata[j].cpt_assigned_icds = updateICDListAdd(newclaimCptRowdata[j].cpt_assigned_icds, indexToAddArr[k]);
        }
    }

    let updateSQL = newclaimCptRowdata.map(row=>{
        let sql = ` update apex.rc.billing_tcpt set cpt_assigned_icds = '${row.cpt_assigned_icds}'  where tcpt_id = ${row.tcpt_id}   `
        return sql;
    }).join('  ');
    //setClaimCptRowdata(newclaimCptRowdata);

    console.log("Update SQL:", updateSQL);
    updateClaimCPTAssignedICDs(updateSQL);   

  const datarow = selectedNodes[0].data;
  console.log('ICD Row selected, add to SELECTED claim CPT', datarow);
//   let newclaimIcdData = claimIcdData.filter((row) => row.icdcode !== datarow.icdcode)
//   setClaimicdData(newclaimIcdData);

  }

  }



  const removeSelectedICDFromCPT = () => {

    const selectedNodes = claimsCPTICDGridRef.current.api
    .getSelectedNodes()
    .filter((node) => node.selected);
    
  setSelectedRow(selectedNodes.length ? selectedNodes[0] : null);
  const datarow = selectedNodes[0].data;
  console.log('ICD Row selected, should remove it from claim CPT', datarow);
  let newclaimIcdData = claimIcdData.filter((row) => row.icdcode !== datarow.icdcode)
  setClaimicdData(newclaimIcdData);

  }

  const onTCPTRowDragMove = React.useCallback(

    (event) => {

    
      var movingNode = event.node;
      var overNode = event.overNode;
      var rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        if (claimCptRowdata.length > 0) {
        var movingData = movingNode.data;
        var overData = overNode.data;
        var fromIndex = claimCptRowdata.indexOf(movingData);
        var toIndex = claimCptRowdata.indexOf(overData);
        var newStore = claimCptRowdata.slice();
        moveInArray(newStore, fromIndex, toIndex);
        //claimCptRowdata = newStore;
        setClaimCptRowdata(newStore);

        let curdispindexMap = 
        newStore.reduce((total, row, curvalindex, inputarr)=> { 
          // console.log("ROW in loop:", row);
           return  total == '' ?  '1' + '_' +  row.tcpt_id :  total + ':' + (curvalindex + 1) + '_' + row.tcpt_id;
        }, '');

       // console.log('TCPT Row moved', curdispindexMap, newStore);
        saveClaimCPTIndextoDB(currentClaimID, curdispindexMap);
        claimsCPTGridRef.current.api.clearFocusedCell();
    }
      }
      function moveInArray(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
    },
    [claimCptRowdata]
  );

  
  const onRowClaimICDDragMove = React.useCallback(
    (event) => {
      var movingNode = event.node;
      var overNode = event.overNode;
      var rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        var movingData = movingNode.data;
        var overData = overNode.data;
        var fromIndex = claimIcdData.indexOf(movingData);
        var toIndex = claimIcdData.indexOf(overData);
        var newStore = claimIcdData.slice();
        moveInArray(newStore, fromIndex, toIndex);
        //claimCptRowdata = newStore;
        updateClaimAssignedICDS (currentClaimID, newStore) ;
        setClaimicdData(newStore);
        claimsICDGridRef.current.api.clearFocusedCell();

      }
      function moveInArray(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
    },
    [claimIcdData]
  );

  const onRowClaimCPTICDDragMove = React.useCallback(
    (event) => {
      var movingNode = event.node;
      var overNode = event.overNode;
      var rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        var movingData = movingNode.data;
        var overData = overNode.data;
        var fromIndex = claimCPTIcdData.indexOf(movingData);
        var toIndex = claimCPTIcdData.indexOf(overData);
        var newStore = claimCPTIcdData.slice();
        moveInArray(newStore, fromIndex, toIndex);
        //claimCptRowdata = newStore;
        setClaimCPTicdData(newStore);
        claimsCPTICDGridRef.current.api.clearFocusedCell();
      }
      function moveInArray(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
    },
    [claimCPTIcdData]
  );

  

  const handleICDQuickFilter = (event) => {
    console.log("Quick Filter:",event.target, event.target.value, apexICDGridRef.current);
    setQuickICDFilter(event.target.value );
    apexICDGridRef.current.api.setQuickFilter(event.target.value);
    apexICDGridRef.current.api.setQuickFilter(event.target.value);
};

const refreshClaimandCPTinfo = () => {
    console.log("Current Claim ID: ", currentClaimID);

    setClaimCptRowdata();
    setClaimDocumentLinks();
    setClaimStatus({
        cptsChecked: false ,
      modifiersChecked: false,
      icdsChecked: false ,
      claimLocked: false ,
      claimReadyforOOV: false,
      apex_encounter_id: '',
      apex_claim_status :  '',
      claim_facility: '',
      claim_servicedt : null,
      claim_pos: null,
      claim_admit_date: null,
      claim_discharge_date: null

    });
    refreshCptData(currentClaimID);
    getClaimDocumentLinks(currentClaimID);
    getClaimStatusInfo(currentClaimID);


}

const saveBillingTClaimCPT = async (rowdata) => {

    //console.log("Will save current CPT row:", rowdata, documentContext, selectedEcwBillingClaim);

    console.log("DOCUMENT CONTEXT", documentContext);
    let docpagenum = '';

    let docpath = '';

     if (documentContext && documentContext.docinfo   ) {
        docpagenum = documentContext.docinfo.documentpage;
        docpath= documentContext.docinfo.documentpath;
        console.log("DOC CONTXT", docpagenum, docpath);
     }


  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found. User must log in.");
    return;
  }

  let authHeader =  {
    "Authorization": `Bearer ${token}`,  // ✅ Attach token
    "Content-Type": "application/json"
  }
    let urlPrefix = apiURL + '/exsql?dbserver=';

    let claim_id_to_use = currentClaimID;
   
    // @sdos = '${format(rowdata.sdos, 'yyyy-MM-dd')}',
    // @edos = '${format(rowdata.edos, 'yyyy-MM-dd')}',
    // @docpagenum  = ${docpagenum},
    // @document_path = '${docpath}',
    // @ecwdoc_id  = NULL,
    // @apexdoc_id  = NULL,

// @cptdesc = '${rowdata.cptdesc}',
    console.log("Ready to exe proc billsp_manage_tcpt", rowdata);
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tcpt
        @arg_op = 'E'  ,
        @tcpt_id  = ${rowdata.claimcpt_id} ,
        @tclaim_id  = ${claim_id_to_use},
        @cptcode = '${rowdata.cptcode}',
        
        @tcpt_status = ${rowdata.cpt_status ? 1 : 0},
        @modifiers = '${rowdata.modifiers}',
        @dxcodes= '${rowdata.dxcodes}',
        @cpt_assigned_icds = '${rowdata.cpt_assigned_icds}',
        @units = ${rowdata.units},
        @comment = '${rowdata.comment}'

       `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add temp Billing claim:", res2.data);
      // let dframe = res2.data['frame0'];
      // //console.log(dframe);
      // let myObj = JSON.parse(dframe);
      // console.log(myObj);
      // let gridData = myObj['rows'];
      // rowdata = {...rowdata , tcpt_id : gridData[0].tcpt_id};
      // console.log("NewRowData after insert:", rowdata);
      refreshCptData(currentClaimID);
      handleClear();


    }


  }

const handleCPTRowUpdate = () => {
    console.log("Update row called");

    const selectedNodes = claimsCPTGridRef.current.api
      .getSelectedNodes()
      .filter((node) => node.selected);
    if (!selectedNodes || selectedNodes.length === 0) {
      console.log("UPdate Called, no row selected");
      //setisCPTItemSelected(isItemSelected());
      return;
    }
    console.log("Selected Nodes", selectedNodes);
    const selectedItem = selectedNodes[0].data;

    if (selectedItem) {
      console.log("Update row called on selected item: ", newItem, selectedItem);

     // console.log("Add row called", newItemRef.current, selectedEcwCPT, selectedEcwBillingClaim);



      let rowdata = {
        claimcpt_id: selectedItem.tcpt_id,
        tclaim_id: selectedItem.claim_id,
        cptcode: selectedItem.cptcode,
        cptdesc: selectedItem.cptdesc,
        sdos: newItemRef.current.sdos,
        edos: newItemRef.current.edos,
        cpt_status: newItemRef.current.cpt_status,
        comment: newItemRef.current.comment,
        units: newItemRef.current.units >= 0 ? newItemRef.current.units : 1,
        modifiers: newItemRef.current.modifiers,
        dxcodes: newItemRef.current.dxcodes,
        cpt_assigned_icds: newItemRef.current.cpt_assigned_icds
      }

     saveBillingTClaimCPT(rowdata);

      // const updatedData = rowData.map(item =>
      //   item.tcpt_id === selectedItem.tcpt_id ? selectedItem : item
      // );
      // setRowData(updatedData);
      //setSelectedItem(null);
    }
  };

  const handleClear = () => {
    //setSelectedItem(null);
    setNewItem({
        tcpt_id: '',
        tclaim_id: '',
        cptcode: '',
        cptdesc: '',
        modifiers: '',
        cpt_assigned_icds: '',
        units: '',
        dxcodes: '',
        comment: '',
        sdos: null,
        edos: null,
    });
  }

  const handleClaimCptReadyChange = (event) => {

  
    console.log("Mark Claim CPT s ready to bill", currentClaimID, event, !newItemRef.current.cpt_status);
    onSetNewItem({ ...newItemRef.current, cpt_status: !newItemRef.current.cpt_status });
    //setIsClaimCptReadyChecked(!isClaimCptReadyChecked);

  }

  const onClaimStatusCptsChecked = () => {
    let updateSQL = ` update rc.billing_tclaim set claim_cpt_checked = ${!claimStatusRef.current.cptsChecked ? 1 : 0} where tclaim_id = ${currentClaimID} `;
    updateClaimCPTAssignedICDs(updateSQL);  
    setClaimStatus({ ...claimStatusRef.current, cptsChecked: !claimStatusRef.current.cptsChecked});
  }

  const onClaimStatusModsChecked = () => {
    let updateSQL = ` update rc.billing_tclaim set claim_modifiers_checked = ${!claimStatusRef.current.modifiersChecked ? 1 : 0} where tclaim_id = ${currentClaimID} `;
    updateClaimCPTAssignedICDs(updateSQL);  
    setClaimStatus({ ...claimStatusRef.current, modifiersChecked: !claimStatusRef.current.modifiersChecked});
  }

  const onClaimStatusICDsChecked = () => {
    let updateSQL = ` update rc.billing_tclaim set claim_icds_checked = ${!claimStatusRef.current.icdsChecked ? 1 : 0} where tclaim_id = ${currentClaimID} `;
    updateClaimCPTAssignedICDs(updateSQL); 
    setClaimStatus({ ...claimStatusRef.current, icdsChecked: !claimStatusRef.current.icdsChecked});
  }

  const onClaimStatusReadyOOV = () => {
    let updateSQL = ` update rc.billing_tclaim set claim_ready_for_oov = ${!claimStatusRef.current.claim_ready_for_oov? 1 : 0} where tclaim_id = ${currentClaimID} `;
    updateClaimCPTAssignedICDs(updateSQL); 
    setClaimStatus({ ...claimStatusRef.current, claim_ready_for_oov: !claimStatusRef.current.claim_ready_for_oov});
  }

  const onClaimStatusLocked = () => {
    let updateSQL = ` update rc.billing_tclaim set claim_locked = ${!claimStatusRef.current.claimLocked? 1 : 0} where tclaim_id = ${currentClaimID} `;
    updateClaimCPTAssignedICDs(updateSQL); 
    setClaimStatus({ ...claimStatusRef.current, claimLocked: !claimStatusRef.current.claimLocked});
  }

  const onAutoAssignModifiers = async () => {
    console.log("Will auto assign Modifiers for claim :", currentClaimID);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }
   

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec rc.billsp_cpt_autoassign_modifiers   @claim_id = ${currentClaimID}  `;


    console.log("SQL to execute auto assign mod : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from auto assign modifiers:", res2.data);
      refreshCptData(currentClaimID);
    }
  }
  

  
  const onCreateOOV_ineCW = async () => {
    console.log("Will create OOV for temp claim :", currentClaimID);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }



    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec rc.billsp_create_ecw_outoff_enc   @tclaim_id = ${currentClaimID}  `;


    console.log("SQL to execute auto assign mod : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from auto create OOV :", res2.data);

      let dframe = res2.data['frame0'];
      //console.log(dframe);
        if (dframe) {
        let myObj = JSON.parse(dframe);
        console.log(myObj);
        let gridData = myObj['rows'];

        let apex_encounter_id = gridData[0].apex_encounter_id;
        console.log("Apex Encoutne Created", apex_encounter_id);

        refreshCptData(currentClaimID);
        getClaimStatusInfo(currentClaimID);
        
        }

    }
}
  
  

  //  isDisabled={(patientPrevRefData && patientPrevRefData.length > 0) ? false : true}
  
  let diagCodesRef =  showPrevICDData && patientPrevRefData.length > 0 ? patientPrevRefData : icdRefData;
  let showEditView = ((!claimsCPTGridRef.current) || ( claimsCPTGridRef.current && claimsCPTGridRef.current.api
    .getSelectedNodes()
    .filter((node) => node.selected).length != 1 )) ? false:true;

  return (
    <div>
      <Button onClick={openModal}>Edit Dx</Button>

      <FullScreenModal isOpen={isOpen} onClose={closeModal} title="">
        {/* Content inside the full-screen modal */}
      <Flex flexDirection={'row'}>
        <VStack>
            <HStack>
        <div className="ag-theme-alpine" style={{ height: '30vh', width: '95vw', marginTop: '-20px', marginLeft: '-60px' }}>
<AgGridReact
                ref={claimsCPTGridRef}
                columnDefs={[
                    {
                    headerName: 'Nr',
                    field : "i",
                    width : 50,
                    lockPosition: true,
                    valueGetter: cptRowIndex,
                    },
                   { headerName: 'Ready', field: 'cpt_status', width: 60, sortable: true,   editable: false, },
                   { headerName: 'facility', field: 'facility', width: 80, sortable: true, resizable: true, },  
                   { headerName: 'sdos', field: 'sdos' ,width: 130, sortable: true, resizable: true, },
                   { headerName: 'cptcode', field: 'cptcode',width: 110, sortable: true, checkboxSelection: true,  resizable: true, },
                   { headerName: 'U', field: 'units',width: 60, },
                   { headerName: 'cptdesc', field: 'cptdesc'  ,width: 250, rowDrag: true, resizable: true, tooltipField: 'cptdesc' },
                   { headerName: 'modifiers', field: 'modifiers',width: 120, sortable: true, },
                   { headerName: 'ICDs', field: 'cpt_assigned_icds',width: 120, tooltipField: 'cpt_assigned_icds' },
                   { headerName: 'tclaim_id', field: 'tclaim_id',width: 90, sortable: true, },
                   { headerName: 'patientId', field: 'patientId',width: 90, sortable: true, },
                   { headerName: 'apex_patient', field: 'apex_patient',width: 200, sortable: true,  },
                   { headerName: 'enc_id', field: 'apex_encounter_id', width: 90, sortable: true, filter: true },
                   { headerName: 'tcpt_id', field: 'tcpt_id', width: 90, sortable: true,  },
                   { headerName: 'cpt_dx', field: 'cpt_dx' },
                   { headerName: 'claim_dx', field: 'claim_dx' },
                   { headerName: 'cpt_comment', field: 'cpt_comment' },
                   { headerName: 'claim_comment', field: 'claim_comment' },
                   { headerName: 'assigned_icds', field: 'assigned_icds' },
                   { headerName: 'new_hosp_patient', field: 'new_hosp_patient' ,width: 200, sortable: true, },
               ]}
                rowData={claimCptRowdata}
                rowSelection="multiple"
                onRowDragMove={onTCPTRowDragMove}
                onSelectionChanged={handleCptRowSelected}
                frameworkComponents={{
                  checkboxRenderer: CheckboxRenderer
                }}
                onSortChanged={handleRowsSorted}
                onDragStarted={handleRowsSorted}
                filterChanged={handleRowsSorted}
             

            />
             </div>
             {/* <div className="ag-theme-alpine" style={{ height: '25vh', width: '26vw', marginTop: '-50px' }}>

<Button onClick={removeSelectedICDFromCPT} >Remove</Button>
<AgGridReact
    ref={claimsCPTICDGridRef}
    columnDefs={[
        {
            headerName: 'Nr',
            field : "i",
            width : 50,
            lockPosition: true,
            valueGetter: icdRowIndex,
            },
       { headerName: 'ICD', field: 'icdcode', width: 90, sortable: true,  },
       { headerName: 'Desc', field: 'icdname', width: 350, sortable: true, rowDrag: true   },

   ]}
    rowData={claimCPTIcdData}
    rowSelection="single"
    applyColumnDefOrder={true}
    onRowDragMove={onRowClaimCPTICDDragMove}
    
  
    onSelectionChanged={handleClaimCPTICDRowSelected}

/>
 </div> */}
             </HStack>
      

 
             <HStack>
                
<VStack>
<HStack  style={{ height: '100px', width: '50vw', marginTop: '-25px' }}>
<Button onClick={onAutoAssignModifiers}>AutoMod</Button>
<Checkbox
  
  isChecked={claimStatusRef.current.cptsChecked}
  onChange={onClaimStatusCptsChecked}
  colorScheme={claimStatusRef.current.cptsChecked ? 'green' : 'gray'}
>
  CPTs Checked
</Checkbox>
<Checkbox
  
  isChecked={claimStatusRef.current.modifiersChecked}
  onChange={onClaimStatusModsChecked}
  colorScheme={claimStatusRef.current.modifiersChecked ? 'green' : 'gray'}
>
  Modifiers
</Checkbox>
<Checkbox
 
  isChecked={claimStatusRef.current.icdsChecked}
  onChange={onClaimStatusICDsChecked}
  colorScheme={claimStatusRef.current.icdsChecked ? 'green' : 'gray'}
>
  ICDs Checked
</Checkbox>
<Checkbox
 
  isChecked={claimStatusRef.current.claim_ready_for_oov}
  onChange={onClaimStatusReadyOOV}
  colorScheme={claimStatusRef.current.claim_ready_for_oov ? 'green' : 'gray'}
>
  Ready OOV
</Checkbox>
<Checkbox
 
  isChecked={claimStatusRef.current.claimLocked}
  onChange={onClaimStatusLocked}
  colorScheme={claimStatusRef.current.claimLocked ? 'green' : 'gray'}
>
  Locked
</Checkbox>

<Button onClick={onCreateOOV_ineCW} isDisabled={claimStatusRef.current.claimLocked && claimStatusRef.current.apex_encounter_id > 0 ? true : false}>CreateOOV</Button>
<Text>Enc {claimStatusRef.current.apex_encounter_id}</Text>
</HStack>
{  !showEditView ? '' : (

<>
<VStack style={{  marginTop: '-px' ,  marginBottom: '40px'}}>

<HStack>

<Text>ICDs</Text>
<Input
  placeholder=''
  value={newItemRef.current.cpt_assigned_icds}
  onChange={e => onSetNewItem({ ...newItemRef.current, cpt_assigned_icds: e.target.value })}
/>
<Text>Mod</Text>
<Input
  placeholder=''
  value={newItemRef.current.modifiers}
  onChange={e => onSetNewItem({ ...newItemRef.current, modifiers: e.target.value })}
/>
<Text>Units </Text>
<Input
  placeholder=''
  value={newItemRef.current.units}
  onChange={e => onSetNewItem({ ...newItemRef.current, units: e.target.value })}
/>


</HStack>

<HStack>
<Text>Dx</Text>
<Input
  placeholder=''
  value={newItemRef.current.dxcodes}
  onChange={e => onSetNewItem({ ...newItemRef.current, dxcodes: e.target.value })}
/>

<Text>Comment</Text>
<Input
  placeholder=''
  value={newItemRef.current.comment}
  onChange={e => onSetNewItem({ ...newItemRef.current, comment: e.target.value })}
/>
<Button colorScheme='blue' onClick={handleCPTRowUpdate}  >
  Update
</Button>
</HStack>

{/* Add input fields for other columns */}


</VStack>
</>
) 
}
<VStack>
<HStack style={{ height: '30vh', width: '50vw', marginTop: '-30px' }}>
             <div className="ag-theme-alpine" style={{ height: '30vh', width: '26vw', marginTop: '10px' }}>
             <HStack><Input as='input' value={quickICDFilter}  onChange={handleICDQuickFilter}  ref={initFocusRef} 
                placeholder="filter..." variant="filled" fontSize='sm' aria-multiline />
               
               
               <Checkbox
  isDisabled={(patientPrevRefData && patientPrevRefData.length > 0) ? false : true}
  isChecked={showPrevICDData}
  onChange={()=>setShowPrevICDData(!showPrevICDData)}
  colorScheme={newItemRef.current.cpt_status ? 'green' : 'gray'}
>
PrevDx
</Checkbox>
               
                {/* <Checkbox
  isChecked={newItemRef.current.cpt_status}
  onChange={handleClaimCptReadyChange}
  colorScheme={newItemRef.current.cpt_status ? 'green' : 'gray'}
>
  PrevDx
</Checkbox>  */}

<Button onClick={addSelectedICD} >Add ICD</Button>
                </HStack>
<AgGridReact
                ref={apexICDGridRef}
                columnDefs={[

                   { headerName: 'ICD', field: 'icdcode', width: 100, sortable: true,  resizable: true, },
                   { headerName: 'Desc', field: 'icdname', width: 350, sortable: true ,  resizable: true, },
                   { headerName: 'Enc', field: 'enc_date', width: 140, sortable: true },
            
               ]}
                rowData={diagCodesRef}
                rowSelection="single"
                applyColumnDefOrder={true}
              
                onSelectionChanged={handleICDRowSelected}

            />
             </div>
             <div className="ag-theme-alpine" style={{ height: '30vh', width: '26vw', marginTop: '10px' }}>
<HStack>
            <Button onClick={removeSelectedICD} >Del</Button>
            <Button onClick={addICDToAllCPTs} >To All</Button>
            <Button onClick={addICDToSelectedCPTs} >To Sel</Button>
            <Button onClick={resetCPTICDs} >Reset</Button>
            <ManageBillingTempClaimCpts isEdit={true} claimID={currentClaimID} />
            </HStack>
            <AgGridReact
                ref={claimsICDGridRef}
                columnDefs={[
                    {
                        headerName: 'Nr',
                        field : "i",
                        width : 50,
                        lockPosition: true,
                        valueGetter: icdRowIndex,
                        },
                   { headerName: 'ICD', field: 'icdcode', width: 90, sortable: true,  resizable: true,  },
                   { headerName: 'Desc', field: 'icdname', width: 350, sortable: true, rowDrag: true ,resizable: true,  },
            
               ]}
                rowData={claimIcdData}
                rowSelection="multiple"
                applyColumnDefOrder={true}
                onRowDragMove={onRowClaimICDDragMove}
                
              
                onSelectionChanged={handleClaimICDRowSelected}

            />
             </div>
            
            
         </HStack>
       
         </VStack>
       
         <Text mt={'60px'} alignContent={'left'}>
            DX: 
            {claimCptRowdata &&  claimCptRowdata.length > 0? (  
                claimCptRowdata.map(row=> {
                    return (
                        `${row.cpt_dx}  `
                    )
                }) ) : '' }

{claimCptRowdata &&  claimCptRowdata.length > 0 ? (                 
                        `${claimCptRowdata[0].claim_dx}  `
                ) : '' }


Comments: 
{claimCptRowdata &&  claimCptRowdata.length > 0 ? (  
                claimCptRowdata.map(row=> {
                    return (
                        `${row.cpt_comment}  `
                    )
                }) ) : '' }

{claimCptRowdata &&  claimCptRowdata.length > 0 ? (                 
                        `${claimCptRowdata[0].claim_comment}  `
                ) : '' }

                {claimStatusRef.current? `Facility: ${claimStatusRef.current.claim_facility}  POS: ${claimStatusRef.current.claim_pos}  Service Date: ${claimStatusRef.current.claim_servicedt}` : ''}
                {claimStatusRef.current? `Admit: ${claimStatusRef.current.claim_admit_date}  Discharge: ${claimStatusRef.current.claim_discharge_date}  ` : ''}

         </Text>
         <Button onClick={refreshClaimandCPTinfo}>Refresh</Button>
             </VStack>
            
             <div height={'200px'}  style={{ height: '48vh', width: '46vw', marginTop: showEditView ? '-80px' : '-15px' }}>
        <HStack align={'flex-end'}>
             {claimDocumentLinks && claimDocumentLinks.map((row, indx)=>{
              return(<Button onClick={()=>ShowDocument(row.docpagenum, row.document_path)}>docuPage{row.docpagenum}</Button>)
             })}
             </HStack>
    
             <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum} setCurrentPageNum={onSetCurrentPageNum}/>
             </div>

             </HStack>
             </VStack>
            
             </Flex>
      </FullScreenModal>
      </div>
  );
}



export default App;

