
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Box,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    Input

} from "@chakra-ui/react";

import { AgGridReact } from 'ag-grid-react';

  import 'ag-grid-community/styles/ag-grid.css';
  import 'ag-grid-community/styles/ag-theme-alpine.css';

export function SelectBillingSheetFile({ selectedBillingSheetFile, setSelectedBillingSheetFile }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const gridRef = React.useRef();
    const initFocusRef = React.useRef();
    const [quickFilenameFilter, setQuickFilenameFilter] = useState('');
    const [ecwBillingFiles, setBillingFiles] = useState([]);
    

    const [gridColumnDefs, setgridColumnDefs] = useState(
        [
            { field: 'docID', filter: true , width:60 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'ID' , 
            resizable: true,editable: true},
            { field: 'CustomName', filter: true, width: 300, sortable: true,cellStyle: {fontSize: '15px'},headerName: 'Name',
            resizable: true, },
            { field: 'dirpath', filter: true , width:350 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Folder' , 
            resizable: true,editable: true},
            { field: 'Scanned_On', width: 100 , sortable: true,cellStyle: {fontSize: '15px'},headerName: 'Scanned On',
            resizable: true,},
            { field: 'Review', width: 50 , sortable: true,cellStyle: {fontSize: '15px'},headerName: 'Review',
            resizable: true,},
            { field: 'filename', filter: true, width: 300, sortable: true,cellStyle: {fontSize: '15px'},headerName: 'FileName',
            resizable: true, },
        
  
        ]
    );

//     daysOpen	daysOpen	LastAction_On	Reviewed_On	CustomName	Scanned_On	ReviewerName	Review	delflag	scandate	doctypeDesc	docID	Patientid	filename	dirpath	ReviewerId	ScannedBy	doc_type
// 39	27	2023-06-05 13:55:59.140	2023-06-05 13:55:59.140	2023-04-28 BL124 Hospital Sheets	04/27/2023	WALKER, LAURIE	1	0	2023-04-27 00:00:00.000	Hospital Sheets	141334	21219	21219_22DDC533-9A69-5240-ACEE-E053A9A765FF.pdf	mobiledoc/2023/04292023	17811	Korlakunta,Siva 	113

    useEffect(()=> {

        const getData = async () => {

            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found. User must log in.");
                return;
            }

            let authHeader =  {
                "Authorization": `Bearer ${token}`,  // ✅ Attach token
                "Content-Type": "application/json"
            }
            let urlPrefix = apiURL + '/exsql?dbserver=';
            let dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 exec apex..apexsp_getBillingDocuments  ";
  
            const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
            if (res2.data) {
              console.log("GOT Billing Files:", res2.data);
              let dframe = res2.data['frame0'];
              //console.log(dframe);
              let myObj = JSON.parse(dframe);
              console.log(myObj);
              let gridData = myObj['rows'];

              setBillingFiles(gridData);
            }
    

        };
      
        getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);

    const handleQuickFilenameFilter = (event) => {
        console.log("Quick Patient Filter:",event.target, event.target.value, gridRef.current);
        setQuickFilenameFilter(event.target.value );
        gridRef.current.api.setQuickFilter(event.target.value);
    };
      
    const handleGridRowSelected = (event) => {

        //console.log("handleGridRowSelected:",event);
        const selectedNodes = event.api
          .getSelectedNodes()
          .filter((node) => node.selected);
        //console.log(selectedNodes);
    
        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);
        setSelectedBillingSheetFile(datarow);
        setQuickFilenameFilter(datarow.CustomName);

      };

      //use selectedEcwFolder to se currently selected value

      return(

        <Popover isLazy initialFocusRef={initFocusRef} >
  <PopoverTrigger>
    <Input as='input' value={selectedBillingSheetFile?.CustomName}  onChange={handleQuickFilenameFilter}  
          placeholder="Select Billing File" variant="filled" fontSize='sm' aria-multiline 
          w='450px' />
  </PopoverTrigger>
  <PopoverContent bg='tomato' color='white' w='800px'>
    <PopoverHeader fontWeight='semibold'>Popover placement</PopoverHeader>
    <PopoverArrow />
    <PopoverCloseButton />
    <PopoverBody>
    <Input as='input' value={quickFilenameFilter}  onChange={handleQuickFilenameFilter}  ref={initFocusRef} 
          placeholder="Select Billing File" variant="filled" fontSize='sm' aria-multiline />
    <Box className="ag-theme-alpine maxWidth" style={{ paddingLeft: '-200px', width: '100%', height: '450px', overflow: 'hidden' }}>
                <AgGridReact 
                ref={gridRef} 
                rowData={ecwBillingFiles} 
                rowHeight={20}
                columnDefs={gridColumnDefs} 
                animateRows={true} 
                resizable={true} 
                rowSelection="single"
                rowDragManaged={true}
                suppressMoveWhenRowDragging={true}
                applyColumnDefOrder={true}
                onSelectionChanged={handleGridRowSelected}
                />
     </Box>
    </PopoverBody>
  </PopoverContent>
</Popover>


      )

    }