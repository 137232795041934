import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Avatar,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Box,
  Text,
  HStack,
  useRadioGroup,
  useRadio,
  VStack,
  Container,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Checkbox,
  Switch,
  Input,
  Textarea

} from "@chakra-ui/react";

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@chakra-ui/react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from "chakra-react-select";

import { useChakraSelectProps } from "chakra-react-select";



import { SelectApexStaff } from "../Modals/SelectApexStaff";
import { SelectEcwFolder } from "../Modals/SelectEcwFolder";
import { SelectEcwPatient } from "../Modals/SelectApexPatient";
import { SelectDocumentType } from "../Modals/SelectDocumentType";
import { SelectOpProject } from "../Modals/SelectOpProject";
import { SelectEcwInsurance } from "../Modals/SelectApexInurance";
import SplitPanel from "./splitpage/splitpanel";

import { AgGridReact } from 'ag-grid-react';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import UploadFile from "./UploadFile";
import apiConfig from '../apiConfig';

import React, { useEffect } from "react";
import useState from 'react-usestateref';

import axios from "axios";
import PatientContext from '../Contexts/PatientContext';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export function AttachDocument({ children, buttonText, fileScope, currentPageNum, data, rowDocsData, setRowDocsData, setSelectedEcwDocsPatient }) {

  let apiURL = apiConfig.REACT_APEX_BASE_API;

  const [quickPatientFilter, setQuickPatientFilter] = useState('');
  const [rouIsChecked, setrouIsChecked] = useState(false);

  const [isLinkDocumentChecked, setIsLinkDocumentChecked] = useState(false);
  const [isCurrentPageChecked, setIsCurrentPageChecked] = useState(false);

  const [isLinkPatientChecked, setIsLinkPatientChecked] = useState(true);
  const [isLinkInsuranceChecked, setIsLinkInsuranceChecked] = useState(false);

  const [taskSubject, setTaskSubject] = useState('');
  const [taskDescription, setTaskDescription] = useState('');

  const [docdate, setDocDate, docdateref] = useState(new Date());
  const [taskDuedate, setTaskDuedate, taskduedateref] = useState(new Date());

  const [selectedDocType, setselectedDocType, docutyperef] = useState(null);

  const otherDocType = { name: "OTHER", value: "Other" };

  const [documentCustomPart, setdocumentCustomPart, doccustompartref] = useState('');

  const [selectedType, setSelectedType] = useState('');
  const [selectedFacility, setSelectedFacility] = useState('');


  const { isOpen, onOpen, onClose } = useDisclosure();
  const [apexstaff, setapexstaff] = useState([]);
  //const [apexfolders, setapexfolders] = useState([]);

  const [customName, setcustomName] = useState('');
  const [selectedFolder, setSelectedFolder] = useState('');
  const [selectedFolderItem, setSelectedFolderItem] = useState();


  const [selectedAssignTo, setselectedAssignTo] = useState('');

  const [selectedStaff, setSelectedStaff] = useState();
  const [selectedEcwFolder, setSelectedEcwFolder] = useState();
  const [selectedEcwPatient, setSelectedEcwPatient] = useState();
  const [selectedEcwInsurance, setSelectedEcwInsurance] = useState();

  const [selectedProject, setSelectedProject] = useState();
  const [markitReviewed, setMarkitReviewed ] = useState(false);
  const [apexfolders, setApexFolders] = useState([]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react',
    onChange: console.log,
  });

  const docTypeToFolderIdMap = {
    consult: 161,
    "progress note": 171,
    echo: 107,
    cath: 107,
    "cath with pci": 107,
    "nuclear stress": 107,
    "treadmill stress": 152,
    facesheet: 136,
  };


  useEffect(() => {

    console.log("fldr autoselect 1", selectedType,apexfolders );
    if (!selectedType || !apexfolders || apexfolders.length === 0) return;

    const folderIdToSelect = docTypeToFolderIdMap[selectedType.toLowerCase()];
    console.log("fldr autoselect 2 target id: ", folderIdToSelect );
    if (!folderIdToSelect) return;

    const matchingFolder = apexfolders.find(f => f.id === folderIdToSelect);
    console.log("fldr autoselect 3 matching folder: ", matchingFolder );
    if (matchingFolder) {
      setSelectedEcwFolder(matchingFolder);
      console.log("Auto-set folder based on doc type:", matchingFolder);
    }
  }, [selectedType, apexfolders]);

  const group = getRootProps()

  const people = [
    { name: "Dan Abramov", image: "https://bit.ly/dan-abramov" },
    { name: "Kent Dodds", image: "https://bit.ly/kent-c-dodds" },
    { name: "Segun Adebayo", image: "https://bit.ly/sage-adebayo" },
    { name: "Prosper Otemuyiwa", image: "https://bit.ly/prosper-baba" },
    { name: "Ryan Florence", image: "https://bit.ly/ryan-florence" },
  ];

  async function getData() {
    console.log("Will Upload File:", docName, docBody);
    let res = await axios.get("https://api.publicapis.org/entries");
    if (res.data) {
      onClose();
    }
  }

  useEffect(() => {
    const loadFolders = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found.");
        return;
      }

      try {
        const res = await axios.get(`${apiURL}/ecw/getdocfolders`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (res.data) {
          console.log("Loaded folder list in AttachDocument:", res.data);
          setApexFolders(res.data);
        }
      } catch (err) {
        console.error("Error loading folders:", err);
      }
    };

    loadFolders();
  }, []);

  const getUploadedFiles = (filedata) => {
    console.log("Parent Received:", filedata);
    setRowDocsData(filedata[0]);
    //uploadDocumentToServer(rowDocsData);
  }

  // useEffect(() => {
  //   if (
  //       data &&
  //       data.fileName &&
  //       selectedType
  //   ) {
  //     const nameWithoutExtension = data.fileName.replace(/\.pdf$/i, '');
  //     setdocumentCustomPart(nameWithoutExtension);
  //     makeTargetFilename();
  //   }
  // }, [data, selectedType]);


  useEffect(() => {

    const getecwData = async () => {

    };

    getecwData(); // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };


  }, []);

  console.log("In attachdocument:", data);
  let docName = data.fileName;
  let docBody = data.fileData;
  let docScope = fileScope;

  if (!docScope) {
    docScope = 'attach';
  }

  let attachMode = false;
  let actionMode = false;

  if (docScope.includes('attach') || docScope.includes('action')) {
    attachMode = docScope.includes('attach');
    actionMode = docScope.includes('action');
  } 

  if (isOpen) {
    console.log("Received props in model :", data);

  }

  const [pickerItems, setPickerItems] = React.useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const handleCreateItem = (item) => {
    setPickerItems((curr) => [...curr, item]);
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
      console.log(selectedItems);
    }
  };

  const handleUploadDocument = (event) => {
    console.log("Ready to Upload: ", data, rowDocsData, docName, docBody, selectedFolder, selectedAssignTo);
    uploadDocumentToServer(data);
  }

  const handleCreateTask = (event) => {
    console.log("Ready to Create Task: ", data, rowDocsData, docName, docBody, selectedFolder, selectedAssignTo);
    createTaskinSelectedProject(data);
    onClose();
  }

  const onTaskSubjectChange = (event) => {

    console.log("Document subject changed: ", event, event.target.value);

    setTaskSubject(event.target.value);
  }


  const onTaskDescChange = (event) => {

    console.log("Document Description changed: ", event, event.target.value);
    setTaskDescription(event.target.value);
  }


  const onIsLinkPatientChecked = (event) => {
    setIsLinkPatientChecked(!isLinkPatientChecked);

  }

  const onIsLinkInsuranceChecked = (event) => {
    setIsLinkInsuranceChecked(!isLinkInsuranceChecked);

  }

  const onSetSelectedProject = (val) => {
    setSelectedProject(val);
    console.log("Received selected project:", val);
  }

  const onSetSelectedEcwInsurance = (val) => {
    setSelectedEcwInsurance(val);
    console.log("Received selected insurance:", val);
  }

  const onSetSelectedStaff = val => {
    setSelectedStaff(val);
    console.log("Received Staff:", val);
  }

  useEffect(() => {
    makeTargetFilename();
  }, [selectedType, selectedFacility, documentCustomPart]);


  const formatDate = (date) => {
    // var date_format_str = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
    const d = date;
    console.log('New Date Value: ', date);
    var date_format_str = '';
    if (date != null) {
      var date_format_str = `${d.getFullYear().toString()}-${(d.getMonth() + 1).toString().length == 2
          ? (d.getMonth() + 1).toString()
          : `0${(d.getMonth() + 1).toString()}`
        }-${d.getDate().toString().length == 2
          ? d.getDate().toString()
          : `0${d.getDate().toString()}`
        }`;
      return date_format_str;
    }
  }

  const handleTypeSelect = (value) => {
    setSelectedType(value);

    // Auto-set document type to "Other" when consult, echo, etc. are selected
    if (["consult", "echo", "progress note", "cath", "cath with pci", "nuclear stress", "treadmill stress"].includes(value.toLowerCase())) {
      setselectedDocType(otherDocType);
    }

  };

  const handleFacilitySelect = (value) => {
    setSelectedFacility(value);
  };

  const clearType = () => {
    setSelectedType('');
    setselectedDocType(null);
  };

  const clearFacility = () => {
    setSelectedFacility('');
  };



  const makeTargetFilename = () => {
    let target_name = '';
    target_name += docdateref.current ? formatDate(docdateref.current) : '';

    if (docutyperef.current && docutyperef.current.value.toLowerCase() === 'other') {
      if (selectedType) {
        target_name += ' ' + selectedType.toUpperCase();
      }

      if (selectedFacility) {
        target_name += ' ' + selectedFacility.toUpperCase();
      }
    }
    if (docutyperef.current && docutyperef.current.value.toLowerCase() !== 'other') {
      target_name += ' ' + docutyperef.current.value;
    }

    target_name += doccustompartref.current ? ' ' + doccustompartref.current : '';
    setcustomName(target_name.trim());
  };


  const createTaskinSelectedProject = (data) => {

    makeTargetFilename();

    console.log("Selected Data:", selectedProject, selectedStaff, selectedEcwFolder, selectedEcwPatient, selectedDocType, data);

    console.log("Starting uploadDocumentToServer:", data, rowDocsData);
    if (data === undefined || data == null) return;

    const context_data = {


      patientid: selectedEcwPatient ? selectedEcwPatient.PatientId : null,
      patientname: selectedEcwPatient ? selectedEcwPatient.Name : null,
      filename: data.fileName,
      renameto: customName,
      filedate: docdate ? formatDate(docdate) : null,
      taskduedate: taskDuedate ? formatDate(taskDuedate) : null,
      assignedto: selectedStaff,
      taskproject: selectedProject,
      tasksubject: taskSubject,
      taskdescription: taskDescription,
      taskassignto: selectedStaff?.name,
      taskpatient: selectedEcwPatient ? selectedEcwPatient.Name : '',
      taskinsurance: selectedEcwInsurance,
      taskreview: 'no',
      linkDocument: isLinkDocumentChecked ? 'yes' : 'no',
      linkCurrentPage: isCurrentPageChecked ? 'yes' : 'no',
      pagenum: currentPageNum ? currentPageNum : 1,
      linkPatient: isLinkPatientChecked ? 'yes' : 'no',
      linkInsurance: isLinkInsuranceChecked ? 'yes' : 'no',

      depaccount: null,

    };

    console.log('Starting process Document File: ', data, context_data);

    console.log("Sending form data to task creation", data, context_data);

    //${apiURL}
    //https://localhost:8044
    axios
      .post(`${apiURL}/proj/createProjectTask`, context_data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log(res.statusText);
        console.log('Called CreateTask Successfully');
      });

    console.log('Called CreateTask', context_data);


    //   if (data.fileData) {

    //     const form = new FormData();
    //     form.append('fileName', data.fileName);
    //     form.append('fileSize', data.fileSize);
    //     form.append('fileData', data.fileData);
    //     form.append('paramArg', JSON.stringify(context_data));

    //     console.log("Sending multipart data", data, context_data, form);

    //   axios
    //   .post(`${apiURL}/uploadFileWithData`, form, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data'
    //       }
    //     })
    //   .then((res) => {
    //     console.log(res.statusText);
    //     console.log('Called funcProcessDocument Successfully');
    //   });
    // }
    // else {

    //   console.log("Sending form data", data, context_data);

    //   axios
    //   .post(`${apiURL}/uploadEcwDocument`, context_data, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res.statusText);
    //     console.log('Called CreateTask Successfully');
    //   });

    //   console.log('Called CreateTask', context_data);

    // }

  }
  const uploadDocumentToServer = (data) => {

    makeTargetFilename();



    console.log("Selected Data:", selectedProject, selectedStaff, selectedEcwFolder, selectedEcwPatient, selectedDocType, markitReviewed, data);

    console.log("Starting uploadDocumentToServer:", data, rowDocsData);
    if (data === undefined || data == null) return;

    const context_data = {
      fileop: rouIsChecked ? 'rename' : 'uploademr',
      context: rouIsChecked ? 'normal' : 'EMR',
      filecategory: selectedDocType ? selectedDocType.name : null,
      patientid: selectedEcwPatient ? selectedEcwPatient.PatientId : null,
      patientname: selectedEcwPatient ? selectedEcwPatient.Name : null,
      filefolderid: selectedEcwFolder ? selectedEcwFolder.id : null,
      filefoldername: selectedEcwFolder ? selectedEcwFolder.label : null,
      filename: data.fileName,
      renameto: customName,
      filedate: docdate ? formatDate(docdate) : null,
      reviewerid: selectedStaff ? selectedStaff.uid : null,
      reviewername: selectedStaff ? selectedStaff.fullname : '',
      reviewstatus: markitReviewed ? 1 : 0,

      depaccount: null,
      taskproject: null,
      tasksubject: null,
      taskdescription: null,
      taskassignto: null,
      taskpatient: null,
      taskinsurance: null,
      taskreview: null,
      linkDocument: isLinkDocumentChecked ? 'yes' : 'no',
      docupages: isCurrentPageChecked ? 'current' : null,
      pagenum: currentPageNum ? currentPageNum : null,

    };

    console.log('Starting process Document File: ', data, context_data);


    if (data.fileData) {


      const form = new FormData();
      form.append('fileName', data.fileName);
      form.append('fileSize', data.fileSize);
      form.append('fileData', data.fileData);
      form.append('paramArg', JSON.stringify(context_data));

      console.log("Sending multipart data", data, context_data, form);


      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found. User must log in.");
        return;
      }
      let authHeader =  {
        "Authorization": `Bearer ${token}`,  // ✅ Attach token
        "Access-Control-Allow-Origin": "*",

      }
      //   'Content-Type': 'multipart/form-data'
      axios
        .post(`${apiURL}/uploadFileWithData`, form, {
          headers: authHeader
        })
        .then((res) => {
          console.log(res.statusText);
          console.log('Called funcProcessDocument Successfully');
        });
    }
    else {

      console.log("Sending form data", data, context_data);

      axios
        .post(`${apiURL}/uploadEcwDocument`, context_data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          console.log(res.statusText);
          console.log('Called funcProcessDocument Successfully');
        });

      console.log('Called funcProcessDocument', context_data);

    }

  };

  const handleRenameOrUpload = (event) => {
    console.log("Rename of Upload change handler", !rouIsChecked, event);
    setrouIsChecked(!rouIsChecked);
    //setuploadFileAttributes({...uploadFileAttributes, fileop : rouIsChecked ? 'rename' : 'uploademr'})

  }

  const onisLinkDocumentCheckedChange = (event) => {
    setIsLinkDocumentChecked(!isLinkDocumentChecked);
  }

  const onIsCurrentPageChanged = (event) => {
    setIsCurrentPageChecked(!isCurrentPageChecked);

  }
  const onFolderSelectionChange = (value) => {
    console.log("Folder selection change:", value);
    let selectedfolderitem = apexfolders.filter(fldr => fldr.label === value);
    console.log("Selected File Folder Category", selectedfolderitem[0]);
    setSelectedFolderItem(selectedfolderitem[0]);
    setSelectedFolder(value);
  }

  const OnCustomDocumentPartChange = (event) => {
    console.log("Custom:", event.target.value);
    setdocumentCustomPart(event.target.value);
    makeTargetFilename();
  }

  const OnCDocuDateChange = (value) => {
    setDocDate(value);
    makeTargetFilename();
  }

  const onDocuTypeChange = (val) => {
    setselectedDocType(val);
    console.log("Parent , setting documenttype:", val);
    makeTargetFilename();
  }

  const onDocumentDescChange = (val) => {
    console.log("Document Description changed: ", val);
  }

  const onDocumentSubjectChange = (val) => {
    console.log("Document subject changed: ", val);
  }

  const onSelectedEcwPatient = (val) => {
    console.log("Attach document got patient info:", val);
    setSelectedEcwPatient(val);
    setSelectedEcwDocsPatient(val);
  }
  const onSetSelectedEcwFolder = (val) => {
    console.log("Attach document to folder:", val);
    setSelectedEcwFolder(val);
  }

  const onMarkitReviewedToggle = () => {

    setMarkitReviewed(!markitReviewed);

  }
  console.log("Attachdocument Current page:", currentPageNum);

  return (

      <SplitPanel split={'horizontal'}>

        <div style={{width: '100%'}}>
        <Box >
            <div>
                
                <HStack>
                {attachMode && (
                  <Flex pt="2" justify="left" align="left" direction="column">
                        <HStack>
                        <FormLabel size="sm" >RenameOnly</FormLabel>
                        <Switch id='rename_or_upload' size="sm"  isChecked={rouIsChecked} onChange={handleRenameOrUpload} />
                            <FormLabel>Date</FormLabel>
                            <SingleDatepicker
                                name="date-input"
                                date={docdate}
                                onDateChange={OnCDocuDateChange}
                            />
                            <SelectDocumentType selectedDocumentType={selectedDocType} setSelectedDocumentType={onDocuTypeChange} />
                        </HStack>
                  </Flex>
                )}
                  
                </HStack>

  
                {attachMode && (
                  <Flex flexDirection={'row'} spacing={'10px'} alignContent={'flex-start'}>
                    <SelectApexStaff flex={3} selectedStaff={selectedStaff} setSelectedStaff={onSetSelectedStaff} />
                    <SelectEcwPatient flex={5}  selectedEcwPatient={selectedEcwPatient} setSelectedEcwPatient={onSelectedEcwPatient} />
                 </Flex>
                  )}

                <Text ml={6} bg={'gray.400'} >{docName}</Text>
                {attachMode && (
                  <Box alignContent={'start'} width={'400px'}>
                    <HStack>
                  <Checkbox  flex={1} mr={'30px'} isChecked={markitReviewed}  onChange={onMarkitReviewedToggle}   colorScheme={markitReviewed ? 'green' : 'gray'}> Reviewed</Checkbox>  
                  <SelectEcwFolder selectedEcwFolder={selectedEcwFolder} setSelectedEcwFolder={onSetSelectedEcwFolder} />
                  </HStack>
                  </Box>
                )}
                {actionMode && (
                  <HStack>
                  <SelectEcwPatient selectedEcwPatient={selectedEcwPatient} setSelectedEcwPatient={onSelectedEcwPatient} />
                  <SelectEcwInsurance selectedEcwInsurance={selectedEcwInsurance} setSelectedEcwInsurance={onSetSelectedEcwInsurance} />
                  </HStack>
                )}
               
                {attachMode && (
                  <div>
                      <Flex>
                        <Input flex={1} placeholder='Name Suffix' value={documentCustomPart} size="sm" width={'100px'} mr={'10px'} onChange={OnCustomDocumentPartChange} />
                        <IconButton
                            size="sm"
                            icon={<CloseIcon fontSize="small" />}
                            aria-label="Clear suffix"
                            onClick={() => {
                              setdocumentCustomPart('');
                              makeTargetFilename(); // update customName after clearing
                            }}
                        />
                        <Text>Name:  </Text><Text flex={3} colorScheme="green" backgroundColor={{ color: 'white', bg: 'gray.400' }} mx={'10px'} border={'solid 1px'} size="sm" >{customName}</Text>
                      </Flex>
                    <Box mt={3}>

                      {/* Type Radio Group */}

                      <HStack spacing={2}>
                        <FormLabel fontSize="sm">Document Type</FormLabel>
                        {[ "Facesheet", "Consult", "Echo", "Progress Note", "CATH", "CATH with PCI", "Nuclear Stress", "Treadmill Stress"].map((type) => (
                            <Button
                                key={type}
                                size="xs"
                                variant={selectedType === type ? "solid" : "outline"}
                                colorScheme="teal"
                                onClick={() => handleTypeSelect(type)}
                            >
                              {type}
                            </Button>
                        ))}
                        <Button size="xs" onClick={clearType} colorScheme="red" variant="ghost">
                          <CloseIcon fontSize="small" />
                        </Button>

                      </HStack>

                      {/* Facility Radio Group */}

                      <HStack spacing={2}>
                        <FormLabel fontSize="sm" mt={3}>Facility</FormLabel>
                        {["MCA", "PresbyD", "HHD", "MCL"].map((facility) => (
                            <Button
                                key={facility}
                                size="xs"
                                variant={selectedFacility === facility ? "solid" : "outline"}
                                colorScheme="purple"
                                onClick={() => handleFacilitySelect(facility)}
                            >
                              {facility}
                            </Button>
                        ))}

                        <Button size="xs" onClick={clearFacility} colorScheme="red" variant="ghost">
                          <CloseIcon fontSize="small" />
                        </Button>

                      </HStack>

                    </Box>


                    {/* <Input placeholder="document Subject" size="sm" onChange={onDocumentSubjectChange} />
                      <Textarea placeholder='document Description' size="sm" onChange={onDocumentDescChange} /> */}
                      </div>
                )}
                     
                     
                      {attachMode && (

                     <HStack>
                       <Button colorScheme="blue" mr={2} onClick={handleUploadDocument}>Upload</Button>
                     </HStack>
                      )}

              {actionMode && (
                    <HStack spacing={5} bg='gray' >
                      <Checkbox size='md' colorScheme='green'  key={'ptc-1'} isChecked={isLinkDocumentChecked} onChange={onisLinkDocumentCheckedChange}>LinkDocument</Checkbox>  
                      <Checkbox size='md' colorScheme='green'  key={'ptc-2'} isChecked={isCurrentPageChecked}  onChange={onIsCurrentPageChanged}>CurrentPage</Checkbox>
                      <Checkbox size='md' colorScheme='green'  key={'ptc-3'} isChecked={isLinkPatientChecked}  onChange={onIsLinkPatientChecked}>LinkPatient</Checkbox>  
                      <Checkbox size='md' colorScheme='green'  key={'ptc-4'} isChecked={isLinkInsuranceChecked} onChange={onIsLinkInsuranceChecked} >LinkInsurance</Checkbox>
              
                    </HStack>
              )}


                  {actionMode && (
    <Box>
    <Flex pt="2"  direction="row" alignContent={"space-evenly"}> 
    <FormLabel >Due Date</FormLabel>
    <SingleDatepicker 
        name="date-input"
        date={taskDuedate}
        onDateChange={setTaskDuedate}
    />
    </Flex>
                       <Flex pt="2"  direction="row" alignContent={"space-evenly"}> 
   
                              <SelectOpProject key={'ptproj-1'} selectedProject={selectedProject} setSelectedProject={onSetSelectedProject} />
                              <SelectApexStaff selectedStaff={selectedStaff} setSelectedStaff={onSetSelectedStaff} />
                       </Flex>
                       </Box>

                      )}

                    {actionMode && (
                      <Box>                            
                        <Input placeholder="Task Subject" size="sm" value={taskSubject} onChange={onTaskSubjectChange} />
                        <Textarea placeholder='Task Description' size="sm" value={taskDescription} onChange={onTaskDescChange} />
                    </Box>
                    )}

                    {actionMode && (
                   <HStack>
                      <Button colorScheme="green" _selected={{ color: 'white', bg: 'green.500' }} mr={1} onClick={handleCreateTask}>Create Task</Button>
                   </HStack>
                    )}

            </div>
        </Box>
        </div>


      </SplitPanel>
  
    );
  }

function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='xs'
        boxShadow='xs'
        _checked={{
          bg: 'teal.600',
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={2}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  )
}

