import React, { useState, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Button, Input,Flex, HStack, VStack } from '@chakra-ui/react';
import apiConfig from '../apiConfig';
import axios from "axios";
import ApexPdfjsViewer from '../Components/ApexPdfjsViewer';
import { useDocument } from '../Contexts/DocumentContext';
import { handleEcwDocument, handleFolderDocument, handleAgDocument } from '../utils/ecw/hadleDocuments';
import ManageBillingTempClaimCpts from '../Features/billing/ManageBillingTempClaimCpts';

export async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

const EcwPatientEncounter = ({ initialRowData , patientid }) => {

    let apiURL = apiConfig.REACT_APEX_BASE_API;
    const { documentContext, updateDocinfo, updateDocPageinfo } = useDocument();
    const gridRef = React.useRef();
    const initFocusRef = React.useRef();

    const [rowData, setRowData] = useState(initialRowData);
    const [quickFilter, setQuickFilter] = useState('');

    const [docToDisplay, setDocToDisplay] = useState();
    const [startingPageNum, setstartingPageNum] = useState(1)
    const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
    const [currentPageNum, setCurrentPageNum] = useState(1);


    // const columnDefs = [
    //     { headerName: 'tcpt_id', field: 'tcpt_id', width: 90, sortable: true,  },
    //     { headerName: 'tclaim_id', field: 'tclaim_id',width: 90, sortable: true, },
    //     { headerName: 'facility', field: 'facility', width: 80, sortable: true,},
    //     { headerName: 'sdos', field: 'sdos' ,width: 130, sortable: true,},
    //     { headerName: 'cptcode', field: 'cptcode',width: 80, sortable: true, },
    //     { headerName: 'modifiers', field: 'modifiers',width: 80, sortable: true, },
    //     { headerName: 'apex_patient', field: 'apex_patient',width: 200, sortable: true,  },
    //     { headerName: 'cptdesc', field: 'cptdesc' },
    //     { headerName: 'cpt_dx', field: 'cpt_dx' },
    //     { headerName: 'claim_dx', field: 'claim_dx' },
    //     { headerName: 'new_hosp_patient', field: 'new_hosp_patient' ,width: 200, sortable: true, },
    // ];

    
function  BtnCellRenderer (props) {
 
    const btnClickedHandler = () => {
        props.clicked(props.data, props.buttonText);
    }
      return (
        <>
        <div><button onClick={btnClickedHandler}>{props.buttonText}</button> </div>
        
        </>
      )
  }


  // navigator.clipboard.writeText(this.state.textToCopy)

  const openURL = (viewerUrl) => {
    console.log(viewerUrl);
    try {
      window.open(viewerUrl, '_webemr');
    } catch (ex) {
      console.error(`_bindPdfLink: ${ex}`);
    }

    // '_blank', 'noreferrer'
  };

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(error => {
        console.error('Error copying text:', error);
      });
  };

  const launchECW =() => {
    openURL (`https://emr.apexheartcare.com/mobiledoc/jsp/webemr/newlogin.jsp `);
  }

    const onShowApexEcwEncounter = function (encargs) {
        window.open(encargs, "ecwEncounterTab");
    }

  const onViewEncoutner = (args) => {
    console.log("View Encounter clicked ", args);
    setToCopytoClipboard(`https://emr.apexheartcare.com/mobiledoc/jsp/catalog/xml/printChartOptions.jsp?FormData=Default&isHtml=true&style=ModernI&encounterID=${args.encounterID}&sessionDID=9121&TrUserId=9121&Device=webemr `);
      onShowApexEcwEncounter(`https://emr.apexheartcare.com/mobiledoc/jsp/catalog/xml/printChartOptions.jsp?FormData=Default&isHtml=true&style=ModernI&encounterID=${args.encounterID}&sessionDID=9121&TrUserId=9121&Device=webemr `);
  }

    const [toCopyToClipboard, setToCopytoClipboard] = useState('');

    // useEffect(()=> {
    //     handleCopyClick(toCopyToClipboard);
    // }, [toCopyToClipboard]);

    const columnDefs = [
        { headerName: 'encounterID', field: 'encounterID',  width: 90, },
       // { headerName: 'patientid', field: 'patientId',  width: 90, },
        { headerName: 'patient_name', field: 'patient_name', width: 150 },
        { headerName: 'VisitType', field: 'VisitType' ,  width: 70,},
        {
            field: 'encounterID', cellRenderer: BtnCellRenderer, editable: false, filter: false,
            cellRendererParams: {
              clicked: onViewEncoutner,
              buttonText: 'View'
            }, width: 60,
          },

        { headerName: 'enc_date', field: 'enc_date', width: 160, },
        { headerName: 'visit_status', field: 'visit_status', width: 60, },
        { headerName: 'visit_locked', field: 'visit_locked' },
        { headerName: 'invoiceid', field: 'invoiceid' },
        { headerName: 'patientID', field: 'patientID' },
        { headerName: 'patient_name', field: 'patient_name' },
        { headerName: 'visit_group', field: 'visit_group' },
        { headerName: 'facility_code', field: 'facility_code' },
        { headerName: 'pos', field: 'pos' },
        { headerName: 'starttime', field: 'starttime' },
        { headerName: 'endtime', field: 'endtime' },
        { headerName: 'reason', field: 'reason' },
        { headerName: 'visit_type', field: 'visit_type' },
        { headerName: 'visit_reason', field: 'visit_reason' },

        { headerName: 'visit_billed', field: 'visit_billed' },

        { headerName: 'primary_ins', field: 'primary_ins' },
        { headerName: 'second_ins', field: 'second_ins' },
        //{ headerName: 'enctype', field: 'enctype' },
        { headerName: 'enclock', field: 'enclock' },
        { headerName: 'generalnotes', field: 'generalnotes' },
       // { headerName: 'facilityId', field: 'facilityId' },



      ];


    const refreshData = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }

        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from apex.rc.billing_patient_encounters where enc_date between dateadd(yy, -2, getdate()) and  getdate()
        order by enc_date desc `;

        const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
        if (res2.data) {
          console.log("GOT Patient Encounters Files:", res2.data);
          let dframe = res2.data['frame0'];
          //console.log(dframe);
          let myObj = JSON.parse(dframe);
          console.log(myObj);
          let gridData = myObj['rows'];

          setRowData(gridData);
        }

    };

    useEffect(()=> {
        refreshData();
    }, []);

    // useEffect(()=> {
        
    // }, [docToDisplay]);

    const handleQuickFilter = (event) => {
        console.log("Quick Filter:",event.target, event.target.value, gridRef.current);
        setQuickFilter(event.target.value );
        gridRef.current.api.setQuickFilter(event.target.value);
    };


    const onSetCurrentPageNum = (pagenum) => {
        console.log("Currnet Page Numnber Changed", docToDisplay, documentContext, pagenum);
        setCurrentPageNum(pagenum);
    
        if (docToDisplay) {
        updateDocinfo({
          ecwdocid: null,
          apexdocid: null,
          documentpath : `${docToDisplay.dirpath}/${docToDisplay.filename}`,
          documentpage: currentPageNum,
          pagecount : 1
        });
      }else {
        updateDocPageinfo(currentPageNum);
      }
      }

      const handleGridRowSelected = (event) => {

        const selectedNodes = event.api
          .getSelectedNodes()
          .filter((node) => node.selected);
    
          if (selectedNodes[0]) {
        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);

        if (datarow.document_path) {
        setstartingPageNum(datarow.docpagenum);

        setDocToDisplay({
            document_path : datarow.document_path,
            docpagenum : datarow.docpagenum
        })

        handleAgDocument({
            filename : datarow.document_path,
            dirpath: '',
            startpage: datarow.docpagenum
        }, apiURL, onSetpdfToDisplayURL, setstartingPageNum);

        }
    }

        //console.log('Document to Display', docToDisplay);

        //setSelectedBillingSheetFile(datarow);
        //setQuickFilenameFilter(datarow.CustomName);

      };   
      
      const onSetpdfToDisplayURL = (args) => {
        console.log("PDFURL changed:", args);
        setpdfToDisplayURL(args);
      }

      //console.log("TO DISPLAY:", patientid, rowData);
      let filteredData =  (rowData && patientid )?  rowData.filter(row => row.patientID === patientid )  : (
         rowData ? rowData : [] );
         //console.log("TO DISPLAY:", patientid, rowData, filteredData);

    return (
        
            <>
                <div  style={{ width: '100%', marginLeft: '25px', marginTop: '5px'}}>
            <Flex flexDir={'row'} >
            <Button onClick={launchECW} colorScheme="blue" mr={'10px'} >ECW Link</Button>
            <ClipboardCopy copyText={toCopyToClipboard} />
           
            <Input as='input' value={quickFilter}  onChange={handleQuickFilter}  ref={initFocusRef} 
                placeholder="filter..." variant="filled" width={'150px'} ml={'100px'} fontSize='sm' aria-multiline />
                <Button onClick={refreshData} colorScheme="blue">Refresh</Button>  
           </Flex>
                </div>
           <div className="ag-theme-alpine" style={{ height: '15vw', width: '100%' }}>
            <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={filteredData}
                rowSelection="single"
                applyColumnDefOrder={true}
                onSelectionChanged={handleGridRowSelected}

            />
             </div>
             </>

       
    );
};

export default EcwPatientEncounter;

function ClipboardCopy({ copyText }) {
    const [isCopied, setIsCopied] = useState(false);
  
    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    return (
      <div>
        <input type="text" value={copyText} mr={'10px'} readOnly />
        {/* Bind our handler function to the onClick button property */}
        <button onClick={handleCopyClick}  ml={'10px'} >
          <span>{isCopied ? 'Copied!' : 'Copy'}</span>
        </button>
      </div>
    );
  }
