import React, { useState } from 'react';
import { Button, TextField, Switch, FormControlLabel } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import axios, { AxiosResponse } from 'axios';
import EditBankTransaction from "./EditBankTransaction";
import apiConfig from "../../apiConfig";
import pLimit from "p-limit";

interface RowData {
    [key: string]: any;
    tran_id?: string;
    status?: string;
}

interface ResultRowData {
    [key: string]: any;
}

interface ParsedData {
    rows: RowData[];
}

const LoadBankTransactions: React.FC = () => {
    const [rowData, setRowData] = useState<RowData[]>([]);
    const [gridApi, setGridApi] = useState<any>(null);
    const [filterText, setFilterText] = useState<string>('');
    const [editRowData, setEditRowData] = useState<RowData | null>(null);
    const [isEditOpen, setEditOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [importSwitch, setImportSwitch] = useState<boolean>(false);

    const apiURL = apiConfig.REACT_APEX_BASE_API;

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const allData: RowData[] = [];
            for (const file of Array.from(files)) {
                await new Promise(async (resolve) => {
                    if (file.type === 'text/csv') {
                        Papa.parse(file, {
                            complete: async (results: { data: any[]; }) => {
                                const parsedData = parseCsvData(results.data);
                                if (importSwitch) {
                                    const updatedData = await executeStoredProcedure(parsedData, 'edit');
                                    if (updatedData) {
                                        allData.push(...updatedData);
                                    }
                                } else {
                                    allData.push(...parsedData);
                                }
                                resolve(true);
                            }
                        });
                    } else {
                        const data = await file.arrayBuffer();
                        const workbook = XLSX.read(data);
                        const sheetName = workbook.SheetNames[0];
                        const worksheet: RowData[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        if (importSwitch) {
                            const updatedData = await executeStoredProcedure(worksheet, 'edit');
                            if (updatedData) {
                                allData.push(...updatedData);
                            }
                        } else {
                            allData.push(...worksheet);
                        }
                        resolve(true);
                    }
                });
            }
            setRowData(allData);
        }
    };

    const getScalarFromResultSet = (response: AxiosResponse<any, any>): ResultRowData | undefined => {
        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj: ParsedData = dframe && JSON.parse(dframe);
            const gridData: ResultRowData[] = myObj && myObj.rows;
            return (gridData && gridData.length > 0 && gridData[0]) || undefined;
        }
        return undefined;
    };

    const parseCsvData = (data: any[]) => {
        if (data.length === 0) {
            return []; // Return empty
        }

        if (data[0][1] === 'CCY') {
            return parseUSBCsvData(data); // use UsBank function if first row second column is 'CCY'
        }

        return parsePNCCsvData(data);
    }

    const parsePNCCsvData = (data: any[]) => {
        let csvFileReference = '';
        let isMonthlyStatement = false;
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based
        const currentYear = currentDate.getFullYear();

        if (data.length > 0) {
            const firstRow = data[0];
            if (firstRow[0] === 'Date') {
                // It's the current month file
                isMonthlyStatement = false;
            } else {
                // It's the monthly statement file
                isMonthlyStatement = true;
                csvFileReference = firstRow.join('~');
            }
        }

        return data.slice(1).reduce((acc, row) => {
            let amount = 0;
            let debitCredit = '';
            let description = '';
            let vendorDesc = '';
            let payerDesc = '';
            let importnotes: string = '';

            if (isMonthlyStatement) {
                // Monthly statement file
                if (row.length < 2) {
                    return acc; // Skip rows where the line might be blank
                }
                amount = parseFloat(row[1].replace(/[\$,]/g, ''));
                if (isNaN(amount)) {
                    return acc; // Skip rows where the amount is not a valid number
                }
                description = `${row[2]} ${row[3]}`;
                importnotes = row[4];
                debitCredit = row[5];
                vendorDesc = debitCredit && debitCredit.toLowerCase() === 'debit' ? row[3] : '';
                payerDesc = debitCredit && debitCredit.toLowerCase() === 'credit' ? row[3] : '';
            } else {
                // Current month file
                const dateParts = row[0].split('/');
                if (dateParts.length === 3) {
                    const transactionMonth = parseInt(dateParts[0], 10);
                    const transactionYear = parseInt(dateParts[2], 10);
                    if (transactionMonth !== currentMonth || transactionYear !== currentYear) {
                        return acc; // Skip transactions not in the current month
                    }
                    const formattedDate = `${dateParts[2]}/${dateParts[0].padStart(2, '0')}/${dateParts[1].padStart(2, '0')}`;
                    row[0] = formattedDate;
                }
                const withdrawals = row[2] ? parseFloat(row[2].replace(/[\$,]/g, '')) : 0;
                const deposits = row[3] ? parseFloat(row[3].replace(/[\$,]/g, '')) : 0;
                amount = withdrawals > 0 ? withdrawals : deposits;
                if (isNaN(amount) || amount === 0) {
                    return acc; // Skip rows where the amount is not a valid number
                }
                debitCredit = withdrawals > 0 ? 'debit' : 'credit';
                description = row[1];
                importnotes = 'month-to-date';
                vendorDesc = withdrawals > 0 ? row[1] : '';
                payerDesc = deposits > 0 ? row[1] : '';
            }

            acc.push({
                trandate: row[0],
                tranamount: amount,
                description: description ? description : '',
                debit_credit: debitCredit,
                importnotes: importnotes,
                vendor_desc: vendorDesc,
                payer_desc: payerDesc,
                importsourceref: csvFileReference,
            });

            return acc;
        }, [] as RowData[]);
    };

    const parseUSBCsvData = (data: any[]) => {
        if (data.length === 0 || data[0][1] !== 'CCY') {
            return []; // Return empty if first row second column is not 'CCY'
        }

        return data.slice(1).reduce((acc, row) => {
            const amount = parseFloat(row[0]);
            const debitCredit = row[4];
            const transactionDate = row[5];
            const accountNumber = row[3];
            const transactionDescription = row[6];
            const transactionDetail = row[8];
            const bankReference = row[9];

            if (isNaN(amount)) {
                return acc; // Skip rows where the amount is not a valid number
            }

            const importnotes = `${transactionDescription} ${transactionDetail}`;
            const descriptionMatch = transactionDetail.match(/ENTRY DESCRIPTION:\s*(.*?)\s*SEC CODE/);
            const description = debitCredit.toLowerCase() === 'debit' ? transactionDescription : (descriptionMatch ? descriptionMatch[1] : '');

            let payerDesc = '';
            if (debitCredit.toLowerCase() === 'credit') {
                const companyNameMatch = transactionDetail.match(/COMPANY NAME:\s*(.*?)\s*SEC CODE/);
                payerDesc = companyNameMatch ? companyNameMatch[1] : '';
            }

            acc.push({
                trandate: formatDateString(transactionDate),
                tranamount: amount,
                description: description,
                debit_credit: debitCredit,
                importnotes: importnotes,
                vendor_desc: debitCredit.toLowerCase() === 'debit' ? transactionDescription : '',
                payer_desc: payerDesc,
                importsourceref: `${accountNumber}~${bankReference}`,
            });

            return acc;
        }, [] as RowData[]);
    };

    const formatDateString = (dateString: string) => {
        const dateParts = dateString.match(/(\d{2})(\d{2})(\d{4})/);
        if (dateParts) {
            return `${dateParts[3]}/${dateParts[1]}/${dateParts[2]}`;
        }
        return dateString;
    };



    const executeStoredProcedure = async (data: RowData[], argOp: string) => {
        const limit = pLimit(2);

        const updatedData = await Promise.all(
            data.map(row => limit(async () => {
                try {

                    const token = localStorage.getItem("token");
                    let authHeader =  {
                        "Authorization": `Bearer ${token}`,  // ✅ Attach token
                        "Content-Type": "application/json"
                    }

                    const urlPrefix = `${apiURL}/exsql?dbserver=`;
                    const sqlText = generateSqlText(row, argOp);
                    const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

                    if (response.data) {
                        const responseRow = getScalarFromResultSet(response);
                        console.log(rowData, "Stored procedure Received Data ",argOp, row, responseRow);
                        if (responseRow) {
                            row.tran_id = responseRow.tran_id;
                            row.status = argOp === 'check' ? (responseRow.tran_id === -1 ? 'new' : 'exists') : '✔️';
                        } else {
                            row.status = '❌'; // Error mark
                        }
                    } else {
                        row.status = '❌'; // Error mark
                    }
                } catch (error) {
                    console.error('Error executing transaction:', error);
                    row.status = '❌'; // Error mark
                }
                return row;
            }))
        );
        return updatedData;
    };

    const generateSqlText = (row: RowData, argOp: string) => {
        const encodeValue = (value: string) => encodeURIComponent(value);

        return `apex&sqltype=customSQL&sqltext=exec rc.mnt_apexbanktransaction
        @arg_op = '${encodeValue(argOp)}',
        @importsourceref = '${encodeValue(row.importsourceref)}',
        @importsource = 'bankcsv',
        @trandate = '${encodeValue(row.trandate)}',
        @tranamount = ${row.tranamount},
        @description = '${encodeValue(row.description)}',
        @debit_credit = '${encodeValue(row.debit_credit)}',
        @importnotes = '${encodeValue(row.importnotes)}',
        @vendor_desc = '${encodeValue(row.vendor_desc)}',
        @payer_desc = '${encodeValue(row.payer_desc)}'`;
    };

    const validateTransactions = async () => {
        const validatedData = await executeStoredProcedure(rowData, 'check');
        console.log(rowData, "Received Data ", validatedData);
        setRowData(validatedData);
    };

    const saveTransaction = async (row: RowData) => {
        const updatedData = await executeStoredProcedure([row], 'edit');
        setRowData((prevData) =>
            prevData.map((r) => (r.tran_id === row.tran_id ? updatedData[0] : r))
        );
    };

    const checkTransaction = async (row: RowData) => {
        const updatedData = await executeStoredProcedure([row], 'check');
        setRowData((prevData) =>
            prevData.map((r) => (r.tran_id === row.tran_id ? updatedData[0] : r))
        );
    };

    const loadTransactions = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;
        const sqlText = 'set rowcount 0 select * from apex.rc.ApexBankTransaction';
        const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj: ParsedData = JSON.parse(dframe);
            const gridData: ResultRowData[] = myObj.rows;
            setRowData(gridData);
        }
    };

    const clearGridData = () => {
        setRowData([]);
    };

    const onGridReady = (params: any) => {
        setGridApi(params.api);
    };

    const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
        if (gridApi) {
            gridApi.setQuickFilter(event.target.value);
        }
    };

    const handleEdit = (row: RowData) => {
        setEditRowData(row);
        setEditOpen(true);
    };

    const handleSave = (updatedRow: RowData) => {
        setRowData((prevData) =>
            prevData.map((row) => (row.tran_id === updatedRow.tran_id ? updatedRow : row))
        );
        setEditOpen(false);
    };

    const handleDelete = (rowId: string) => {
        setRowData((prevData) => prevData.filter((row) => row.tran_id !== rowId));
        setEditOpen(false);
    };

    return (
        <div>
            <input type="file" accept=".csv, .xlsx, .xls" onChange={handleFileUpload} multiple />
            <TextField
                label="Filter"
                variant="outlined"
                value={filterText}
                onChange={handleFilterTextChange}
                style={{ marginLeft: '10px' }}
            />
            <Button onClick={loadTransactions} style={{ marginLeft: '10px' }}>
                Load Transactions
            </Button>
            <FormControlLabel
                control={
                    <Switch
                        checked={importSwitch}
                        onChange={(event) => setImportSwitch(event.target.checked)}
                        color="primary"
                    />
                }
                label="IMPORT"
                style={{ marginLeft: '10px' }}
            />
            <Button onClick={clearGridData} style={{ marginLeft: '10px' }}>
                Clear
            </Button>
            <Button onClick={validateTransactions} style={{ marginLeft: '10px' }}>
                VALIDATE
            </Button>
            <div className="ag-theme-alpine" style={{ height: 1000, width: '100%' }}>
                <AgGridReact
                    onGridReady={onGridReady}
                    rowData={rowData}
                    defaultColDef={{ floatingFilter: true }}
                    columnDefs={[
                        {
                            headerName: 'Actions',
                            field: 'actions',
                            width: 150,
                            cellRenderer: (params: any) => (
                                <div>
                                    <Button onClick={() => saveTransaction(params.data)}>Save</Button>
                                    <Button onClick={() => checkTransaction(params.data)}>Check</Button>
                                </div>
                            ),
                        },
                        { headerName: 'Row ID', field: 'tran_id', resizable: true, sortable: true, filter: true },
                        { headerName: 'Status', field: 'status', resizable: true, sortable: true, filter: true },
                        {
                            headerName: 'Transaction Date',
                            field: 'trandate',
                            resizable: true,
                            sortable: true,
                            filter: true
                        },
                        { headerName: 'Amount', field: 'tranamount', resizable: true, sortable: true, filter: true },
                        {
                            headerName: 'Description',
                            field: 'description',
                            resizable: true,
                            sortable: true,
                            filter: true
                        },
                        {
                            headerName: 'Debit/Credit',
                            field: 'debit_credit',
                            resizable: true,
                            sortable: true,
                            filter: true
                        },
                        {
                            headerName: 'Vendor Description',
                            field: 'vendor_desc',
                            resizable: true,
                            sortable: true,
                            filter: true
                        },
                        {
                            headerName: 'Payer Description',
                            field: 'payer_desc',
                            resizable: true,
                            sortable: true,
                            filter: true
                        }
                    ]}
                />
            </div>
            {editRowData && (
                <EditBankTransaction
                    open={isEditOpen}
                    anchorEl={anchorEl}
                    onClose={() => setEditOpen(false)}
                    rowData={editRowData}
                    onSave={handleSave}
                    onDelete={handleDelete}
                />
            )}
        </div>
    );
};

export default LoadBankTransactions;
