
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Container,
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";

import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
  } from "@choc-ui/chakra-autocomplete";



export function SelectApexStaff({ selectedStaff, setSelectedStaff }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const [apexstaff, setapexstaff] = useState([]);




    useEffect(()=> {

        const getData = async () => {

            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found. User must log in.");
                return;
            }

            let authHeader =  {
                "Authorization": `Bearer ${token}`,  // ✅ Attach token
                "Content-Type": "application/json"
            }

          const res = await axios.get(`${apiURL}/ecw/getstaff`, { headers: authHeader });
          if (res.data) {
            console.log("GOT Staff:", res.data);
            setapexstaff(res.data);
          }

        };
      
        getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);

      const onAssignedToSelectionChange = (value) => {
        console.log("Assigned to selection change:", value);
        let selectedAssignedToitem = apexstaff.filter(prsn=> prsn.name === value.label);
        console.log("Selected Assignedto Person", selectedAssignedToitem[0]);
        setSelectedStaff(selectedAssignedToitem[0]);
      }

      //use setSelectedStaff to se currently selected value

      let apexUsersJson = apexstaff?.map(({uid, name})=>{ return ({value: uid, label: name}) }); // return ({staff.uid, staff.name}));c

      return(
        <Container mb={1}>
        <FormControl p={1} w='500px'>
            <HStack>
          <FormLabel>AssingnTo</FormLabel>
          <Select 
            isClearable={true}
            options={apexUsersJson}
            placeholder="Select staff..."
            closeMenuOnSelect={true}
            onChange={onAssignedToSelectionChange}
          />
          </HStack>
        </FormControl>
      </Container>
      )

    }