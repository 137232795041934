import React, { useState, useContext , useEffect} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Box,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  HStack,
  Flex,
    Text
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import apiConfig from '../apiConfig';
import axios from "axios";
import { useDocument } from '../Contexts/DocumentContext';
import { usePatient} from "../Contexts/PatientContext";
import ViewDocumentModal from './ViewDocumentModal';
import { handleEcwDocument, handleFolderDocument, handleAgDocument } from '../utils/ecw/hadleDocuments';
import ApexPdfjsViewer from '../Components/ApexPdfjsViewer';


const CreateBillingTempPatient = ({onNewPatientAdded}) => {

  const gridRef = React.useRef();
  const initFocusRef = React.useRef();
  let apiURL=apiConfig.REACT_APEX_BASE_API;

  const documentContext = useDocument();
  const { addPatientRow } = usePatient();
  //console.log("DOCUMENT CONTEXT", documentContext);


  const [rowData, setRowData] = useState([]);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [showInsertForm, setShowInsertForm] = useState(false);
  const [newRowData, setNewRowData] = useState({
    tpat_id: '',
    patientid: '',
    firstname: '',
    lastname: '',
    dob: '',
    comment: '',
  });

  const [pdfToDisplayURL, setpdfToDisplayURL] = useState();
  const [startingPageNum, setStartingPageNum] = useState();
  const [currentPageNum, setCurrentPageNum] = useState();

  const [quickInsuranceFilter, setQuickInsuranceFilter] = useState('');

  const onViewDocument = (event) => {
    console.log("View Temp Patient Document", event);
    let data = {
        dirpath : '',
        filename: event.document_path,
        startpage: event.docpagenum,
    }
    handleAgDocument(data, apiURL, setpdfToDisplayURL, setStartingPageNum);
  }

  const onLinkDemogDocument = async (event) => {
    console.log("Link Demog page to Temp Patient Document", event);
    let data = {
        dirpath : '',
        filename: event.document_path,
        startpage: event.docpagenum,
    }
    console.log("Will save new row:", event);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }


    console.log("DOCUMENT CONTEXT", documentContext);
    let docpagenum = documentContext.docinfo.documentpage;
    let docpath = documentContext.docinfo.documentpath;
    console.log("DOC CONTXT", docpagenum, docpath);

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_doculink
    @arg_op = 'E', 
    @patient_id = ${event.tpat_id},
    @linkcategory = 'patientinfo',
    @docpagenum = ${docpagenum},
    @document_path = '${docpath}',
    @ecwdoc_id = NULL,
    @apexdoc_id  = NULL,
	@arg_entity_linkto = 'patient' ,
	@arg_entity_id  = ${event.tpat_id} ,
    @comment = '${event.comment}' ,
    @delflag  = NULL

       `;

    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add temp Billing patient:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      // let myObj = JSON.parse(dframe);
      
      // let gridData = myObj['rows'];
      // console.log(myObj, gridData);
      // rowdata = {...rowdata , tpat_id : gridData[0].tpat_id};
      // console.log("NewRowData after insert:", rowdata);
      // //setRowData([...rowData, rowdata]);
    }

  }


  const columnDefs = [
    { headerName: 'ID', field: 'tpat_id' , width: 80, sortable: true},
    { headerName: 'PatID', field: 'patientid', width: 80 ,  sortable: true},
    { headerName: 'First Name', field: 'firstname', editable: true , width: 140, sortable: true},
    { headerName: 'Last Name', field: 'lastname', editable: true,  width: 140 , sortable: true },
    { headerName: 'Date of Birth', field: 'dob', editable: true, width: 140, sortable: true},
    {
        field: '', cellRenderer: BtnCellRenderer, editable: false, filter: false,
        cellRendererParams: {
          clicked: onViewDocument,
          buttonText: 'View'
        }, width: 60,
      },
      {
        field: '', cellRenderer: BtnCellRenderer, editable: false, filter: false,
        cellRendererParams: {
          clicked: onLinkDemogDocument ,
          buttonText: 'DemogLink'
        }, width: 60,
      },
      { headerName: 'Comment', field: 'comment', editable: true, width: 240 },

    
  ];

  useEffect(()=> {

    const getData = async () => {

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found. User must log in.");
        return;
      }

      let authHeader =  {
        "Authorization": `Bearer ${token}`,  // ✅ Attach token
        "Content-Type": "application/json"
      }

        let urlPrefix = apiURL + '/exsql?dbserver=';
        let dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.billing_tpatient ";

        const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
        if (res2.data) {
          console.log("GOT Billing Files:", res2.data);
          let dframe = res2.data['frame0'];
          //console.log(dframe);
          let myObj = JSON.parse(dframe);
          console.log(myObj);
          let gridData = myObj['rows'].map(row=>  {
            console.log(row['dob'], row['dob'].substring(0,10));
           // console.log(format(row['dob'].substring(0,10), 'yyyy-MM-dd'));
            //row['dob'] = format(row['dob'].substring(0,10), 'yyyy-MM-dd');
             return row;
           });

           //console.log("Temp Patients", gridData);

          setRowData(gridData);
        }


    };
  
   // getData(); 
  
    return () => {
      // this now gets called when the component unmounts
    };


  }, []);

  const saveBillingTpat = async (rowdata)  =>{

    console.log("Will save new row:", rowdata);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }
    console.log("DOCUMENT CONTEXT", documentContext);
    let docpagenum = documentContext.docinfo.documentpage;
    let docpath = documentContext.docinfo.documentpath;
    console.log("DOC CONTXT", docpagenum, docpath);

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tpatient   
     @arg_op = 'E',    
     @firstname = '${rowdata.firstname}' ,     
     @lastname = '${rowdata.lastname}' ,
     @middlename = '${rowdata.middlename ? rowdata.middlename : ''}' ,
    @dob  = '${rowdata.dob}' ,  
         @facility = '${rowdata.facility ? rowdata.facility : ''}' , 
          @priority = ${rowdata.priority ? rowdata.priority : 0} , 
        @encdate  = '${rowdata.encdate ? rowdata.encdate : ''}' ,    
    @docpagenum = ${docpagenum},
    @document_path = '${docpath}',    @ecwdoc_id  = NULL,    @apexdoc_id  = NULL,    @comment = '${rowdata.comment ? rowdata.comment : ''}' ,
     @demog_comment = '${rowdata.demog_comment ? rowdata.demog_comment : ''}'
       `;

    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add temp Billing patient:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      // rowdata = {...rowdata , tpat_id : gridData[0].tpat_id};
      // console.log("NewRowData after insert:", rowdata);
      // setRowData([...rowData, rowdata]);
  

    let newPatientRow = {
      'Comment' : `${rowdata.comment}`,
      'DOB': `${rowdata.dob}`,
      'Name' : `${rowdata.lastname}, ${rowdata.firstname} ${rowdata.middlename}`,
      'PatientId': gridData[0].tpat_id,
      'Phone1' : '',
      'src' : `Temp`
    }

    console.log("NEW PATIENT AFTER INSERT:", newPatientRow);
    onNewPatientAdded(newPatientRow);

  }

  }

  const handleAddRow = () => {
    saveBillingTpat(newRowData);
    setShowInsertForm(false);
    setNewRowData({
      tpat_id: '',
      patientid: '',
      firstname: '',
      lastname: '',
      middlename: '',
      dob: '',
      comment: '',
      demog_comment : '',
      facility : '',
      priority : 0,
      encdate : ''
    });
  };

  const handleUpdateRow = () => {
    gridApi.applyTransaction({ update: [newRowData] });
  };

  const handleDeleteRow = () => {
    const selectedRowNodes = gridApi.getSelectedNodes();
    const selectedIds = selectedRowNodes.map((node) => node.data.tpat_id);

    // Delete the row from database. If success , Delete from local table or refresh the table
    const updatedRowData = rowData.filter((row) => !selectedIds.includes(row.tpat_id));


    setRowData(updatedRowData);
  };


  const onUseSelectedPatient = () => {
    const selectedRowNodes = gridApi.getSelectedNodes();
    if (selectedRowNodes[0]) {
    console.log("USE Patient:", selectedRowNodes[0].data);
    addPatientRow(selectedRowNodes[0].data);
    }
  }

  const onSetCurrentPageNum = (pagenum) => {
    console.log("Viewing now page: ", pagenum);
    setCurrentPageNum(pagenum);
}



const handleQuickInsuranceFilter = (event) => {
    console.log("Quick Patient Filter:",event.target, event.target.value, gridRef.current);
    setQuickInsuranceFilter(event.target.value );
    gridRef.current.api.setQuickFilter(event.target.value);
};
  
//         <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum} setCurrentPageNum={onSetCurrentPageNum} />
  return (

      <Box p={4}>
        {/* <Flex >
        <Button onClick={() => setShowInsertForm(!showInsertForm)} mr={4}>Add New</Button>
        <Button onClick={onUseSelectedPatient} mr={4}>Select</Button>
        <Input as='input'  value={quickInsuranceFilter} placeholder='Filter' onChange={handleQuickInsuranceFilter} ref={initFocusRef}  />
        </Flex> */}
        { (

          <Stack direction="column" spacing={2} mt={4}>

            <Input
              placeholder="Last Name"
              value={newRowData.lastname}
              onChange={(e) => setNewRowData({ ...newRowData, lastname: e.target.value })}
            />
            <Input
              placeholder="First Name"
              value={newRowData.firstname}
              onChange={(e) => setNewRowData({ ...newRowData, firstname: e.target.value })}
            />
              <Input
              placeholder="Middle Name"
              value={newRowData.middlename}
              onChange={(e) => setNewRowData({ ...newRowData, middlename: e.target.value })}
            />
            <HStack>
              <Text>DOB </Text>
            <Input placeholder="DOB"
              type="date"
              value={newRowData.dob}
              onChange={(e) => setNewRowData({ ...newRowData, dob: e.target.value })}
            />
            </HStack>
            <Input
                placeholder="Facility"
                value={newRowData.facility}
                onChange={(e) => setNewRowData({ ...newRowData, facility: e.target.value })}
            />
            <Input
                placeholder="Priority"
                value={newRowData.priority}
                onChange={(e) => setNewRowData({ ...newRowData, priority: e.target.value })}
            />
            <HStack>
              <Text>Encounter </Text>
            <Input
                placeholder="Encounter Date"
                type="date"
                value={newRowData.encdate}
                onChange={(e) => setNewRowData({ ...newRowData, encdate: e.target.value })}
            />
            </HStack>
            <Input
              placeholder="Comment"
              value={newRowData.comment}
              onChange={(e) => setNewRowData({ ...newRowData, comment: e.target.value })}
            />
            <Input
                placeholder="Demog Comment"
                value={newRowData.demog_comment}
                onChange={(e) => setNewRowData({ ...newRowData, demog_comment: e.target.value })}
            />
          
          <HStack alignItems={'right'}>
      <Button colorScheme='green'  onClick={handleAddRow} >
        Save Patient
      </Button>

      {/* <Button colorScheme='gray'  >
        Clear Selection
      </Button> */}
      </HStack>
          </Stack>

          
        )}

{/* onClick={saveClaim} */}

        {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginTop: '1rem' }}>
          <AgGridReact
            onGridReady={(params) => {
              setGridApi(params.api);
              setGridColumnApi(params.columnApi);
            }}
            ref={gridRef} 
            rowData={rowData}
            editType={'fullRow'}
            editable={true}
            columnDefs={columnDefs}
            rowSelection="single"
          />
        </div> */}

      </Box>

  );
};

export default CreateBillingTempPatient;

function  BtnCellRenderer (props) {
    //let cellformattedValue = ` ${props.data.price}`;
  
    console.log("Button Text btncellrenderer", props);
    let editingCells = props.api.getEditingCells();
    // checks if the rowIndex matches in at least one of the editing cells
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === props.node.rowIndex;
    });
  
    let btnText = props.buttonText;
    if (btnText === "Edit") {
      if (isCurrentRowEditing) {
        btnText = "Save";
      }else {
        btnText = "Edit";
      }
  
    } 
  
    if (btnText === "Delete") {
      if (isCurrentRowEditing) {
        btnText = "Cancel";
      }else {
        btnText = "Delete";
      }
  
    } 
  
    const btnClickedHandler = () => {
        props.clicked(props.data, props.buttonText);
    }
      return (
        <>
        <div><button onClick={btnClickedHandler}>{btnText}</button> </div>
        
        </>
      )
  }
  
