const getLocalData = (key) => {
  console.log("Checking getlocaldata", key);
  if (key) {
    const data = localStorage.getItem(key);
    if (data) {
      console.log("Checking getlocaldata=> present", key);
    }else {
      console.log("Checking getlocaldata=> not present", key);
    }

    return data;
  }
};

const saveLocalData = (key, value) => {
  if (key && value) {
    localStorage.setItem(key, value);
  }
};

export { getLocalData, saveLocalData };
