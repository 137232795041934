
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Container,
    Box
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
  } from "@choc-ui/chakra-autocomplete";

export function SelectEcwFolder({ selectedEcwFolder, setSelectedEcwFolder }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const [apexfolders, setapexfolders] = useState([]);

    useEffect(()=> {

        const getData = async () => {

            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found. User must log in.");
                return;
            }

            let authHeader =  {
                "Authorization": `Bearer ${token}`,  // ✅ Attach token
                "Content-Type": "application/json"
            }

            const res2 = await axios.get(`${apiURL}/ecw/getdocfolders`, { headers: authHeader });
            if (res2.data) {
              console.log("GOT Folders:", res2.data);
              setapexfolders(res2.data);
            }
    

        };
      
        getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);

    const onFolderSelectionChange = (value) => {
        console.log("Folder selection change:", value);

        if (!value) {
            setSelectedEcwFolder(null); // Clear the selection
            return;
        }

        const selectedfolderitem = apexfolders.find(fldr => fldr.id === value.value);
        console.log("Selected File Folder Category", selectedfolderitem);
        setSelectedEcwFolder(selectedfolderitem || null);
    };


    //use selectedEcwFolder to se currently selected value

  let ecwFolders = apexfolders?.map(({id, label})=>{ return ({value: id, label: label}) }); // return ({staff.uid, staff.name}));c

      return(
          <Container mb={1}>
              <FormControl width="650px">
                  <HStack align="center">
                      <FormLabel>Folder</FormLabel>
                      <Box flex="1">

                          <Select
                              isClearable
                              options={ecwFolders}
                              placeholder="Select Folder..."
                              closeMenuOnSelect
                              onChange={onFolderSelectionChange}
                              value={
                                  selectedEcwFolder
                                      ? { value: selectedEcwFolder.id, label: selectedEcwFolder.label }
                                      : null
                              }
                              styles={{
                                  container: (base) => ({
                                      ...base,
                                      width: '100%',
                                      height: '100px'
                                  }),
                              }}
                          />
                      </Box>
                  </HStack>
              </FormControl>
          </Container>
      )

    }