
import React, { useEffect } from "react";
import useState from 'react-usestateref';
import apiConfig from '../apiConfig';
import axios from "axios";
import { format } from 'date-fns';
import { useClaim } from "../Contexts/ClaimContext";

import {
    Flex,
    Text,
    FormControl,
    HStack,
    FormLabel,
    Box,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    Input,
    Textarea

} from "@chakra-ui/react";

import { AgGridReact } from 'ag-grid-react';
import CreateBillingTempClaim from "./CreateBillingTempClaim";


  import 'ag-grid-community/styles/ag-grid.css';
  import 'ag-grid-community/styles/ag-theme-alpine.css';

export function SelectEcwBillingClaim({ selectedEcwBillingClaim, setSelectedEcwBillingClaim, currentClaimID }) {

    let apiURL=apiConfig.REACT_APEX_BASE_API;
    const gridRef = React.useRef();
    const initFocusRef = React.useRef();

    const { ClaimList , refreshClaims} = useClaim();
    console.log("Received claims form context :", ClaimList);
    const [quickBillingClaimFilter, setQuickBillingClaimFilter] = useState('');
    const [ecwBillingClaim, setecwBillingClaims] = useState([]);
    const [currentClaimData, setcurrentClaimData] = useState({});
    

    const [BillingClaimColumnDefs, setBillingClaimColumnDefs] = useState(
        [
            { field: 'RecType', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Src' , 
            resizable: true,editable: true},
            { field: 'servicedt', filter: true , width:130 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'DOS' , 
            resizable: true,editable: true},
            { field: 'filestatus', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Status' , 
            resizable: true,editable: true},
            { field: 'patientname', filter: true , width:180 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Patient' , 
            resizable: true,editable: true},
            { field: 'dob', filter: true , width:130 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'DOB' , 
            resizable: true,editable: true},
            { field: 'facility', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Facility' , 
            resizable: true,editable: true},
            { field: 'pos', filter: true , width:60 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'POS' , 
            resizable: true,editable: true},
            { field: 'dxcodes', filter: true , width:200 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'DxCodes' , 
            resizable: true,editable: true},
            { field: 'comment', filter: true , width:200 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Address' , 
            resizable: true,editable: true},
            { field: 'claim_id', width: 100 , sortable: true,cellStyle: {fontSize: '15px'},headerName: 'claim_id',
            resizable: true,},
            { field: 'claimdt', filter: true, width: 100, sortable: true,cellStyle: {fontSize: '15px'},headerName: 'claimdt',
            resizable: true, },


            { field: 'patient_id', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Address' , 
            resizable: true,editable: true},

            { field: 'resource', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Address' , 
            resizable: true,editable: true},
            { field: 'tclaim_id', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Address' , 
            resizable: true,editable: true},
            { field: 'patient_id', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Address' , 
            resizable: true,editable: true},
            { field: 'tpatient_id', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Address' , 
            resizable: true,editable: true},
        

            { field: 'updated_at', filter: true , width:100 ,  sortable: true,cellStyle: {fontSize: '15px'}, headerName: 'Address' , 
            resizable: true,editable: true},
        
        
  
        ]
    );


    const getGivenClaimData = async (claimid) => {

      if (!claimid) return;

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }


      let urlPrefix = apiURL + '/exsql?dbserver=';
      let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select top 4000 * from apex.rc.billing_all_claims where tclaim_id = ${claimid} order by servicedt desc `;

      const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
      if (res2.data) {
        console.log("GOT Billing Files:", res2.data);
        let dframe = res2.data['frame0'];
        //console.log(dframe);
        let myObj = JSON.parse(dframe);
        console.log(myObj);
        let gridData = myObj['rows'];

        setcurrentClaimData(gridData.map(row=>  {
          row['servicedt'] = row['servicedt'].substring(0,10);
          return row;
        })[0]);

          console.log('Selecting claim for given claim id ', claimid, gridData[0]);
          setSelectedEcwBillingClaim(gridData[0]);
          setQuickBillingClaimFilter(gridData[0].Name);

          //selectGridRowForClaimId(currentClaimID);
      }


  };


    const getData = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }

      let urlPrefix = apiURL + '/exsql?dbserver=';
      let dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 	select * from apex.rc.billing_all_claims where servicedt > dateadd(yy, -2, getdate()) order by servicedt desc  ";

      const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
      if (res2.data) {
        console.log("GOT Billing Files:", res2.data);
        let dframe = res2.data['frame0'];
        //console.log(dframe);
        let myObj = JSON.parse(dframe);
        console.log(myObj);
        let gridData = myObj['rows'];

        setecwBillingClaims(gridData.map(row=>  {
          row['servicedt'] = row['servicedt'].substring(0,10);
          return row;
        }));


      }


  };

    useEffect(()=> {

      
       // getData(); 
      
        return () => {
          // this now gets called when the component unmounts
        };
  
  
      }, []);


      useEffect(()=> {
          if (currentClaimID) {
              console.log("Will auto-select billing claim based on given id:", currentClaimID);
              getGivenClaimData(currentClaimID);
              //selectGridRowForClaimId(currentClaimID);
          }
        //

      }, [currentClaimID]);

    const handleQuickBillingClaimFilter = (event) => {
        console.log("Quick Patient Filter:",event.target, event.target.value, gridRef.current);
        setQuickBillingClaimFilter(event.target.value );
        gridRef.current.api.setQuickFilter(event.target.value);
    };

    const selectGridRowForClaimId = async (claim_id) => {

      let claimrow = ClaimList.filter(row=> {
        return row.claim_id == claim_id
      })

        console.log('selectGridRowForClaimId  ', claim_id, claimrow);

      if (claimrow && claimrow[0]) {
        let datarow = claimrow[0];
      console.log('Selecting claim for given claim id ', claim_id, datarow);
      setSelectedEcwBillingClaim(datarow);
      setQuickBillingClaimFilter(datarow.Name);
      }

      //console.log("handleGridRowSelected:",event);
      // const selectedNodes = event.api
      //   .getSelectedNodes()
      //   .filter((node) => node.selected);
      // //console.log(selectedNodes);
  
      // const datarow = selectedNodes[0].data;
      // console.log('AG Row selected', datarow);
      // setSelectedEcwBillingClaim(datarow);
      // setQuickBillingClaimFilter(datarow.Name);

  };
      
    const handleGridRowSelected = (event) => {

        //console.log("handleGridRowSelected:",event);
        const selectedNodes = event.api
          .getSelectedNodes()
          .filter((node) => node.selected);
        //console.log(selectedNodes);
    
        const datarow = selectedNodes[0].data;
        console.log('AG Row selected', datarow);
        setSelectedEcwBillingClaim(datarow);
        setQuickBillingClaimFilter(datarow.Name);

    };

    let claiminfoToDisplay = '';
      //use selectedEcwFolder to se currently selected value
      if (selectedEcwBillingClaim)  {
      let claiminfo = selectedEcwBillingClaim? `${selectedEcwBillingClaim.claim_id}-${selectedEcwBillingClaim.RecType}-[${selectedEcwBillingClaim.filestatus}]-${selectedEcwBillingClaim.servicedt}-${selectedEcwBillingClaim.patientname}-${selectedEcwBillingClaim.facility}-${selectedEcwBillingClaim.pos} ` : '';
      let claiminfo2 = selectedEcwBillingClaim? `${selectedEcwBillingClaim.dxcodes} ` : '';
      claiminfoToDisplay = claiminfo + claiminfo2;
  }else {

    if (currentClaimID && currentClaimData) {
      let datarow = currentClaimData;
    console.log('Selecting claim for given claim id ', currentClaimID, datarow);

    let claiminfo = datarow? `${datarow.claim_id}-${datarow.RecType}-[${datarow.filestatus}]-${datarow.servicedt}-${datarow.patientname}-${datarow.facility}-${datarow.pos} ` : '';
    let claiminfo2 = datarow? `${datarow.dxcodes} ` : '';
    claiminfoToDisplay = claiminfo + claiminfo2;

    }else {
      claiminfoToDisplay = "Claim ID: " + currentClaimID;
    }
  }

  if (!ClaimList) return('');


  // onClick={handleRefresh}
      return(

        <Popover isLazy initialFocusRef={initFocusRef}>
  <PopoverTrigger>


<div>
<Flex flexDirection={'row'} width={'35vw'} >


    <Textarea value={claiminfoToDisplay}   width={'160vw'}
          placeholder="Select BillingClaim" variant="filled" fontSize='sm' aria-multiline 
          />

<Button
 height='15px'
  width='auto'
  border='2px'
  alight={'center'}
  marginTop={'10'}
  paddingX={'-5'}
  borderColor='green.500'
  backgroundColor='blue.500'
  transform= {'rotate(90deg)'}
  flex={8}
  
>
        S E L E C T
      </Button>
    <Button  height='15px'
             width='auto'
             border='2px'
             alight={'center'}
             marginTop={'10'}
             paddingX={'-5'}
             borderColor='green.500'
             backgroundColor='blue.500'
             transform= {'rotate(90deg)'}
             flex={8}
             onClick={refreshClaims} >Refresh</Button>
      </Flex>
      </div>
   
  </PopoverTrigger>
  <PopoverContent bg='tomato' color='white' w='1000px'>
    <PopoverHeader fontWeight='semibold'>Recent Claims (includes Temp Claims)</PopoverHeader>
    <PopoverArrow />
    <PopoverCloseButton />
    <PopoverBody>
    

<HStack>
<Input as='input' value={quickBillingClaimFilter}  onChange={handleQuickBillingClaimFilter}  ref={initFocusRef}
          placeholder="Select BillingClaim" variant="filled" fontSize='sm' aria-multiline />
             {/* <CreateBillingTempClaim /> */}
    <Button  onClick={refreshClaims} >Refresh</Button>
    </HStack>

    <Box className="ag-theme-alpine maxWidth" style={{ paddingLeft: '-200px', width: '100%', height: '450px', overflow: 'hidden' }}>
                <AgGridReact 
                ref={gridRef} 
                rowData={ClaimList}
                rowHeight={20}
                columnDefs={BillingClaimColumnDefs} 
                animateRows={true} 
                resizable={true} 
                rowSelection="single"
                rowDragManaged={true}
                suppressMoveWhenRowDragging={true}
                applyColumnDefOrder={true}
                onSelectionChanged={handleGridRowSelected}
                />
     </Box>
    </PopoverBody>
  </PopoverContent>
</Popover>


      )

    }