// @ts-nocheck 
import * as React from 'react';
import { useEffect } from 'react'
import useState from 'react-usestateref';
import ClaimForm from './ClaimForm';
import ManageBillingTempClaimCpts from './ManageBillingTempClaimCpts';
import apiConfig from '../../apiConfig';
import axios from "axios";
import { SelectEcwPatient } from '../../Modals/SelectApexPatient';
import { useDocument } from '../../Contexts/DocumentContext';
import SelectCptToDocuLink from '../../Modals/SelectCptToDocuLink';
import PatientManagementComponent from '../../Modals/PatientManagementComponent';
import SplitPage from '../../Components/splitpage/splitpage';
import SplitPanel from '../../Components/splitpage/splitpanel';

import {
  Box,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  FormLabel,
  HStack,
  VStack,
  Stack,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Input,
  Checkbox,
  Text

} from '@chakra-ui/react';
import { SingleDatepicker, RangeDatepicker } from 'chakra-dayzed-datepicker';
import { SelectBillingSheetFile } from '../../Modals/SelectBillingSheetFile';
import ApexPdfjsViewer from '../../Components/ApexPdfjsViewer';
import SuperClaimComponent from '../../Modals/SuperClaimComponent';
import { handleEcwDocument, handleFolderDocument, handleAgDocument } from '../../utils/ecw/hadleDocuments';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { MinusIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'

import {
  loadGridData,
  DATA_APEX_BILLING_RECENT100_CLAIMS
} from '../../utils/ecw/EcwData';

const Billing = () => {


  let apiURL = apiConfig.REACT_APEX_BASE_API;
  const { documentContext, updateDocinfo, updateDocPageinfo } = useDocument();
  const gridRef2 = React.useRef();
  const docuRefGrid = React.useRef();
  const initFocusRef = React.useRef();

  const [selectedEcwPtDocsPatient, setSelectedEcwPtDocsPatient] = useState();
  const [selectedBillingSheetFile, setSelectedBillingSheetFile] = useState();
  const [recentClaims, setrecentClaims] = useState([]);
  const [dosDate, setdosDate, dosDateRef] = useState(new Date());

  const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);


  const [startingPageNum, setstartingPageNum] = useState(1)
  const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
  const [currentPageNum, setCurrentPageNum, currPageRef] = useState(1);

  const [documentRefRows, setDocumentRefRows] = useState([]);

  const [quickDoculinkFilter, setQuickDoculinkFilter] = useState('');
  const [currentClaimID, setcurrentClaimID]= useState();
  const [claimDocumentLinks, setClaimDocumentLinks] = useState();

  const [ viewThisPageLinks, setviewThisPageLinks] = useState(false);

  const onSelectedEcwPtDocsPatient = (data: any) => {
    console.log("select ptDoc patient in documanager", data);
    setSelectedEcwPtDocsPatient(data);
  }

  const onViewDocumentPage = (datarow) => {
    console.log("OnViewDocumentPage", datarow);
    handleAgDocument({
      filename : datarow.document_path,
      dirpath: '',
      startpage: datarow.docpagenum
    }, apiURL, onSetpdfToDisplayURL, setstartingPageNum);
  }

  const onLinkPageToCpt = (datarow) => {
    console.log("onLinkPageToCpt", datarow);
  }


  const docuColumnDefs = [
    { headerName: 'Patient', field: 'patient_name', sortable: true, filter: true, width: 140, tooltipField: 'patient_name',tooltipShowDelay: 100, tooltipHideDelay: 5000 },
    { headerName: 'Facility', field: 'facility', sortable: true, filter: true , width: 80},
    { headerName: 'cpt', field: 'cptcode', sortable: true, filter: true , width: 80},
    {
      field: '', cellRenderer: BtnCellRenderer, editable: false, filter: false,
      cellRendererParams: {
        clicked: onViewDocumentPage,
        buttonText: 'Page'
      }, width: 60,
    },
    { headerName: 'sdos', field: 'sdos', sortable: true, filter: true , width: 130},
    { headerName: 'cptdesc', field: 'cptdesc', sortable: true, filter: true , width: 180, tooltipField: 'cptdesc', tooltipShowDelay: 100, tooltipHideDelay: 5000 },
    { headerName: 'Page', field: 'docpagenum', sortable: true, filter: true , width: 80},
    { headerName: 'Entity', field: 'entity_type', sortable: true, filter: true, width: 80 },


    {
      field: '', cellRenderer: BtnCellRenderer, editable: false, filter: false,
      cellRendererParams: {

        clicked: onLinkPageToCpt ,
        buttonText: 'LikePage'
      }, width: 60,
    },
    { headerName: 'EntityID', field: 'entity_id', sortable: true, filter: true , width: 140},
    { headerName: 'Attach ID', field: 'attach_id', sortable: true, filter: true, width: 140 },
    { headerName: 'Link ID', field: 'link_id', sortable: true, filter: true },


    { headerName: 'Attach Comment', field: 'attach_comment', sortable: true, filter: true },
    { headerName: 'Patient ID', field: 'patient_id', sortable: true, filter: true },
    { headerName: 'Link Category', field: 'linkcategory', sortable: true, filter: true },

    { headerName: 'Document Path', field: 'document_path', sortable: true, filter: true },
    { headerName: 'ECW Document ID', field: 'ecwdoc_id', sortable: true, filter: true },
    { headerName: 'APEX Document ID', field: 'apexdoc_id', sortable: true, filter: true },
    { headerName: 'Link Comment', field: 'link_comment', sortable: true, filter: true },
    { headerName: 'Linked ECW Document ID', field: 'linkecwdoc_id', sortable: true, filter: true },
    { headerName: 'File Name', field: 'fileName', sortable: true, filter: true },
    { headerName: 'Modified Document Path', field: 'modc_document_path', sortable: true, filter: true },
    { headerName: 'Custom Name', field: 'customName', sortable: true, filter: true },
    { headerName: 'Document Type', field: 'doc_Type', sortable: true, filter: true },
    { headerName: 'Scan Date', field: 'scandate', sortable: true, filter: true },
    // Add more columns as needed
  ];



  const getClaimDocumentLinks = async (claimid)  =>{

    console.log("Will get document reference links for claim :", claimid);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=select docpagenum, document_path, min(entity_type) as entity_type from rc.billing_doc_links  where tclaim_id = ${claimid} group by document_path, docpagenum `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add document refs lookup:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      if (dframe) {
        let myObj = JSON.parse(dframe);
        console.log(myObj);
        let gridData = myObj['rows'];
        if (gridData) {
          setClaimDocumentLinks(gridData);
        }
        console.log("Claim Document Links Grid", gridData);
      }
    }


  }


  const onViewThisPageLinksChange = () => {
    setviewThisPageLinks(!viewThisPageLinks);
  }
  const getDocumentReferences = async (documentPath)  =>{

    console.log("Will get document reference links for :", documentPath);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }


    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=select * from rc.billing_document_links where document_path = '${documentPath}' order by docpagenum desc, entity_id desc `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add document refs lookup:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      if (dframe) {
        let myObj = JSON.parse(dframe);
        console.log(myObj);
        let gridData = myObj['rows'];
        if (gridData) {
          setDocumentRefRows(gridData);
        }
        console.log("DocumentRefs Grid", gridData);
      }
    }


  }

  const refreshDocumentLinksTable = (documentpath) => {

    console.log("Will refresh links for :",documentpath);

    // if (!documentpath) {
    //   documentpath=`${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`;
    // }

    getDocumentReferences(documentpath);
  }

  const onSelectedBillingSheetFile = (data: any) => {
    console.log("select billing Sheet File in documanager", data);
    getDocumentReferences(`${data.dirpath}/${data.filename}`);
    setSelectedBillingSheetFile(data);
    updateDocinfo({
      ecwdocid: null,
      apexdocid: null,
      documentpath : `${data.dirpath}/${data.filename}`,
      documentpage: currentPageNum,
      pagecount : 1
    });
    handleAgDocument(data, apiURL, setpdfToDisplayURL, setstartingPageNum);

  }

  const refreshRecent100Clains = () => {
    loadGridData(DATA_APEX_BILLING_RECENT100_CLAIMS, {}, recvGridData);
  }

  const refreshPatientClaims = () => {
    console.log("Selected patient", selectedEcwPtDocsPatient);
    if (!selectedEcwPtDocsPatient) return;
    let patid: any = selectedEcwPtDocsPatient.PatientId;
    loadGridData(DATA_APEX_BILLING_RECENT100_CLAIMS, { "patientid": patid }, recvGridData);
  }

  const formatDate = (date) => {
    // var date_format_str = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
    const d = date;
    console.log('New Date Value: ', date);
    var date_format_str = '';
    if (date != null) {
      var date_format_str = `${d.getFullYear().toString()}-${(d.getMonth() + 1).toString().length == 2
          ? (d.getMonth() + 1).toString()
          : `0${(d.getMonth() + 1).toString()}`
      }-${d.getDate().toString().length == 2
          ? d.getDate().toString()
          : `0${d.getDate().toString()}`
      }`;
      return date_format_str;
    }
  }


  const onSetdosDate = (args) => {

    setdosDate(args);
    console.log("Selected Date", args);
    if (!args) return;
    let sdos: any = args;
    loadGridData(DATA_APEX_BILLING_RECENT100_CLAIMS, { "sdos": formatDate(sdos) }, recvGridData);

  }

  const recvGridData = (gridName: any, args: any, gridData: []) => {
    console.log('ReceivedData for :', gridName, args, gridData)

    switch (gridName) {
      case DATA_APEX_BILLING_RECENT100_CLAIMS:
        console.log(gridName, gridData);
        setrecentClaims(gridData);
        break;
      default:
        break;
    }
  }

  //   const range = (start:Number, stop:Number, step=1) => {
  //     const length = Math.ceil((stop - start) / step);
  //     return Array.from({length}, (_, i) => (i * step) + start);
  // }

  const onSetCurrentPageNum = (pagenum, locval) => {
    console.log("Currnet Page Numnber Changed", selectedBillingSheetFile, documentContext, pagenum, locval);
    setCurrentPageNum(pagenum);

    if (selectedBillingSheetFile) {
      updateDocinfo({
        ecwdocid: null,
        apexdocid: null,
        documentpath : `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`,
        documentpage: currentPageNum,
        pagecount : 1
      });
    }else {
      updateDocPageinfo(currentPageNum);
    }



  }

  const  handleAddDemogLink = async (event) => {


    if (!selectedEcwPtDocsPatient) {
      console.log("Select patient first");
      return;
    }

    if (!refreshDocumentLinksTable) {
      console.log("Select a page in document first");
      return;
    }



    // documentpath : `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`,
    // documentpage: currentPageNum,


    console.log("Link Demog page to Temp Patient Document", event, selectedEcwPtDocsPatient, selectedBillingSheetFile, currentPageNum);



    console.log("Will save new row:", event);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_doculink
    @arg_op = 'E', 
    @patient_id = ${selectedEcwPtDocsPatient.PatientId},
    @linkcategory = 'patientinfo',
    @docpagenum = ${currentPageNum},
    @document_path = '${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}',
    @ecwdoc_id = NULL,
    @apexdoc_id  = NULL,
	@arg_entity_linkto = 'patient' ,
	@arg_entity_id  = ${selectedEcwPtDocsPatient.PatientId} ,
    @comment = '' ,
    @delflag  = NULL

       `;

    console.log("SQL to execute : ", dataURL)

    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add temp Billing patient:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      // let myObj = JSON.parse(dframe);

      // let gridData = myObj['rows'];
      // console.log(myObj, gridData);
      // rowdata = {...rowdata , tpat_id : gridData[0].tpat_id};
      // console.log("NewRowData after insert:", rowdata);
      // //setRowData([...rowData, rowdata]);


      console.log("Will refresh table for the document", `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);
      refreshDocumentLinksTable(`${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);

    }


  }

  const handleAddEcwDemogDocument = async (event) => {


    if (!selectedEcwPtDocsPatient) {
      console.log("Select patient first");
      return;
    }

    if (!refreshDocumentLinksTable) {
      console.log("Select a page in document first");
      return;
    }



    // documentpath : `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`,
    // documentpage: currentPageNum,


    console.log("Link Demog page to eCW Patient Docs", event, selectedEcwPtDocsPatient, selectedBillingSheetFile, currentPageNum);
    let docpath = `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`;
    let pagenum = currentPageNum;

    let full_file_path = `\\\\192.168.1.17\\d$\\eClinicalWorks\\ftp\\${docpath}`;
    full_file_path = full_file_path.replace('/', '\\');

    let curPatId = selectedEcwPtDocsPatient.PatientId;

    full_file_path = `${apiURL}/facesheet/${curPatId}/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}/${pagenum}/1`;

    console.log(full_file_path);

    fetch(
        encodeURI(full_file_path), {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },}
    )
        // .then(this.handleErrors)
        .then((r) => r.json())
        .then((jsonresp) => {

          console.log("Received from create facesheet:", jsonresp);
          // let url = URL.createObjectURL(blob);
          //
          // setpdfToDisplayURL(url);
          // setstartingPageNum(1);
          // pdfViewerRef.current.UI.loadDocument(url, {
          //   extension: 'pdf'
          // });

          // pdfViewerRef.current.UI.loadDocument(url, {
          //   extension: 'pdf'
          // });

        });


    // console.log("Will save new row:", event);
    //
    // let authHeader = {};
    //
    // const authH = JSON.parse(localStorage.getItem('authHeader'));
    // if (authH) {
    //   authHeader =  authH;
    // }
    //
    //
    // let urlPrefix = apiURL + '/exsql?dbserver=';
    // let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_doculink
    // @arg_op = 'E',
    // @patient_id = ${selectedEcwPtDocsPatient.PatientId},
    // @linkcategory = 'patientinfo',
    // @docpagenum = ${currentPageNum},
    // @document_path = '${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}',
    // @ecwdoc_id = NULL,
    // @apexdoc_id  = NULL,
    // @arg_entity_linkto = 'patient' ,
    // @arg_entity_id  = ${selectedEcwPtDocsPatient.PatientId} ,
    // @comment = '' ,
    // @delflag  = NULL
    //
    //    `;
    //
    // console.log("SQL to execute : ", dataURL)
    //
    // const res2 = await axios.get(urlPrefix + dataURL, authHeader);
    // if (res2.data) {
    //   console.log("GOT result from add temp Billing patient:", res2.data);
    //   let dframe = res2.data['frame0'];
    //   //console.log(dframe);
    //   // let myObj = JSON.parse(dframe);
    //
    //   // let gridData = myObj['rows'];
    //   // console.log(myObj, gridData);
    //   // rowdata = {...rowdata , tpat_id : gridData[0].tpat_id};
    //   // console.log("NewRowData after insert:", rowdata);
    //   // //setRowData([...rowData, rowdata]);
    //
    //
    // console.log("Will refresh table for the document", `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);
    // refreshDocumentLinksTable(`${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);



  }


  const handleRemoveLink = async () => {

    const selectedNodes = docuRefGrid.current.api
        .getSelectedNodes()
        .filter((node) => node.selected);

    if (!selectedNodes) return;

    if (selectedNodes) {

      console.log('AG Claim Row selected, link to this CPT ', selectedNodes);

      const datarow = selectedNodes[0].data;
      console.log('AG Claim Row selected, DELINK DOCUMENT PAGE FROM  this CPT / PATEINT ', datarow);

      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found. User must log in.");
        return;
      }

      let authHeader =  {
        "Authorization": `Bearer ${token}`,  // ✅ Attach token
        "Content-Type": "application/json"
      }

      // link_id
      // linkcategory
      // attach_id

      let urlPrefix = apiURL + '/exsql?dbserver=';
      let dataURL = `apex&sqltype=customSQL&sqltext=delete apex.rc.billings_links_attach where attach_id = ${datarow.attach_id} `;

      console.log("SQL to execute : ", dataURL)

      const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
      if (res2.data) {
        console.log("GOT result from add temp Billing patient:", res2.data);
        let dframe = res2.data['frame0'];

        console.log("Will refresh table for the document", `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);

        refreshDocumentLinksTable(`${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`);

      }

    }
  }

  let recentclaimidsArr = recentClaims.map((item: any) => item.InvoiceId)
      .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
      );
  let arrIndex = recentclaimidsArr.map((row, indx) => indx);

  const docureflinkRowSelected = (event) => {

    //let rowselected = setSelectedItem(docuColumnDefs.current.api.getSelectedRows()[0]);

    const selectedNodes = docuRefGrid.current.api
        .getSelectedNodes()
        .filter((node) => node.selected);

    if (!selectedNodes || selectedNodes.length === 0) return false;

    console.log("Selected Nodes", selectedNodes);
    const selectedItem = selectedNodes[0].data;

    if (selectedItem) {
      console.log("Documreflinkrow selected", selectedItem);
      setcurrentClaimID(selectedItem.claim_id);
    }


    //

  }

  const handleQuickDoculinkFilter = (event) => {
    console.log("Quick Filter:",event.target, event.target.value, docuRefGrid.current);
    setQuickDoculinkFilter(event.target.value );
    docuRefGrid.current.api.setQuickFilter(event.target.value);
  };

  const onSetpdfToDisplayURL = (args) => {
    console.log("PDFURL changed:", args);
    setpdfToDisplayURL(args);
  }

  let filteredDocumentRefRows = viewThisPageLinks?  documentRefRows.filter(row=>row.docpagenum === currentPageNum) : documentRefRows;

  console.log("OPEN indexes:", arrIndex);

  return (


      <SplitPanel
          id="first"
          split="horizontal"
          hidePanel1={false}
          hidePanel2={false}
          showExpandButton={false}
          disablePanel2Collapse={true}
          initialSize={30}
      >

        <Flex flexDirection={'column}'}>
          <HStack>
            <SelectBillingSheetFile selectedBillingSheetFile={selectedBillingSheetFile}
                                    setSelectedBillingSheetFile={onSelectedBillingSheetFile}/>

            <PatientManagementComponent/>
          </HStack>

          <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum}
                           setCurrentPageNum={onSetCurrentPageNum}/>

        </Flex>

        <Flex flexDirection={'column}'} ml={'100px'}>
          <Flex flexDirection={'row'}>

            {selectedBillingSheetFile ? (
                <Button
                    onClick={() => refreshDocumentLinksTable(`${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}`)}>Refresh</Button>) : ''}

            <ManageBillingTempClaimCpts isEdit={false}
                                        currentDocumentPath={selectedBillingSheetFile ? `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}` : ''}
                                        currentPageNum={currentPageNum}/>
            <ManageBillingTempClaimCpts isEdit={true} claimID={currentClaimID}
                                        currentDocumentPath={selectedBillingSheetFile ? `${selectedBillingSheetFile.dirpath}/${selectedBillingSheetFile.filename}` : ''}
                                        currentPageNum={currentPageNum}/>


          </Flex>
          {/* <Flex flexDirection={'row'}>
      <FormLabel >Service Date</FormLabel>

      <SingleDatepicker
        name="date-input"
        date={dosDate}
        onDateChange={onSetdosDate}
      />

      <RangeDatepicker
        selectedDates={selectedDates}
        onDateChange={setSelectedDates}
      />

    </Flex> */}

          <HStack>
            <SelectEcwPatient selectedEcwPatient={selectedEcwPtDocsPatient}
                              setSelectedEcwPatient={onSelectedEcwPtDocsPatient}/>
            {/* <Button onClick={refreshRecent100Clains}>Recent100</Button>
    <Button onClick={refreshPatientClaims}>PatientClaims</Button> */}
            <Button onClick={handleAddDemogLink} ml={'1px'} mr={'50px'}>DemogLink</Button>
            <Button onClick={handleAddEcwDemogDocument} ml={'1px'} mr={'50px'}>ecw FS</Button>
            <Text>{filteredDocumentRefRows.length}</Text>
            <Checkbox

                isChecked={viewThisPageLinks}
                onChange={onViewThisPageLinksChange}
                colorScheme={viewThisPageLinks ? 'green' : 'gray'}
            >
              Page
            </Checkbox>
            {/* <SelectCptToDocuLink  selectedBillingSheetFile={selectedBillingSheetFile} currentPageNum={currPageRef.current}   /> */}
            <Button onClick={handleRemoveLink}>DeLink</Button>
            <Input as='input' value={quickDoculinkFilter} onChange={handleQuickDoculinkFilter} ref={initFocusRef}
                   placeholder="filter..." variant="filled" fontSize='sm' width={'250px'} aria-multiline/>
          </HStack>


          <div className="ag-theme-alpine" style={{height: '80vh',}}>
            <AgGridReact
                ref={docuRefGrid}
                rowData={filteredDocumentRefRows}
                columnDefs={docuColumnDefs}
                rowSelection='single'
                animateRows={true}
                onSelectionChanged={docureflinkRowSelected}
            ></AgGridReact>
          </div>

          {/* <Flex flexDirection={'column'} >
      {recentClaims && recentClaims.map((item: any) => item.InvoiceId)
        .filter(
          (value, index, current_value) => current_value.indexOf(value) === index
        ).map(invid => {
          let invrow: any = recentClaims.filter((row: any) => row.InvoiceId === invid)[0];

          return (
              <SuperClaimComponent isEdit={false} key={invrow.InvoiceId} invdata={invrow} invcpt={recentClaims.filter((row: any) => row.InvoiceId === invid)} />
          )
        }
        )
      }
    </Flex> */}

        </Flex>
      </SplitPanel>

  );

};

export default Billing;

function  BtnCellRenderer (props) {
  //let cellformattedValue = ` ${props.data.price}`;

  //console.log("Button Text btncellrenderer", props);
  let btnText = props.buttonText;

  const btnClickedHandler = () => {
    props.clicked(props.data, props.buttonText);
  }
  return (
      <>
        <div><button onClick={btnClickedHandler}>{btnText}</button> </div>

      </>
  )
}