import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
    Typography,
    TextField,
    Card,
    CardContent,
    IconButton,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import apiConfig from "../../apiConfig";
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface ERAClaimsRowData {
    filepath?: string;
    filename835: string;
    id?: number;
    FileId?: number;
    PaymentId?: number;
    PayorName?: string;
    ProviderName?: string;
    PostedBy?: number;
    PostedDate?: string;
    ST02?: string;
    TransactionType?: string;
    TransactionAmount?: number;
    transactionmethod?: string;
    TransactionDate?: string;
    TransactionNumber?: string;
    DeleteFlag?: number;
    DeletedBy?: number;
    DeletedDate?: string;
    DeletedReason?: string;
    PayorId?: number;
    ProviderId?: number;
    StOrderId?: number;
    GsOrderId?: number;
    IsaOrderId?: number;
    PostingMessage?: string;
    PayeeName?: string;
    TaxId?: string;
    NPI?: string;
    practiceid?: number;
    readytopost?: number;
    ActionBy?: number;
    erafielid: number;
    eraimportedby: number;
    eraimportedat: string;
    RawFileName: string;
    eraxml?: string;
    isa13: string;
    isa06: string;
    trn02: string;
    trn03: string;
}

interface ERAWithoutPayorRowData {
    erafileid: number;
    payorname?: string;
    transactionamount?: number;
    transactionmethod?: string;
    payorid: string;
}

const ERAReviewPage: React.FC = () => {
    const [eraClaims, setEraClaims] = useState<ERAClaimsRowData[]>([]);
    const [eraWithoutPayor, setEraWithoutPayor] = useState<ERAWithoutPayorRowData[]>([]);
    const [payorIdInput, setPayorIdInput] = useState<{ [key: number]: string }>({});
    const [updateStatus, setUpdateStatus] = useState<{ [key: number]: 'success' | 'error' }>({});
    const [errorMessage, setErrorMessage] = useState<{ [key: number]: string }>({});

    const gridErasRef = useRef<any>(null);

    const fetchERAClaims = async () => {
        const sqlText = `SELECT top 100 * FROM rc.ecw_eraxml ORDER BY fileid DESC`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj = JSON.parse(dframe);
            const gridData = myObj.rows;
            setEraClaims(gridData);
        }
    };

    const fetchERAWithoutPayor = async () => {
        const sqlText = `SELECT distinct * FROM rc.billing_eras_wout_payorid order by erafileid desc`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj = JSON.parse(dframe);
            const gridData = myObj.rows;
            setEraWithoutPayor(gridData);
            console.log("Received ERs without payer id:", gridData);
            // setPayorIdInput(gridData.reduce((acc: any, row: ERAWithoutPayorRowData) => {
            //     acc[row.erafileid] = row.payorid;
            //     return acc;
            // }, {}));
        }
    };

    const handlePayorIdChange = (fileid: number, value: string) => {
        setPayorIdInput({ ...payorIdInput, [fileid]: value });
    };

    const handleUpdatePayorId = async (fileid: number) => {
        const payorid = payorIdInput[fileid];
        try {
            await axios.get(`${apiConfig.REACT_APEX_BASE_API}/update_era_payorid?fileid=${fileid}&payorid=${payorid}`);
            setUpdateStatus({ ...updateStatus, [fileid]: 'success' });
            setErrorMessage({ ...errorMessage, [fileid]: '' });
            fetchERAWithoutPayor();
        } catch (error) {
            setUpdateStatus({ ...updateStatus, [fileid]: 'error' });
            //setErrorMessage({ ...errorMessage, [fileid]: error.message });
        }
    };

    useEffect(() => {
        fetchERAClaims();
        fetchERAWithoutPayor();
    }, []);

    const eraClaimsColumnDefs = [
        { headerName: 'File ID', field: 'FileId', width: 100 },
        { headerName: 'Payment ID', field: 'PaymentId', width: 100 },
        { headerName: 'Payor Name', field: 'PayorName', width: 150 },
        { headerName: 'Payor ID', field: 'PayorId', width: 100 },
        { headerName: 'Provider Name', field: 'ProviderName', width: 150 },
        { headerName: 'Posted By', field: 'PostedBy', width: 100 },
        { headerName: 'Posted Date', field: 'PostedDate', width: 150 },
        { headerName: 'ST02', field: 'ST02', width: 100 },
        { headerName: 'Transaction Type', field: 'TransactionType', width: 150 },
        { headerName: 'Transaction Amount', field: 'TransactionAmount', width: 150 },
        { headerName: 'Transaction Method', field: 'transactionmethod', width: 150 },
        { headerName: 'Transaction Date', field: 'TransactionDate', width: 150 },
        { headerName: 'Transaction Number', field: 'TransactionNumber', width: 150 },
        { headerName: 'Provider ID', field: 'ProviderId', width: 100 },
        { headerName: 'Posting Message', field: 'PostingMessage', width: 200 },
        { headerName: 'Payee Name', field: 'PayeeName', width: 150 },
        { headerName: 'Tax ID', field: 'TaxId', width: 100 },
        { headerName: 'NPI', field: 'NPI', width: 100 },
        { headerName: 'File Path', field: 'filepath', width: 200 },
        { headerName: 'File Name', field: 'filename835', width: 200 },
        { headerName: 'Practice ID', field: 'practiceid', width: 100 },
        { headerName: 'Ready to Post', field: 'readytopost', width: 100 },
        { headerName: 'ERA File ID', field: 'erafielid', width: 100 },
        { headerName: 'ERA Imported By', field: 'eraimportedby', width: 150 },
        { headerName: 'ERA Imported At', field: 'eraimportedat', width: 150 },
        { headerName: 'Raw File Name', field: 'RawFileName', width: 200 },
        { headerName: 'ISA13', field: 'isa13', width: 100 },
        { headerName: 'ISA06', field: 'isa06', width: 100 },
        { headerName: 'TRN02', field: 'trn02', width: 150 },
        { headerName: 'TRN03', field: 'trn03', width: 150 },
    ];

    const getPaymentERALinkforCheck = (params: any) => {
        const selectedNode = gridErasRef.current.api.getSelectedNodes()[0];
        console.log("Selected Node for Link",selectedNode.data );
        if (selectedNode.data.TransactionNumber) {
            return (`https://emr.apexheartcare.com/mobiledoc/jsp/edi/x12utils/ViewEraFile_Encode.jsp?TrUserId=9121&CheckNo=${selectedNode.data.TransactionNumber}&DetailId=${selectedNode.data.id}&PaymentId=${selectedNode.data.paymentid ? selectedNode.data.paymentid : 0}`);
        }
    }

    const handleViewCheckERA = (params: any) => {
        console.log("Trying to view the check ER:", params);
        let url = getPaymentERALinkforCheck(params);
        console.log("Goto URL:", url);
        url && window.open(url, "_ecwPaymentViewTab");
    }

    const handleERASelectionChange = (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        console.log("Selected ERA row: ", selectedNode ? selectedNode.data : '');
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">ERA Claims</Typography>
                <Button onClick={fetchERAWithoutPayor}>
                    <RefreshIcon />
                </Button>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} style={{ overflowY: 'scroll', height: '250px' }}>
                {eraWithoutPayor.slice(0, 2).map((era) => (
                    <Card key={era.erafileid} style={{ margin: '1px', minWidth: '250px' }}>
                        <CardContent>
                            <Typography variant="h6">ERA File ID: {era.erafileid}</Typography>
                            <Typography variant="subtitle1">Payor Name: {era.payorname}</Typography>
                            <Typography variant="subtitle1">Transaction Amount: {era.transactionamount}</Typography>
                            <Typography variant="subtitle1">Transaction Method: {era.transactionmethod}</Typography>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <TextField
                                    label="Payor ID"
                                    value={payorIdInput[era.erafileid] || ''}
                                    onChange={(e) => handlePayorIdChange(era.erafileid, e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                <Button onClick={() => handleUpdatePayorId(era.erafileid)} disabled={!payorIdInput[era.erafileid]}>
                                    Update Payor ID
                                </Button>
                                <Button onClick={() => handleViewCheckERA(era.erafileid)}>View</Button>
                                {updateStatus[era.erafileid] === 'success' && <CheckCircleIcon style={{ color: 'green' }} />}
                                {updateStatus[era.erafileid] === 'error' && <ErrorIcon style={{ color: 'red' }} />}
                            </Box>
                            {updateStatus[era.erafileid] === 'error' && (
                                <Typography variant="body2" color="error">
                                    {errorMessage[era.erafileid]}
                                </Typography>
                            )}
                        </CardContent>
                    </Card>

                ))}
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">ERA Claims Grid</Typography>
                <Button onClick={fetchERAClaims}>
                    <RefreshIcon />
                </Button>
            </Box>
            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    ref={gridErasRef}
                    rowData={eraClaims}
                    columnDefs={[
                        { headerName: 'File ID', field: 'FileId', width: 100 },
                        {
                            headerName: 'Num',
                            width: 70,
                            cellRenderer: (params: any) => (
                                <>
                                    <Button onClick={() => handleViewCheckERA(params.data)} style={{backgroundColor:'gray'}}>View</Button>
                                </>
                            ),
                        },
                        { headerName: 'Payment ID', field: 'PaymentId', checkboxSelection: true, width: 100 },
                        { headerName: 'Payor Name', field: 'PayorName', width: 150 },
                        { headerName: 'Payor ID', field: 'PayorId', width: 100 },
                        { headerName: 'Provider Name', field: 'ProviderName', width: 150 },
                        { headerName: 'Posted By', field: 'PostedBy', width: 100 },
                        { headerName: 'Posted Date', field: 'PostedDate', width: 150 },
                        { headerName: 'ST02', field: 'ST02', width: 100 },
                        { headerName: 'Transaction Type', field: 'TransactionType', width: 150 },
                        { headerName: 'Transaction Amount', field: 'TransactionAmount', width: 150 },
                        { headerName: 'Transaction Method', field: 'transactionmethod', width: 150 },
                        { headerName: 'Transaction Date', field: 'TransactionDate', width: 150 },
                        { headerName: 'Transaction Number', field: 'TransactionNumber', width: 150 },
                        { headerName: 'Provider ID', field: 'ProviderId', width: 100 },
                        { headerName: 'Posting Message', field: 'PostingMessage', width: 200 },
                        { headerName: 'Payee Name', field: 'PayeeName', width: 150 },
                        { headerName: 'Tax ID', field: 'TaxId', width: 100 },
                        { headerName: 'NPI', field: 'NPI', width: 100 },
                        { headerName: 'File Path', field: 'filepath', width: 200 },
                        { headerName: 'File Name', field: 'filename835', width: 200 },
                        { headerName: 'Practice ID', field: 'practiceid', width: 100 },
                        { headerName: 'Ready to Post', field: 'readytopost', width: 100 },
                        { headerName: 'ERA File ID', field: 'erafielid', width: 100 },
                        { headerName: 'ERA Imported By', field: 'eraimportedby', width: 150 },
                        { headerName: 'ERA Imported At', field: 'eraimportedat', width: 150 },
                        { headerName: 'Raw File Name', field: 'RawFileName', width: 200 },
                        { headerName: 'ISA13', field: 'isa13', width: 100 },
                        { headerName: 'ISA06', field: 'isa06', width: 100 },
                        { headerName: 'TRN02', field: 'trn02', width: 150 },
                        { headerName: 'TRN03', field: 'trn03', width: 150 },
                    ]}
                    defaultColDef={{ sortable: true, filter: true, resizable: true }}
                    rowSelection="single"
                    onSelectionChanged={handleERASelectionChange}
                />
            </div>
        </Box>
    );
};

export default ERAReviewPage;
