import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut, signInWithCustomToken } from "firebase/auth";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
    firebaseUser: any | null;
    authToken: string | null;
    loginWithToken: (customToken: string) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [firebaseUser, setFirebaseUser] = useState<any | null>(null);
    const [authToken, setAuthToken] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        return onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdToken();
                setFirebaseUser(user);
                setAuthToken(token);
            } else {
                setFirebaseUser(null);
                setAuthToken(null);
            }
        });
    }, []);

    const loginWithToken = async (customToken: string) => {
        try {
            const auth = getAuth();
            const userCredential = await signInWithCustomToken(auth, customToken);
            const token = await userCredential.user.getIdToken();
            setFirebaseUser(userCredential.user);
            setAuthToken(token);
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    const logout = async () => {
        const auth = getAuth();
        await signOut(auth);
        setFirebaseUser(null);
        setAuthToken(null);
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ firebaseUser, authToken, loginWithToken, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuthContext must be used within an AuthProvider");
    }
    return context;
};
