import React from "react";
import useState from 'react-usestateref';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Button } from "@chakra-ui/react";
import FullScreenModal from "../Components/FullScreenModel";
import { HStack , VStack, Flex, Input, Text, Checkbox, Box, Select} from "@chakra-ui/react";
import ApexPdfjsViewer from "../Components/ApexPdfjsViewer";
import { useDocument } from '../Contexts/DocumentContext';
import apiConfig from "../apiConfig";
import { handleEcwDocument, handleFolderDocument, handleAgDocument } from '../utils/ecw/hadleDocuments';
import CheckboxRenderer from "../Components/CheckboxRenderer";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import axios from "axios";
import { format } from 'date-fns'
import ManageBillingTempClaimCpts from "../Features/billing/ManageBillingTempClaimCpts";
import { current } from "@reduxjs/toolkit";



function PatientFacesheets() {


  let apiURL = apiConfig.REACT_APEX_BASE_API;
  const claimsCPTGridRef = React.useRef();
  const claimsICDGridRef = React.useRef();
  const apexICDGridRef = React.useRef();
  const claimsCPTICDGridRef = React.useRef();
  
  const initFocusRef = React.useRef();
  const { documentContext, updateDocinfo, updateDocPageinfo } = useDocument();

  const [patientsData, setPatientsData] = useState();
  const [patientDocumentsData, setPatientDocumentsData] = useState();


  const [docToDisplay, setDocToDisplay] = useState();
  const[claimDocumentLinks, setClaimDocumentLinks] = useState();
  const [startingPageNum, setstartingPageNum] = useState(1)
  const [pdfToDisplayURL, setpdfToDisplayURL] = useState('')
  const [currentPageNum, setCurrentPageNum] = useState(1);

  const [claimCptRowdata, setClaimCptRowdata] = useState();
  const [selectedRow, setSelectedRow] = useState(null);

  const [showPrevICDData, setShowPrevICDData] = useState(false);
  const [patientPrevRefData, setPatientPrevRefData ] = useState();
  const [icdRefData, seticdRefData] = useState();
  const [quickICDFilter, setQuickICDFilter] = useState('');
  const [claimIcdData, setClaimicdData] = useState([]);
  const [claimCPTIcdData, setClaimCPTicdData] = useState([]);

  const [selectedCPTRow, setSelectedCPTRow] = useState(null);
  const [newItem, setNewItem, newItemRef ] = useState({
    tcpt_id: '',
    tclaim_id: '',
    cptcode: '',
    cptdesc: '',
    modifiers: '',
    cpt_assigned_icds: '',
    units: 1,
    dxcodes: '',
    comment: '',
    sdos: null,
    edos: null,
  });

  const [claimStatus, setClaimStatus, claimStatusRef ] = useState({
    cptsChecked: false,
    modifiersChecked: false,
    icdsChecked: false,
    claimLocked: false,
    claimReadyforOOV: false,
    apex_encounter_id: null,
    apex_claim_status : null,
    claim_facility: null,
    claim_servicedt : null,
    claim_pos: null,
    claim_admit_date: null,
    claim_discharge_date: null,
  });


  const ShowDocument = (pagenum, docpath) =>{
    handleAgDocument({
      filename : docpath,
      dirpath: '',
      startpage:pagenum
  }, apiURL, onSetpdfToDisplayURL, setstartingPageNum);
    }

    const ShowPatient = (row) => {
        console.log("show patient", row);
    }

    const onSetpdfToDisplayURL = (args) => {
        console.log("PDFURL changed:", args);
        setpdfToDisplayURL(args);
      }

      const onSetNewItem = (row) => {
        console.log("SetNewItem Called", row);
        setNewItem(row);
      }
    

  const onSetCurrentPageNum = (pagenum) => {
    console.log("Currnet Page Numnber Changed", docToDisplay, documentContext, pagenum);
    setCurrentPageNum(pagenum);

    if (docToDisplay) {
    updateDocinfo({
      ecwdocid: null,
      apexdocid: null,
      documentpath : `${docToDisplay.dirpath}/${docToDisplay.filename}`,
      documentpage: currentPageNum,
      pagecount : 1
    });
  }else {
    updateDocPageinfo(currentPageNum);
  }
  }



const getPatientFacesheetData = async (patientid) => {
    const token = localStorage.getItem("token");
    if (!token) {
        console.error("No token found. User must log in.");
        return;
    }

    let authHeader =  {
        "Authorization": `Bearer ${token}`,  // ✅ Attach token
        "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from rc.billing_temp_patient_facesheets `;

    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT Patient Prev Ref Data:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];

      setPatientDocumentsData(gridData);
    }

};


const getTempPatientsWithFacesheets = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
        console.error("No token found. User must log in.");
        return;
    }

    let authHeader =  {
        "Authorization": `Bearer ${token}`,  // ✅ Attach token
        "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from rc.billing_temp_patient_facesheets`;

    console.log("Executing SQL:", dataURL);
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT Claims Status Retrieved:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      
      setPatientsData(gridData);
    }

};




React.useEffect(()=> {
    getTempPatientsWithFacesheets();
}, []);



  const getPatientDocumentLinks = async (patientid)  =>{

      const token = localStorage.getItem("token");
      if (!token) {
          console.error("No token found. User must log in.");
          return;
      }

      let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
          "Content-Type": "application/json"
      }
   
//and linkcategory = 'patientinfo'
    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext= select distinct patient_id, patient_name, lnk.docpagenum, linkcategory, lnk.document_path, attach_comment
      from rc.billings_links_attach atch, rc.billing_document_links lnk      where lnk.link_id = atch.link_id and  atch.entity_type = 'patient' and   atch.entity_id =  ${patientid} order by linkcategory
 `;


    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add document refs lookup:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      if (dframe) {
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      if (gridData) {
        setClaimDocumentLinks(gridData);
      }

      console.log("Claim Document Links Grid", gridData);

      if (gridData && gridData[0]) {
            ShowDocument(gridData[0].docpagenum, gridData[0].document_path);
      }

    }
    }


  }

 const  refreshPatientsList = () => {
    getTempPatientsWithFacesheets();
 }

  let showEditView = ((!claimsCPTGridRef.current) || ( claimsCPTGridRef.current && claimsCPTGridRef.current.api
    .getSelectedNodes()
    .filter((node) => node.selected).length != 1 )) ? false:true;

const gotoNextPatient = () => {
    console.log("Goto next patient");
}

const onPatientSelectionChange = (event) => {
    console.log("Selected Patient", event?.target?.value);
    //getPatientFacesheetData(event?.target?.value);
    getPatientDocumentLinks(event?.target?.value);
    //console.log("Selected Patient", event?.target?.label);

}

  return (
    <Box style={{ widht:'97vw',  marginTop: '40px'} }>

             <div  >
             <HStack align={'flex-end'}>
             <Select placeholder='Select patient' onChange={onPatientSelectionChange} 
              
            >
             {patientsData && patientsData.map((row, indx)=>{
              return(<option key={row.tpat_id} value={row.tpat_id}>{row.lastname}, {row.firstname} {row.dob.substring(0,10)} {row.comment}</option>)
             })}
             </Select>
             <Button onClick={refreshPatientsList}>Refresh</Button>
           
             </HStack>
        <HStack align={'flex-end'}>
             {patientDocumentsData && patientDocumentsData.map((row, indx)=>{
              return(<Button onClick={()=>ShowDocument(row.docpagenum, row.document_path)}>docuPage{row.docpagenum}</Button>)
             })}
             </HStack>
    
             <ApexPdfjsViewer pdfUrl={pdfToDisplayURL} startingPageNum={startingPageNum} setCurrentPageNum={onSetCurrentPageNum}/>
             </div>

      </Box>
  );
}



export default PatientFacesheets;

