import React, { useState, useContext , useEffect} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Box,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  HStack,
  Text,
  Flex
} from '@chakra-ui/react';
import { subDays, addDays, startOfDay, format } from 'date-fns';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import apiConfig from '../apiConfig';
import axios from "axios";
import { useDocument } from '../Contexts/DocumentContext';
import { usePatient} from "../Contexts/PatientContext";
import ViewDocumentModal from './ViewDocumentModal';
import { handleEcwDocument, handleFolderDocument, handleAgDocument } from '../utils/ecw/hadleDocuments';
import ApexPdfjsViewer from '../Components/ApexPdfjsViewer';
import { SelectEcwPatient } from './SelectApexPatient';
import { Selectfacility } from './SelectApexFacility';
import { SelectPOS } from './SelectPOS';

import { Selectresource } from './SelectApexResource';
import { SelectTOS } from './SelectTOS';
import CPTdropDownRenderer from './CellRendererCPT';
import { SelectEcwInsurance } from './SelectApexInurance';
import { SelectApexStaff } from './SelectApexStaff';
import CustomEditorComponent from './CptCellEditor';
import ColourCellRenderer  from './colorCellRenderer';
import AlertStatusDropdown from './AnotherColumnDropdown';
import { SelectEcwCPT } from './SelectApexCPT';
import { SelectEcwBillingClaim } from './SelectBillingClaim';


const CreateBillingTempClaim = ({  isNewClaimModeOn, selectedEcwBillingClaim, setSelectedEcwBillingClaim, currentClaimID, curDocumentPath, curDocumentPage }) => {

  let apiURL=apiConfig.REACT_APEX_BASE_API;

  const documentContext = useDocument();
  const { addPatientRow , getPatientRow } = usePatient();
  //console.log("DOCUMENT CONTEXT", documentContext);


  const [rowData, setRowData] = useState([]);
  let [editing, setEditing] = useState(false);
  let [editingRow, setEditingRow] = useState('');
  const [selectedClaimPatient, setSelectedClaimPatient] = useState(); 
  const [selectedClaimFacility, setSelectedClaimFacility] = useState(); 
  const [selectedClaimResource, setSelectedClaimResource] = useState(); 
  const [selectedClaimPOS, setSelectedClaimPOS] = useState(); 
  const [selectedClaimTOS, setSelectedClaimTOS] = useState(); 
  const [selectedEcwCPT, setselectedEcwCPT] = useState(); 
  const [claimDxCodes, setClaimDxCodes] = useState(); 
  const [claimComments, setClaimComments]= useState();
  const [serviceDate, setServiceDate] = useState();
  const [claimDate, setClaimDate] = useState();
  const [admitDate, setadmitDate] = useState();
  const [dischargeDate, setdischargeDate] = useState();
  const [claimRefPhys, setclaimRefPhys] = useState();
  const [claimServiceLoc, setclaimServiceLoc] = useState();

  const [ firstcptModifier, setFirstCptModifier ] = useState();
  const [ currentSelectedClaimRow, setCurrentSelectedClaimRow] = useState({});


  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [showInsertForm, setShowInsertForm] = useState(false);
  const [newRowData, setNewRowData] = useState({
    tpat_id: '',
    patientid: '',
    firstname: '',
    lastname: '',
    dob: '',
    comment: '',
  });

  const [pdfToDisplayURL, setpdfToDisplayURL] = useState();
  const [startingPageNum, setStartingPageNum] = useState();
  const [currentPageNum, setCurrentPageNum] = useState();



  const onViewDocument = (event) => {
    console.log("View Temp Patient Document", event);
    let data = {
        dirpath : '',
        filename: event.document_path,
        startpage: event.docpagenum,
    }
    handleAgDocument(data, apiURL, setpdfToDisplayURL, setStartingPageNum);
  }

  const onLinkDemogDocument = async (event) => {
    console.log("Link Demog page to Temp Patient Document", event);
    let data = {
        dirpath : '',
        filename: event.document_path,
        startpage: event.docpagenum,
    }
    console.log("Will save new row:", event);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    console.log("DOCUMENT CONTEXT", documentContext);
    let docpagenum = documentContext.docinfo.documentpage;
    let docpath = documentContext.docinfo.documentpath;
    console.log("DOC CONTXT", docpagenum, docpath);

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_doculink
    @arg_op = 'E', 
    @patient_id = ${event.tpat_id},
    @linkcategory = 'patientinfo',
    @docpagenum = ${curDocumentPage}, 
    @document_path = '${curDocumentPath}',
    @ecwdoc_id = NULL,
    @apexdoc_id  = NULL,
	@arg_entity_linkto = 'patient' ,
	@arg_entity_id  = ${event.tpat_id} ,
    @comment = '${event.comment}' ,
    @delflag  = NULL

       `;

    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add temp Billing patient:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      // let myObj = JSON.parse(dframe);
      
      // let gridData = myObj['rows'];
      // console.log(myObj, gridData);
      // rowdata = {...rowdata , tpat_id : gridData[0].tpat_id};
      // console.log("NewRowData after insert:", rowdata);
      // //setRowData([...rowData, rowdata]);
    }

  }



  const columnDefs = [
    { headerName: 'ID', field: 'tclaim_id' , width: 120},
    { headerName: 'PatID', field: 'patient_id', width: 80 },
    { headerName: 'servicedt', field: 'servicedt', editable: true, width: 140},
    { headerName: 'facility', field: 'facility', editable: true , width: 140},
    { headerName: 'pos', field: 'pos', editable: true,  width: 140  },

    {
        field: '', cellRenderer: BtnCellRenderer, editable: false, filter: false,
        cellRendererParams: {
          clicked: onViewDocument,
          buttonText: 'View'
        }, width: 60,
      },
      {
        field: '', cellRenderer: BtnCellRenderer, editable: false, filter: false,
        cellRendererParams: {
          clicked: onLinkDemogDocument ,
          buttonText: 'DemogLink'
        }, width: 60,
      },
      { headerName: 'Comment', field: 'comment', editable: true, width: 240 },

    
  ];

  // useEffect(()=> {

  //   const getData = async () => {

  //       let authHeader = {};

  //       const authH = JSON.parse(localStorage.getItem('authHeader'));
  //       if (authH) {
  //         authHeader =  authH;
  //       }

  //       let urlPrefix = apiURL + '/exsql?dbserver=';
  //       let dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.billing_tclaim ";

  //       const res2 = await axios.get(urlPrefix + dataURL, authHeader);
  //       if (res2.data) {
  //         console.log("GOT Temp Billing Claims:", res2.data);
  //         let dframe = res2.data['frame0'];
  //         //console.log(dframe);
  //         let myObj = JSON.parse(dframe);
  //         console.log(myObj);
  //         let gridData = myObj['rows'].map(row=>  {
  //           //console.log(row['dob'], row['dob'].substring(0,10));
  //          // console.log(format(row['dob'].substring(0,10), 'yyyy-MM-dd'));
  //           //row['dob'] = format(row['dob'].substring(0,10), 'yyyy-MM-dd');
  //            return row;
  //          });

  //          console.log("Temp Claims", gridData);

  //         setRowData(gridData);
  //       }


  //   };
  
  //   getData(); 
  
  //   return () => {
  //     // this now gets called when the component unmounts
  //   };


  // }, []);

  const saveBillingTpat = async (rowdata)  =>{

    console.log("Will save new row:", rowdata);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    console.log("DOCUMENT CONTEXT", documentContext);
    let docpagenum = documentContext.docinfo.documentpage;
    let docpath = documentContext.docinfo.documentpath;
    console.log("DOC CONTXT", docpagenum, docpath);

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tpatient   
     @arg_op = 'E',    
     @firstname = '${rowdata.firstname}' ,     
     @lastname = '${rowdata.lastname}' ,
    @dob  = '${rowdata.dob}' ,    
    @docpagenum = ${docpagenum},
    @document_path = '${docpath}',    @ecwdoc_id  = NULL,    @apexdoc_id  = NULL,    @comment = '${rowdata.comment}' 
       `;

    console.log("SQL to execute : ", dataURL)
    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT result from add temp Billing patient:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      rowdata = {...rowdata , tpat_id : gridData[0].tpat_id};
      console.log("NewRowData after insert:", rowdata);
      setRowData([...rowData, rowdata]);
    }


  }

  const handleAddRow = () => {
    saveBillingTpat(newRowData);
    setShowInsertForm(false);
    setNewRowData({
      tpat_id: '',
      patientid: '',
      firstname: '',
      lastname: '',
      dob: '',
      comment: '',
      refphys: '',
      serviceloc : ''
    });
  };

  const handleUpdateRow = () => {
    gridApi.applyTransaction({ update: [newRowData] });
  };

  const handleDeleteRow = async () => {
console.log("DELETE CLAIM", currentClaimID);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }
let urlPrefix = apiURL + '/exsql?dbserver=';
let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tclaim
    @arg_op = 'D' ,
    @tclaim_id  = ${currentClaimID}

   `;


console.log("SQL to execute to DELETE : ", dataURL)

const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });

if (res2.data) {
  console.log("GOT result from delete temp Billing claim:", res2.data);
 
  }
};


  const onUseSelectedPatient = () => {
    const selectedRowNodes = gridApi.getSelectedNodes();
    if (selectedRowNodes[0]) {
    console.log("USE Patient:", selectedRowNodes[0].data);
    addPatientRow(selectedRowNodes[0].data);
    }
  }

  const onSetCurrentPageNum = (pagenum) => {
    console.log("Viewing now page: ", pagenum);
    setCurrentPageNum(pagenum);
}


const onSelectedClaimPatient = (data) => {
    console.log("select ptDoc patient in documanager", data);
    setSelectedClaimPatient(data);
  }

  const onSelectedFacility = (data) => {
    console.log("select facility documanager", data);
    setSelectedClaimFacility(data);
  }

  const onSelectedResource = (data) => {
    console.log("select resource documanager", data);
    setSelectedClaimResource(data);
  }

  const onSelectedPOS = (data) => {
    console.log("select POS documanager", data);
    setSelectedClaimPOS(data);
  }

  const onSelectedTOS = (data) => {
    console.log("select TOS documanager", data);
    setSelectedClaimTOS(data);
  }

  const onsetSelectedEcwCPT = (data) => {
    console.log("select CPT documanager", data);
    setselectedEcwCPT(data);
  }

  

  const onCPTChange = (cptCode) => {
    console.log("CPT Change", cptCode)
  }

  const onClaimDxCodesChange = (event) => {
    setClaimDxCodes(event.target.value);
  }


  const onClaimCommentsChange= (event) => {
    setClaimComments(event.target.value);
  }

  const onclaimRefPhysChange= (event) => {
    setclaimRefPhys(event.target.value);
  }

  const onclaimServiceLocChange= (event) => {
    setclaimServiceLoc(event.target.value);
  }

  const onClaimModifierChange= (event) => {
    console.log("Modifier changed", event.target.value, event);
    setFirstCptModifier(event.target.value);
  }


 

  
  const saveBillingTClaim = async (rowdata)  =>{

    console.log("Will save new row:", rowdata);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tclaim
        @arg_op = 'E' ,
        @tclaim_id  = ${rowdata.tclaim_id},
        @patient_id = ${rowdata.patient.PatientId},
        @facility = '${rowdata.facility}' ,
        @pos = ${rowdata.pos} ,
        @servicedt = '${rowdata.servicedt}' ,
        @admitdt = '${rowdata.admitdt}' ,
        @dischargedt = '${rowdata.dischargedt}' ,
        @dxcodes = '${rowdata.dxcodes ? rowdata.dxcodes : ''}' ,
        @comment = '${rowdata.comment ? rowdata.comment : ''} ',
        @refphys = '${rowdata.refphys ? rowdata.refphys : ''} ',
        @serviceloc = '${rowdata.serviceloc ? rowdata.serviceloc : ''} '
        

       `;


    console.log("SQL to execute : ", dataURL)

    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });

    let claim_id_to_use = -1;

    if (res2.data) {
      console.log("GOT result from add temp Billing claim:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      rowdata = {...rowdata , tclaim_id : gridData[0].tclaim_id};
      claim_id_to_use = gridData[0].tclaim_id;
      console.log("NewRowData after insert:", rowdata);

      if (selectedEcwCPT ) {
        //Create First CPT for this new claim
        console.log("CPT INFO",selectedEcwCPT, firstcptModifier);

            
          console.log("DOCUMENT CONTEXT", documentContext);
          let docpagenum = documentContext.docinfo.documentpage;
          let docpath = documentContext.docinfo.documentpath;
          console.log("DOC CONTXT", docpagenum, docpath);

        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found. User must log in.");
          return;
        }

        let authHeader =  {
          "Authorization": `Bearer ${token}`,  // ✅ Attach token
          "Content-Type": "application/json"
        }
      
          let urlPrefix = apiURL + '/exsql?dbserver=';
      

          let dataURL = `apex&sqltype=customSQL&sqltext=exec apex.rc.billsp_manage_tcpt
              @arg_op = 'E'  ,
              @tcpt_id  = null ,
              @tclaim_id  = ${claim_id_to_use},
              @cptcode = '${selectedEcwCPT.cptcode}',
              @cptdesc = '${selectedEcwCPT.cptdesc}',
              @modifiers = '${firstcptModifier ? firstcptModifier : ''}',
              @dxcodes= '${claimDxCodes ? claimDxCodes : ''}',
              @units = 1 ,
              @sdos = '${format(serviceDate,'yyyy-MM-dd')}',
              @docpagenum  = ${curDocumentPage}, 
              @document_path = '${curDocumentPath}',
              @ecwdoc_id  = NULL,
              @apexdoc_id  = NULL,
              @comment = '${claimComments ? claimComments : ''}'
      
             `;
      
      
          console.log("SQL to execute : ", dataURL)
          const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
          if (res2.data) {
            console.log("GOT result from add temp Billing claim:", res2.data);
            // let dframe = res2.data['frame0'];
            // //console.log(dframe);
            // let myObj = JSON.parse(dframe);
            // console.log(myObj);
            // let gridData = myObj['rows'];
            // rowdata = {...rowdata , tcpt_id : gridData[0].tcpt_id};
            // console.log("NewRowData after insert:", rowdata);
            //getBillingClaimCPTs(claim_id_to_use);
          }
      
      }

      // Save newly created Claim to Claim Context and let the ManageBillingTempClaimCpts use this Claim

      
      //setSelectedEcwBillingClaim

      //setCurrentClaimData(rowdata);

      getGivenClaimData(`${claim_id_to_use}`)
    }


  }


  const saveClaim = (facility, pos, resource, tclaim_id ) => {
    console.log("Save claim to temp billing", facility, selectedClaimPatient, pos, selectedClaimTOS, resource, claimDxCodes, serviceDate, claimDate );
    let rowdata = {
      tclaim_id : tclaim_id,
      facility : facility,
      resource : resource,
      patient : selectedClaimPatient,
      pos: pos,
      tos: selectedClaimTOS ? selectedClaimTOS.value : '',
      dxcodes : claimDxCodes,
      servicedt: serviceDate ? format(serviceDate,'yyyy-MM-dd') : '',
      claimdt  : claimDate ? format(claimDate,'yyyy-MM-dd') : '',
      admitdt: admitDate ? format(admitDate,'yyyy-MM-dd') : '',
      dischargedt  : dischargeDate ? format(dischargeDate,'yyyy-MM-dd') : '',
      comment : claimComments ? claimComments.replace('undefined','') : '',
      refphys : claimRefPhys,
      serviceloc :  claimServiceLoc
    }

    saveBillingTClaim(rowdata);
  }


  const getGivenClaimData = async (claimid) => {

    if (!claimid) return;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Content-Type": "application/json"
    }

    let urlPrefix = apiURL + '/exsql?dbserver=';
    let dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select top 4000 * from apex.rc.billing_all_claims where tclaim_id = ${claimid} order by servicedt desc `;

    const res2 = await axios.get(urlPrefix + dataURL, { headers: authHeader });
    if (res2.data) {
      console.log("GOT Billing Files:", res2.data);
      let dframe = res2.data['frame0'];
      //console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];

      console.log("EDITING EXISTING CLAIM:", gridData[0]);
      setCurrentSelectedClaimRow(gridData[0]);


      ///////////////

     
      //setSelectedClaimResource(gridData[0].resource);
    
      //setSelectedClaimTOS(gridData[0].tos);
      //setServiceDate(gridData[0].servicedt);
      if (gridData[0]) {

        console.log("Seeting patient to :", gridData[0]);
        //setSelectedClaimPatient(getPatientRow(gridData[0].patient_id));
        onSelectedClaimPatient(getPatientRow(gridData[0].patient_id))
        setSelectedClaimFacility(gridData[0].facility);
        setSelectedClaimPOS(Number(gridData[0].pos) > 0 ?  Number(gridData[0].pos): setCurrentSelectedClaimRow.pos);
//

        //console.log("service Date", gridData[0].servicedt, gridData[0].servicedt.substring(0,10), (new Date(gridData[0].servicedt.substring(0,10) + "T20:00:00.000+10:00")));
        setServiceDate(gridData[0].servicedt ? (new Date(gridData[0].servicedt.substring(0,10) + "T20:00:00.000+10:00")): undefined)
        setClaimDxCodes(gridData[0].dxcodes);
        setClaimComments(gridData[0].comment)
        setclaimRefPhys(gridData[0].ref_physician)
        setclaimServiceLoc(gridData[0].service_location)

        console.log('Admit Date', gridData[0].admit_date, gridData[0].discharge_date);
        setadmitDate(gridData[0].admit_date ? (new Date(gridData[0].admit_date.substring(0,10) + "T20:00:00.000+10:00")): undefined)
        setdischargeDate(gridData[0].discharge_date ? (new Date(gridData[0].discharge_date.substring(0,10) + "T20:00:00.000+10:00")): undefined)

      }

      // const [selectedClaimPatient, setSelectedClaimPatient] = useState(); 
      // const [selectedClaimFacility, setSelectedClaimFacility] = useState(); 
      // const [selectedClaimResource, setSelectedClaimResource] = useState(); 
      // const [selectedClaimPOS, setSelectedClaimPOS] = useState(); 
      // const [selectedClaimTOS, setSelectedClaimTOS] = useState(); 
      // const [selectedEcwCPT, setselectedEcwCPT] = useState(); 
      // const [claimDxCodes, setClaimDxCodes] = useState(); 
      // const [claimComments, setClaimComments]= useState();
      // const [serviceDate, setServiceDate] = useState(new Date());
      // const [claimDate, setClaimDate] = useState(new Date());



      /////////////////

      // setcurrentClaimData(gridData.map(row=>  {
      //   row['servicedt'] = row['servicedt'].substring(0,10);
      //   return row;
      // })[0]);
    }


};

  useEffect(()=> {
    console.log("Will auto-select billing claim for EDITING based on given id:", !isNewClaimModeOn, currentClaimID, selectedEcwBillingClaim);
   if (!isNewClaimModeOn) {
    if (currentClaimID) {
      getGivenClaimData(currentClaimID);
    }else {
      if (selectedEcwBillingClaim){
        getGivenClaimData(selectedEcwBillingClaim.claim_id);
      }
    }
  }else {
    currentClaimID = null;
    selectedEcwBillingClaim = null;

  }

  }, [isNewClaimModeOn, currentClaimID, selectedEcwBillingClaim]);

  useEffect(()=> {
    if (isNewClaimModeOn) {
      setSelectedClaimPatient('');
      setSelectedClaimFacility(null);

      setSelectedClaimPOS(null);
      setSelectedClaimTOS('');

      setselectedEcwCPT('');
      setClaimDxCodes("");
      setClaimComments('');
      setServiceDate('');
      setClaimDate('');
      setadmitDate('');
      setdischargeDate('');
      setclaimRefPhys('');
      setclaimServiceLoc('');

    }

  }, [isNewClaimModeOn]);

  console.log("CPT Selected = ", selectedEcwCPT);
  return (
      <Box p={4}>
        {/* <Button onClick={() => setShowInsertForm(!showInsertForm)}>Add New Row</Button>
        <Button onClick={onUseSelectedPatient}>Use Selected Patient</Button>
        <Button
        variant={editing ? "outlined" : "contained"}
        color="blue"
        onClick={saveClaim}
        colorScheme="blue"
        disabled={editing}
      >
        SaveClaim
      </Button> */}
        { (

<Stack  colorScheme="black">

  <Text>{curDocumentPath}  {curDocumentPage}</Text>
    <HStack>
      <HStack>
        <Text>Patient</Text>
         <SelectEcwPatient selectedEcwPatient={selectedClaimPatient} setSelectedEcwPatient={onSelectedClaimPatient} />
      </HStack>
    </HStack>
      <HStack>
        <Box  width='200px' no-wrap >
          <HStack>
        <Text>DOS</Text>
        <SingleDatepicker
          name="date-input"
          date={serviceDate}
          onDateChange={setServiceDate}
      />
          </HStack>
        </Box>
        <HStack>
          <Text>CPT</Text>
      <SelectEcwCPT selectedEcwCPT={selectedEcwCPT} setSelectedEcwCPT={onsetSelectedEcwCPT}  />
        </HStack>
      </HStack>



      
         <Flex gap='1'>
      <Selectfacility selectedfacility={selectedClaimFacility}  setSelectedfacility={onSelectedFacility} />
      <SelectPOS selectedPOS={selectedClaimPOS} setSelectedPOS={onSelectedPOS} />


      {/*<Selectresource selectedResource={selectedClaimResource}  setSelectedresource={onSelectedResource} />*/}
      </Flex>

  <HStack>

    <Text >Diag</Text><Input placeholder='Diagnosis Codes and notes' value={claimDxCodes} onChange={onClaimDxCodesChange} />
    <Text >Mod</Text><Input placeholder='Modifiers' value={firstcptModifier} onChange={onClaimModifierChange} />
  </HStack>
  <HStack>
  <Text >RefPhys</Text>
  <Input
      placeholder='Ref Physician'
      value={claimRefPhys}
      onChange={onclaimRefPhysChange}
  />

  <Text >Loc</Text>
  <Input
      placeholder='CareLocation'
      value={claimServiceLoc}
      onChange={onclaimServiceLocChange}
  />

  </HStack>


      <HStack>

    

  
  </HStack>

<HStack>

{/*{ selectedClaimPOS && selectedClaimPOS.value == 21 ? (*/}
  <HStack>
  <Text>Admit Date</Text>
  <SingleDatepicker
    name="date-input"
    date={admitDate}
    onDateChange={setadmitDate}
  />
  <Text>Discharge Date</Text>
  
  <SingleDatepicker
    name="date-input"
    date={dischargeDate}
    onDateChange={setdischargeDate}
    minDate={subDays(new Date(), 720)}
    maxDate={addDays(new Date(), 365)}

    propsConfigs={{
      dateNavBtnProps: {
        colorScheme: 'blue',
        variant: 'outline',
      },
      dayOfMonthBtnProps: {
        defaultBtnProps: {
          borderColor: 'red.300',
          _hover: {
            background: 'blue.400',
          },
        },
        isInRangeBtnProps: {
          color: 'purple.800',
          borderColor: 'blue.300',
        },
        selectedBtnProps: {
          background: 'blue.200',
          borderColor: 'blue.300',
          color: 'blue.600',
        },
        todayBtnProps: {
          background: 'teal.200',
          color: 'teal.700',
        },
      },
      inputProps: {
        size: 'sm',
      },
    }}
   
    configs={{
      dateFormat: 'yyyy-MM-dd',
      firstDayOfWeek: 0, // default is 0, the dayNames[0], which is Sunday if you don't specify your own dayNames,
    }}
  />
  </HStack>

  {/*) : ''}*/}


</HStack>
  <HStack>
  <Text>Comments</Text><Input placeholder='claim comments' value={claimComments} onChange={onClaimCommentsChange} />
  </HStack>
  </Stack>
        )}

<HStack alignItems={'right'}>
      <Button colorScheme='green'  onClick={()=> saveClaim(
                               selectedClaimFacility && selectedClaimFacility.value ? selectedClaimFacility.value : currentSelectedClaimRow.facility, 
                               Number(selectedClaimPOS.value) > 0 ?  Number(selectedClaimPOS.value) : currentSelectedClaimRow.pos,
                               selectedClaimResource && selectedClaimResource.value ? selectedClaimResource.value : currentSelectedClaimRow.resource,
                               isNewClaimModeOn ? null : currentSelectedClaimRow.tclaim_id  )} >
        Save Claim
      </Button>
      <Button colorScheme='red' onClick={handleDeleteRow}>Delete Claim</Button> 

      {/* <Button colorScheme='gray'  >
        Clear Selection
      </Button> */}
      </HStack>

      {/* onClick={handleClear}  */}
        {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginTop: '1rem' }}>
          <AgGridReact
            onGridReady={(params) => {
              setGridApi(params.api);
              setGridColumnApi(params.columnApi);
            }}
            rowData={rowData}
            editType={'fullRow'}
            editable={true}
            columnDefs={columnDefs}
            rowSelection="single"
          />
        </div>
        <Button onClick={handleUpdateRow}>Update Selected Row</Button>
        <Button onClick={handleDeleteRow}>Delete Selected Rows</Button> */}
      </Box>
  );
};

export default CreateBillingTempClaim;

function  BtnCellRenderer (props) {
    //let cellformattedValue = ` ${props.data.price}`;
  
    console.log("Button Text btncellrenderer", props);
    let editingCells = props.api.getEditingCells();
    // checks if the rowIndex matches in at least one of the editing cells
    let isCurrentRowEditing = editingCells.some((cell) => {
      return cell.rowIndex === props.node.rowIndex;
    });
  
    let btnText = props.buttonText;
    if (btnText === "Edit") {
      if (isCurrentRowEditing) {
        btnText = "Save";
      }else {
        btnText = "Edit";
      }
  
    } 
  
    if (btnText === "Delete") {
      if (isCurrentRowEditing) {
        btnText = "Cancel";
      }else {
        btnText = "Delete";
      }
  
    } 
  
    const btnClickedHandler = () => {
        props.clicked(props.data, props.buttonText);
    }
      return (
        <>
        <div><button onClick={btnClickedHandler}>{btnText}</button> </div>
        
        </>
      )
  }
  
