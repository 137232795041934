import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios, {AxiosResponse} from 'axios';
import apiConfig from '../../apiConfig';
import EditMerchantTransaction from './EditMerchantTransaction'; // Import the new component
import pLimit from 'p-limit';

interface RowData {
    [key: string]: any;
    row_id?: string;
    status?: string;
}

interface ResultRowData {
    [key: string]: any;
}

interface ParsedData {
    rows: RowData[];
}

const LoadMerchantTransactions: React.FC = () => {
    const [rowData, setRowData] = useState<RowData[]>([]);
    const [gridApi, setGridApi] = useState<any>(null);
    const [filterText, setFilterText] = useState<string>('');
    const [editRowData, setEditRowData] = useState<RowData | null>(null);
    const [isEditOpen, setEditOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [importsourceref, setImportsourceref] = useState<string>('');
    const apiURL = apiConfig.REACT_APEX_BASE_API;
    const limit = pLimit(2);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const allData: RowData[] = [];
            for (const file of Array.from(files)) {
                await new Promise(async (resolve) => {
                    if (file.type === 'text/csv') {
                        Papa.parse(file, {
                            complete: async (results: { data: any[]; }) => {
                                const parsedData = parseCsvData(results.data);
                                const updatedData = await executeStoredProcedure(parsedData);
                                if (updatedData) {
                                    allData.push(...updatedData);
                                }
                                resolve(true);
                            }
                        });
                    } else {
                        const data = await file.arrayBuffer();
                        const workbook = XLSX.read(data);
                        const sheetName = workbook.SheetNames[0];
                        const worksheet: RowData[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        const updatedData = await executeStoredProcedure(worksheet);
                        if (updatedData) {
                            allData.push(...updatedData);
                        }
                        resolve(true);
                    }
                });
            }
            setRowData(allData);
        }
    };

    const parseCsvData = (data: any[]) => {
        let csvFileReference = '';
        if (data.length > 0) {
            console.log("CSV File first row:", data[0], data[0][0]);
            csvFileReference = data[0].join('~');
        }

        return data.slice(1).reduce((acc, row) => {
            const amount = parseFloat(row[1]);
            if (isNaN(amount)) {
                return acc; // Skip rows where the amount is not a valid number
            }

            const description = `${row[2]} ${row[3]}`;
            const debitCredit = row[5];
            const vendorDesc = debitCredit && debitCredit.toLowerCase() === 'debit' ? row[3] : '';
            const payerDesc = debitCredit && debitCredit.toLowerCase() === 'credit' ? row[3] : '';

            acc.push({
                trandate: row[0],
                tranamount: amount,
                description: description ? description : '',
                debit_credit: debitCredit,
                importnotes: row[4],
                vendor_desc: vendorDesc,
                payer_desc: payerDesc,
                importsourceref: csvFileReference,
            });

            return acc;
        }, [] as RowData[]);
    };



    const getScalarFromResultSet = (response: AxiosResponse<any,any>) => {
        if (response.data) {
            const dframe: string = response.data.frame0;
            const myObj: ParsedData = dframe && JSON.parse(dframe);
            const gridData: ResultRowData[] = myObj && myObj.rows;
            return (gridData && gridData.length > 0 && gridData[0]);
        }
    }

    const executeStoredProcedure = async (data: RowData[]) => {
        const updatedData = await Promise.all(
            data.map(row => limit(async () => {
                try {
                    const token = localStorage.getItem("token");
                    let authHeader =  {
                        "Authorization": `Bearer ${token}`,  // ✅ Attach token
                        "Content-Type": "application/json"
                    }
                    const urlPrefix = `${apiURL}/exsql?dbserver=`;
                    const sqlText = generateSqlText(row, 'edit');
                    //console.log('SQL To execute:', sqlText);
                    const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

                    if (response.data) {
                        let responseRow = getScalarFromResultSet(response);
                        row.row_id = responseRow && responseRow.row_id;
                        row.status = '✔️'; // Green check mark
                    } else {
                        row.status = '❌'; // Error mark
                    }
                } catch (error) {
                    console.error('Error adding transaction:', error);
                    row.status = '❌'; // Error mark
                }
                return row;
            }))
        );
        return updatedData;
    };



    const generateSqlText = (row: RowData, op: string) => {
        const getField = (fieldName: string, defaultValue: string = '') => row[fieldName] || defaultValue;

        return `apex&sqltype=customSQL&sqltext=exec rc.mnt_merchanttransaction
        @TransactionID = '${getField("Transaction Id", getField("Transaction ID"))}',
        @TransactionType = '${getField("Transaction Type")}',
        @status = '${getField("Transaction State")}',
        @CardNumber = '${getField("Card #")}',
        @Amount = ${parseFloat(getField("Amount", '0').replace('$', '').replace(',', ''))},
        @SubmittedTime = '${getField("Date & Time", getField("Submitted Time"))}',
        @InvoiceNumber = '${getField("Customer Name", '')} ${getField("Invoice Number")}',
        @PONumber = '${getField("PO Number")}',
        @ClerkID = '${getField("User Id", getField("Clerk ID"))}',
        @PaymentType = '${getField("Payment Type")}',
        @CardBrand = '${getField("Card Brand Name", getField("Card Brand"))}',
        @TerminalID = '${getField("Terminal Id", getField("Terminal ID"))}',
        @TerminalName = '${getField("Terminal Name")}',
        @arg_op = '${op}',
        @sourceaccount = 'openedge';`;
    };

    const loadTransactions = async () => {
        const token = localStorage.getItem("token");
        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiURL}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;
        const sqlText = 'set rowcount 0 select * from apex.rc.MerchantTransactions';
        //console.log('SQL To execute:', sqlText);
        const response = await axios.get(`${urlPrefix}${sqlText} `, { headers: authHeader });

        if (response.data) {
            //console.log('GOT transaction:', response.data);

            const dframe: string = response.data.frame0;
            //console.log('Next Frame:', dframe);

            const myObj: ParsedData = JSON.parse(dframe);
            //console.log(myObj);

            const gridData: ResultRowData[] = myObj.rows;
            //console.log('Got merchant rows:', gridData);

            setRowData(gridData);
        }
    };

    const onGridReady = (params: any) => {
        setGridApi(params.api);
    };

    const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
        if (gridApi) {
            gridApi.setQuickFilter(event.target.value);
        }
    };

    const handleEdit = (row: RowData) => {
        setEditRowData(row);
        setEditOpen(true);
    };

    const handleSave = (updatedRow: RowData) => {
        setRowData((prevData) =>
            prevData.map((row) => (row.row_id === updatedRow.row_id ? updatedRow : row))
        );
    };

    const handleDelete = (rowId: string) => {
        setRowData((prevData) => prevData.filter((row) => row.row_id !== rowId));
    };

    const handleOpenEdgeLink = () => {
        window.open("https://openedgeview.paygateway.com/", "openEdgeTab");
    }

    return (
        <div>
            <input type="file" accept=".csv, .xlsx, .xls" onChange={handleFileUpload} multiple/>
            <TextField
                label="Filter"
                variant="outlined"
                value={filterText}
                onChange={handleFilterTextChange}
                style={{marginLeft: '10px'}}
            />
            <Button onClick={loadTransactions} style={{marginLeft: '10px'}}>
                Load Transactions
            </Button>
            <Button onClick={handleOpenEdgeLink} style={{marginLeft: '10px'}}>
                OpenEdge Link
            </Button>
            <div className="ag-theme-alpine" style={{height: 1000, width: '100%'}}>
                <AgGridReact
                    onGridReady={onGridReady}
                    rowData={rowData}
                    defaultColDef={{floatingFilter: true}}
                    columnDefs={[
                        {
                            headerName: 'Actions',
                            field: 'actions',
                            width: 75,
                            cellRenderer: (params: any) => (
                                <Button onClick={() => handleEdit(params.data)}>Edit</Button>
                            ),
                        },
                        {headerName: 'Row ID', field: 'row_id', resizable: true, sortable: true, filter: true},
                        {headerName: 'Status', field: 'status', resizable: true, sortable: true, filter: true},
                        ...Object.keys(rowData[0] || {}).map((key) => ({
                            headerName: key,
                            field: key,
                            resizable: true,
                            sortable: true,
                            filter: true,
                            width: key.length * 7 + 30,
                        }))
                    ]}
                />
            </div>
            {editRowData && (
                <EditMerchantTransaction
                    open={isEditOpen}
                    anchorEl={anchorEl}
                    onClose={() => setEditOpen(false)}
                    rowData={editRowData}
                    onSave={handleSave}
                    onDelete={handleDelete}
                />
            )}
        </div>
    );
};

export default LoadMerchantTransactions;
