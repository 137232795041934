import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getLocalData, saveLocalData } from "../../utils/localStorage";
import axios from "axios";
import { setToast } from "../../utils/Authenticate";
import apiConfig from "../../apiConfig";
import { auth } from "../../firebaseConfig"; // Firebase Auth instance
import { signInWithCustomToken } from "firebase/auth";


const initialState = {
  isAuth: getLocalData("token") ? true : false,
  token: getLocalData("token") || "",
  profileData: [],
  isLoading: false,
  isError: false,
};

let authToken = getLocalData("token") ;
const Headers = {
  'Content-Type': `application/json`,
  "Access-Control-Allow-Origin": "*",
  'Authorization': `Bearer ${authToken ? authToken : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c"}`,
};

const HeadersWithoutToken = {
  'Content-Type': `application/json`,
  "Access-Control-Allow-Origin": "*",
};

let apiURL=apiConfig.REACT_APP_AUTHENTICATION;

// axios.defaults.headers.common.PlaidEnvironment = `${payload.plaidenv}`;

export const register = createAsyncThunk ('auth/register', async (payload) => {
  return axios
    .post(`${apiURL}/auth/signup`, payload)
    .then((r) => {
      return r.data ;
    })
    .catch((e) => {
      setToast(payload.toast, e.response.data.msg, "error");
    });
});

export const login = createAsyncThunk ('auth/login', async (payload, { dispatch, rejectWithValue } ) => {

  try {
    const response = await axios.post(`${apiURL}/auth/login`, payload, { headers: HeadersWithoutToken });

    console.log("Login Success:", response.data);

    if (!response.data || !response.data.customToken) {
      return rejectWithValue("Invalid login response from server.");
    }

    // 🔹 Step 2: Sign in to Firebase with the received custom token
    console.log("Logging into Firebase with custom token...");
    const userCredential = await signInWithCustomToken(auth, response.data.customToken);

    // 🔹 Step 3: Retrieve Firebase ID token
    const idToken = await userCredential.user.getIdToken();
    console.log("Firebase ID Token received:", idToken);

    // 🔹 Step 4: Save Firebase ID token instead of custom token
    saveLocalData("token", idToken);
    saveLocalData("authHeader", JSON.stringify({ Authorization: `Bearer ${idToken}` }) );
    //localStorage.setItem("authHeader", JSON.stringify({ Authorization: `Bearer ${idToken}` }));


    // 🔹 Step 5: Dispatch Redux action to update state
    const userData = {
      firebaseToken: idToken,
      email: response.data.unique_name,
      role: response.data.role
    };

    dispatch({ type: "LOGIN_SUCCESS", payload: userData });

    return userData;

  } catch (error) {
    console.log("Login Failed:", error);
    setToast(payload.toast, error.response?.data?.msg || "Login failed", "error");
    throw error;
  }
  //
  //   //console.log("Call to thunk auth/login:", payload, Headers);
  // return axios.post
  // (`${apiURL}/auth/login`, payload, { headers: HeadersWithoutToken} )
  // .then((r) => {
  //   console.log("Returing success frm LOGIN", r);
  //   return r.data ;
  // })
  // .catch((e) => {
  //   console.log("Returing failure frm LOGIN", e);
  //   if (e.includes("Authori")) {
  //     //return { 'token': 'it is ok'};
  //   }
  //   setToast(payload.toast, e.response.data.msg, "error");
  // });
});

export const profile = createAsyncThunk ('auth/profile', async (payload) => {
  const options = {
    method: "GET",
    url: `${apiURL}/${payload.email}`,
    headers: { Authorization: `Bearer ${payload.token}` },
  };
  return axios(options)
    .then((r) => {
      console.log("RECEIVED from Profile Call: ", r);
      return  r.data;
    })
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    REGISTER_REQUEST(state, action) {
      return { ...state, isLoading: true };
    },
    REGISTER_SUCCESS(state, action) {
      return { ...state, isLoading: false, RegisterUser: action.payload };
    },
    REGISTER_FAILURE(state, action) {
      return { ...state, isLoading: false, isError: true };
    },
    LOGIN_REQUEST(state, action) {
      return { ...state, isLoading: true };
    },
    LOGIN_SUCCESS(state, action) {
      console.log("Storing token:", action.payload.firebaseToken);

      // Store token in localStorage
      saveLocalData("token", action.payload.firebaseToken);

      const authHeader = { Authorization: `Bearer ${action.payload.firebaseToken}` };
      saveLocalData("authHeader", authHeader);

      console.log("Updated Redux State - isAuth:", true);  // ✅ Debug log

      return { ...state, isLoading: false, isAuth: true, token: action.payload.firebaseToken };
    },

    LOGIN_FAILURE(state, action) {
      return {
        ...state,
        isLoading: false,
        isError: true,
        isAuth: false,
        token: "",
      };
    },
    PROFILE_SUCCESS(state, action) {
      console.log("PROFILE_DATA_RECEIEVED", action.payload);
      return {
        ...state,
        profileData: action.payload,
      };
    },   
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, (state, action) => {
      })
      .addCase(register.fulfilled, (state, action) => {
      })
      .addCase(login.pending, (state, action) => {
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("LOGIN FULFILLED", action);
        saveLocalData("token", action.payload.token);
      })
      .addCase(profile.pending, (state, action) => {
      })
      .addCase(profile.fulfilled, (state, action) => {
      })
  }
})

export const { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE, LOGIN_REQUEST,  LOGIN_SUCCESS, LOGIN_FAILURE, PROFILE_SUCCESS} = authSlice.actions

export default authSlice.reducer