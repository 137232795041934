import React from "react";
import { Container, Box } from "@chakra-ui/react";
import "./App.css";
import MainRoutes from "./Pages/MainRoutes";
import { DocumentProvider } from "./Contexts/DocumentContext";
import { PatientProvider } from "./Contexts/PatientContext";
import { ClaimProvider } from "./Contexts/ClaimContext";


function App() {
    return (

                <Container width="100%" maxWidth="100%" minWidth="6xl" bg={"#e4e7e4"} fontFamily="cursive">
                    <Box overflow="hidden">
                        <PatientProvider>
                            <DocumentProvider>
                                <ClaimProvider>
                                    <MainRoutes />
                                </ClaimProvider>
                            </DocumentProvider>
                        </PatientProvider>
                    </Box>
                </Container>

    );
}

export default App;
