import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { blueTheme } from "./Components/css/appthememanager";
//import { UserProvider } from './Contexts/UserContext';
import { AuthProvider } from "./Contexts/AuthProvider"; // Import AuthProvider
import { UserProvider } from "./Contexts/UserContext"; // Import UserProvider
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
    shadows: [
        'none',
        '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
        '0px 3px 6px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14), 0px 1px 12px rgba(0, 0, 0, 0.12)',
        '0px 4px 8px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14), 0px 2px 16px rgba(0, 0, 0, 0.12)',
        // Add all shadows up to the desired elevation level
    ],
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
});

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <AuthProvider>
                <UserProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <ChakraProvider>
                        <App />
                    </ChakraProvider>
                </ThemeProvider>
                </UserProvider>
            </AuthProvider>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
