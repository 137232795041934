import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { PlaidLink } from 'react-plaid-link';
import { connect, useSelector, useDispatch } from 'react-redux';
// import RGL, { WidthProvider } from 'react-grid-layout';


// import ImageListItem from '@mui/material/ImageListItem';
//  import GridListTileBar from '@material-ui/core/GridListTileBar';
import axios from 'axios';
//import ApexDataGrid from '../../components/datagrid/ApexDataGrid';
import  apiConfig, { useLocalStorage }  from '../../apiConfig'
import { DATA_CURRENT_STATUS, loadGridData } from './accountsData';
import { useAppSelector, useAppDispatch } from '../../Redux/hooks';

import { Select } from "chakra-react-select";



// import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
  Grid,
  Card,
  Text,
  Box,
  Flex,
  Spacer ,
  Button,
  Modal,
  MenuItem,
  InputLabel,
  FormControl,
  HStack
} from '@chakra-ui/react';


import { AgGridReact } from 'ag-grid-react';


import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import SpredsheetView from './spreadsheet';


import {
  setPlaidEnvironment,
  getTransactions,
  getCombinedTransactions,
  refreshAccount,
  addAccount
} from './perfinSlice';

// import MaterialTable from "material-table"; // https://mbrn.github.io/material-table/#/
// import { DataGrid } from '@material-ui/data-grid';

// import MUIDataTable from "mui-datatables";
import AccountCard from './AccountCard';
// import DataGrid from '../DataGridNew'

//const ReactGridLayout = WidthProvider(RGL);



// axios.interceptors.request.use(function (config) {

//   const authH = JSON.parse(localStorage.getItem('authHeader'));
//   if (authH) {
//     const token = authH.headers["Authorization"];
//     console.log("Setting axios header", token);
//     config.headers.Authorization = token;
//   }

//   return config;
// });

// const style = {
//   position: 'relative' ,
//   top: '100px',
//   left: '100px',
//   width: '80%',
//   height: '80%',
//   overflow: 'scroll',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const SpacingGrid = (props) => {
  return (
    <Box sx={{
      width: '100%', display: 'flex', padding: '60px 10px 20px 60px',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden'
    }}>
      <Grid container wrap="nowrap" spacing={8} sx={{ overflow: 'auto' }}>
        {props.children}

      </Grid>
    </Box>
  );
}


export default function Accounts() {
  // constructor (props) {
  //   super(props)
  //   this.tranGridElement = React.createRef()

  //   this.onLayoutChange = this.onLayoutChange.bind(this)
  // }

  const gridRef = React.useRef();
  console.log("In Accounts start:");
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    console.log("Click open spreadsheet");
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
    console.log(" close spreadsheet");
  };


  const dispatch = useDispatch();

  // const onLayoutChange = (layout) => {
  //   // this.props.onLayoutChange(layout);
  // };

  const handleEnvironmentChange = (event) => {
    console.log("SELECTED ENVIRONMENT: ", (event.target.value));
    dispatch(setPlaidEnvironment(event.target.value));
  };

  const [state, setState] = useState({
    dataCurrentStatus: { environemnt: '' },
    updateAccessToken: null,
    transactionsData: [],
    filepath: '',
    iframeRef: createRef(),
    tranGridElement: createRef(),
    //   filters : {},
    //   transactionsData : []
  });

  const [quickFilterText, setquickFilterText] = useState('');


  const nextPDFPage = () => { };

  const PlaidEnvironment = useAppSelector((state) => {
    console.log('Got PlaidEnvironment:', state.perfin.PlaidEnvironment);
    return state.perfin.PlaidEnvironment;
  });

  const linkToken = useAppSelector((state1) => {
    console.log('Got LinkToken:', state1.perfin.linkToken);
    return state1.perfin.linkToken.link_token;
  });

  const accounts = useAppSelector((state1) => {
    console.log('Got Accounts:', state1.perfin.accounts);
    return state1.perfin.accounts;
  });

  const accountsLoading = useAppSelector((state1) => {
    return state1.perfin.accountsLoading;
  });

  const transactions = useAppSelector((state1) => {
    console.log('Got Transactions:', state1.perfin.transactions);
    return state1.perfin.transactions;
  });

  const transactionsLoading = useAppSelector((state1) => {
    return state1.perfin.transactionsLoading;
  });

  // componentDidMount () {
  //   //const { accounts } = this.props;
  //   //this.props.getTransactions(accounts);

  //   const path = 'file.pdf'
  //   const frame_element = `/pdfjs/web/viewer.html?file=${path}`
  //   loadGridData(DATA_CURRENT_STATUS, {}, this.recvGridData);

  //   this.setState({ filepath: frame_element })
  // }

  const recvGridData = (gridName, args, gridData) => {
    console.log('ReceivedData for :', gridName, args, gridData);

    switch (gridName) {
      case DATA_CURRENT_STATUS:
        setState(
          {
            dataCurrentStatus: gridData,
            loaded: true,
          },
          () => {
            console.log(
              'Changed state Current Status',
              state.dataCurrentStatus.length
            );
          }
        );
        break;

      default:
    }
  };

  // Add account
  const onGetTransactionsClick = () => {
    // const { accounts } = this.props
    // this.props.getTransactions(accounts)
    //dispatch(getTransactions(PlaidEnvironment, accounts));
  };

  const handleOnLoad = (token, metadata) => { };
  // Add account
  const handleOnSuccess = (token, metadata) => {
    //const { accounts } = this.props
    const plaidData = {
      public_token: token,
      metadata: metadata,
      //accounts: accounts
    }
    console.log('Received Public Token: ' + token, metadata)
    dispatch(addAccount({ plaidenv: PlaidEnvironment, plaidData }));
    // this.props.addAccount(plaidData)
  };

  const handleOnUpdateTokenSuccess = (token, metadata) => {
    //const { accounts } = this.props
    const plaidData = {
      public_token: token,
      //updateAccessToken: this.state.updateAccessToken,
      metadata: metadata,
      //accounts: accounts
    }
    console.log('Received Public Token: ' + token, metadata)
    dispatch(refreshAccount({ plaidenv: PlaidEnvironment, plaidData }));
    // this.props.refreshAccount(plaidData)
  };

  const onUploadFromFileClick = (id) => {
    console.log("Will OPEN dialog to upload file for account: ", id);
    setSelectedValue(id);
    handleClickOpen();
    //   <div style={{ width: 1400 , minWidth: 1200, maxWidth: 1400 }}>
    // <Button variant="outlined" onClick={handleClickOpen} >
    //         Open simple dialog
    //       </Button>
    //       <SpredsheetView  style={{ width: 1400 , minWidth: 1200, maxWidth: 1400 }}
    //         selectedValue={selectedValue}
    //         open={open}
    //         onClose={handleClose}
    //         ItemID={'SELECTED-ITEM'}
    //       />
    //        </div>
  }


  const onShowTransactionsClick = (id) => {
    // this.setState({ transactionsData: [] })
    // this.props.getTransactions(id, 'view')
    console.log('READY DISPATCH get transactions', id);
    dispatch(getTransactions({ plaidenv: PlaidEnvironment, id: id, txnscope: 'view' }));
  };

  const onGetPlaidTransactionsClick = (id) => {
    console.log('Called Retrieve-100: ' + id)
    dispatch(getTransactions({ plaidenv: PlaidEnvironment, id: id, txnscope: 'refresh' }));
    //this.props.getTransactions(id, 'refresh')
  };

  const onGetAllPlaidTransactionsClick = (id) => {
    dispatch(getTransactions({ plaidenv: PlaidEnvironment, id: id, txnscope: 'gethx' }));
    // this.props.getTransactions(id, 'gethx')
  };

  const onUpdateAccountsClick = (id) => {
    dispatch(getTransactions({ plaidenv: PlaidEnvironment, id: id, txnscope: 'ua' }));
    // this.props.getTransactions(id, 'ua')
  };

  const handleQuickFilter = (event) => {
    setquickFilterText(event.target.value);
    gridRef.current.api.setQuickFilter(event.target.value);
  };

  const onUpdateTokenClick = (id) => {

    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    console.log(axios.defaults.headers.common.Authorization);
    axios.defaults.headers.common.PlaidEnvironment = `${PlaidEnvironment}`;

    axios
      .post(apiConfig.API_URL + '/get_update_link_token', { id: id })
      .then(res => {
        let linkToken_forupdate = res.data.link_token
        console.log("RECEIVED Update link token: ", linkToken_forupdate);
        //this.setState({ updateAccessToken: linkToken_forupdate })

        setState(
          { updateAccessToken: linkToken_forupdate }
        );

      })
      .catch(err => console.log(err))
  };


  // Delete account
  const onDeleteClick = (id) => {
    // const { accounts } = this.props
    // const accountData = {
    //   id: id,
    //   accounts: accounts
    // }
    // this.props.deleteAccount(accountData)
  };

  //    handleFilterChange = filter => filters => {
  //     const newFilters = { ...filters };
  //     if (filter.filterTerm) {
  //       newFilters[filter.column.key] = filter;
  //     } else {
  //       delete newFilters[filter.column.key];
  //     }
  //     this.setState({filters: newFilters})
  //     return newFilters;
  //   };

  //    getValidFilterValues = (rows, columnId)  => {
  //     return rows
  //       .map(r => r[columnId])
  //       .filter((item, i, a) => {
  //         return i === a.indexOf(item);
  //       });
  //   }

  //    getRows = (rows, filters) => {
  //     //console.log(filters);
  //     return Data.Selectors.getRows({ rows, filters });
  //   }

  //  // const filteredRows = getRows(this.state.transactionsData, this.state.filters);

  //  sortRows = (initialRows, sortColumn, sortDirection) => rows => {
  // const comparer = (a, b) => {
  //   if (sortDirection === "ASC") {
  //     return a[sortColumn] > b[sortColumn] ? 1 : -1;
  //   } else if (sortDirection === "DESC") {
  //     return a[sortColumn] < b[sortColumn] ? 1 : -1;
  //   }
  // };
  // this.setState ({ transactionsData : sortDirection === "NONE" ? initialRows : [...rows].sort(comparer) });
  // };

  // onRowSelectExam = data => {

  //   let check_num = data[0].row.name.replace("CHECK CLEARED #","");
  //   let check_path = `\\\\pcode-nas1\\skshare\\AcctDocs\\Banks\\Apex\\BBVA\\Checking5555\\CheckImages\\${check_num}check`;
  //   cli_showfile(check_path);

  //   // window.open(check_path)
  //   // console.log(check_path);
  //   // console.log(data);
  // };

  const onRowSelectExam = (event) => {
    console.log('AG Row selected', event);

    const selectedNodes = event.api
      .getSelectedNodes()
      .filter((node) => node.selected);
    console.log(selectedNodes);
  };

  const loadPersonalAccounts = () => {
    console.log("WILL LOAD COMBINED LIST OF PERSONAL TRANSACTIONS");
    dispatch(getCombinedTransactions({ plaidenv: PlaidEnvironment, txnscope: 'pesonal' }));
  }

  const loadBusinessAccounts = () => {
    console.log("WILL LOAD COMBINED LIST OF BUSINESS TRANSACTIONS");
    dispatch(getCombinedTransactions({ plaidenv: PlaidEnvironment, txnscope: 'business' }));
  }

  const refresh_all_accounts = () => {
    console.log("WILL GET ALL ACCOUNTS RECENT TRANSACTIONS");
    try {
      window.open(`${apiConfig.API_URL}/refresh_all_accounts`);
    } catch (ex) {

      console.log("Error:", ex);
    }
  }

  const onRowSelectView = (data) => {
    console.log('Transaction View:', data);
    console.log(`TO DIsplay${data.dirpath}/${data.fileName}`);

    const check_num = data.name.replace('CHECK CLEARED #', '');
    const check_path = `\\\\pcode-nas1\\skshare\\AcctDocs\\Banks\\Apex\\BBVA\\Checking5555\\CheckImages\\${check_num}check.pdf`;

    console.log('Starting to get Check File', check_path);

    let full_file_path = check_path.replace('/', '\\')

    console.log('Starting to get deposit ', full_file_path)

    full_file_path = `${apiConfig.API_URL}/downloadFile/${full_file_path.replaceAll("\\", "~").replaceAll("/", "~")}`;
    // file_name =
    //   '\\' +
    //   file_name
    //     .replace('/mnt/scanhome', '\\192.168.1.17\\scanhome')
    //     .replace('/', '\\');
    // console.log('Getting windows file: ', file_name);

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User must log in.");
      return;
    }

    let authHeader =  {
      "Authorization": `Bearer ${token}`,  // ✅ Attach token
      "Access-Control-Allow-Origin": "*"
    }


    fetch(
      encodeURI(full_file_path, { headers: authHeader })
    )
      //.then(this.handleErrors)
      .then((r) => r.blob())
      .then((blob) => {

        //this.showFilePdf(blob);
        let url = URL.createObjectURL(blob);
        let viewerUrl = encodeURIComponent(url);

        const frame_element = `/pdfjs/web/viewer.html?file=${viewerUrl} `;


        setState({ filepath: frame_element });
      });

    // this.handleLinqReportPdf(check_path);
  };

  // const handleLinqReportPdf = (filename) => {
  //   console.log('Starting to get Check File', filename);
  //   fetch(encodeURI(`${API_URL}/getecwfile?filename=${filename}`))
  //     .then(handleErrors)
  //     .then((r) => r.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const viewerUrl = encodeURIComponent(url);

  //       const frame_element = `../public/pdfjs/web/viewer.html?file=${viewerUrl} `;

  //       setState({ filepath: frame_element });
  //     });
  // };

  // const loadblobPDF = (blob) => {
  //   console.log('Ready to Loadin PDF from base64');

  //   try {
  //     const iframePdf = pdfControl.iframeRef.current.contentWindow;
  //     // console.log(iframePdf);
  //     // console.log(iframePdf.PDFViewerApplication);
  //     if (iframePdf !== undefined) {
  //       const iframePdf2 = iframePdf.contentWindow;
  //       // iframePdf.PDFViewerApplication.open();

  //       iframePdf.PDFViewerApplication.open(blob);
  //       // iframePdf.PDFViewerApplication.toolbar.openFile.click(); // iframePdf.PDFViewerApplication
  //       // iframePdf.print();
  //     }
  //   } catch (error) {}

  //   // ipcRenderer.send('show-file', 'ping')
  // };

  const loadblobPDFURL = (pdfURL) => {
    console.log('Ready to Loadin PDF from base64', pdfURL);

    const frame_element = `/pdfjs/web/viewer.html?file=${pdfURL} `;

    setState({ filepath: frame_element });

    try {
      // let iframePdf = pdfControl.iframeRef.current.contentWindow
      // //console.log(iframePdf);
      // //console.log(iframePdf.PDFViewerApplication);
      // if (iframePdf !== undefined) {
      //   let iframePdf2 = iframePdf.contentWindow
      //   //iframePdf.PDFViewerApplication.open();
      //   iframePdf.PDFViewerApplication.open(pdfURL)
      //   //iframePdf.PDFViewerApplication.toolbar.openFile.click(); // iframePdf.PDFViewerApplication
      //   //iframePdf.print();

      let url = {
        url: pdfURL,
        originalUrl: 'Temp File Name',
      };

      // const iframePdf = pdfControl.iframeRef.current.contentWindow;
      // // //console.log(iframePdf);
      // // //console.log(iframePdf.PDFViewerApplication);
      // if (iframePdf !== undefined) {
      //   const iframePdf2 = iframePdf.contentWindow;
      //   //   //iframePdf.PDFViewerApplication.open();

      //   // iframePdf2.PDFViewerApplication.open(pdfURL)

      //   //   //iframePdf.PDFViewerApplication.toolbar.openFile.click(); // iframePdf.PDFViewerApplication
      //   //   //iframePdf.print();

      //   // PDFViewerApplication.open(url);
      // }
    } catch (error) { }

    // ipcRenderer.send('show-file', 'ping')
  };

  // const { user, accounts, linkToken } = this.props
  // const { transactions, transactionsLoading } = this.props.plaid

  // {accountItems.map((tile) => (
  //           <GridListTile key={tile.account_id}>
  //             <img src={tile.img} alt={tile.institutionName} />
  // {tile}
  //           </GridListTile>
  //         ))
  // }

  // Setting up data table
  const transactionsColumns = [
    { headerName: 'Account', field: 'account', width: 200, type: 'string' },
    { headerName: 'Date', field: 'date', width: 110, type: 'date' },
    { headerName: 'Amount', field: 'amount', type: 'number', width: 120 },
    { headerName: 'Name', field: 'name', width: 250 },
    { headerName: 'Category', field: 'category', width: 150 },
  ];

  // const filteredRows = this.getRows(this.state.transactionsData, this.state.filters);

  // { field: 'firstName', headerName: 'First name', width: 130 },

  // let transactionsData = [];
  // transactions.forEach(function(account) {
  //   account.transactions.forEach(function(transaction) {
  //     transactionsData.push({
  //       account: account.accountName,
  //       date: transaction.date,
  //       category: transaction.category[0],
  //       name: transaction.name,
  //       amount: transaction.amount
  //     });
  //   });
  // });

  //   let rowdata = {
  //     account: row.account.accountName,
  //     date: row.transaction.date,
  //     category: row.transaction.category[0],
  //     'name': row.transaction.name,
  //     'amount': row.transaction.amount
  //   }
  //   return rowdata;
  // });

  // transactions.map( row => {
  //   let rowdata = {
  //     account: row.account.accountName,
  //     date: row.transaction.date,
  //     category: row.transaction.category[0],
  //     'name': row.transaction.name,
  //     'amount': row.transaction.amount
  //   }
  //   return rowdata;
  // });

  // transactions.forEach(function(account) {
  //   account.transactions.forEach(function(transaction) {
  //     transactionsData.push({
  //       account: account.accountName,
  //       date: transaction.date,
  //       category: transaction.category[0],
  //       name: transaction.name,
  //       amount: transaction.amount
  //     });
  //   });
  // });

  console.log("preparing transactions:", transactions);
  let transactionsData = [];
  if (transactions) {
    let trans = transactions.transactions;

    if (!trans && transactions) trans = transactions;
    if (trans && trans.length > 0) {

      transactionsData = trans.map((tran) => {

        console.log("TRAN:", tran);
        if (tran.id) {
          return {

            date: tran.date,
            category: tran.category,
            merchant: tran.merchant_name,
            amount: tran.amount,
            name: tran.transaction_name,
            payer: tran.payer,
            payee: tran.payee,

            id: tran.trnid,
            account: tran.account,
          };
        } else if (tran.trnid) {
          return {
            date: tran.date,
            category: tran.category,
            merchant: tran.merchant_name,
            amount: tran.amount,
            name: tran.transaction_name,
            payer: tran.payer,
            payee: tran.payee,
            id: tran.id,
            account: tran.account_name,
          };
        }
      });

      // this.setState({ transactionsData : transactionsData})
      console.log('Ready to display transactions:', transactionsData);
      // this.tranGridElement.current.changeGridData(transactionsData,rdg_columns );
      // if (state.transactionsData && state.transactionsData.length == 0) {
      //   setState({ ...state, transactionsData: transactionsData });
      // }

      // useEffect(() => {
      //  setState({ ...state, transactionsData: transactions });
      // }, [transactionsData]);
    }



    console.log("TRANSACITOND DATA after processing:", transactionsData, transactions);

  }

  console.log("preparing accountItems:", accounts);
  const accountItems = (
    <>

    <Flex spacing={2}  overflowY="hidden" ml="40px"  w="90vw" >
    {accounts.map((account) => (
      <>
        <AccountCard
          key={account.itemId}
          account={account}
          onView={onShowTransactionsClick}
          onUpdateAccounts={onUpdateAccountsClick}
          onRetrieve100={onGetPlaidTransactionsClick}
          onRetrieveAll={onGetAllPlaidTransactionsClick}
          onUpdateToken={onUpdateTokenClick}
          onUploadFile={onUploadFromFileClick}
        />
        <Spacer/>
        </>
    ))}
    </Flex>


    </>
  );

  console.log("In Accounts:", PlaidEnvironment);
  return (
     <>
    <FormControl size="small" >
        <Select
          id="demo-simple-select"
          value={PlaidEnvironment}
          label="Environment"
          onChange={handleEnvironmentChange}
        >
          <MenuItem value={'sandbox'}>Sandbox</MenuItem>
          <MenuItem value={'development'}>Development</MenuItem>
        </Select>
      </FormControl>

      {linkToken ? (

        <PlaidLink
          token={linkToken}
          onLoad={handleOnLoad}
          onSuccess={handleOnSuccess}
          onExit={function (err, metadata) {
            // The user exited the Link flow.
            if (err != null) {
              // The user encountered a Plaid API error prior to exiting.
              console.log(err);
              console.log('plaid link error');
            }

            console.log('plaid link exited');
            // metadata contains information about the institution
            // that the user selected and the most recent API request IDs.
            // Storing this information can be helpful for support.
          }}
          onEvent={function (eventName, metadata) {
            // Optionally capture Link flow events, streamed through
            // this callback as your users connect an Item to Plaid.
            // For example:
            // eventName = "TRANSITION_VIEW"
            // metadata  = {
            //   link_session_id: "123-abc",
            //   mfa_type:        "questions",
            //   timestamp:       "2017-09-14T14:42:19.350Z",
            //   view_name:       "MFA",
            // }
            console.log(`plaid link event: ${eventName}`);
          }}
        >
          Link Account
        </PlaidLink>
      ) : (
        ''
      )}

      {state.updateAccessToken ? (
        <PlaidLink
          token={state.updateAccessToken}
          onLoad={handleOnLoad}
          onSuccess={handleOnUpdateTokenSuccess}
          onExit={function (err, metadata) {
            // The user exited the Link flow.
            if (err != null) {
              // The user encountered a Plaid API error prior to exiting.
              console.log(err);
              console.log('plaid link error');
            }

            console.log('plaid link exited');
            // metadata contains information about the institution
            // that the user selected and the most recent API request IDs.
            // Storing this information can be helpful for support.
          }}
          onEvent={function (eventName, metadata) {
            // Optionally capture Link flow events, streamed through
            // this callback as your users connect an Item to Plaid.
            // For example:
            // eventName = "TRANSITION_VIEW"
            // metadata  = {
            //   link_session_id: "123-abc",
            //   mfa_type:        "questions",
            //   timestamp:       "2017-09-14T14:42:19.350Z",
            //   view_name:       "MFA",
            // }
            console.log(`plaid link event: ${eventName}`);
          }}
        >
          Update Account Token
        </PlaidLink>


      ) : (
        ''
      )}

      <HStack>
      <button onClick={loadPersonalAccounts} >Load Personal</button>
      <button onClick={loadBusinessAccounts} >Load Business</button>
      <button onClick={refresh_all_accounts} >Refresh Accounts</button>

      <input
        type="text"
        placeholder="Quick Filter"
        value={quickFilterText}
        onChange={handleQuickFilter}
      />
      <SpredsheetView 
          selectedValue={selectedValue}
          open={open}
          onParentClose={handleClose}
          ScopeCode={PlaidEnvironment}
          ItemID={selectedValue}
        />
        </HStack>
      {accountItems}

        <Box className="ag-theme-alpine maxWidth" style={{ paddingLeft: '-200px', width: '100%', height: '450px', overflow: 'hidden' }}>
          <AgGridReact
            ref={gridRef}
            rowData={transactionsData}
            rowHeight={20}
            columnDefs={[
              {field: 'date', width: 125}, 
              {field: 'amount', width: 100},
              {field: 'account'},
              {field: 'name', filter: true},
              {field: 'merchant', filter: true}, 
              {field: 'category'},
              {field: 'id'}
            ]}
            animateRows={true}
            resizable={true}
            rowSelection="single"
            rowDragManaged={true}
            suppressMoveWhenRowDragging={true}
            applyColumnDefOrder={true}
            onSelectionChanged={onRowSelectExam}
          />
        </Box>

        <div style={{ width: 1400, minWidth: 1200, maxWidth: 1400 }}>
        
      </div>

        <button id="myButton3" onClick={nextPDFPage}>
          Previous Page{' '}
        </button>
        <button id="myButton4" onClick={nextPDFPage}>
          Next Page{' '}
        </button>
        <iframe
          width="100%"
          height="600px"
          backgroundcolor="lightgrey"
          ref={state.iframeRef}
          src={state.filepath}
        />

      </>
  );

      }


