import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Box,
    FormLabel,
    Switch,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Collapse,
    AppBar,
    IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SplitPanel from "../../Components/splitpage/splitpanel";
import ApexPdfjsViewer from "../../Components/ApexPdfjsViewer";
import apiConfig from "../../apiConfig";
import axios from "axios";
import { handleAgDocument } from "../../utils/ecw/hadleDocuments";
import RefreshIcon from '@mui/icons-material/Refresh';
import { SelectEcwPatient} from "../../Modals/SelectApexPatient";

interface ClaimsRowData {
    claim_id: number,
    patientname?: string;
    facilityname?: string;
    billedto?: string;
    ClaimStatus?: string;
    claimwith?: string;
    EncounterId?: number;
    PatientId?: number;
    InvoiceDt?: string;
    ServiceDt?: string;
    InvoiceAmount?: number;
    inv_payment?: number;
    inv_copay?: number;
    uncoveredAmount?: number;
    inv_ptresp?: number;
    inv_ptpayment?: number;
    inv_ptbalance?: number;
    inv_balance?: number;
    inv_filestatus?: string;
    BilledToId?: number;
    SubmissionId?: number;
    BilledToIdType?: number;
    InvFacilityId?: number;
    inv_netpayment?: number;
    inv_allowedfee?: number;
    invpos?: number;
    assignedTo?: string;
    assignedToId?: number;
    PrimaryInsId?: number;
    SecondaryInsId?: number;
    invPriIns?: string;
    invSecIns?: string;
    billedfee?: number;
    Paid?: number;
    Deduct?: number;
    Coins?: number;
    Memresp?: number;
    Adjustment?: number;
    Adjustment2?: number;
    Withheld?: number;
}

interface TagsRowData {
    tagid?: number;
    tag?: string;
    docpath?: string;
    onentitytype?: string;
    onentitykey?: number;
    linkentitytype?: string;
    linkentitykey?: number;
    tagdesc?: string;
    tagpage?: string;
    tagdate?: string;
    deleteflag?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
}

interface EraClaimsRowData {
    row_id: number;
    ERAFileID: number;
    ClaimID: number;
    ClaimSubmittersIdentifier?: string;
    ClaimStatusCode?: string;
    TotalClaimChargeAmount?: number;
    ClaimPaymentAmount?: number;
    PatientResponsibilityAmount?: number;
    ClaimFilingIndicatorCode?: string;
    PayerClaimControlNumber?: string;
    FacilityTypeCode?: string;
    ClaimFrequencyCode?: string;
    DiagnosisRelatedGroupCode?: string;
    DiagnosisRelatedGroupWeight?: number;
    DischargeFraction?: number;
    RemainingPatientLiabilityAmount?: number;
    PayorEntityIdentifierCode?: string;
    PayorName?: string;
    PayorIdentificationCodeQualifier?: string;
    PayorIdentificationCode?: string;
    paymentid: number;
    payorid: number;
    postedby?: number;
    posteddate: string;
    providername: string;
    TransactionNumber: string;
    TransactionDate: string;
    DeleteFlag?: number;
    transactionmethod: string;
    transactionamount: number;
}

interface CPTRowData {
    claim_id?: number;
    cpt_id?: number;
    cpt_diaginfo?: string;
    dos?: string;
    cptdos?: string;
    patientname?: string;
    facilityname?: string;
    cptdesc?: string;
    mods?: string;
    ClaimStatus?: string;
    queueCode?: string;
    queueDesc?: string;
    queueIndex?: number;
    last_action_time?: string;
    claimwith?: string;
    billedto?: string;
    invPriIns?: string;
    invSecIns?: string;
    EncounterId?: number;
    PatientId?: number;
    InvoiceDt?: string;
    ServiceDt?: string;
    InvoiceAmount?: number;
    inv_payment?: number;
    inv_copay?: number;
    uncoveredAmount?: number;
    inv_ptresp?: number;
    inv_ptpayment?: number;
    inv_ptbalance?: number;
    inv_balance?: number;
    inv_filestatus?: string;
    BilledToId?: number;
    SubmissionId?: number;
    BilledToIdType?: number;
    InvFacilityId?: number;
    inv_netpayment?: number;
    inv_allowedfee?: number;
    invpos?: number;
    assignedTo?: string;
    assignedToId?: number;
    PrimaryInsId?: number;
    SecondaryInsId?: number;
    invcptid?: number;
    itemid?: number;
    code?: string;
    mod1?: string;
    mod2?: string;
    mod3?: string;
    mod4?: string;
    sdos?: string;
    edos?: string;
    tos?: string;
    cptpos?: string;
    cptBilledUnitFee?: number;
    cptUnits?: number;
    cptBilledFee?: number;
    icd1?: string;
    icd2?: string;
    icd3?: string;
    icd4?: string;
    inv_icds?: string;
    cptAllowedFee?: number;
    cptAllowedUnitFee?: number;
    cptDisplayIndex?: number;
    cptBalance?: number;
    billedfee?: number;
    Paid?: number;
    Deduct?: number;
    Coins?: number;
    Memresp?: number;
    Adjustment?: number;
    Adjustment2?: number;
    Withheld?: number;
    CptPaid?: number;
}

interface PatientRowData {
    src: string;
    Name: string;
    lastname: string;
    firstname: string;
    PatientId: number;
    DOB: string;
    Phone1: string;
    Comment: string;
    facility: string;
    encdate: string;
    mdate: string;
    Balance: number;
    RecentDOS: string;
}

interface EOBRowData {
    eobid?: number;
    eobcptid?: number;
    eobinvid?: number;
    eobpmtdtlid?: number;
    eobpaymentid?: number;
    eobAllowed?: number;
    eobdeduct?: number;
    eobcoins?: number;
    eobmemresp?: number;
    eobpaid?: number;
    eobadjustment?: number;
    eobwithheld?: number;
    eobmsgcode?: string;
    eobcalcadjust?: number;
    eobcreatedby?: number;
    eobcreatedat?: string;
    eobmodifiedby?: number;
    eobmodifiedat?: string;
    eobencid?: number;
    pmtdtlinvid?: number;
    pmtdtladjust?: number;
    pmtdtlallowed?: number;
    pmtdtldeduct?: number;
    pmtdtlcoins?: number;
    pmtdtlmemberbalance?: number;
    pmtdtpaid?: number;
    pmtdtlmsgcode?: string;
    pmtdtlcalcadjust?: number;
    pmtdtlcreatedby?: number;
    pmtdtlcreatedat?: string;
    pmtdtlmodifiedby?: number;
    pmtdtlclaiminsid?: number;
    pmtdtlseqno?: number;
    pmtdtleraclaimstatusclp02?: number;
    pmtpayortype?: number;
    pmtpayorid?: number;
    pmtamount?: number;
    pmtcheckno?: string;
    pmtcheckdate?: string;
    pmttype?: string;
    pmtmemo?: string;
    pmtpostedby?: number;
    pmtpostedat?: string;
    pmtmodifiedat?: string;
    pmtfacilityid?: number;
    pmtlock?: number;
    pmtdelflag?: number;
    pmtunpostedamout?: number;
    pmtlockedby?: number;
    pmtcreditcardtype?: number;
    pmtchecktotalamt?: number;
    pmteradetailid?: number;
    pmtencounterid?: number;
}

const ReviewByClaim: React.FC = () => {
    const [claims, setClaims] = useState<ClaimsRowData[]>([]);
    const [filteredClaims, setFilteredClaims] = useState<ClaimsRowData[]>([]);
    const [selectedClaim, setSelectedClaim] = useState<ClaimsRowData | null>(null);
    const [showNonZeroBalance, setShowNonZeroBalance] = useState(true);
    const [patientNameFilter, setPatientNameFilter] = useState<string>('');
    const [selectedPatientId , setSelectedPatientId] = useState<PatientRowData | null>(null);

    const [selectedCptId, setSelectedCptId] = useState<number | null>(null);
    const [cptsByClaim, setCptsByClaim] = useState<CPTRowData[]>([]);
    const [eobsByClaim, setEobsByClaim] = useState<EOBRowData[]>([]);

    const [tags, setTags] = useState<TagsRowData[]>([]);
    const [filteredTags, setFilteredTags] = useState<TagsRowData[]>([]);

    const [eras, setEras] = useState<EraClaimsRowData[]>([]);
    const [filteredEras, setFilteredEras] = useState<EraClaimsRowData[]>([]);

    const [eraClaimsByClaim, setEraClaimsByClaim] = useState<EraClaimsRowData[]>([]);

    const [cpts, setCpts] = useState<CPTRowData[]>([]);
    const [filteredCpts, setFilteredCpts] = useState<CPTRowData[]>([]);

    const [eobs, setEobs] = useState<EOBRowData[]>([]);
    const [filteredEobs, setFilteredEobs] = useState<EOBRowData[]>([]);

    const [currentPageNum, setCurrentPageNum] = useState<number>();
    const [pdfUrl, setPdfUrl] = useState('');
    const [collapse, setCollapse] = useState(false);
    const [showPdfViewer, setShowPdfViewer] = useState(false);
    const [showTags, setShowTags] = useState(true);
    const [showEras, setShowEras] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const [showUnmatchedOnly, setShowUnmatchedOnly] = useState(true);

    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);

    const gridClaimsRef = useRef<any>(null);
    const gridTagsRef = useRef<any>(null);
    const gridErasRef = useRef<any>(null);
    const gridCptsRef = useRef<any>(null);
    const gridEobsRef = useRef<any>(null);
    const apiURL = apiConfig.REACT_APEX_BASE_API;

    useEffect(() => {
        fetchClaims();
        fetchTags();
        fetchEras();
        fetchCpts();
        fetchEobs();
    }, []);

    useEffect(() => {
        applyFilterToGrids();
    }, [showUnmatchedOnly, claims, tags, eras, cpts, eobs]);

    const applyAdditionalFilters = () => {
        let filteredData = claims;

        if (showNonZeroBalance) {
            filteredData = filteredData.filter(claim => claim.inv_balance !== 0);
        }

        if (patientNameFilter.trim() !== '') {
            filteredData = filteredData.filter(claim =>
                claim.patientname?.toLowerCase().includes(patientNameFilter.trim().toLowerCase())
            );
        }

        if (selectedPatientId) {
            filteredData = filteredData.filter(claim =>
                claim.PatientId === selectedPatientId.PatientId
            );
        }

        setFilteredClaims(filteredData);
    };

    useEffect(() => {
        applyAdditionalFilters();
    }, [showNonZeroBalance, patientNameFilter, selectedPatientId, claims]);

    useEffect(() => {
        if (selectedClaim) {
            fetchCptsByClaim(selectedClaim.claim_id);
            fetchEobsByClaim(selectedClaim.claim_id);
            fetchEraClaimsByClaim(selectedClaim.claim_id);
        }
    }, [selectedClaim]);

    const fetchClaims = async () => {
        const sqlText = `SELECT * FROM rc.ecw_claims ORDER BY EncounterId DESC`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setClaims(gridData);
                setFilteredClaims(gridData);
            }
        } catch (error) {
            console.error('Error fetching claims:', error);
        }
    };

    const fetchTags = async () => {
        const sqlText = `SELECT apex.dbo.ecwdocumentpath(onentitykey) as docpath, * FROM rc.apex_tags WHERE linkentitytype = 'claim' ORDER BY tagid DESC`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setTags(gridData);
                setFilteredTags(gridData);
            }
        } catch (error) {
            console.error('Error fetching tags:', error);
        }
    };

    const fetchEras = async () => {
        const sqlText = `SELECT * FROM rc.ecw_eraclaims ORDER BY erafileid DESC`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setEras(gridData);
                setFilteredEras(gridData);
            }
        } catch (error) {
            console.error('Error fetching ERAs:', error);
        }
    };

    const fetchEraClaimsByClaim = async (claimId: number) => {
        const sqlText = `SELECT * FROM rc.ecw_eraclaims WHERE ClaimID = ${claimId} ORDER BY row_id DESC`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setEraClaimsByClaim(gridData);
            }
        } catch (error) {
            console.error('Error fetching EraClaims by claim:', error);
        }
    };

    const fetchCptsByClaim = async (claimId: number) => {
        const sqlText = `SELECT * FROM rc.ecw_cpts WHERE claim_id = ${claimId} ORDER BY claim_id DESC`;

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }

        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setCptsByClaim(gridData);
            }
        } catch (error) {
            console.error('Error fetching CPTs by claim:', error);
        }
    };

    const fetchEobsByClaim = async (claimId: number) => {
        const sqlText = `SELECT * FROM rc.ecw_eobs WHERE eobinvid = ${claimId} ORDER BY eobid DESC`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setEobsByClaim(gridData);
                setFilteredEobs(selectedCptId ? gridData.filter((eob: { eobcptid: number; }) => eob.eobcptid === selectedCptId) : gridData);
            }
        } catch (error) {
            console.error('Error fetching EOBs by claim:', error);
        }
    };

    const fetchCpts = async () => {
        const sqlText = `SELECT * FROM rc.ecw_cpts ORDER BY claim_id DESC`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setCpts(gridData);
                setFilteredCpts(gridData);
            }
        } catch (error) {
            console.error('Error fetching CPTs:', error);
        }
    };

    const fetchEobs = async () => {
        const sqlText = `SELECT * FROM rc.ecw_eob ORDER BY eobid DESC`;
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found. User must log in.");
            return;
        }

        let authHeader =  {
            "Authorization": `Bearer ${token}`,  // ✅ Attach token
            "Content-Type": "application/json"
        }
        const urlPrefix = `${apiConfig.REACT_APEX_BASE_API}/exsql?dbserver=apex&sqltype=customSQL&sqltext=`;

        try {
            const response = await axios.get(`${urlPrefix}${sqlText}`, { headers: authHeader });

            if (response.data) {
                const dframe: string = response.data.frame0;
                const myObj = JSON.parse(dframe);
                const gridData = myObj.rows;
                setEobs(gridData);
                setFilteredEobs(gridData);
            }
        } catch (error) {
            console.error('Error fetching EOBs:', error);
        }
    };

    const handleClaimCPtSelectionChange = async (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        const selectedData = selectedNode ? selectedNode.data : null;

        if (selectedData) {
            setSelectedCptId(selectedData.cpt_id);
            setFilteredEobs(eobsByClaim.filter(eob => eob.eobcptid === selectedData.cpt_id));
        } else {
            setSelectedCptId(null);
            setFilteredEobs(eobsByClaim);
        }
    };

    const handleClaimSelectionChange = async (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        const selectedData = selectedNode ? selectedNode.data : null;
        setSelectedClaim(selectedData);
        if (selectedData) {
            setShowPdfViewer(false);
            filterOtherGrids(selectedData);
            await fetchCptsByClaim(selectedData.claim_id);
            await fetchEobsByClaim(selectedData.claim_id);
            await fetchEraClaimsByClaim(selectedData.claim_id);
        } else {
            // Clear the filtered data if no claim is selected
            setFilteredTags(tags);
            setFilteredEras(eras);
            setCptsByClaim([]);
            setEobsByClaim([]);
            setEraClaimsByClaim([]);
        }
    };

    const handleTagSelectionChange = (params: any) => {
        const selectedNode = params.api.getSelectedNodes()[0];
        const selectedTag = selectedNode ? selectedNode.data : null;

        if (selectedTag && selectedTag.tagpage && selectedTag.docpath) {
            ShowDocument(Number(selectedTag.tagpage), selectedTag.docpath);
            setShowPdfViewer(true);
        }
    };

    const filterOtherGrids = (claim: ClaimsRowData) => {
        setFilteredTags(tags.filter(tag => tag.linkentitykey === claim.claim_id));
        setFilteredEras(eras.filter(era => era.ClaimID === claim.claim_id));
        setFilteredCpts(cpts.filter(cpt => cpt.claim_id === claim.EncounterId));
        setFilteredEobs(eobs.filter(eob => eob.eobinvid === claim.EncounterId));
    };

    const clearOtherGrids = () => {
        setFilteredTags(tags);
        setFilteredEras(eras);
        setFilteredCpts(cpts);
        setFilteredEobs(eobs);
    };

    const handleCriteriaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        applyFilter(event.target.value);
    };

    const applyFilter = (criteria: string) => {
        // Apply criteria filter
    };

    const handleSelectAll = () => {
        gridClaimsRef.current.api.selectAll();
    };

    const handleSelectNone = () => {
        gridClaimsRef.current.api.deselectAll();
    };

    const onSetCurrentPageNum = (pageNum: number) => {
        setCurrentPageNum(pageNum);
    };

    const onSetpdfToDisplayURL = (args: React.SetStateAction<string>) => {
        setPdfUrl(args);
    };

    const ShowDocument = (pagenum: number, docpath: string) => {
        handleAgDocument({
            filename: docpath,
            dirpath: '',
            startpage: pagenum
        }, apiURL, onSetpdfToDisplayURL, setCurrentPageNum);
    };

    const dateFormatter = (params: { value: string }) => {
        const date = new Date(params.value);
        return date.toISOString().split('T')[0];
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        if (name === 'showTags') {
            setShowTags(checked);
        } else if (name === 'showEras') {
            setShowEras(checked);
        } else if (name === 'showAll') {
            setShowAll(checked);
            if (checked) {
                setShowTags(true);
                setShowEras(true);
            } else {
                setShowTags(false);
                setShowEras(false);
            }
        }
    };

    const applyFilterToGrids = () => {
        // if (showUnmatchedOnly) {
        //     setFilteredClaims(claims.filter(claim => !claim.assignedTo));
        //     setFilteredTags(tags.filter(tag => !tag.linkentitykey));
        //     setFilteredEras(eras.filter(era => !era.eradid));
        //     setFilteredCpts(cpts.filter(cpt => !cpt.cpt_id));
        //     setFilteredEobs(eobs.filter(eob => !eob.eobid));
        // } else {
        //     setFilteredClaims(claims);
        //     setFilteredTags(tags);
        //     setFilteredEras(eras);
        //     setFilteredCpts(cpts);
        //     setFilteredEobs(eobs);
        // }
    };

    const refreshData = () => {
        fetchClaims();
        fetchTags();
        fetchEras();
        fetchCpts();
        fetchEobs();
    };

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const getPaymentERALinkforCheck = (params: any) => {
        const selectedNode = gridErasRef.current.api.getSelectedNodes()[0];
        console.log("Selected ERA node: ", selectedNode);
        if (selectedNode.data.TransactionNumber) {
            return (`https://emr.apexheartcare.com/mobiledoc/jsp/edi/x12utils/ViewEraFile_Encode.jsp?TrUserId=9121&CheckNo=${selectedNode.data.TransactionNumber}&DetailId=0&PaymentId=${selectedNode.data.paymentid}&InvoiceId=${selectedNode.data.ClaimID}`);
        }
    }

    const handleViewCheckERA = (params: any) => {

        let url = getPaymentERALinkforCheck(params);
        console.log("Trying to view the check ER:", params, url);
        url && window.open(url, "_ecwPaymentViewTab");
    }

    const handlePatientSelection = (patient: any) => {
        setSelectedPatientId(patient ? patient : null);
    };

    const clearSelectedPatient = () => {
        setSelectedPatientId(null);
    };

    return (
        <SplitPanel split="vertical">
            <>
                <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                    <Button onClick={refreshData}>Refresh</Button>
                    <Button onClick={() => setCollapse(!collapse)}>
                        {collapse ? 'Hide Criteria' : 'Show Criteria'}
                    </Button>
                    <Box>{selectedClaim ? selectedClaim.EncounterId : 'No claim selected'}</Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showPdfViewer}
                                onChange={() => setShowPdfViewer(!showPdfViewer)}
                                name="showPdfViewer"
                                color="primary"
                            />
                        }
                        label="Show PDF Viewer"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showUnmatchedOnly}
                                onChange={() => setShowUnmatchedOnly(!showUnmatchedOnly)}
                                name="showUnmatchedOnly"
                                color="primary"
                            />
                        }
                        label="Show Unmatched Only"
                    />
                    <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2} padding={0}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={showAll} onChange={handleCheckboxChange} name="showAll" />}
                                label="Show All"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showTags} onChange={handleCheckboxChange} name="showTags" />}
                                label="Tags"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showEras} onChange={handleCheckboxChange} name="showEras" />}
                                label="ERAs"
                            />
                        </FormGroup>
                    </Box>

                    <TextField label="Quick Filter" onChange={(event: React.ChangeEvent<HTMLInputElement>) => gridClaimsRef.current.api.setQuickFilter(event.target.value)} />
                </Box>
                <Collapse in={collapse}>
                    <Box display={"flex"} flexDirection={"row"} alignItems="center" gap={2}>
                        <FormLabel>Criteria</FormLabel>
                        <TextField
                            label="Start Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={startDate || ''}
                            onChange={handleStartDateChange}
                        />
                        <TextField
                            label="End Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={endDate || ''}
                            onChange={handleEndDateChange}
                        />
                        <Button onClick={() => { setStartDate(null); setEndDate(null); }}>Reset Date Filter</Button>
                        <Button onClick={handleSelectAll}>Select All</Button>
                        <Button onClick={handleSelectNone}>Select None</Button>
                    </Box>
                </Collapse>
                <SplitPanel split={'horizontal'}>
                    <Box style={{ flex: 1, height: '100%', overflowY: 'auto' }}>
                        <AppBar position="static" style={{ marginBottom: 8 }}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                                <Box>Claims</Box>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={showNonZeroBalance}
                                            onChange={() => setShowNonZeroBalance(!showNonZeroBalance)}
                                            color="primary"
                                        />
                                    }
                                    label=">0 only"
                                />
                                <SelectEcwPatient selectedEcwPatientID={selectedPatientId} setSelectedEcwPatient={handlePatientSelection} />
                                {selectedPatientId && (
                                    <IconButton onClick={clearSelectedPatient}>
                                        <ClearIcon />
                                    </IconButton>
                                )}
                                <TextField
                                    label="Filter by Patient Name"
                                    type="text"
                                    value={patientNameFilter}
                                    onChange={(e) => setPatientNameFilter(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: patientNameFilter && (
                                            <IconButton
                                                onClick={() => setPatientNameFilter('')}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        ),
                                        style: { height: '32px' } // Adjust the height as needed
                                    }}
                                />
                                <IconButton edge="end" color="inherit" onClick={fetchClaims}>
                                    <RefreshIcon />
                                </IconButton>
                            </Box>
                        </AppBar>
                        <div className="ag-theme-alpine" style={{ height: '30%' }}>
                            <AgGridReact
                                ref={gridClaimsRef}
                                rowData={filteredClaims}
                                rowSelection="single"
                                defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                onSelectionChanged={handleClaimSelectionChange}
                                columnDefs={[
                                    { headerName: 'Claim', field: 'claim_id', checkboxSelection: true, width: 110 },
                                    { headerName: 'Patient Name', field: 'patientname', width: 150, tooltipField: 'patientname' },
                                    { headerName: 'Facility', field: 'facilityname', width: 100 },
                                    { headerName: 'Billed To', field: 'billedto', width: 150, tooltipField: 'billedto' },
                                    { headerName: 'Claim Status', field: 'ClaimStatus', width: 100, tooltipField: 'ClaimStatus' },
                                    { headerName: 'Invoice Date', field: 'InvoiceDt', width: 130, valueFormatter: dateFormatter },
                                    { headerName: 'Service Date', field: 'ServiceDt', width: 130, valueFormatter: dateFormatter },
                                    { headerName: 'Invoice Amount', field: 'InvoiceAmount', width: 120 },
                                    { headerName: 'Balance', field: 'inv_balance', width: 120 },
                                    { headerName: 'Assigned To', field: 'assignedTo', width: 150 },
                                    { headerName: 'Primary Insurance', field: 'invPriIns', width: 150 },
                                    { headerName: 'Secondary Insurance', field: 'invSecIns', width: 150 },
                                ]}
                                gridOptions={{
                                    suppressRowHoverHighlight: true,
                                    suppressColumnVirtualisation: true,
                                    rowHeight: 25,
                                    headerHeight: 20
                                }}
                            />
                        </div>

                        {showTags && (
                            <>
                                <AppBar position="static" style={{ paddingLeft: 5, paddingRight: 5, marginLeft: 1, marginTop: 1 }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" p={0}>
                                        <Box>Tags</Box>
                                        <IconButton edge="end" color="inherit" onClick={fetchTags}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Box>
                                </AppBar>
                                <div className="ag-theme-alpine" style={{ height: '15%' }}>
                                    <AgGridReact
                                        ref={gridTagsRef}
                                        rowData={filteredTags}
                                        rowSelection="single"
                                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                        onSelectionChanged={handleTagSelectionChange}
                                        columnDefs={[
                                            { headerName: 'Tag', field: 'tag', width: 150 },
                                            { headerName: 'Description', field: 'tagdesc', width: 300 },
                                            { headerName: 'linkid', field: 'linkentitykey', width: 100 },
                                            { headerName: 'link', field: 'linkentitytype', width: 50 },
                                            { headerName: 'Page', field: 'tagpage', width: 150 },
                                            { headerName: 'Date', field: 'tagdate', width: 150, valueFormatter: dateFormatter },
                                        ]}
                                        gridOptions={{
                                            suppressRowHoverHighlight: true,
                                            suppressColumnVirtualisation: true,
                                            rowHeight: 25,
                                            headerHeight: 20
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {showEras && (
                            <>
                                <AppBar position="static" style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 1, marginTop: 1 }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" p={0}>
                                        <Box>ERAs</Box>
                                        <IconButton edge="end" color="inherit" onClick={() => fetchEraClaimsByClaim(selectedClaim ? selectedClaim.claim_id : 0)}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Box>
                                </AppBar>
                                <div className="ag-theme-alpine" style={{ height: '15%' }}>
                                    <AgGridReact
                                        ref={gridErasRef}
                                        rowData={eraClaimsByClaim}
                                        rowSelection="single"
                                        defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                        columnDefs={[
                                            {
                                                headerName: 'Num',
                                                width: 70,
                                                cellRenderer: (params: any) => (
                                                    <>
                                                        <Button onClick={() => handleViewCheckERA(params.data)} style={{backgroundColor:'gray'}}>View</Button>
                                                    </>
                                                ),
                                            },
                                            { headerName: 'ERA File ID', field: 'ERAFileID', width: 150 },
                                            { headerName: 'Claim ID', field: 'ClaimID', width: 150 },
                                            { headerName: 'Submitter ID', field: 'ClaimSubmittersIdentifier', width: 150 },
                                            { headerName: 'Status Code', field: 'ClaimStatusCode', width: 150 },
                                            { headerName: 'Total Charge', field: 'TotalClaimChargeAmount', width: 150 },
                                            { headerName: 'Payment Amount', field: 'ClaimPaymentAmount', width: 150 },
                                            { headerName: 'Patient Responsibility', field: 'PatientResponsibilityAmount', width: 150 },
                                            { headerName: 'Payor Name', field: 'PayorName', width: 150 },
                                            { headerName: 'Payor ID', field: 'PayorIdentificationCode', width: 150 },
                                        ]}
                                        gridOptions={{
                                            suppressRowHoverHighlight: true,
                                            suppressColumnVirtualisation: true,
                                            rowHeight: 25,
                                            headerHeight: 20
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </Box>
                    {showPdfViewer ? (
                        <div style={{ height: '100%' }}>
                            <Box>
                                <FormLabel>{selectedClaim && selectedClaim.claimwith}</FormLabel>
                                <ApexPdfjsViewer pdfUrl={pdfUrl} startingPageNum={currentPageNum} setCurrentPageNum={onSetCurrentPageNum} />
                            </Box>
                        </div>
                    ) : selectedClaim ? (
                        <Box style={{ flex: 1, height: '100%', overflowY: 'auto' }}>
                            <AppBar position="static" style={{ marginBottom: 8 }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                                    <Box>CPTs</Box>
                                    <IconButton edge="end" color="inherit" onClick={() => fetchCptsByClaim(selectedClaim.claim_id)}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                            </AppBar>
                            <div className="ag-theme-alpine" style={{ height: '30%' }}>
                                <AgGridReact
                                    ref={gridCptsRef}
                                    rowData={cptsByClaim}
                                    rowSelection="single"
                                    defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                    onSelectionChanged={handleClaimCPtSelectionChange}
                                    columnDefs={[
                                        { headerName: 'CptID', field: 'cpt_id', checkboxSelection: true, width: 120 },
                                        { headerName: 'CPT Desc', field: 'cptdesc', width: 200 },
                                        { headerName: 'Service Date', field: 'dos', width: 150, valueFormatter: dateFormatter },
                                        { headerName: 'Paid', field: 'Paid', width: 150 },
                                        { headerName: 'Balance', field: 'cptBalance', width: 150 },
                                        { headerName: 'Invoice Amount', field: 'InvoiceAmount', width: 150 },
                                        { headerName: 'Allowed Fee', field: 'cptAllowedFee', width: 150 },

                                    ]}
                                    gridOptions={{
                                        suppressRowHoverHighlight: true,
                                        suppressColumnVirtualisation: true,
                                        rowHeight: 25,
                                        headerHeight: 20
                                    }}
                                />
                            </div>
                            <AppBar position="static" style={{ marginBottom: 1, marginTop: 1 }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
                                    <Box>EOBs</Box>
                                    <IconButton edge="end" color="inherit" onClick={() => fetchEobsByClaim(selectedClaim.claim_id)}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                            </AppBar>
                            <div className="ag-theme-alpine" style={{ height: '30%' }}>
                                <AgGridReact
                                    ref={gridEobsRef}
                                    rowData={filteredEobs}
                                    rowSelection="single"
                                    defaultColDef={{ sortable: true, filter: true, resizable: true }}
                                    columnDefs={[
                                        { headerName: 'CptID', field: 'eobcptid', width: 100 },
                                        { headerName: 'EOB ID', field: 'eobid', width: 100 },
                                        { headerName: 'Paid Amount', field: 'eobpaid', width: 150 },
                                        { headerName: 'Deductible', field: 'eobdeduct', width: 150 },
                                        { headerName: 'Coinsurance', field: 'eobcoins', width: 150 },
                                        { headerName: 'Allowed Amount', field: 'eobAllowed', width: 150 },
                                    ]}
                                    gridOptions={{
                                        suppressRowHoverHighlight: true,
                                        suppressColumnVirtualisation: true,
                                        rowHeight: 25,
                                        headerHeight: 20
                                    }}
                                />
                            </div>
                        </Box>
                    ) : null}
                </SplitPanel>
            </>
        </SplitPanel>
    );
};

export default ReviewByClaim;
