


import { jwtDecode } from "jwt-decode";
import apiConfig from '../../apiConfig';


export const DATA_DEPOSIT_DOCS = 'data_deposit_documents';
export const DATA_PATIENT_LIST = 'data_pat_list';
export const DATA_PATIENT_FULL_LIST = 'DATA_PATIENT_FULL_LIST';
export const DATA_PATIENT_LIST_BY_IDS = 'data_pat_list_from_ids'
export const DATA_DEPOSIT_DETAIL = 'data_deposit_detail';
export const DATA_APEX_CLAIMS = 'apex_claims_data';
export const DATA_APEX_EOB_DOCS = 'apex_eob_docs';
export const DATA_APEX_BL_DOCS = 'apex_bl_docs';
export const DATA_APEX_PT_ENC = 'apex_pt_enc_list';
export const DATA_APEX_PT_ENC_CLAIMS = 'apex_pt_enc_claims_list';
export const DATA_APEX_PT_LINQ = 'apex_pt_linq_docs';
export const DATA_APEX_PT_DOCS = 'apex_pt_docs';
export const DATA_PATIENT_MONITOR_LIST = 'apex_monitor_list';
export const DATA_APEX_PASS_ENTRIES = 'apex_pass_entries';

export const DATA_CLAIM_LIST = 'data_claim_list';
export const DATA_CLAIM_LIST_BY_IDS = 'data_claim_list_from_ids'

export const DATA_APEX_BILLING_RECENT100_CLAIMS = 'DATA_APEX_BILLING_RECENT100_CLAIMS';
export const DATA_PATIENT_ROW = 'DATA_PATIENT_ROW' ;

export const loadGridData = (gridName, args, recvfn) => {


  let apiURL=apiConfig.REACT_APEX_BASE_API;

  const token = localStorage.getItem("token");
  if (!token) {
    console.error("No token found. User must log in.");
    return;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

    if (decodedToken.exp < currentTime) {
      console.error("Token has expired. Logging out...");
      localStorage.removeItem("token");
      // Redirect to login page or show a login prompt
      return;
    }

    console.log("Token is valid. Proceeding...");
  } catch (error) {
    console.error("Invalid token format. Logging out...");
    localStorage.removeItem("token");
    return;
  }

  let authHeader =  {
        "Authorization": `Bearer ${token}`,  // ✅ Attach token
        "Content-Type": "application/json"
  }
  // let authHeader = {};
  //
  // const authH = JSON.parse(localStorage.getItem('authHeader'));
  // if (authH) {
  //   authHeader =  authH;
  // }


  console.log('Retrieve Data for :', gridName, authHeader);
  let urlPrefix = apiURL + '/exsql?dbserver=';
  let dataURL = '';

  
  if (gridName === DATA_APEX_PASS_ENTRIES ) {

    let jsonDataURL = apiURL + "/getfolders" ;


  fetch(jsonDataURL, authHeader)
    .then((response) => {
      if (response.status !== 200) {
        recvfn(gridName, args, {
          placeholder: 'Something went wrong in getting data',
        });
      } else {
        return response.json();
      }
    })
    .then((data) => {
      console.log(data);
      let gridData = data;
      console.log(
        'Sending data to receving fn from URL:',
        jsonDataURL,
        gridName,
        args
      );
      recvfn(gridName, args, gridData);
    });

    return;
  }



  switch (gridName) {


case DATA_PATIENT_ROW :

  console.log('Retrieve Data for patient :', args);
  dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select * from rc.billing_all_patients where patientid = ${args.patid }  `;

  break;

case DATA_APEX_BILLING_RECENT100_CLAIMS :

console.log(DATA_APEX_BILLING_RECENT100_CLAIMS, args);

if (args.patientid > 0 ) {
  dataURL= `apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.billing_claims
  where 1=1
  and patientid = ${args.patientid } 
  order by invoiceid desc `;
}else if (args.sdos != undefined) {
  dataURL= `apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.billing_claims
  where 1=1
  and sdos = cast ('${args.sdos}' as date) 
  order by facility desc, sdos, patientname  `;

}else {

  dataURL= `apex&sqltype=customSQL&sqltext=set rowcount 0 select top 100 * from apex.rc.billing_claims
  where 1=1
  order by invoiceid desc `;
}

  break;
    case DATA_APEX_EOB_DOCS:
      dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 exec apex..apexsp_getEOBDocuments  ";
      break;
    case DATA_APEX_BL_DOCS:
      dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 exec apex..apexsp_getBillingDocuments  ";
      break;
    case DATA_APEX_CLAIMS:
      dataURL = "apex&sqltype=customSQL&sqltext=set rowcount 0 select  PatientId, patientname, sdos, docname, cptcode, cptdesc, cptcat, cptsubcat, facility, pinsname,  edos ,claimdate from apex.rc.fn_ApexBillingData_2002( null, null,  '2/01/2021', '3/12/2021', null) ";
      break;

    case DATA_APEX_PT_LINQ:
      console.log('Retrieve PT LINQ DOCS :', gridName, args);
        dataURL = `apex&sqltype=customSQL&sqltext=set%20rowcount%20100%20  select doc.docID, doc.customName , doc.scanDate, doc.ScannedBy, doc.Review, doc.ReviewerId, doc.ReviewerName , doc.delFlag,  doc.PatientId, apex.dbo.PatientName(doc.patientid) as PatientName, doc.dirpath, doc.fileName from mobiledoc..document doc where doc.patientid =  ${args.patient_id } and doc.doc_Type in  (107,114, 115,116,117,119,121,152,154,157,174) and delflag = 0 order by customName desc `;
        break;

    case DATA_APEX_PT_DOCS:
            console.log('Retrieve PT  DOCS :', gridName, args);
              dataURL = `apex&sqltype=customSQL&sqltext=set%20rowcount%20100%20  select doc.docID, doc.customName , doc.scanDate, doc.ScannedBy, doc.Review, doc.ReviewerId, doc.ReviewerName , doc.delFlag,  doc.PatientId, apex.dbo.PatientName(doc.patientid) as PatientName, doc.dirpath, doc.fileName from mobiledoc..document doc where doc.patientid =  ${args.patient_id }  and delflag = 0 order by docID desc `;
              break;        

    case DATA_PATIENT_MONITOR_LIST :

      dataURL = `apex&sqltype=customSQL&sqltext=set%20rowcount%20100%20  select PatientId, PatientName,
        recentdoc = (select max(scandate) from  mobiledoc..document doc where doc.patientid = monit.patientid and doc.doc_Type in  (107, 114, 115,116,117,119,121,154,157,174) and delflag = 0
             )
        from apex.rc.Recent_Monitors monit
        order by 3 desc `;

    break;

    case DATA_APEX_PT_ENC :
      console.log('Retrieve PT ENC :', gridName, args);
      dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0   select convert(varchar(10),date,121) as date, convert(varchar(10),dateadd(dd, 32, date),121) as next_LINQdt, convert(varchar(10),dateadd(dd, 92, date),121) asnext_PMdt, reason, VisitType, STATUS, enclock,  InvoiceId  from mobiledoc..enc e0 where e0.patientID = ${args.patient_id }  and VisitType = 'NV' and deleteflag = 0 order by date desc `;
      break;

    case DATA_APEX_PT_ENC_CLAIMS :
      console.log('Retrieve PT ENC CLAIMS :', gridName, args);

      dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select invcptid, convert(varchar(10),claimdate,121) as claimdate, billedfee, Cpt_Allowed, inspaid, patpaid, insbal, patbal, PatientName, cptcode, cptdesc, pinsname from apex.rc.Apex_Invoice_Cpt_Summary where InvoiceId = ${args.invoice_id } `;
      break;

      // case DATA_APEX_PASS_ENTRIES :
      //   console.log('Retrieve PASS ENTRIES :', gridName, args);

      //   dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from apex..pps_entries where 1 = 1 order by modified desc  `;
      //   break;


      case DATA_APEX_PASS_ENTRIES :
        console.log('Retrieve PASS ENTRIES :', gridName, args);

        dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0  select * from apex..pps_entries where 1 = 1 order by modified desc  `;
        break;


        case DATA_PATIENT_FULL_LIST:
          console.log('Retrieve Data for2 :', gridName);
          dataURL = 'apex&sqltype=customSQL&sqltext=set rowcount 0 select * from rc.billing_all_patients order by PatientId desc ';
          break;

    case DATA_PATIENT_LIST:
      console.log('Retrieve Data for2 :', gridName);
      dataURL = 'apex&sqltype=customSQL&sqltext=set rowcount 0 select  Name, PatientId, pat.DOB, pat.Phone1, mdate, Balance, RecentDOS from apex.rc.vPatient pat order by Name ';
      break;

      case DATA_CLAIM_LIST_BY_IDS:
      case DATA_CLAIM_LIST:
        console.log('Retrieve Data for2 :', gridName);

        dataURL= `apex&sqltype=customSQL&sqltext=set rowcount 0 select * from apex.rc.billing_all_claims where servicedt > dateadd(yy, -2, getdate()) order by servicedt desc`;
        break;


    case DATA_PATIENT_LIST_BY_IDS:
        console.log('Retrieve Data for2 :', gridName);
        dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0 select  Name, PatientId, pat.DOB, pat.Phone1 ,   Balance from apex.rc.vPatient pat where patientid in ( ${args.patient_ids } ) order by Name `;
        break;
    case DATA_DEPOSIT_DOCS:

      dataURL = `apex&sqltype=customSQL&sqltext=set rowcount 0
       select adoc.docid, adoc.filename, adoc.customname as adoc_customname, mdoc.filename as customname, mdoc.dirpath as dirpath, account,
      check_amount = (select sum(docdtl.checkamount) from apex..apex_documentdetail docdtl where adoc.docid = docdtl.docID and docdtl.checkamount> 0),
      card_amount = (select sum(docdtl.cardamount) from apex..apex_documentdetail docdtl where adoc.docid = docdtl.docID and docdtl.cardamount> 0), adoc.dirpath as adoc_dirpath, adoc.foldername, depositbatchid
      from apex..apex_document adoc left join mobiledoc..document mdoc on mdoc.customName = replace(adoc.customname, '.pdf', '')
	  where adoc.foldername in ( 'EOB', 'deposit') order by adoc.docID desc `;

    // select docid, filename, customname,
    //   check_amount = (select sum(docdtl.checkamount) from apex..apex_documentdetail docdtl where adoc.docid = docdtl.docID and docdtl.checkamount> 0),
    //   card_amount = (select sum(docdtl.cardamount) from apex..apex_documentdetail docdtl where adoc.docid = docdtl.docID and docdtl.cardamount> 0), dirpath, foldername, depositbatchid
    //   from apex..apex_document adoc where foldername = 'EOB' order by adoc.docID desc `;
      break;
    case DATA_DEPOSIT_DETAIL:
      dataURL =
      `apex&sqltype=customSQL&sqltext=select
      docID ,
      docdetailID,
      pageno ,
     endpageno,
      detailType,
      checkamount,
      cardamount,
      otheramount ,
      checkdate ,
      checknumber ,
      PatientId,
        PatientName,
      DOS_start ,
      DOS_end ,
      cptcode ,
      cptunits ,
      encId ,
      invoiceid ,
      invcptid ,
      delFlag,
      insuranceid ,
      notes,
     pmtrow,
     pmtprocessed,
     pmtprocesseddt,
     pmtreceipt
     from apex..apex_documentdetail where docid = (select apexdoc.docid from apex..apex_document apexdoc where ecwdocid =  ${args.document_id})`;


      break;
    
    default:
  }

  console.log('Getting data  (Calling) from URL:', authHeader, gridName, urlPrefix + dataURL);


  fetch(urlPrefix + dataURL, { headers: authHeader })
    .then((response) => {
      if (response.status !== 200) {
        recvfn(gridName, args, {
          placeholder: 'Something went wrong in getting data',
        });
      } else {
        return response.json();
      }
    })
    .then((data) => {
      console.log(data);
      let dframe = data['frame0'];
      console.log(dframe);
      let myObj = JSON.parse(dframe);
      console.log(myObj);
      let gridData = myObj['rows'];
      console.log(
        'Sending data to receving fn from URL:',
        urlPrefix + dataURL,
        gridName,
        args
      );
      recvfn(gridName, args, gridData);
    });
};






